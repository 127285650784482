// from https://github.com/ianstormtaylor/slate/blob/master/examples/markdown-preview/index.js

import { Editor } from 'slate-react';
import Plain from 'slate-plain-serializer';
import { confirmAlert } from 'react-confirm-alert';

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ButtonCustom from '../ButtonCustom';

import Prism from './prism-markdown';
import { uploadMarkdownImage } from '../../../Business/actions/MarkdownPreviewActions';

import './markdown.scss';
import Icon from './icons/Icon';

/**
 * Define a decorator for markdown styles.
 *
 * @param {Node} node
 * @param {Function} next
 * @return {Array}
 */
function decorateNode(node, editor, next) {
  const others = next() || [];
  if (node.object !== 'block') return others;

  const string = node.text;
  const texts = node.getTexts().toArray();
  const grammar = Prism.languages.markdown;
  const tokens = Prism.tokenize(string, grammar);
  const decorations = [];
  let startText = texts.shift();
  let endText = startText;
  let startOffset = 0;
  let endOffset = 0;
  let start = 0;

  function getLength(token) {
    if (typeof token === 'string') {
      return token.length;
    }
    if (typeof token.content === 'string') {
      return token.content.length;
    }
    return token.content.reduce((l, t) => l + getLength(t), 0);
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const token of tokens) {
    startText = endText;
    startOffset = endOffset;

    const length = getLength(token);
    const end = start + length;

    let available = startText.text.length - startOffset;
    let remaining = length;

    endOffset = startOffset + remaining;

    while (available < remaining) {
      endText = texts.shift();
      remaining = length - available;
      available = endText.text.length;
      endOffset = remaining;
    }

    if (typeof token !== 'string') {
      const dec = {
        anchor: {
          key: startText.key,
          offset: startOffset
        },
        focus: {
          key: endText.key,
          offset: endOffset
        },
        mark: {
          type: token.type
        }
      };

      decorations.push(dec);
    }

    start = end;
  }

  return [...others, ...decorations];
}

function insertLoadingImage(editor, src, target) {
  if (target) {
    editor.select(target);
  }

  editor.insertBlock({
    type: 'image:fromSource:temp',
    data: { src }
  });
}

function insertImageCommand(editor, src, target) {
  if (target) {
    editor.select(target);
  }

  editor.insertText(`![](${src})`);
}

/**
 * The markdown preview example.
 *
 * @type {Component}
 */
class MarkdownPreview extends React.Component {
  /* eslint-disable react/forbid-prop-types */
  static propTypes = {
    style: PropTypes.object,
    readOnly: PropTypes.bool,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
    name: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    dispatch: PropTypes.func.isRequired
  };
  /* eslint-enable */

  static defaultProps = {
    style: {
      minHeight: '10em',
      backgroundColor: window
        .getComputedStyle(document.body)
        .getPropertyValue('--light'),
      padding: '1em'
    },
    readOnly: false,
    className: '',
    name: 'description',
    minLength: 20,
    maxLength: undefined,
    onChange() {}
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      editorId: Math.random()
        .toString(32)
        .slice(2)
    };
    this.textareaReference = React.createRef();
  }

  displayHelp = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <div
          className="modal-content"
          style={{
            margin: 'auto',
            padding: '1em',
            maxWidth: 'calc(100% - 1rem)'
          }}>
          <div>
            <ButtonCustom
              className="close"
              onClick={onClose}
              aria-label="Close">
              <span aria-hidden="true">x</span>
            </ButtonCustom>
            <div>
              <p>
                Vous pouvez utiliser les balises Markdown suivantes
                pour mettre en forme ce texte :
              </p>
              <ul>
                <li>
                  Titre de niveau 1 : #, de niveau 2 : ##, de niveau 3
                  : ###
                </li>
                <li>Listes à puce : - ou + </li>
                <li>Listes numérotées : 1. , 2. ou 1), 2)</li>
                <li>Emphase / italique : * ou _ </li>
                <li>Emphase forte : ** ou __ </li>
                <li>Texte barré : ~ </li>
                <li>
                  Liens hypertextes : [sowefund](https://sowefund.com)
                </li>
                <li>ligne de séparation : *** </li>
                <li>Blocs de code ou de contenu brut : ~~~ ou ```</li>
                <li> Code en lignes : `code en ligne` </li>
                <li>Citations / références : ></li>
                <li>
                  {`Tableaux : voir le guide complet : `}
                  <a
                    href="https://help.github.com/articles/organizing-information-with-tables/"
                    rel="noopener noreferrer"
                    target="_blank">
                    https://help.github.com/articles/organizing-information-with-tables/
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )
    });
  };

  /**
   * @param {Editor} editor
   */
  ref = editor => {
    this.editor = editor;
  };

  /**
   * Render a Slate mark.
   *
   * @param {Object} props
   * @param {Editor} editor
   * @param {Function} next
   * @return {Element}
   */
  renderMark = (props, editor, next) => {
    const { children, mark, attributes, text } = props;

    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>;

      case 'code':
        return <code {...attributes}>{children}</code>;

      case 'italic':
        return <em {...attributes}>{children}</em>;

      case 'underlined':
        return <u {...attributes}>{children}</u>;

      case 'title': {
        return (
          <span
            {...attributes}
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
              margin: '20px 0 10px 0',
              display: 'inline-block'
            }}>
            {children}
          </span>
        );
      }

      case 'punctuation': {
        return (
          <span {...attributes} style={{ opacity: 0.2 }}>
            {children}
          </span>
        );
      }

      case 'list': {
        return (
          <span
            {...attributes}
            style={{
              paddingLeft: '10px',
              lineHeight: '10px',
              fontSize: '20px'
            }}>
            {children}
          </span>
        );
      }

      case 'hr': {
        return (
          <span
            {...attributes}
            style={{
              borderBottom: '2px solid #000',
              display: 'block',
              opacity: 0.2
            }}>
            {children}
          </span>
        );
      }

      case 'url':
        return (
          <span
            {...attributes}
            style={{
              textDecoration: 'underline',
              color: window
                .getComputedStyle(document.body)
                .getPropertyValue('--blue')
            }}>
            {children}
          </span>
        );

      case 'image':
        return (
          <span {...attributes}>
            <div
              style={{
                opacity: 0.8,
                fontSize: '0.8em',
                marginBottom: '1em'
              }}>
              {children}
            </div>
            <img
              src={(/!\[.*?\]\((.+)\)/.exec(text) || [null, null])[1]}
              alt={(/!\[(.*?)\]\(.+\)/.exec(text) || [null, null])[1]}
              style={{
                display: 'block',
                maxWidth: '80%',
                opacity: 0.9,
                margin: '0 auto 1em auto'
              }}
            />
          </span>
        );

      default: {
        return next();
      }
    }
  };

  renderNode = (props, editor, next) => {
    const { attributes, node } = props;

    if (node.type !== 'image:fromSource:temp') {
      return next();
    }

    return (
      <span {...attributes}>
        <img
          src={node.data.get('src')}
          className="img--loading"
          alt=""
        />
      </span>
    );
  };

  /**
   * updates the state's value given a change value
   * change {Change}
   */
  onEditorChange = change => {
    const { minLength, maxLength, name } = this.props;
    const textarea = this.textareaReference.current;
    const value = Plain.serialize(change.value);
    this.setState({
      value
    });
    const message = document.getElementById(`validation_${name}`);
    const markdownDiv = document.getElementsByClassName(
      `markdown markdown_${name}`
    )[0];
    if (value.length < minLength || value.length > maxLength) {
      if (textarea) {
        // It seems like onEditorChange runs sometimes before componentDidMout
        // and textarea might be null...
        textarea.setCustomValidity('error');
      }
      message.style.display = 'block';
      if (markdownDiv.classList.contains('border-success')) {
        markdownDiv.classList.remove('border-success');
      }
      if (!markdownDiv.classList.contains('border-danger')) {
        if (!markdownDiv.classList.contains('border')) {
          markdownDiv.classList.add('border');
        }
        markdownDiv.classList.add('border-danger');
      }
    } else {
      if (textarea) {
        textarea.setCustomValidity('');
      }
      message.style.display = 'none';
      if (!markdownDiv.classList.contains('border-success')) {
        if (!markdownDiv.classList.contains('border')) {
          markdownDiv.classList.add('border');
        }
        markdownDiv.classList.add('border-success');
      }
      if (markdownDiv.classList.contains('border-danger')) {
        markdownDiv.classList.remove('border-danger');
      }
    }
  };

  addImage = event => {
    const firstFile = event.target.files[0];

    if (/\.(gif|jp?g|png)/i.test(firstFile.name)) {
      this.editor.command(
        insertLoadingImage,
        window.URL.createObjectURL(firstFile)
      );
      this.uploadImage(firstFile);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  uploadImage = file => {
    const { dispatch } = this.props;
    const { editor } = this;

    dispatch(uploadMarkdownImage(file))
      .then(res => {
        if (!res.ok) {
          editor.delete();
          throw new Error(
            "Une erreur est survenue durant l'upload de l'image"
          );
        }
        return res.json();
      })
      .then(({ url }) => {
        editor.delete().command(insertImageCommand, url);
      })
      .catch((/* error */) => {
        // TODO: should be displayed to user...
        // Defining the custom validity of the textarea did not work.
        // Moreover, it's an error related to one event, not the validity of
        // the textarea. Do we have a way to display errors nicely to the
        // user?
        // `alert(error.message);`
      });
  };

  onClickMark = type => e => {
    const { readOnly } = this.props;
    if (readOnly) {
      return null;
    }
    switch (type) {
      case 'bold':
        this.editor.wrapText('**').focus();
        break;
      case 'italic':
        this.editor.wrapText('_').focus();
        break;
      case 'list':
        this.editor
          .moveToStart()
          .insertText('- ')
          .focus();
        break;
      case 'orderedlist':
        this.editor
          .moveToStart()
          .insertText('1. ')
          .focus();
        break;
      case 'url':
        this.editor
          .insertText(
            `[${window
              .getSelection()
              .toString()}](${window.getSelection().toString()})`
          )
          .moveToStart()
          .focus();
        break;
      case 'help':
        this.displayHelp();
        break;
      default:
        break;
    }
    return null;
  };

  onClickMarkTitle = level => e => {
    const { readOnly } = this.props;
    if (readOnly) {
      return null;
    }
    e.preventDefault();
    this.editor.wrapText(`${'#'.repeat(level)} `, '').focus();
    return null;
  };

  /**
   *
   * Render the example.
   *
   * @return {Component} component
   */
  render() {
    const {
      style,
      readOnly,
      value,
      onChange,
      className,
      name,
      maxLength,
      minLength
    } = this.props;
    const { value: textareaValue, editorId } = this.state;
    return (
      <>
        <textarea
          ref={this.textareaReference}
          className="markdown-textarea"
          name={name}
          value={textareaValue}
          onChange={onChange}
          minLength={minLength}
          maxLength={maxLength}
          style={{ display: 'none' }}
          required
        />
        <div className="toolbar mt-3">
          {['t1', 't2'].map((type, i) => (
            <span
              className="toolbar__btn"
              role="button"
              key={`format-btn-${type}`}
              onClick={this.onClickMarkTitle(Number(type[1]))}
              onKeyDown={this.onClickMarkTitle(Number(type[1]))}
              tabIndex={i + 4}>
              <Icon type={type} />
            </span>
          ))}
          {['bold', 'italic', 'list', 'orderedlist', 'url'].map(
            (type, i) => (
              <span
                className="toolbar__btn"
                role="button"
                key={`format-btn-${type}`}
                onClick={this.onClickMark(type)}
                onKeyDown={this.onClickMark(type)}
                tabIndex={i + 2}>
                <Icon type={type} />
              </span>
            )
          )}
          <span className="toolbar__btn" role="button">
            <label htmlFor={`image-uploader-${editorId}`}>
              <Icon type="image" />
              <input
                style={{ display: 'none' }}
                id={`image-uploader-${editorId}`}
                type="file"
                disabled={readOnly}
                onChange={this.addImage}
              />
            </label>
          </span>
          <span
            className="toolbar__btn"
            role="button"
            key="format-btn-help"
            onClick={this.onClickMark('help')}
            onKeyDown={this.onClickMark('help')}
            tabIndex="1">
            <Icon type="help" />
          </span>
        </div>
        <Editor
          className={className}
          defaultValue={Plain.deserialize(value)}
          placeholder="Write some markdown..."
          renderMark={this.renderMark}
          renderNode={this.renderNode}
          decorateNode={decorateNode}
          style={style}
          readOnly={readOnly}
          onChange={this.onEditorChange}
          ref={this.ref}
        />
      </>
    );
  }
}

/**
 * Export.
 */
export default connect()(MarkdownPreview);
