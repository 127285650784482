export const HIDE_LOADER = 'HIDE_LOADER';

export function hideLoaderAction() {
  return () => {
    const loader = document.getElementById('loader');
    if (!loader) {
      return;
    }
    loader.addEventListener('transitionend', () => {
      loader.remove();
    });

    loader.classList.add('hide');
  };
}
