// @flow

// React libs
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
// Components
import Common from '../../Resources/Common/index';
import Template from '../../Components/Template/TemplateComponent';
import Messages from '../../Resources/Common/Messages';

type Props = {
  match: Object
};

type State = {
  code: number
};

class ErrorScene extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      code: 0
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const code: number = parseInt(match.params.code, 10);
    this.setState({ code });
  }

  render() {
    const { code } = this.state;

    return (
      <Template
        title={
          code === 500
            ? Messages.errorTitle500
            : Messages.errorTitle404
        }
        width="600px">
        <Row>
          <Col md={{ size: 12 }}>
            {code === 500
              ? Messages.errorCode500
              : Messages.errorCode404}
          </Col>
          <Col md={{ size: 12 }}>
            <Link to={`/${Common.Routes.routeLogin}`}>
              Retour à l&apos;accueil
            </Link>
          </Col>
        </Row>
      </Template>
    );
  }
}

const mapStateToProps = state => ({
  activeContainer: state.container.activeContainer.id
});

export default connect(mapStateToProps)(ErrorScene);
