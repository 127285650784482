// @flow
// React Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
// Components
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import InputCustom from '../../../InputCustom';
import FrontValidation from '../../../../../Resources/Common/FrontValidation';
import Tooltip from '../../../../../Resources/Common/Tooltip';
import TruncateCustom from '../../../TruncateCustom';

type Props = {
  changeProposalsData: Array<any>,
  hasRights: boolean,
  blocked: boolean,
  proposalUid: string
};

type State = { tagline: string, collapse: boolean };

class Tagline extends Component<Props, State> {
  constructor(props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = _.pick(
      _.defaults(_.get(changeProposalsFiltered, '[0].content'), {
        tagline: ''
      }),
      ['tagline']
    );
    this.state.collapse = true;
  }

  handleCollapse = e => {
    e.preventDefault();
    const { collapse } = this.state;
    this.setState({ collapse: !collapse });
  };

  render() {
    const { tagline, collapse } = this.state;
    const { hasRights, blocked } = this.props;
    return (
      <FormGroup>
        <Label for="tagline">Tagline de l'entreprise</Label>
        <small className="form-text text-muted mb-1 mt-0">
          <TruncateCustom
            collapse={collapse}
            tooltip={Tooltip.ProjectOfferTagline.tagline}
            handleCollapse={this.handleCollapse}
          />
        </small>
        <InputCustom
          placeholder="Tagline de l'entreprise"
          id="tagline"
          name="tagline"
          defaultValue={tagline}
          minLength="3"
          maxLength="100"
          disabled={!hasRights || blocked}
          required
        />
        <FormFeedback>
          {FrontValidation.ProjectOfferTagline.tagline}
        </FormFeedback>
      </FormGroup>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData
});

export default withRouter(connect(mapStateToProps)(Tagline));
