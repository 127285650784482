// @flow
// React Libs
import React from 'react';
// Components
import Tooltip from '../../../../../Resources/Common/Tooltip';
import SimpleDocumentComponent from '../../../Documents/SimpleDocumentComponent';

type Props = {
  hasRights: boolean,
  blocked: boolean,
  proposalUid: string
};

export default function GeneralAssemblyReport({
  hasRights,
  blocked,
  proposalUid
}: Props) {
  return (
    <SimpleDocumentComponent
      title="PV D'AG d'ouverture du capital"
      tooltip={Tooltip.DocumentProjectGeneralAssemblyReport.document}
      hasRights={hasRights}
      blocked={blocked}
      proposalUid={proposalUid}
    />
  );
}
