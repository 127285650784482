// @flow

import Common from '../../Resources/Common';
import {
  GET_CONTAINER_RIGHTS,
  POST_CONTAINER_INVITATION,
  GET_CONTAINER_INVITATIONS,
  DELETE_CONTAINER_INVITATIONS,
  PATCH_USER_RIGHTS,
  DELETE_USER_RIGHTS,
  GET_CONTAINER_DATA
} from '../actions/DashboardSettingsActions';

const defaultState = {
  containerData: {},
  deleteRightsError: '',
  deleteRightsSuccess: false,
  deleteInvitationError: '',
  deleteInvitationLoading: false,
  deleteInvitationSuccess: false,
  getContainerRightsError: '',
  getContainerDataError: '',
  getInvitationError: '',
  isLoading: false,
  isLoadingPatchUserRights: false,
  isLoadingPostContainerInvitation: false,
  invitationsData: [],
  postInvitationError: '',
  patchRightsError: '',
  patchRightsSuccess: false,
  rights: []
};

const dashboardSettingsReducer = (
  state: Object = defaultState,
  action: Object = {}
) => {
  switch (action.type) {
    case GET_CONTAINER_RIGHTS:
      return Object.assign({}, state, {
        getContainerRightsError: action.payload.error,
        rights: action.payload.aggregate,
        isLoading:
          action.payload.status === Common.Constants.PENDING_STATUS
      });
    case POST_CONTAINER_INVITATION:
      return Object.assign({}, state, {
        postInvitationError: action.payload.error,
        isLoadingPostContainerInvitation:
          action.payload.status === Common.Constants.PENDING_STATUS,
        invitationSuccess: action.payload.hasSuccess
      });
    case GET_CONTAINER_INVITATIONS:
      return Object.assign({}, state, {
        getInvitationError: action.payload.error,
        isLoading:
          action.payload.status === Common.Constants.PENDING_STATUS,
        invitationsData: action.payload.aggregate
      });
    case DELETE_CONTAINER_INVITATIONS:
      return Object.assign({}, state, {
        deleteInvitationError: action.payload.error,
        deleteInvitationLoading:
          action.payload.status === Common.Constants.PENDING_STATUS,
        deleteInvitationSuccess: action.payload.hasSuccess
      });
    case PATCH_USER_RIGHTS:
      return Object.assign({}, state, {
        patchRightsError: action.payload.error,
        isLoadingPatchUserRights:
          action.payload.status === Common.Constants.PENDING_STATUS,
        patchRightsSuccess: action.payload.hasSuccess
      });
    case DELETE_USER_RIGHTS:
      return Object.assign({}, state, {
        deleteRightsError: action.payload.error,
        isLoading:
          action.payload.status === Common.Constants.PENDING_STATUS,
        deleteRightsSuccess: action.payload.hasSuccess
      });
    case GET_CONTAINER_DATA:
      return Object.assign({}, state, {
        getContainerDataError: action.payload.error,
        isLoading:
          action.payload.status === Common.Constants.PENDING_STATUS,
        containerData: action.payload.aggregate
      });
    default:
      return state;
  }
};

export default dashboardSettingsReducer;
