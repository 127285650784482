// @flow

import Common from '../../Resources/Common';
import {
  GET_CHECKLIST,
  PATCH_TODO
} from '../actions/DashboardChecklistActions';

const defaultState = {
  isLoading: false,
  getTodolistError: '',
  patchTodoError: '',
  patchTodoSuccess: '',
  todolists: []
};

const DashboardChecklistReducer = (
  state: Object = defaultState,
  action: Object = {}
) => {
  switch (action.type) {
    case GET_CHECKLIST:
      return Object.assign({}, state, {
        getTodolistError: action.payload.error,
        todolists: action.payload.aggregate,
        isLoading:
          action.payload.status === Common.Constants.PENDING_STATUS
      });
    case PATCH_TODO:
      return Object.assign({}, state, {
        patchTodoError: action.payload.error,
        patchTodoSuccess: action.payload.aggregate,
        isLoading:
          action.payload.status === Common.Constants.PENDING_STATUS
      });
    default:
      return state;
  }
};

export default DashboardChecklistReducer;
