// @flow

import React from 'react';
import PropTypes from 'prop-types';
import FinancialData from './FinancialData';
import Tooltip from '../../../../../Resources/Common/Tooltip';

export default function ClientsB2C({
  hasRights,
  blocked,
  proposalUid
}) {
  return (
    <>
      <FinancialData
        title="Clients B2C"
        tooltip={Tooltip.ProjectFinancialDataB2C}
        fieldNames={{
          figures: 'Clients B2C',
          comment: 'Commentaire Clients B2C',
          highlight: 'Valeur mise en avant Clients B2C',
          breakdown: 'Graph Camembert Clients B2C'
        }}
        hasRights={hasRights}
        blocked={blocked}
        proposalUid={proposalUid}
      />
    </>
  );
}

ClientsB2C.propTypes = {
  hasRights: PropTypes.bool.isRequired,
  blocked: PropTypes.bool.isRequired,
  proposalUid: PropTypes.string
};

ClientsB2C.defaultProps = {
  proposalUid: undefined
};
