// @flow
// @todo récupèrer tous les membres

// React Libs
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import update from 'immutability-helper';
// Components
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import FinancingHistoryInfos from './FinancingHistoryInfos';
import ButtonCustom from '../../../ButtonCustom';

type FundraisingItem = {
  title: string,
  amount: number,
  breakdown: Array<{
    name: string,
    value: number
  }>,
  date: Date,
  newElement: boolean
};

type Props = {
  fundraisings?: FundraisingItem[],
  hasRights?: boolean,
  blocked: boolean,
  newElement?: boolean,
  changeProposalsData: Array<any>,
  proposalUid?: string
};

type State = {
  fundraisings: FundraisingItem[]
};

class FinancingHistory extends React.Component<Props, State> {
  static NOTHING = {
    title: '',
    amount: 0,
    breakdown: [
      {
        name: '',
        value: 0
      }
    ],
    date: new Date(),
    newElement: true
  };

  static defaultProps = {
    fundraisings: [FinancingHistory.NOTHING],
    hasRights: false,
    newElement: false,
    proposalUid: undefined
  };

  constructor(props: Props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = {
      fundraisings: _.get(
        changeProposalsFiltered,
        '[0].content.fundraisings'
      ) || [FinancingHistory.NOTHING]
    };
  }

  addFundraisingItem = () => {
    const { fundraisings } = this.state;
    this.setState({
      fundraisings: update(fundraisings, {
        $push: [FinancingHistory.NOTHING]
      })
    });
  };

  deleteFundraisingItem = index => () => {
    const { fundraisings } = this.state;
    this.setState({
      fundraisings: update(fundraisings, { $splice: [[index, 1]] })
    });
  };

  render() {
    const {
      hasRights,
      blocked,
      newElement,
      proposalUid
    } = this.props;
    const { fundraisings } = this.state;
    const fundraisingsInfo = fundraisings.map(
      (fundraising, index) => (
        <section
          className={
            index > 0 ? `border-top border-secondary mt-4 pt-4` : ``
          }
          key={`section${index + 1}`}>
          <ButtonCustom
            disabled={blocked}
            onClick={this.deleteFundraisingItem(index)}
            key={`button${index + 1}`}
            className="mb-3">
            Supprimer ce financement
          </ButtonCustom>
          <FinancingHistoryInfos
            key={`fundraisings${index + 1}`}
            index={index}
            hasRights={hasRights}
            blocked={blocked}
            newElement={newElement}
            proposalUid={proposalUid}
            {...fundraising}
          />
        </section>
      )
    );

    return (
      <>
        {fundraisingsInfo}
        <Button
          color="secondary"
          className="mx-5 mb-3"
          onClick={this.addFundraisingItem}>
          ajouter un financement
        </Button>
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData,
  containerDocument: state.folder.getContainerDocument,
  activeContainer: state.container.activeContainer.id,
  isLoadingGetContainerDocument:
    state.folder.isLoadingGetContainerDocument
});

export default withRouter(connect(mapStateToProps)(FinancingHistory));
