// @flow

import Common from '../../Resources/Common';
import {
  GET_CALENDAR_EVENTS,
  POST_CALENDAR_EVENTS,
  PATCH_CALENDAR_EVENTS,
  DELETE_CALENDAR_EVENTS
} from '../actions/DashboardCalendarActions';

const defaultState = {
  isLoading: false,
  isLoadingPatchCalendarEvents: false,
  isLoadingPostCalendarEvents: false,
  getEventsError: '',
  eventsData: [],
  postEventError: '',
  postEventSuccess: false,
  patchEventError: '',
  patchEventSuccess: false,
  isLoadingDeleteCalendarEvents: false,
  deleteEventSuccess: false,
  deleteEventError: false,
  getCalendarEventsSuccess: false
};

const DashboardCalendarReducer = (
  state: Object = defaultState,
  action: Object = {}
) => {
  switch (action.type) {
    case GET_CALENDAR_EVENTS:
      return Object.assign({}, state, {
        getEventsError: action.payload.error,
        eventsData: action.payload.aggregate,
        isLoading:
          action.payload.status === Common.Constants.PENDING_STATUS,
        getCalendarEventsSuccess:
          action.payload.status === Common.Constants.SUCCESS_STATUS
      });
    case POST_CALENDAR_EVENTS:
      return Object.assign({}, state, {
        postEventError: action.payload.error,
        postEventSuccess: action.payload.success,
        isLoadingPostCalendarEvents:
          action.payload.status === Common.Constants.PENDING_STATUS
      });
    case PATCH_CALENDAR_EVENTS:
      return Object.assign({}, state, {
        patchEventError: action.payload.error,
        patchEventSuccess: action.payload.success,
        isLoadingPatchCalendarEvents:
          action.payload.status === Common.Constants.PENDING_STATUS
      });
    case DELETE_CALENDAR_EVENTS:
      return Object.assign({}, state, {
        deleteEventError: action.payload.error,
        deleteEventSuccess: action.payload.success,
        isLoadingDeleteCalendarEvents:
          action.payload.status === Common.Constants.PENDING_STATUS
      });
    default:
      return state;
  }
};

export default DashboardCalendarReducer;
