// @flow

import React, { Component } from 'react';
import { InputGroup, Label } from 'reactstrap';
import Constants from '../../Resources/Common/Constants';
import InputCustom from '../Components/InputCustom';
import ButtonCustom from '../Components/ButtonCustom';

type Right =
  | Constants.USER_RIGHTS.PROJECT_BASIC_INFO
  | Constants.USER_RIGHTS.PROJECT_OFFER
  | Constants.USER_RIGHTS.PROJECT_MEDIA
  | Constants.USER_RIGHTS.PROJECT_FINANCE
  | Constants.USER_RIGHTS.SEND_EMAIL
  | Constants.USER_RIGHTS.SEND_COMMUNICATION
  | Constants.USER_RIGHTS.CHECKLIST_CALENDAR
  | Constants.USER_RIGHTS.ADMINISTRATION;

type UserRight = {
  email: string,
  userId: string,
  firstName: string,
  lastName: string,
  rights: Right[]
};

type Props = {
  activeUserEmail: string,
  userRight: UserRight,
  onRightsUpdate: Function,
  deleteUser: Function
};

type State = {
  isAdmin: boolean,
  rightsList: Right[]
};

export default class UserRights extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isAdmin: props.userRight.rights.includes(
        Constants.USER_RIGHTS.ADMINISTRATION
      ),
      rightsList: props.userRight.rights
    };
  }

  updateRight = (right: string) => (event: Event) => {
    const { onRightsUpdate } = this.props;
    const { rightsList } = this.state;
    // Used for flow typing
    const initialCheckbox: HTMLInputElement = (event.target: any);
    const { checked } = initialCheckbox;

    this.setState(
      {
        rightsList: checked
          ? rightsList.concat(right)
          : rightsList.filter(r => r !== right)
      },
      () => {
        onRightsUpdate(
          checked
            ? rightsList.concat(right)
            : rightsList.filter(r => r !== right)
        );
      }
    );
  };

  updateAdminStatus = () => {
    const { onRightsUpdate } = this.props;
    const { isAdmin } = this.state;
    this.setState(
      {
        isAdmin: !isAdmin,
        rightsList: isAdmin // wasAdmin
          ? Constants.RIGHTS_ARRAY.slice(1)
          : Constants.RIGHTS_ARRAY
      },
      () => {
        const { rightsList: newRightsList } = this.state;
        onRightsUpdate(newRightsList);
      }
    );
  };

  render() {
    const { activeUserEmail, deleteUser, userRight } = this.props;
    const { isAdmin, rightsList } = this.state;
    const concernsActiveUser = activeUserEmail === userRight.email;
    return (
      <tr>
        <td>{`${userRight.firstName} ${userRight.lastName}`}</td>
        <td role="presentation" className="text-center">
          <InputGroup className="custom-control custom-checkbox text-center">
            <InputCustom
              type="checkbox"
              className="custom-control-input"
              onChange={this.updateAdminStatus}
              defaultChecked={isAdmin}
              id={`${userRight.userId}_admin`}
              disabled={isAdmin && concernsActiveUser}
            />
            <Label
              className="custom-control-label d-flex no-label"
              for={`${userRight.userId}_admin`}
            />
          </InputGroup>
        </td>
        {Constants.RIGHTS_ARRAY.slice(1).map(right => (
          <td
            key={`RIGHT_${right}_CHECKBOX`}
            role="presentation"
            className="text-center">
            <InputGroup className="custom-control custom-checkbox">
              <InputCustom
                type="checkbox"
                className="custom-control-input"
                onChange={this.updateRight(right)}
                checked={isAdmin || rightsList.includes(right)}
                disabled={isAdmin}
                id={`${userRight.userId}_${right}`}
              />
              <Label
                className="custom-control-label d-flex no-label"
                for={`${userRight.userId}_${right}`}
              />
            </InputGroup>
          </td>
        ))}
        <td>
          {!concernsActiveUser && (
            <ButtonCustom
              className="close"
              aria-label="Close"
              onClick={() => deleteUser(userRight.userId)}>
              <span aria-hidden="true">&times;</span>
            </ButtonCustom>
          )}
        </td>
      </tr>
    );
  }
}
