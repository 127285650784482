// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { format } from 'date-fns';
import ReactPlaceholder from 'react-placeholder';
import _ from 'lodash';
// Components
import { Row, Table, Col } from 'reactstrap';
import Template from '../../Components/Template/TemplateComponent';
import AlertCustom from '../../Components/Components/AlertCustom';
import ButtonCustom from '../../Components/Components/ButtonCustom';
// Utils
import Routes from '../../Resources/Common/Routes';
import Messages from '../../Resources/Common/Messages';
import Constants from '../../Resources/Common/Constants';
import { getVisibleObject } from '../../Resources/Common/Utilities';
// Actions
import {
  getContainerRightsAction,
  getInvitationsAction,
  deleteInvitationsAction
} from '../../Business/actions/DashboardSettingsActions';
import UserRightsTable from '../../Components/UserRights/UserRightsTable';

type State = {
  visibleObject: {
    getInvitationError: boolean,
    patchRightsSuccess: boolean,
    patchRightsError: boolean,
    postInvitationError: boolean,
    deleteRightsError: boolean,
    deleteRightsSuccess: boolean,
    getContainerRightsError: boolean,
    deleteInvitationError: boolean,
    deleteInvitationSuccess: boolean
  }
};

type Props = {
  activeContainer: string,
  dispatch: Function,
  getInvitationError: string,
  invitationsData: Array<Object>,
  patchRightsSuccess: boolean,
  patchRightsError: string,
  deleteRightsError: string,
  deleteRightsSuccess: boolean,
  postInvitationError: string,
  getContainerRightsError: string,
  deleteInvitationError: string,
  deleteInvitationSuccess: boolean,
  operatorEmail: string
};

class DashboardSettingsScene extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      visibleObject: {
        getInvitationError: false,
        patchRightsSuccess: false,
        patchRightsError: false,
        postInvitationError: false,
        deleteRightsError: false,
        deleteRightsSuccess: false,
        getContainerRightsError: false,
        deleteInvitationError: false,
        deleteInvitationSuccess: false
      }
    };
  }

  componentDidMount() {
    const { activeContainer, dispatch, operatorEmail } = this.props;
    if (activeContainer) {
      dispatch(getContainerRightsAction(activeContainer));
      dispatch(getInvitationsAction(activeContainer, operatorEmail));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      activeContainer,
      dispatch,
      deleteInvitationSuccess,
      operatorEmail
    } = this.props;
    if (activeContainer !== prevProps.activeContainer) {
      dispatch(getInvitationsAction(activeContainer, operatorEmail));
    }
    if (
      deleteInvitationSuccess &&
      deleteInvitationSuccess !== prevProps.deleteInvitationSuccess
    ) {
      dispatch(getInvitationsAction(activeContainer, operatorEmail));
    }
    this.setVisibleAlerts(prevProps, prevState);
  }

  onDismiss = target => {
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        [target]: false
      }
    }));
  };

  displayAlert = () => {
    const {
      getInvitationError,
      patchRightsSuccess,
      patchRightsError,
      postInvitationError,
      deleteRightsError,
      deleteRightsSuccess,
      getContainerRightsError,
      deleteInvitationError,
      deleteInvitationSuccess
    } = this.props;
    const { visibleObject } = this.state;
    const alert = [];
    let key = 0;

    if (getInvitationError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="getInvitationError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={getInvitationError}
          visible={visibleObject.getInvitationError}
        />
      );
    }
    if (patchRightsError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="patchRightsError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={patchRightsError}
          visible={visibleObject.patchRightsError}
        />
      );
    }
    if (deleteRightsError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="deleteRightsError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={deleteRightsError}
          visible={visibleObject.deleteRightsError}
        />
      );
    }
    if (postInvitationError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="postInvitationError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={postInvitationError}
          visible={visibleObject.postInvitationError}
        />
      );
    }
    if (getContainerRightsError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="getContainerRightsError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={getContainerRightsError}
          visible={visibleObject.getContainerRightsError}
        />
      );
    }
    if (patchRightsSuccess) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="patchRightsSuccess"
          color="success"
          onDismiss={this.onDismiss}
          errorMessage={Messages.patchUserRightSuccess}
          visible={visibleObject.patchRightsSuccess}
        />
      );
    }
    if (deleteRightsSuccess) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="deleteRightsSuccess"
          color="success"
          onDismiss={this.onDismiss}
          errorMessage={Messages.deleteUserRightSuccess}
          visible={visibleObject.deleteRightsSuccess}
        />
      );
    }
    if (deleteInvitationError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="deleteInvitationError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={deleteInvitationError}
          visible={visibleObject.deleteInvitationError}
        />
      );
    }
    if (deleteInvitationSuccess) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="deleteInvitationSuccess"
          color="success"
          onDismiss={this.onDismiss}
          errorMessage={Messages.deleteInvitationSuccess}
          visible={visibleObject.deleteInvitationSuccess}
        />
      );
    }
    return alert;
  };

  setVisibleAlerts = (prevProps, prevState) => {
    const errorPropsArray = [
      'getInvitationError',
      'patchRightsSuccess',
      'patchRightsError',
      'postInvitationError',
      'deleteRightsError',
      'deleteRightsSuccess',
      'getContainerRightsError',
      'deleteInvitationError',
      'deleteInvitationSuccess'
    ];
    const { props, state } = this;
    let newState = Object.assign({}, this.state);
    const visibleObject = getVisibleObject(
      props,
      prevProps,
      state,
      prevState,
      errorPropsArray
    );
    newState = {
      ...newState,
      visibleObject: {
        ...newState.visibleObject,
        ...visibleObject
      }
    };
    if (
      Object.values(visibleObject).includes(true) &&
      !_.isEqual(newState, state)
    ) {
      this.setState(newState);
    }
  };

  renderInvitations = () => {
    const { activeContainer, invitationsData } = this.props;
    const invitationsComponent = (
      <>
        <h2>Invitations</h2>
        <ReactPlaceholder
          className="my-3"
          ready={!!invitationsData}
          type="text"
          showLoadingAnimation
          rows={8}
          color={window
            .getComputedStyle(document.body)
            .getPropertyValue('--light')}>
          <Row>
            <Col md={{ size: 12 }}>
              <Table striped className="table-borderless">
                <thead className="border-bottom border-primary">
                  <tr>
                    <th>Adresse Email</th>
                    <th>Date de création</th>
                    <th>Statut</th>
                  </tr>
                </thead>
                <tbody>
                  {invitationsData && (
                    <>
                      {invitationsData.map(invitation => (
                        <React.Fragment key={invitation.uuid}>
                          {invitation.status === 0 && (
                            <tr key={invitation.uuid}>
                              <td>{invitation.email}</td>
                              <td>
                                {format(
                                  invitation.creationDate,
                                  'DD/MM/YYYY'
                                )}
                              </td>
                              <td>
                                {invitation.status <
                                  Constants.STATUS_ARRAY.length && (
                                  <span>
                                    {
                                      Constants.STATUS_ARRAY[
                                        invitation.status
                                      ]
                                    }
                                  </span>
                                )}
                              </td>
                              <td>
                                {invitation.status === 0 && (
                                  <ButtonCustom
                                    className="close"
                                    aria-label="Close"
                                    onClick={() =>
                                      this.deleteInvitationConfirmationBox(
                                        invitation.uuid
                                      )
                                    }>
                                    <span aria-hidden="true">
                                      &times;
                                    </span>
                                  </ButtonCustom>
                                )}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="flex-column">
            <Col md={{ size: 12 }}>
              <Link
                to={`/${Routes.routeDashboard}/${activeContainer}/${
                  Routes.routeDashboardSettingsInvitation
                }`}
                className="align-self-end">
                <ButtonCustom color="primary" className="float-right">
                  Inviter un nouvel utilisateur
                </ButtonCustom>
              </Link>
            </Col>
          </Row>
        </ReactPlaceholder>
      </>
    );
    return invitationsComponent;
  };

  deleteInvitationConfirmationBox = invitationUuid => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="modal d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <ButtonCustom
                  className="close"
                  onClick={onClose}
                  aria-label="Close">
                  <span aria-hidden="true">x</span>
                </ButtonCustom>
                <p>
                  {Messages.dashboardDeleteInvitationConfirmMessage}
                </p>
              </div>
              <div className="modal-footer">
                <ButtonCustom
                  color="primary"
                  className="btn btn-primary"
                  onClick={() => {
                    this.deleteInvitation(invitationUuid);
                    onClose();
                  }}>
                  Oui
                </ButtonCustom>
                <ButtonCustom
                  className="btn btn-secondary"
                  onClick={onClose}
                  data-dismiss="modal">
                  Non
                </ButtonCustom>
              </div>
            </div>
          </div>
        </div>
      )
    });
  };

  deleteInvitation = invitationUuid => {
    const { dispatch, activeContainer, operatorEmail } = this.props;
    dispatch(
      deleteInvitationsAction(
        activeContainer,
        invitationUuid,
        operatorEmail
      )
    );
  };

  render() {
    return (
      <Template
        title="Paramètres"
        subtitle="Administrez les utilisateurs et leurs droits. Invitez de nouveaux membres."
        menu={Routes.routeDashboard}>
        {this.displayAlert()}
        <UserRightsTable />
        {this.renderInvitations()}
      </Template>
    );
  }
}

const mapStateToProps = state => ({
  activeContainer: state.container.activeContainer.id,
  operatorEmail: state.container.activeContainer.operatorEmail,
  email: state.account.accountInfos.email,
  rights: state.dashboardSettings.rights,
  invitationError: state.dashboardSettings.invitationError,
  isLoadingPatchUserRights:
    state.dashboardSettings.isLoadingPatchUserRights,
  getInvitationError: state.dashboardSettings.getInvitationError,
  getContainerRightsError:
    state.dashboardSettings.getContainerRightsError,
  invitationsData: state.dashboardSettings.invitationsData,
  patchRightsSuccess: state.dashboardSettings.patchRightsSuccess,
  patchRightsError: state.dashboardSettings.patchRightsError,
  deleteRightsError: state.dashboardSettings.deleteRightsError,
  deleteRightsSuccess: state.dashboardSettings.deleteRightsSuccess,
  deleteInvitationError:
    state.dashboardSettings.deleteInvitationError,
  deleteInvitationSuccess:
    state.dashboardSettings.deleteInvitationSuccess,
  postInvitationError: state.dashboardSettings.postInvitationError
});

export default connect(mapStateToProps)(DashboardSettingsScene);
