// @flow
// React Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import _ from 'lodash';
// Components
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import InputCustom from '../InputCustom';
import { isImage } from '../../../Resources/Common/Utilities';
import TruncateCustom from '../TruncateCustom';

// Actions
import { getChangeContainerDocumentAction } from '../../../Business/actions/FolderActions';
import FrontValidation from '../../../Resources/Common/FrontValidation';

type Props = {
  containerDocument: Object,
  operatorEmail: string,
  changeProposalsData: Array<any>,
  activeContainer: string,
  dispatch: Function,
  hasRights: boolean,
  blocked: boolean,
  isLoadingGetContainerDocument: boolean,
  title: string,
  tooltip: string,
  match: Object,
  proposalUid: string
};

type State = {
  document: {
    url: string,
    text: string
  },
  documentRequired: string,
  readOnly: boolean,
  changeProposalsFiltered: Object,
  collapse: boolean
};

class SimpleDocumentComponent extends Component<Props, State> {
  constructor(props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = _.pick(
      _.defaultsDeep(_.get(changeProposalsFiltered, '[0].content'), {
        document: { url: '', text: '' }
      }),
      ['document']
    );
    this.state.documentRequired = !_.get(
      changeProposalsFiltered,
      '[0].content.document'
    );
    this.state.changeProposalsFiltered = changeProposalsFiltered;
    this.state.collapse = true;
  }

  componentDidMount = () => {
    const {
      dispatch,
      activeContainer,
      match,
      operatorEmail
    } = this.props;
    const { kind } = match.params;
    const { changeProposalsFiltered } = this.state;
    if (
      changeProposalsFiltered[0] &&
      _.has(changeProposalsFiltered[0], 'content.document') &&
      changeProposalsFiltered[0].kind === kind
    ) {
      dispatch(
        getChangeContainerDocumentAction({
          containerId: activeContainer,
          docUuid: _.get(
            changeProposalsFiltered,
            '[0].content.document'
          ),
          operatorEmail
        })
      );
    }
  };

  componentDidUpdate = prevProps => {
    const {
      containerDocument,
      dispatch,
      activeContainer,
      changeProposalsData,
      operatorEmail
    } = this.props;
    if (
      containerDocument &&
      !_.isEqual(containerDocument, prevProps.containerDocument) &&
      containerDocument.constructor === Object
    ) {
      this.updateDocument();
    }
    if (
      changeProposalsData[0] &&
      !_.isEqual(changeProposalsData, prevProps.changeProposalsData)
    ) {
      dispatch(
        getChangeContainerDocumentAction({
          containerId: activeContainer,
          docUuid: _.get(changeProposalsData, '[0].content.document'),
          operatorEmail
        })
      );
    }
  };

  updateDocument = () => {
    const { containerDocument } = this.props;
    this.setState(prevState => ({
      document: {
        ...prevState.document,
        url: containerDocument.url
      }
    }));
  };

  handleDocument = e => {
    e.persist();
    this.setState(prevState => ({
      document: {
        ...prevState.document,
        text: e.target.value.replace(/C:\\fakepath\\/i, '')
      }
    }));
  };

  handleCollapse = () => {
    const { collapse } = this.state;
    this.setState({ collapse: !collapse });
  };

  render() {
    const { document, documentRequired, collapse } = this.state;
    const {
      hasRights,
      isLoadingGetContainerDocument,
      title,
      tooltip,
      blocked
    } = this.props;
    const { changeProposalsFiltered } = this.state;
    return (
      <>
        <span className="mb-2">{title}</span>
        <small className="form-text text-muted mb-1 mt-0">
          <TruncateCustom
            collapse={collapse}
            tooltip={tooltip}
            handleCollapse={this.handleCollapse}
          />
        </small>
        <FormGroup>
          <InputCustom
            type="file"
            className="custom-file-input"
            id="document"
            name="document"
            onChange={this.handleDocument}
            required={documentRequired}
            disabled={!hasRights || blocked}
          />
          <Label className="custom-file-label" for="document">
            {document.text || 'Choisir un fichier'}
          </Label>
          <FormFeedback>
            {FrontValidation.documentFrontValidation}
          </FormFeedback>
          <div className="mt-3">
            {!document.url && isLoadingGetContainerDocument ? (
              <div className="sweet-loading">
                <ClipLoader
                  css="margin: 'O auto';"
                  sizeUnit="px"
                  loading={
                    !document.url && isLoadingGetContainerDocument
                  }
                />
              </div>
            ) : (
              <>
                {document.url && (
                  <>
                    {isImage(document.url) ? (
                      <img
                        src={document.url}
                        className="mw-100"
                        alt="document"
                      />
                    ) : (
                      <a href={document.url}>
                        Télécharger le document
                      </a>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </FormGroup>
        <InputCustom
          type="hidden"
          name="defaultDocument"
          value={
            documentRequired
              ? ''
              : _.get(
                  changeProposalsFiltered,
                  '[0].content.document',
                  ''
                )
          }
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  containerDocument: state.folder.getContainerDocument,
  changeProposalsData: state.folder.getChangeProposalsData,
  activeContainer: state.container.activeContainer.id,
  operatorEmail: state.container.activeContainer.operatorEmail,
  isLoadingGetContainerDocument:
    state.folder.isLoadingGetContainerDocument
});

export default withRouter(
  connect(mapStateToProps)(SimpleDocumentComponent)
);
