// @flow
// Common
import Common from '../../Resources/Common';
import AccountService from '../../Layer/Network/Service/AccountService';
import Messages from '../../Resources/Common/Messages';
import Constants from '../../Resources/Common/Constants';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

const ChangePasswordPending = () => ({
  type: CHANGE_PASSWORD,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: null,
    error: '',
    hasSuccess: false
  }
});
const ChangePasswordSuccess = info => ({
  type: CHANGE_PASSWORD,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: info,
    error: '',
    hasSuccess: true
  }
});
const ChangePasswordFailure = error => ({
  type: CHANGE_PASSWORD,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: null,
    error,
    hasSuccess: false
  }
});

export function ChangePasswordAction(
  password: string,
  newPassword: string
) {
  return (dispatch: Function) => {
    dispatch(ChangePasswordPending());
    return AccountService.patchPassword(password, newPassword)
      .then(() => {
        const successMessage = Messages.recoverPasswordChanged;
        dispatch(ChangePasswordSuccess(successMessage));
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.USER.INVALID_CREDENTIALS:
            error = Messages.accountSecurityInvalidCredentails;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(ChangePasswordFailure(error));
      });
  };
}
