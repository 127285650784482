import { store } from '../../../UI/AppScene';
import LocalStorage from '../../Storage/LocalStorage';

export default class MarkdownImageService {
  static STORAGE_SERVICE_URL = LocalStorage.get(
    'GLOBAL.STORAGE_SERVICE_URL'
  );

  /**
   * @param {File|Blob} file a given File/Blob to upload
   * @return {Promise<Response>}
   */
  static uploadFile(file) {
    return new Promise(resolve => {
      const fileReader = new FileReader();

      fileReader.addEventListener('load', () => {
        resolve(
          // will remove the `data:..,` part
          fileReader.result.slice(fileReader.result.indexOf(',') + 1)
        );
      });

      fileReader.readAsDataURL(file);
    }).then(fileAsBase64 => {
      const { token } = store.getState().token;
      return fetch(MarkdownImageService.STORAGE_SERVICE_URL, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          token: token.split(' ')[1], // trim the `Bearer `
          mimeType: file.type,
          data: fileAsBase64
        })
      });
    });
  }
}
