// @flow
// React Libs
import React from 'react';
// Components
import Tooltip from '../../../../../Resources/Common/Tooltip';
import SimpleDocumentComponent from '../../../Documents/SimpleDocumentComponent';

type Props = {
  hasRights: boolean,
  blocked: boolean,
  proposalUid: string
};

export default function ShareholdersAgreement({
  hasRights,
  blocked,
  proposalUid
}: Props) {
  return (
    <SimpleDocumentComponent
      title="Projet de pacte d'actionnaires"
      tooltip={Tooltip.DocumentProjectShareholdersAgreement.document}
      hasRights={hasRights}
      blocked={blocked}
      proposalUid={proposalUid}
    />
  );
}
