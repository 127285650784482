// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
// Components
import { Col, Row } from 'reactstrap';
import Template from '../../Components/Template/TemplateComponent';
// Utils
import Route from '../../Resources/Common/Routes';
import ButtonCustom from '../../Components/Components/ButtonCustom';
import AlertCustom from '../../Components/Components/AlertCustom';
import Messages from '../../Resources/Common/Messages';
// Actions
import LocalStorage from '../../Layer/Storage/LocalStorage';
import {
  setActiveContainerAction,
  getContainersAction,
  clearActiveContainer,
  getUserRightsAction
} from '../../Business/actions/ContainerActions';
import { getContainerDataAction } from '../../Business/actions/DashboardSettingsActions';

type Props = {
  activeContainer: string,
  containers: Array<any>,
  userRights: Array<any>,
  dispatch: Function,
  match: Object
};

type State = {
  visibleObject: {
    access: boolean
  },
  updatedActive: boolean
};

class DashboardChoseScene extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      visibleObject: {
        access: false
      },
      updatedActive: false
    };
    this.onDismiss = this.onDismiss.bind(this);
  }

  componentDidMount = () => {
    const { containers, dispatch } = this.props;
    if (!containers || !Array.isArray(containers)) {
      dispatch(getContainersAction());
    } else if (!_.isEmpty(containers)) {
      const redirect = LocalStorage.get('redirect');
      if (redirect) {
        const { pathname } = redirect;
        if (
          /(container|folder)/.test(pathname.split('/')[1]) &&
          !/(containers)/.test(pathname.split('/')[1])
        ) {
          const container = pathname.split('/')[2];
          if (container) {
            this.setActiveContainer(
              containers.find(c => container === c.uuid)
            );
          }
        }
      } else if (containers.length === 1) {
        this.setActiveContainer(containers[0]);
      }
    }
    this.setVisibleAlerts();
  };

  componentDidUpdate = prevProps => {
    const { containers, dispatch, activeContainer } = this.props;
    const { updatedActive } = this.state;
    if (!containers || !Array.isArray(containers)) {
      dispatch(getContainersAction());
    } else if (
      !_.isEmpty(containers) &&
      !_.isEqual(containers, prevProps.containers)
    ) {
      if (LocalStorage.get('redirect')) {
        const { pathname } = LocalStorage.get('redirect');
        if (
          /(container|folder)/.test(pathname.split('/')[1]) &&
          !/(containers)/.test(pathname.split('/')[1])
        ) {
          const container = pathname.split('/')[2];
          if (container) {
            this.setActiveContainer(
              containers.find(c => container === c.uuid)
            );
          }
        }
      } else if (containers.length === 1) {
        this.setActiveContainer(containers[0]);
      }
    } else if (activeContainer && !updatedActive) {
      // this will prevent the automatic redirection from LoginScene
      dispatch(clearActiveContainer());
    }
  };

  displayContainers = () => {
    const { containers } = this.props;
    let containersComponent;
    if (!containers || !Array.isArray(containers)) {
      containersComponent = <div>Loading...</div>;
    } else if (containers.length === 0) {
      containersComponent = (
        <div>Vous n&apos;avez accès à aucun projet.</div>
      );
    } else {
      containersComponent = (
        <>
          {containers.map(container => (
            <ButtonCustom
              className="m-3"
              color="primary"
              onClick={() => this.setActiveContainer(container)}
              key={container.uuid}>
              {` ${container.name}`}
            </ButtonCustom>
          ))}
        </>
      );
    }
    return containersComponent;
  };

  setActiveContainer = container => {
    const { dispatch } = this.props;
    dispatch(setActiveContainerAction(container));
    dispatch(getContainerDataAction(container.uuid));
    dispatch(
      getUserRightsAction(container.uuid),
      this.setState({ updatedActive: true })
    );
  };

  setVisibleAlerts = () => {
    const { match } = this.props;
    const { access } = match.params;
    if (access) {
      this.setState(prevState => ({
        visibleObject: {
          ...prevState.visibleObject,
          access: true
        }
      }));
    }
  };

  onDismiss = target => {
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        [target]: false
      }
    }));
  };

  render() {
    const { match, activeContainer, userRights } = this.props;
    const { access } = match.params;
    const { visibleObject, updatedActive } = this.state;
    if (LocalStorage.get('redirect')) {
      const { pathname } = LocalStorage.get('redirect');
      if (
        pathname &&
        activeContainer &&
        updatedActive &&
        !_.isEmpty(userRights)
      ) {
        LocalStorage.delete('redirect');
        return <Redirect to={pathname} />;
      }
    }

    if (activeContainer && updatedActive && userRights) {
      return (
        <Redirect
          to={`/${Route.routeDashboard}/${activeContainer}`}
        />
      );
    }

    const errorMessage = (
      <>
        <header>
          <p>Accès refusé</p>
        </header>
        <p>{Messages.deniedAccessError}</p>
      </>
    );

    return (
      <Template
        title="Middle-office"
        subtitle="Vos projets"
        menu="logoutMenu">
        {access && (
          <AlertCustom
            target="access"
            color="danger"
            onDismiss={this.onDismiss}
            visible={visibleObject.access}
            errorMessage={errorMessage}
          />
        )}
        <Row>
          <Col
            md={{ size: 12 }}
            className="d-flex flex-column align-items-center">
            {this.displayContainers()}
          </Col>
        </Row>
      </Template>
    );
  }
}

const mapStateToProps = state => ({
  activeContainer: state.container.activeContainer.id,
  containers: state.container.containers,
  userRights: state.container.userRights.rights
});

export default connect(mapStateToProps)(DashboardChoseScene);
