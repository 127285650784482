import React from 'react';
import PropTypes from 'prop-types';

export default function Tos({ content }) {
  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: content
      }}
    />
  );
}

Tos.propTypes = {
  content: PropTypes.string.isRequired
};
