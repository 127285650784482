// @flow

import Constants from '../../Resources/Common/Constants';
import Messages from '../../Resources/Common/Messages';
import { getInfosAccountAction } from './AccountInfosActions';
import LegalService from '../../Layer/Network/Service/LegalService';

export const GET_CGU = 'GET_CGU';
export const ACCEPT_CGU = 'ACCEPT_CGU';
export const DID_NOT_ACCEPT_CGU = 'DID_NOT_ACCEPT_CGU';
export const DID_ACCEPT_CGU = 'DID_ACCEPT_CGU';
export const DISCONNECT_ACCEPT_CGU = 'DISCONNECT_ACCEPT_CGU';

// Get CGU
const getCguPending = () => ({
  type: GET_CGU,
  payload: {
    status: Constants.PENDING_STATUS
  }
});
const getCguSuccess = data => ({
  type: GET_CGU,
  payload: {
    status: Constants.SUCCESS_STATUS,
    aggregate: data
  }
});
const getCguError = error => ({
  type: GET_CGU,
  payload: {
    status: Constants.FAILURE_STATUS,
    error
  }
});

// AcceptCGU
const acceptCguPending = () => ({
  type: ACCEPT_CGU,
  payload: {
    status: Constants.PENDING_STATUS,
    aggregate: null
  }
});
const acceptCguSuccess = data => ({
  type: ACCEPT_CGU,
  payload: {
    status: Constants.SUCCESS_STATUS,
    aggregate: data
  }
});
const acceptCguError = error => ({
  type: ACCEPT_CGU,
  payload: {
    status: Constants.FAILURE_STATUS,
    aggregate: null,
    error
  }
});

export function GetCguAction() {
  return (dispatch: Function) => {
    dispatch(getCguPending());
    return LegalService.getCgu()
      .then(data => {
        dispatch(getCguSuccess(data));
      })
      .catch(err => {
        dispatch(getCguError(err));
      });
  };
}

export function AcceptCguAction() {
  return (dispatch: Function) => {
    dispatch(acceptCguPending());
    return LegalService.acceptCgu()
      .then(data => {
        dispatch(acceptCguSuccess(data));
        dispatch(getInfosAccountAction());
      })
      .catch(err => {
        let error = '';
        if (err.message === Constants.API_ERRORS.USER.FROZEN) {
          error = Messages.frozenAccount;
        }
        dispatch(acceptCguError(error));
      });
  };
}

export function didNotAcceptCgu() {
  return { type: DID_NOT_ACCEPT_CGU };
}

export function AcceptCgu() {
  return { type: DID_ACCEPT_CGU };
}

export function disconnectAcceptCgu() {
  return { type: DISCONNECT_ACCEPT_CGU };
}
