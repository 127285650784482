// @flow

import _ from 'lodash';
import Common from '../../Resources/Common';
import ContainerService from '../../Layer/Network/Service/ContainerService';
import Messages from '../../Resources/Common/Messages';
import Constants from '../../Resources/Common/Constants';

export const GET_CONTAINERS = 'GET_CONTAINERS';
export const GET_USER_RIGHTS = 'GET_USER_RIGHTS';
export const SET_ACTIVE_CONTAINER = 'SET_ACTIVE_CONTAINER';
export const CLEAR_ACTIVE_CONTAINER = 'CLEAR_ACTIVE_CONTAINER';
export const SET_SIDEBAR_TOGGLE = 'SET_SIDEBAR_TOGGLE';

// Get user rights
const getUserRightsPending = () => ({
  type: GET_USER_RIGHTS,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: null,
    error: ''
  }
});
const getUserRightsSuccess = data => ({
  type: GET_USER_RIGHTS,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: ''
  }
});
const getUserRightsFailure = error => ({
  type: GET_USER_RIGHTS,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: null,
    error
  }
});

// Get Containers
const getContainersSuccess = data => ({
  type: GET_CONTAINERS,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: ''
  }
});
const getContainersError = error => ({
  type: GET_CONTAINERS,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: null,
    error
  }
});

// Set sidebar collapse
const setSidebarToggle = toggle => ({
  type: SET_SIDEBAR_TOGGLE,
  payload: {
    aggregate: toggle
  }
});

export function getContainersAction() {
  return (dispatch: Function) =>
    ContainerService.getContainers()
      .then(data => {
        dispatch(getContainersSuccess(data));
      })
      .catch(err => {
        dispatch(getContainersError(err));
      });
}

export function getUserRightsAction(containerId: string) {
  return (dispatch: Function) => {
    dispatch(getUserRightsPending());
    return ContainerService.getUserRights(containerId).then(
      data => {
        dispatch(getUserRightsSuccess(_.uniq(data)));
      },
      err => {
        let error;
        switch (err.message) {
          case Constants.API_ERRORS.CHANGE_CONTAINER.NOT_FOUND:
            error = Messages.dashboardSettingsNoContainers;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(getUserRightsFailure(error));
      }
    );
  };
}

// Set ActiveContainer
const setActiveContainer = container => ({
  type: SET_ACTIVE_CONTAINER,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: container
  }
});

export function setActiveContainerAction(container: string) {
  return (dispatch: Function) => {
    dispatch(setActiveContainer(container));
  };
}

export function clearActiveContainer() {
  return {
    type: CLEAR_ACTIVE_CONTAINER
  };
}

export function setSidebarToggleAction(toggle: boolean) {
  return (dispatch: Function) => {
    dispatch(setSidebarToggle(toggle));
  };
}
