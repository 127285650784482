// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import ReactPlaceholder from 'react-placeholder';
// Components
import _ from 'lodash';
import Template from '../../Components/Template/TemplateComponent';
import LogContent from '../../Components/Components/Dashboard/LogContent';
// Actions
import {
  getContainerChangeAction,
  setUndefinedRemainingKindsAction
} from '../../Business/actions/FolderActions';
// Utilities
import Routes from '../../Resources/Common/Routes';

type Props = {
  activeContainer: string,
  dispatch: Function,
  history: Object,
  location: Object,
  getContainerChangeData: Object,
  getContainerChangeSuccess: boolean
};

class FolderScene extends Component<Props> {
  componentDidMount() {
    const { activeContainer, dispatch } = this.props;
    dispatch(setUndefinedRemainingKindsAction());
    if (activeContainer) {
      dispatch(getContainerChangeAction(activeContainer));
    }
  }

  render() {
    const {
      location,
      history,
      getContainerChangeData,
      getContainerChangeSuccess
    } = this.props;
    return (
      <Template
        title="Dossier"
        menu={Routes.routeFolder}
        currentPath={location}
        history={history}>
        <ReactPlaceholder
          className="my-3"
          ready={getContainerChangeData && getContainerChangeSuccess}
          type="text"
          showLoadingAnimation
          rows={8}
          color={window
            .getComputedStyle(document.body)
            .getPropertyValue('--light')}>
          <Row>
            {_.isEmpty(getContainerChangeData.proposals) &&
            getContainerChangeSuccess ? (
              <Col md={{ size: 8, offset: 2 }}>
                <p className="text-center">Votre dossier est vide</p>
              </Col>
            ) : (
              <>
                <Col md={{ size: 8, offset: 2 }}>
                  <LogContent folderView />
                </Col>
              </>
            )}
          </Row>
        </ReactPlaceholder>
      </Template>
    );
  }
}

const mapStateToProps = state => ({
  activeContainer: state.container.activeContainer.id,
  containers: state.container.containers,
  getContainerChangeData: state.folder.getContainerChangeData,
  getChangeProposalsData: state.folder.getChangeProposalsData,
  isLoadingAllChangeProposals:
    state.folder.isLoadingGetAllChangeProposals,
  getContainerChangeSuccess: state.folder.getContainerChangeSuccess
});

export default connect(mapStateToProps)(FolderScene);
