// @flow

import React from 'react';
import PropTypes from 'prop-types';
import ProjectContentDescription from '../ContentDescription';
import Tooltip from '../../../../../Resources/Common/Tooltip';

export default function Market(props) {
  const { proposalUid, blocked, hasRights } = props;
  return (
    <ProjectContentDescription
      title="Le marché"
      tooltip={Tooltip.ProjectOfferMarket.description}
      proposalUid={proposalUid}
      blocked={blocked}
      hasRights={hasRights}
    />
  );
}

Market.propTypes = {
  hasRights: PropTypes.bool.isRequired,
  blocked: PropTypes.bool.isRequired,
  proposalUid: PropTypes.string
};

Market.defaultProps = {
  proposalUid: undefined
};
