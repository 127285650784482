// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Components
import { Form, Label, FormGroup, FormFeedback } from 'reactstrap';
import Template from '../../Components/Template/TemplateComponent';
import InputCustom from '../../Components/Components/InputCustom';
import AlertCustom from '../../Components/Components/AlertCustom';
import ButtonCustom from '../../Components/Components/ButtonCustom';
import { getVisibleObject } from '../../Resources/Common/Utilities';
// Utils
import { PostResetPasswordAction } from '../../Business/actions/UserActions';
import Messages from '../../Resources/Common/Messages';

type State = {
  email: string,
  validationError: string,
  visibleObject: {
    hasSendEmail: boolean,
    validationError: boolean
  }
};

type Props = {
  dispatch: Function,
  hasSendEmail: boolean,
  postResetError: '',
  isLoadingResetPassword: boolean
};

class ResetPasswordScene extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      validationError: '',
      visibleObject: {
        hasSendEmail: false,
        postResetError: false,
        validationError: false
      }
    };
    this.onDismiss = this.onDismiss.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    this.setVisibleAlerts(prevProps, prevState);
  }

  onDismiss = target => {
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        [target]: false
      }
    }));
  };

  displayAlert = () => {
    const { hasSendEmail, postResetError } = this.props;
    const { visibleObject, validationError } = this.state;
    const alert = [];
    let key = 0;

    if (hasSendEmail) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="hasSendEmail"
          color="success"
          onDismiss={this.onDismiss}
          errorMessage={Messages.recoverPasswordEmailSend}
          visible={visibleObject.hasSendEmail}
        />
      );
    }
    if (validationError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="validationError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={validationError}
          visible={visibleObject.validationError}
        />
      );
    }
    if (postResetError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="postResetError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={postResetError}
          visible={visibleObject.postResetError}
        />
      );
    }
    return alert;
  };

  setVisibleAlerts = (prevProps, prevState) => {
    const errorPropsArray = [
      'hasSendEmail',
      'validationError',
      'postResetError'
    ];
    const { props, state } = this;
    const { hasSendEmail, postResetError } = props;
    const { validationError } = state;
    if (
      hasSendEmail !== prevProps.hasSendEmail ||
      validationError !== prevState.validationError ||
      postResetError !== prevProps.postResetError
    ) {
      let newState = Object.assign({}, this.state);
      const visibleObject = getVisibleObject(
        props,
        prevProps,
        state,
        prevState,
        errorPropsArray
      );
      newState = {
        ...newState,
        visibleObject: {
          ...newState.visibleObject,
          ...visibleObject
        }
      };
      if (Object.values(visibleObject).includes(true)) {
        this.setState(newState);
      }
    }
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  submitForgottenForm = e => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { email } = this.state;
    if (e.target.checkValidity()) {
      dispatch(PostResetPasswordAction(email));
      this.setState({ validationError: '' });
    } else {
      this.setState({ validationError: Messages.validationError });
      e.stopPropagation();
    }
    e.target.classList.add('was-validated');
  };

  render() {
    const { isLoadingResetPassword } = this.props;
    const { email } = this.state;
    return (
      <Template
        className="login-form"
        title="Middle-Office"
        subtitle="Réinitialisation du mot de passe"
        width="412px">
        {this.displayAlert()}
        <Form
          className="d-flex flex-column"
          onSubmit={this.submitForgottenForm}
          noValidate>
          <FormGroup>
            <Label for="email">E-mail</Label>
            <InputCustom
              id="email"
              placeholder="E-mail"
              value={email}
              type="email"
              onChange={this.handleEmailChange}
              required
            />
            <FormFeedback>
              {Messages.emailFrontValidation}
            </FormFeedback>
          </FormGroup>
          <ButtonCustom
            color="primary"
            disabled={isLoadingResetPassword}
            className="align-self-end mb-3">
            Réinitialiser
          </ButtonCustom>
        </Form>
      </Template>
    );
  }
}

const mapStateToProps = state => ({
  postResetError: state.user.postResetError,
  hasSendEmail: state.user.hasSendEmail,
  isLoadingResetPassword: state.user.isLoadingResetPassword
});

export default connect(mapStateToProps)(ResetPasswordScene);
