// @flow

// React libs
import React from 'react';
// Boostrap components
import { Button } from 'reactstrap';

const ButtonCustom = (button: Object) => (
  <Button {...button}>{button.children}</Button>
);

export default ButtonCustom;
