// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Form,
  Label,
  FormGroup,
  FormFeedback,
  Row,
  Col
} from 'reactstrap';
import FacebookComponent from '../../Components/Components/Svgs/FacebookComponent';
import LinkedinComponent from '../../Components/Components/Svgs/LinkedinComponent';
import GoogleComponent from '../../Components/Components/Svgs/GoogleComponent';
// Components
import Template from '../../Components/Template/TemplateComponent';
import InputCustom from '../../Components/Components/InputCustom';
import ButtonCustom from '../../Components/Components/ButtonCustom';
import AlertCustom from '../../Components/Components/AlertCustom';
// Utils
import { getVisibleObject } from '../../Resources/Common/Utilities';
import { PostLoginAction } from '../../Business/actions/LoginActions';
import Route from '../../Resources/Common/Routes';
import Messages from '../../Resources/Common/Messages';
import Constants from '../../Resources/Common/Constants';
import {
  AcceptCgu,
  didNotAcceptCgu
} from '../../Business/actions/LegalActions';

type State = {
  email: string,
  password: string,
  submitted: boolean,
  validationError: string,
  visibleObject: {
    alertMessage: boolean,
    error: boolean,
    postSocialLoginError: boolean,
    preRegisterError: boolean,
    validationError: boolean,
    isRedirected: boolean
  }
};

type Props = {
  cguData: string,
  dispatch: Function,
  error: string,
  alertMessage: string,
  isLoadingToken: boolean,
  containerSuccess: boolean,
  postLoginSuccess: boolean,
  isLoadingPostLogin: boolean,
  isValidToken: boolean,
  postSocialLoginError: boolean,
  hasAcceptedPrerequisites: boolean | typeof undefined,
  needCguAcceptance: boolean | typeof undefined,
  preRegisterError: string,
  location: Object
};

class LoginScene extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      email: '',
      submitted: false,
      visibleObject: {
        alertMessage: false,
        error: false,
        postSocialLoginError: false,
        preRegisterError: false,
        validationError: false,
        isRedirected: false
      }
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { isRedirected } = location;
    if (isRedirected) {
      this.setState(prevState => ({
        visibleObject: {
          ...prevState.visibleObject,
          isRedirected: true
        }
      }));
    }
  }

  shouldComponentUpdate(nextProps) {
    const { submitted } = this.state;
    return !(
      submitted &&
      // eslint-disable-next-line eqeqeq
      (nextProps.hasAcceptedPrerequisites == undefined ||
        nextProps.isLoadingToken)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    this.setSubmited(prevProps);
    this.setVisibleAlerts(prevProps, prevState);
    this.getCguAcceptance(prevProps);
  }

  getCguAcceptance = prevProps => {
    const { hasAcceptedPrerequisites, dispatch } = this.props;
    if (
      hasAcceptedPrerequisites !== undefined &&
      hasAcceptedPrerequisites !== prevProps.hasAcceptedPrerequisites
    ) {
      if (hasAcceptedPrerequisites) {
        dispatch(AcceptCgu());
      } else {
        dispatch(didNotAcceptCgu());
      }
    }
  };

  setSubmited = prevProps => {
    const { postLoginSuccess } = this.props;
    if (
      postLoginSuccess &&
      postLoginSuccess !== prevProps.postLoginSuccess
    ) {
      this.setState({ visible: true, submitted: true });
    }
  };

  displayAlert = () => {
    const {
      alertMessage,
      error,
      postSocialLoginError,
      preRegisterError,
      location
    } = this.props;
    const { isRedirected } = location;
    const { validationError, visibleObject } = this.state;
    const alert = [];
    let key = 0;

    if (validationError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="validationError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={validationError}
          visible={visibleObject.validationError}
        />
      );
    }
    if (isRedirected) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="isRedirected"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={Messages.loginMustBeConnected}
          visible={visibleObject.isRedirected}
        />
      );
    }
    if (alertMessage) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="alertMessage"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={alertMessage}
          visible={visibleObject.alertMessage}
        />
      );
    }
    if (error) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="error"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={error}
          visible={visibleObject.error}
        />
      );
    }
    if (postSocialLoginError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="postSocialLoginError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={postSocialLoginError}
          visible={visibleObject.postSocialLoginError}
        />
      );
    }
    if (preRegisterError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="preRegisterError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={preRegisterError}
          visible={visibleObject.preRegisterError}
        />
      );
    }
    return alert;
  };

  onDismiss = target => {
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        [target]: false
      }
    }));
  };

  setVisibleAlerts = (prevProps, prevState) => {
    const errorArray = [
      'alertMessage',
      'error',
      'postSocialLoginError',
      'preRegisterError',
      'validationError'
    ];
    const { props, state } = this;
    const {
      alertMessage,
      error,
      postSocialLoginError,
      preRegisterError
    } = props;
    const { validationError } = state;
    if (
      alertMessage !== prevProps.alertMessage ||
      error !== prevProps.error ||
      postSocialLoginError !== prevProps.postSocialLoginError ||
      preRegisterError !== prevProps.preRegisterError ||
      validationError !== prevState.validationError
    ) {
      let newState = Object.assign({}, this.state);
      const visibleObject = getVisibleObject(
        props,
        prevProps,
        state,
        prevState,
        errorArray
      );
      newState = {
        ...newState,
        visibleObject: {
          ...newState.visibleObject,
          ...visibleObject
        }
      };
      if (Object.values(visibleObject).includes(true)) {
        this.setState(newState);
      }
    }
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  submitLoginForm = e => {
    const { dispatch } = this.props;
    const { password, email } = this.state;
    e.preventDefault();
    if (e.target.checkValidity()) {
      this.setState({ validationError: '' });
      dispatch(PostLoginAction(email, password, true));
    } else {
      this.setState({ validationError: Messages.validationError });
      e.stopPropagation();
    }
    e.target.classList.add('was-validated');
  };

  render() {
    const {
      cguData,
      isLoadingPostLogin,
      containerSuccess,
      isValidToken,
      isLoadingToken,
      needCguAcceptance
    } = this.props;
    const { email, password } = this.state;

    if (needCguAcceptance !== undefined) {
      if (
        // If we go to LoginScene while connected we should redirect
        (!isLoadingPostLogin &&
          !isLoadingToken &&
          isValidToken &&
          !needCguAcceptance) ||
        (isValidToken && !needCguAcceptance && containerSuccess)
      ) {
        return <Redirect to={Route.routeChoseDashboard} />;
      }

      if (cguData && needCguAcceptance && isValidToken) {
        return <Redirect to={Route.acceptTos} />;
      }
    }

    return (
      <Template
        className="login-form"
        title="Middle-Office"
        width="412px">
        {this.displayAlert()}
        <>
          <Row>
            <Col md={{ size: 12 }}>
              <Form
                className="d-flex flex-column my-4"
                onSubmit={this.submitLoginForm}
                noValidate>
                <FormGroup>
                  <Label for="email">E-mail</Label>
                  <InputCustom
                    placeholder="E-mail"
                    type="email"
                    id="email"
                    value={email}
                    onChange={this.handleEmailChange}
                    required
                    autoComplete="email"
                  />
                  <FormFeedback>
                    {Messages.emailFrontValidation}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="password">Mot de passe</Label>
                  <InputCustom
                    placeholder="Mot de passe"
                    id="password"
                    value={password}
                    onChange={this.handlePasswordChange}
                    required
                    type="password"
                    minLength="6"
                    autoComplete="current-password"
                  />
                  <FormFeedback>
                    {Messages.passwordFrontValidation}
                  </FormFeedback>
                </FormGroup>
                <ButtonCustom
                  type="submit"
                  color="primary"
                  disabled={isLoadingPostLogin}
                  className="mb-3 align-self-end">
                  Se connecter
                </ButtonCustom>
                <a
                  href={Route.routeResetPassword}
                  className="align-self-end">
                  Mot de passe oublié
                </a>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 12 }}>
              <a href={Constants.googleUri}>
                <ButtonCustom className="mr-3" color="primary">
                  <GoogleComponent />
                  Google
                </ButtonCustom>
              </a>
              <a href={Constants.facebookUri}>
                <ButtonCustom className="mr-3" color="primary">
                  <FacebookComponent />
                  Facebook
                </ButtonCustom>
              </a>
              <a href={Constants.linkedinUri}>
                <ButtonCustom color="primary">
                  <LinkedinComponent />
                  Linkedin
                </ButtonCustom>
              </a>
            </Col>
          </Row>
        </>
      </Template>
    );
  }
}

const mapStateToProps = state => ({
  error: state.login.error,
  alertMessage: state.token.alertMessage,
  isLoadingPostLogin: state.login.isLoadingPostLogin,
  postLoginSuccess: state.login.postLoginSuccess,
  cguData: state.legal.cguData,
  needCguAcceptance: state.legal.needCguAcceptance,
  isLoadingToken: state.token.isLoading,
  isValidToken: state.token.isValidToken,
  containerSuccess: state.container.containerSuccess,
  postSocialLoginError: state.login.postSocialLoginError,
  hasAcceptedPrerequisites: state.account.hasAcceptedPrerequisites,
  preRegisterError: state.user.preRegisterError
});

export default connect(mapStateToProps)(LoginScene);
