// @flow

// React Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import _ from 'lodash';
// Components
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import InputCustom from '../../InputCustom';
import FrontValidation from '../../../../Resources/Common/FrontValidation';
// Actions
import { getChangeContainerDocumentAction } from '../../../../Business/actions/FolderActions';

type Props = {
  changeProposalsData: Array<any>,
  hasRights: boolean,
  blocked: boolean,
  containerDocument: Object,
  activeContainer: string,
  dispatch: Function,
  isLoadingGetContainerDocument: boolean,
  proposalUid: string,
  operatorEmail: string
};

type State = {
  logo: { url: string, text: string },
  photo: { url: string, text: string },
  coversheet: { url: string, text: string },
  logoRequired: boolean,
  photoRequired: boolean,
  coversheetRequired: boolean,
  containerDocumentArray: Array<any>,
  documentsToGet: Array<any>,
  changeProposalsFiltered: Object
};

class MediaDisplay extends Component<Props, State> {
  constructor(props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = _.pick(
      _.defaultsDeep(_.get(changeProposalsFiltered, '[0].content'), {
        logo: { url: '', text: '' },
        photo: { url: '', text: '' },
        coversheet: { url: '', text: '' }
      }),
      ['logo', 'photo', 'coversheet']
    );
    this.state.logoRequired = !_.get(
      changeProposalsFiltered,
      '[0].content.logo'
    );
    this.state.photoRequired = !_.get(
      changeProposalsFiltered,
      '[0].content.photo'
    );
    this.state.coversheetRequired = !_.get(
      changeProposalsFiltered,
      '[0].content.coversheet'
    );

    this.state.containerDocumentArray = [];
    this.state.documentsToGet =
      changeProposalsFiltered && changeProposalsFiltered[0]
        ? [
            changeProposalsFiltered[0].content.logo,
            changeProposalsFiltered[0].content.photo,
            changeProposalsFiltered[0].content.coversheet
          ]
        : [];
    this.state.changeProposalsFiltered = changeProposalsFiltered;
  }

  componentDidMount = () => {
    const { dispatch, activeContainer, operatorEmail } = this.props;
    const { documentsToGet } = this.state;
    if (
      documentsToGet.length > 0 &&
      typeof documentsToGet[0] === 'string'
    ) {
      dispatch(
        getChangeContainerDocumentAction({
          containerId: activeContainer,
          docUuid: documentsToGet[0],
          operatorEmail
        })
      );
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      containerDocument,
      dispatch,
      activeContainer,
      isLoadingGetContainerDocument,
      operatorEmail
    } = this.props;
    const { documentsToGet } = this.state;
    if (
      containerDocument &&
      Object.keys(containerDocument).length !== 0 &&
      containerDocument.constructor === Object &&
      !_.isEqual(containerDocument, prevProps.containerDocument)
    ) {
      this.prepareDocuments();
    }
    if (
      !isLoadingGetContainerDocument &&
      documentsToGet.length > 0 &&
      !_.isEqual(documentsToGet, prevState.documentsToGet) &&
      typeof documentsToGet[0] === 'string'
    ) {
      dispatch(
        getChangeContainerDocumentAction({
          containerId: activeContainer,
          docUuid: documentsToGet[0],
          operatorEmail
        })
      );
    }
  };

  prepareDocuments = () => {
    const { containerDocument } = this.props;
    const { containerDocumentArray, documentsToGet } = this.state;
    const newContainerDocumentArray = _.clone(containerDocumentArray);
    const newDocumentsToGet = _.clone(documentsToGet);
    if (
      Object.keys(containerDocument).length !== 0 &&
      containerDocument.constructor === Object
    ) {
      newContainerDocumentArray.push(containerDocument);
      newDocumentsToGet.shift();
      this.setState({
        containerDocumentArray: newContainerDocumentArray,
        documentsToGet: newDocumentsToGet
      });
    }
  };

  handleDocument = (e, fieldName) => {
    e.persist();
    this.setState(prevState => ({
      [fieldName]: {
        ...prevState[fieldName],
        text: e.target.value.replace(/C:\\fakepath\\/i, '')
      }
    }));
  };

  render() {
    const {
      logo,
      photo,
      coversheet,
      logoRequired,
      photoRequired,
      coversheetRequired,
      containerDocumentArray,
      documentsToGet,
      changeProposalsFiltered
    } = this.state;
    const { hasRights, blocked } = this.props;
    return (
      <>
        <span className="mb-2">Logo principal</span>
        <FormGroup>
          <InputCustom
            type="file"
            className="custom-file-input document"
            id="logo"
            name="document.logo"
            onChange={e => {
              this.handleDocument(e, 'logo');
            }}
            disabled={!hasRights || blocked}
            required={logoRequired}
          />
          <Label className="custom-file-label" for="plop">
            {logo.text !== '' ? logo.text : 'Choisir un fichier'}
          </Label>
          <FormFeedback>
            {FrontValidation.documentFrontValidation}
          </FormFeedback>
          <div className="mt-3">
            {documentsToGet.length > 0 &&
            containerDocumentArray[0] &&
            containerDocumentArray[0].url ? (
              <div className="sweet-loading">
                <ClipLoader
                  css="margin: 'O auto';"
                  sizeUnit="px"
                  loading={documentsToGet.length > 0}
                />
              </div>
            ) : (
              <>
                {containerDocumentArray &&
                  containerDocumentArray[0] &&
                  containerDocumentArray[0].url && (
                    <img
                      src={
                        containerDocumentArray &&
                        containerDocumentArray[0] &&
                        containerDocumentArray[0].url
                      }
                      className="mw-100"
                      alt="document"
                    />
                  )}
              </>
            )}
          </div>
          <InputCustom
            type="hidden"
            name="logo"
            value={
              logoRequired
                ? ''
                : changeProposalsFiltered[0] &&
                  _.has(changeProposalsFiltered[0], 'content.logo') &&
                  changeProposalsFiltered[0].content.logo
            }
          />
        </FormGroup>
        <span className="mb-2">
          Image photo entreprise (1280x960px)
        </span>
        <FormGroup>
          <InputCustom
            type="file"
            className="custom-file-input document"
            id="photo"
            name="document.photo"
            onChange={e => {
              this.handleDocument(e, 'photo');
            }}
            disabled={!hasRights || blocked}
            required={photoRequired}
          />
          <Label className="custom-file-label" for="document">
            {photo.text !== '' ? photo.text : 'Choisir un fichier'}
          </Label>
          <FormFeedback>
            {FrontValidation.documentFrontValidation}
          </FormFeedback>
          <div className="mt-3">
            {documentsToGet.length > 0 ? (
              <div className="sweet-loading">
                <ClipLoader
                  css="margin: 'O auto';"
                  sizeUnit="px"
                  loading={documentsToGet.length > 0}
                />
              </div>
            ) : (
              <>
                {containerDocumentArray &&
                  containerDocumentArray[1] &&
                  containerDocumentArray[1].url && (
                    <img
                      src={
                        containerDocumentArray &&
                        containerDocumentArray[1] &&
                        containerDocumentArray[1].url
                      }
                      className="mw-100"
                      alt="document"
                    />
                  )}
              </>
            )}
          </div>
          <InputCustom
            type="hidden"
            name="photo"
            value={
              photoRequired
                ? ''
                : changeProposalsFiltered[0] &&
                  _.has(
                    changeProposalsFiltered[0],
                    'content.photo'
                  ) &&
                  changeProposalsFiltered[0].content.photo
            }
          />
        </FormGroup>

        <span className="mb-2">Coversheet minifiche (560x360px)</span>
        <FormGroup>
          <InputCustom
            type="file"
            className="custom-file-input document"
            id="coversheet"
            name="document.coversheet"
            onChange={e => {
              this.handleDocument(e, 'coversheet');
            }}
            disabled={!hasRights || blocked}
            required={coversheetRequired}
          />
          <Label className="custom-file-label" for="document">
            {coversheet.text !== ''
              ? coversheet.text
              : 'Choisir un fichier'}
          </Label>
          <FormFeedback>
            {FrontValidation.documentFrontValidation}
          </FormFeedback>
          <div className="mt-3">
            {documentsToGet.length > 0 ? (
              <div className="sweet-loading">
                <ClipLoader
                  css="margin: 'O auto';"
                  sizeUnit="px"
                  loading={documentsToGet.length > 0}
                />
              </div>
            ) : (
              <>
                {containerDocumentArray &&
                  containerDocumentArray[2] &&
                  containerDocumentArray[2].url && (
                    <img
                      src={
                        containerDocumentArray &&
                        containerDocumentArray[2] &&
                        containerDocumentArray[2].url
                      }
                      className="mw-100"
                      alt="document"
                    />
                  )}
              </>
            )}
          </div>
          <InputCustom
            type="hidden"
            name="coversheet"
            value={
              coversheetRequired
                ? ''
                : changeProposalsFiltered[0] &&
                  _.has(
                    changeProposalsFiltered[0],
                    'content.coversheet'
                  ) &&
                  changeProposalsFiltered[0].content.coversheet
            }
          />
        </FormGroup>
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData,
  containerDocument: state.folder.getContainerDocument,
  activeContainer: state.container.activeContainer.id,
  isLoadingGetContainerDocument:
    state.folder.isLoadingGetContainerDocument,
  operatorEmail: state.container.activeContainer.operatorEmail
});

export default withRouter(connect(mapStateToProps)(MediaDisplay));
