// @flow

// Services
import LoginService from '../../Layer/Network/Service/LoginService';
// Common
import Common from '../../Resources/Common';
// Actions
import { getInfosAccountAction } from './AccountInfosActions';
// Messages
import Messages from '../../Resources/Common/Messages';
import { getContainersAction } from './ContainerActions';
import { disconnectAcceptCgu, GetCguAction } from './LegalActions';
import Constants from '../../Resources/Common/Constants';
import { clearAlertMessage } from './TokenActions';
import LocalStorage from '../../Layer/Storage/LocalStorage';

export const POST_LOGIN = 'POST_LOGIN';
export const AUTH_SET_DISCONNECT = 'AUTH_SET_DISCONNECT';
export const POST_SOCIAL_LOGIN = 'POST_SOCIAL_LOGIN';

// Login info
const postLoginPending = () => ({
  type: POST_LOGIN,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: null,
    error: ''
  }
});
const postLoginSuccess = info => ({
  type: POST_LOGIN,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: info,
    error: ''
  }
});
const postLoginFailure = error => ({
  type: POST_LOGIN,
  payload: {
    error,
    status: Common.Constants.FAILURE_STATUS,
    aggregate: null
  }
});

// Social Login
const postSocialLoginPending = () => ({
  type: POST_SOCIAL_LOGIN,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: null,
    error: ''
  }
});
const postSocialLoginSuccess = info => ({
  type: POST_SOCIAL_LOGIN,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: info,
    error: ''
  }
});
const postSocialLoginFailure = error => ({
  type: POST_SOCIAL_LOGIN,
  payload: {
    error,
    status: Common.Constants.FAILURE_STATUS,
    aggregate: null
  }
});

export function logoutAction() {
  return (dispatch: Function) => {
    dispatch(disconnectAcceptCgu());
    LoginService.logout().then(() => {
      LocalStorage.delete('persist:root');
      window.location.href = '/';
    });
  };
}

export function PostLoginAction(
  email: string,
  password: string,
  remember: boolean
) {
  return (dispatch: Function) => {
    dispatch(postLoginPending());
    return LoginService.postLogin(email, password, remember)
      .then(data => {
        dispatch(clearAlertMessage());
        dispatch(postLoginSuccess(data));
        dispatch(getInfosAccountAction());
        dispatch(GetCguAction());
        dispatch(getContainersAction());
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.GENERAL.UNKNOWN_ERROR:
          case Constants.API_ERRORS.MALFORMED:
            error = Messages.loginWrongPassword;
            break;
          case Constants.API_ERRORS.USER.INVALID_CREDENTIALS:
            error = Messages.loginWrongPassword;
            break;
          case Constants.API_ERRORS.USER.FORBIDDEN:
          case Constants.API_ERRORS.USER.FROZEN:
            error = Messages.loginRefusedAccess;
            break;
          case Constants.API_ERRORS.USER.TOO_MANY_REQUEST:
          case Constants.API_ERRORS.JWT.TOO_MANY_REQUESTS:
            error = Messages.loginAccesTimeout;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(postLoginFailure(error));
      })
      .catch(err => err);
  };
}

export function PostSocialLoginAction({
  network,
  code,
  accessToken,
  invitationToken
}: {
  network: string,
  code: string,
  accessToken: string,
  invitationToken: string
}) {
  return (dispatch: Function) => {
    dispatch(postSocialLoginPending());
    return LoginService.postSocialLogin({
      network,
      code,
      accessToken,
      invitationToken
    })
      .then(data => {
        dispatch(clearAlertMessage());
        dispatch(postSocialLoginSuccess(data));
        dispatch(getInfosAccountAction());
        dispatch(getContainersAction());
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.GENERAL.UNKNOWN_ERROR:
          case Constants.API_ERRORS.MALFORMED:
            error = Messages.loginWrongPassword;
            break;
          case Constants.API_ERRORS.USER.FORBIDDEN:
          case Constants.API_ERRORS.USER.FROZEN:
            error = Messages.loginRefusedAccess;
            break;
          case Constants.API_ERRORS.USER.TOO_MANY_REQUEST:
            error = Messages.loginAccesTimeout;
            break;
          case Constants.API_ERRORS.JWT.TOO_MANY_REQUESTS:
            error = Messages.loginAccesTimeout;
            break;
          case Constants.API_ERRORS.USER.MANDATORY_INVITATIONS:
            error = Messages.mandatoryInvitations;
            break;
          case Constants.API_ERRORS.USER.ALREADY_ENROLLED:
            error = Messages.alreadyEnrolled;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(postSocialLoginFailure(error));
      })
      .catch(err => err);
  };
}
