// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Label, FormGroup, Input, FormFeedback } from 'reactstrap';
import DateTime from 'react-datetime';
import 'moment/locale/fr';
import _ from 'lodash';
import update from 'immutability-helper';
// Components
import InputCustom from '../../../../InputCustom';
import ButtonCustom from '../../../../ButtonCustom';
import Tooltip from '../../../../../../Resources/Common/Tooltip';
import MarkdownPreview from '../../../../TextEditor/MarkdownPreview';
import FrontValidation from '../../../../../../Resources/Common/FrontValidation';
import { isImage } from '../../../../../../Resources/Common/Utilities';
import TruncateCustom from '../../../../TruncateCustom';
import Constants from '../../../../../../Resources/Common/Constants';
// Actions
import { getChangeContainerDocumentAction } from '../../../../../../Business/actions/FolderActions';

type Props = {
  firstName: string,
  lastName: string,
  hasRights: boolean,
  blocked: boolean,
  index: number,
  changeProposalsData: Array<any>,
  containerDocument: Object,
  dispatch: Function,
  activeContainer: string,
  isLoadingGetContainerDocument: boolean,
  containerDocumentUuid: string,
  newElement: boolean,
  proposalUid: string,
  operatorEmail: string
};

type State = {
  currentKind: 0 | 1,
  isFeatured: boolean,
  document: {
    url: string,
    text: string
  },
  firstName: string,
  lastName: string,
  job: string,
  smallBiography: string,
  joinDate: Date,
  biography: string,
  kind: 0 | 1,
  url: string,
  company: string,
  photo: string,
  containerDocumentArray: Array<any>,
  changeProposalsFiltered: Object,
  collapse: {
    firstName: boolean,
    lastName: boolean,
    job: boolean,
    smallBiography: boolean,
    joinDate: boolean,
    biography: boolean,
    kind: boolean,
    url: boolean,
    company: boolean,
    photo: boolean,
    containerDocumentArray: boolean,
    changeProposalsFiltered: boolean,
    isFeatured: boolean
  },
  collapsePeople: boolean
};

class PeoplesInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = props.newElement
      ? {
          isFeatured: false,
          firstName: '',
          lastName: '',
          job: '',
          smallBiography: '',
          joinDate: new Date(),
          biography: '',
          kind: 0,
          url: '',
          company: '',
          photo: '',
          containerDocumentArray: [],
          document: {
            text: '',
            url: ''
          },
          currentKind: 0
        }
      : _.pick(
          _.defaults(
            _.get(
              changeProposalsFiltered,
              `[0].content.people[${props.index}]`
            ),
            {
              isFeatured: false,
              firstName: '',
              lastName: '',
              job: '',
              smallBiography: '',
              joinDate: new Date(),
              biography: '',
              kind: 0,
              url: '',
              company: '',
              photo: '',
              containerDocumentArray: [],
              document: {
                text: '',
                url: ''
              },
              currentKind: 0
            }
          ),
          [
            'isFeatured',
            'firstName',
            'lastName',
            'job',
            'smallBiography',
            'joinDate',
            'biography',
            'kind',
            'url',
            'company',
            'photo',
            'currentKind'
          ]
        );
    this.state.document = {
      text: '',
      url: ''
    };
    this.state.containerDocumentArray = [];
    this.state.changeProposalsFiltered = changeProposalsFiltered;
    this.state.collapse = {
      firstName: true,
      lastName: true,
      job: true,
      smallBiography: true,
      joinDate: true,
      biography: true,
      kind: true,
      url: true,
      company: true,
      photo: true,
      containerDocumentArray: true,
      changeProposalsFiltered: true,
      isFeatured: true
    };
    this.state.collapsePeople = true;
  }

  componentDidMount = () => {
    const {
      dispatch,
      activeContainer,
      index,
      operatorEmail
    } = this.props;
    const { changeProposalsFiltered } = this.state;
    if (
      changeProposalsFiltered &&
      changeProposalsFiltered[0] &&
      _.has(
        changeProposalsFiltered[0],
        `content.people[${index}].photo`
      )
    ) {
      dispatch(
        getChangeContainerDocumentAction({
          containerId: activeContainer,
          docUuid: _.get(
            changeProposalsFiltered,
            `[0].content.people[${index}].photo`
          ),
          operatorEmail
        })
      );
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      containerDocument,
      dispatch,
      activeContainer,
      changeProposalsData,
      index,
      operatorEmail
    } = this.props;
    const { containerDocumentArray } = this.state;
    if (
      containerDocument &&
      _.isPlainObject(containerDocument) &&
      !_.isEmpty(containerDocument) &&
      !_.isEqual(containerDocument, prevProps.containerDocument)
    ) {
      this.prepareDocuments();
    }
    if (
      containerDocumentArray &&
      !_.isEqual(
        containerDocumentArray,
        prevState.containerDocumentArray
      )
    ) {
      this.updateDocument();
    }
    if (
      changeProposalsData[0] &&
      _.has(changeProposalsData[0], `content`) &&
      !_.isEqual(changeProposalsData, prevProps.changeProposalsData)
    ) {
      dispatch(
        getChangeContainerDocumentAction({
          containerId: activeContainer,
          docUuid: _.get(
            changeProposalsData,
            `[0].content.people[${index}].photo`
          ),
          operatorEmail
        })
      );
    }
  };

  handleCollapse = tooltip => () => {
    const { collapse } = this.state;
    this.setState(prevState => ({
      collapse: {
        ...prevState.collapse,
        [tooltip]: !collapse[tooltip]
      }
    }));
  };

  prepareDocuments = () => {
    const {
      containerDocument,
      index,
      containerDocumentUuid,
      newElement
    } = this.props;
    const {
      containerDocumentArray,
      changeProposalsFiltered
    } = this.state;
    if (!newElement) {
      if (
        _.isPlainObject(containerDocument) &&
        !_.isEmpty(containerDocument) &&
        containerDocumentUuid ===
          _.get(
            changeProposalsFiltered,
            `[0].content.people[${index}].photo`
          )
      ) {
        this.setState({
          containerDocumentArray: update(containerDocumentArray, {
            [index]: {
              $set: containerDocument
            }
          })
        });
      }
    }
  };

  updateDocument = () => {
    const { index, newElement } = this.props;
    const { document, containerDocumentArray } = this.state;
    if (
      !newElement &&
      containerDocumentArray &&
      containerDocumentArray[index]
    ) {
      this.setState({
        document: update(document, {
          url: {
            $set: containerDocumentArray[index].url
          }
        })
      });
    }
  };

  handleDocument = e => {
    const { document } = this.state;
    e.persist();
    this.setState({
      document: update(document, {
        text: {
          $set: e.target.value.replace(/C:\\fakepath\\/i, '')
        }
      })
    });
  };

  handleIsFeatured = () => e => {
    e.persist();
    this.setState({
      isFeatured: e.target.checked
    });
  };

  handleCollapsePeople = e => {
    e.preventDefault();
    const { collapsePeople } = this.state;
    this.setState({ collapsePeople: !collapsePeople });
  };

  render() {
    const { index, blocked, hasRights } = this.props;
    const {
      changeProposalsFiltered,
      collapse,
      collapsePeople
    } = this.state;
    const {
      currentKind,
      document,
      documentRequired,
      smallBiography,
      job,
      joinDate,
      biography,
      isFeatured,
      url,
      company
    } = this.state;

    const infoTitles = {
      firstName: "Prénom du membre d'équipe",
      lastName: "Nom du membre d'équipe",
      job: 'Position',
      smallBiography: 'Biographie courte',
      biography: 'Biographie',
      photo: "Photo du membre de l'équipe",
      kind: 'Catégorie',
      url: 'Profil Linkedin',
      isFeatured: 'Featured flag',
      company: 'Société du membre du board',
      joinDate: "no_label/Date d'embauche"
    };

    const textElements = ['firstName', 'lastName'];

    const textFormElements = textElements.map(textElement => {
      const { [textElement]: defaultValue } = this.state;
      return (
        <FormGroup key={textElement}>
          <Label for={`${textElement}_${index}`}>
            {infoTitles[textElement]}
          </Label>
          <FormFieldDescription
            label={textElement}
            collapse={collapse}
            handleCollapse={this.handleCollapse}
          />
          <InputCustom
            id={`${textElement}_${index}`}
            name={`people.[${index}].${textElement}`}
            defaultValue={defaultValue}
            disabled={!hasRights || blocked}
            minLength="1"
            maxLength="100"
            pattern={Constants.pattern.internationalName}
            required
          />
          <FormFeedback>
            {FrontValidation.ProjectOfferPeople[textElement]}
          </FormFeedback>
        </FormGroup>
      );
    });

    return (
      <>
        {textFormElements}
        <div
          className={
            collapsePeople
              ? 'collase_people d-none'
              : 'collapse_people d-block'
          }>
          <FormGroup key="job">
            <Label for={`job_${index}`}>{infoTitles.job}</Label>
            <FormFieldDescription
              label="job"
              collapse={collapse}
              handleCollapse={this.handleCollapse}
            />
            <InputCustom
              id={`job_${index}`}
              name={`people.[${index}].job`}
              defaultValue={job}
              disabled={!hasRights || blocked}
              minLength="1"
              maxLength="100"
              pattern={Constants.pattern.internationalName}
              required
            />
            <FormFeedback>
              {FrontValidation.ProjectOfferPeople.job}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for={`smallBiography_${index}`}>
              {infoTitles.smallBiography}
            </Label>
            <FormFieldDescription
              label="smallBiography"
              collapse={collapse}
              handleCollapse={this.handleCollapse}
            />
            <textarea
              id={`smallBiography_${index}`}
              name={`people.[${index}].smallBiography`}
              className="form-control"
              rows={5}
              disabled={!hasRights || blocked}
              defaultValue={smallBiography}
              minLength={20}
              maxLength={200}
            />
            <FormFeedback>
              {FrontValidation.ProjectOfferPeople.shortBiography}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for={`biography_${index}`}>
              {infoTitles.biography}
            </Label>
            <FormFieldDescription
              label="biography"
              collapse={collapse}
              handleCollapse={this.handleCollapse}
            />
            <MarkdownPreview
              id={`biography_${index}`}
              name={`people.[${index}].biography`}
              value={biography}
              readOnly={!hasRights || blocked}
              minLength={20}
              maxLength={600}
              className={`markdown markdown_people.[${index}].biography`}
            />
            <FormFeedback
              id={`validation_people.[${index}].biography`}
              className="validation">
              {FrontValidation.ProjectOfferPeople.biography}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for={`joinDate_${index}`}>
              {infoTitles.joinDate}
            </Label>
            <FormFieldDescription
              label="joinDate"
              collapse={collapse}
              handleCollapse={this.handleCollapse}
            />
            <DateTime
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
              defaultValue={joinDate || new Date()}
              renderInput={props => (
                <Input
                  id={`joinDate_${index}`}
                  type="date"
                  name={`people.[${index}].joinDate`}
                  max={new Date().toISOString().split('T')[0]}
                  autoComplete="false"
                  {...props}
                />
              )}
              isValidDate={currentDate =>
                currentDate.isSameOrBefore(new Date())
              }
            />
            <FormFeedback>
              {FrontValidation.ProjectOfferPeople.joinDate}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for={`kind_${index}`}>{infoTitles.kind}</Label>
            <FormFieldDescription
              label="kind"
              collapse={collapse}
              handleCollapse={this.handleCollapse}
            />
            <Input
              type="select"
              name={`people.[${index}].kind`}
              id={`kind_${index}`}
              onChange={e => {
                this.setState({ currentKind: e.target.value });
              }}
              disabled={!hasRights || blocked}
              required
              defaultValue={currentKind}>
              <option value="">--Choisir--</option>
              {[
                "Membre de l'équipe",
                "Membre du conseil d'administration"
              ].map((option, optionIndex) => (
                <option value={`${optionIndex}`} key={option}>
                  {option}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup className="custom-control custom-checkbox">
            <InputCustom
              type="checkbox"
              className="custom-control-input"
              name={`people.[${index}].isFeatured`}
              id={`isFeatured_${index}`}
              checked={isFeatured}
              value={isFeatured}
              onChange={this.handleIsFeatured()}
              disabled={!hasRights || blocked}
            />
            <Label
              className="custom-control-label d-flex"
              for={`isFeatured_${index}`}>
              {infoTitles.isFeatured}
            </Label>
            <FormFieldDescription
              label="isFeatured"
              className="ml-1"
              collapse={collapse}
              handleCollapse={this.handleCollapse}
            />
          </FormGroup>
          <FormGroup>
            <Label for={`url_${index}`}>{infoTitles.url}</Label>
            <FormFieldDescription
              label="url"
              collapse={collapse}
              handleCollapse={this.handleCollapse}
            />
            <Input
              type="url"
              name={`people.[${index}].url`}
              id={`url_${index}`}
              disabled={!hasRights || blocked}
              pattern={Constants.pattern.uri}
              minLength="10"
              maxLength="300"
              defaultValue={url}
            />
          </FormGroup>
          {currentKind > 0 && (
            <FormGroup>
              <Label for={`company_${index}`}>
                {infoTitles.company}
              </Label>
              <FormFieldDescription
                label="company"
                collapse={collapse}
                handleCollapse={this.handleCollapse}
              />
              <Input
                type="text"
                name={`people.[${index}].company`}
                id={`company_${index}`}
                disabled={!hasRights || blocked}
                minLength="1"
                maxLength="200"
                pattern={Constants.pattern.internationalName}
                defaultValue={company}
              />
            </FormGroup>
          )}
          <Label for={`${index}_photo`}>{infoTitles.photo}</Label>
          <FormFieldDescription
            label="photo"
            collapse={collapse}
            handleCollapse={this.handleCollapse}
          />
          <FormGroup>
            <InputCustom
              type="file"
              className="custom-file-input"
              id={`photo${index}`}
              name={`document.${index}.photo`}
              onChange={e => {
                this.handleDocument(e);
              }}
              disabled={!hasRights || blocked}
              required={documentRequired}
            />
            <Label
              className="custom-file-label"
              for={`document_${index}`}>
              {document.text !== ''
                ? document.text
                : 'Choisir un fichier'}
            </Label>
            <FormFeedback>
              {FrontValidation.documentFrontValidation}
            </FormFeedback>
            {document.url && (
              <div className="mt-3">
                {document.url && (
                  <>
                    {isImage(document.url) ? (
                      <img
                        src={document.url}
                        className="mw-100"
                        alt="document"
                      />
                    ) : (
                      <a href={document.url}>
                        Télécharger le document
                      </a>
                    )}
                  </>
                )}
              </div>
            )}
            <InputCustom
              type="hidden"
              name={`people.[${index}].photo`}
              value={
                documentRequired
                  ? ''
                  : _.get(
                      changeProposalsFiltered,
                      `[0].content.people[${index}].photo`
                    )
              }
            />
          </FormGroup>
        </div>
        <ButtonCustom
          className="mb-3"
          onClick={this.handleCollapsePeople}
          color="primary">
          {collapsePeople
            ? 'Afficher le membre'
            : 'Masquer le membre'}
        </ButtonCustom>
      </>
    );
  }
}

const FormFieldDescription = ({
  label,
  className = '',
  collapse,
  handleCollapse
}: {
  label: string,
  className?: string,
  collapse: boolean,
  handleCollapse: Function
}) => (
  <small
    className={`form-text text-muted mb-1 mt-0 ${className || ''}`}>
    <TruncateCustom
      collapse={collapse[label]}
      tooltip={
        Tooltip.ProjectOfferPeople[label]
          ? Tooltip.ProjectOfferPeople[label]
          : ''
      }
      handleCollapse={handleCollapse([label])}
    />
  </small>
);

FormFieldDescription.defaultProps = { className: '' };

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData,
  containerDocument: state.folder.getContainerDocument,
  activeContainer: state.container.activeContainer.id,
  isLoadingGetContainerDocument:
    state.folder.isLoadingGetContainerDocument,
  containerDocumentUuid: state.folder.getContainerDocumentUuid,
  operatorEmail: state.container.activeContainer.operatorEmail
});

export default withRouter(connect(mapStateToProps)(PeoplesInfo));
