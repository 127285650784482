// @flow

// React libs
import React from 'react';
// Boostrap components
import { Input } from 'reactstrap';

const InputCustom = (input: Object) => <Input {...input} />;

export default InputCustom;
