// @flow

// React libs
import { Component } from 'react';
// Common
import Common from '../../Resources/Common';

type Props = {
  children: any
};

type State = {
  hasError: boolean
};

class ErrorBoundaryComponent extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError && process.env.NODE_ENV !== 'development') {
      window.location.replace(`/${Common.Routes.routeError}/500`);
    }
    return children;
  }
}

export default ErrorBoundaryComponent;
