// @flow
// React Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import _ from 'lodash';
import DateTime from 'react-datetime';
// Components
import { FormGroup, Label, FormFeedback, Table } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import BreakdownComponent from '../../../Charts/BreakdownComponent';
import InputCustom from '../../../InputCustom';
import ButtonCustom from '../../../ButtonCustom';
import FrontValidation from '../../../../../Resources/Common/FrontValidation';
import Tooltip from '../../../../../Resources/Common/Tooltip';
import TruncateCustom from '../../../TruncateCustom';
import Constants from '../../../../../Resources/Common/Constants';

type Props = {
  changeProposalsData: Array<any>,
  hasRights: boolean,
  blocked: boolean,
  index: number,
  newElement: boolean,
  proposalUid: string
};

type State = {
  title: string,
  amount: number,
  breakdown: Array<{
    name: string,
    value: number
  }>,
  date: Date,
  changeProposalsFiltered: Object,
  collapse: {
    title: boolean,
    amount: boolean,
    breakdown: boolean,
    date: boolean,
    changeProposalsFiltered: boolean
  }
};

class FinancingHistoryInfos extends Component<Props, State> {
  constructor(props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = props.newElement
      ? {
          title: '',
          amount: 0,
          breakdown: [
            {
              name: '',
              value: 0
            }
          ],
          date: new Date()
        }
      : _.pick(
          _.defaults(
            _.get(
              changeProposalsFiltered,
              `[0].content.fundraisings[${props.index}]`
            ),
            {
              title: '',
              amount: 0,
              breakdown: [
                {
                  name: '',
                  value: 0
                }
              ],
              date: new Date()
            }
          ),
          [
            'breakdown',
            'breakdown.name',
            'breakdown.value',
            'title',
            'amount',
            'date'
          ]
        );
    this.state.changeProposalsFiltered = changeProposalsFiltered;
    this.state.collapse = {
      title: true,
      amount: true,
      breakdown: true,
      date: true,
      changeProposalsFiltered: true
    };
  }

  handleBreakdownName = itemNumber => e => {
    e.persist();
    const { breakdown } = this.state;
    this.setState({
      breakdown: update(breakdown, {
        [itemNumber]: { name: { $set: e.target.value } }
      })
    });
  };

  handleBreakdownValue = itemNumber => e => {
    e.persist();
    const { breakdown } = this.state;
    this.setState({
      breakdown: update(breakdown, {
        [itemNumber]: { value: { $set: e.target.value } }
      })
    });
  };

  addBreakdown = () => {
    const { breakdown } = this.state;
    this.setState({
      breakdown: update(breakdown, {
        $push: [{ name: '', value: 0 }]
      })
    });
  };

  deleteBreakdownTd = index => {
    const { breakdown } = this.state;
    _.pullAt(breakdown, [index]);
    this.setState({ breakdown });
  };

  renderBreakdownInputs = () => {
    const { breakdown } = this.state;
    const { hasRights, blocked, index } = this.props;
    return breakdown.map((item, iterator) => (
      <tr key={`${iterator + 1}_breakdown_tr`}>
        <td key={`${iterator + 1}_breakdown_name_td`}>
          <InputCustom
            placeholder="Intitulé"
            id={`fundraisings.${index}.breakdown.${iterator}.name`}
            name={`fundraisings.${index}.breakdown.${iterator}.name`}
            value={item.name}
            onChange={this.handleBreakdownName(iterator)}
            minLength="1"
            maxLength="30"
            pattern={Constants.pattern.internationalName}
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.FinancialData.breakdown.name}
          </FormFeedback>
        </td>
        <td key={`${iterator + 1}_breakdown_value_td`}>
          <InputCustom
            placeholder="Valeur"
            id={`fundraisings.${index}.breakdown.${iterator}.value`}
            name={`fundraisings.${index}.breakdown.${iterator}.value`}
            type="number"
            value={item.value}
            onChange={this.handleBreakdownValue(iterator)}
            min="0"
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.FinancialData.breakdown.value}
          </FormFeedback>
        </td>
        <td>
          {hasRights && !blocked && (
            <ButtonCustom
              className="close"
              aria-label="Close"
              onClick={() => this.deleteBreakdownTd(iterator)}>
              <span aria-hidden="true">&times;</span>
            </ButtonCustom>
          )}
        </td>
      </tr>
    ));
  };

  handleCollapse = tooltip => () => {
    const { collapse } = this.state;
    this.setState(prevState => ({
      collapse: {
        ...prevState.collapse,
        [tooltip]: !collapse[tooltip]
      }
    }));
  };

  render() {
    const { hasRights, blocked, index } = this.props;
    const { title, amount, breakdown, date, collapse } = this.state;
    const style = getComputedStyle(document.body);
    const bootstrapTheme = [
      style.getPropertyValue('--primary'),
      style.getPropertyValue('--secondary'),
      style.getPropertyValue('--info'),
      style.getPropertyValue('--warning'),
      style.getPropertyValue('--danger'),
      style.getPropertyValue('--success'),
      style.getPropertyValue('--light'),
      style.getPropertyValue('--dark')
    ];
    const displayBreakdown =
      breakdown[0] &&
      _.has(breakdown[0], 'name') &&
      breakdown[0].name !== '' &&
      breakdown[0].value !== 0;
    return (
      <>
        <FormGroup>
          <Label for={`fundraisings.${index}.title`}>
            Titre de l'opération
          </Label>
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse.title}
              tooltip={Tooltip.FinancingHistory.title}
              handleCollapse={this.handleCollapse('title')}
            />
          </small>
          <InputCustom
            type="select"
            id={`fundraisings.${index}.title`}
            name={`fundraisings.${index}.title`}
            defaultValue={title}
            minLength="1"
            maxLength="100"
            pattern={Constants.pattern.internationalName}
            required>
            <option value="">--Choisir--</option>
            <option value="Première levée">Première levée</option>
            <option value="Contribution au capital des fondateurs">
              Contribution au capital des fondateurs
            </option>
            <option value="Seed money">Seed money</option>
            <option value="Love money">Love money</option>
            <option value="Pre-seed">Pre-seed</option>
          </InputCustom>
        </FormGroup>
        <FormGroup>
          <Label for={`fundraisings.${index}.amount`}>
            Valorisation post-money
          </Label>
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse.amount}
              tooltip={Tooltip.FinancingHistory.amount}
              handleCollapse={this.handleCollapse('amount')}
            />
          </small>
          <InputCustom
            type="number"
            id={`fundraisings.${index}.amount`}
            name={`fundraisings.${index}.amount`}
            defaultValue={amount}
            min="0"
            max="9999999.99"
            step="0.01"
            required
          />
          <FormFeedback>
            {FrontValidation.FinancingHistory.amount}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for={`fundraisings.${index}.date`}>
            Date de l'opération
          </Label>
          <DateTime
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
            defaultValue={date}
            renderInput={props => (
              <InputCustom
                id={`fundraisings.${index}.date`}
                type="date"
                name={`fundraisings.${index}.date`}
                autoComplete="false"
                required
                {...props}
              />
            )}
            isValidDate={currentDate =>
              currentDate.isSameOrBefore(new Date())
            }
          />
          <FormFeedback>
            {FrontValidation.FinancingHistory.date}
          </FormFeedback>
        </FormGroup>
        {breakdown.length === 0 ? (
          <>
            {hasRights && !blocked && (
              <FormGroup>
                <ButtonCustom
                  color="primary"
                  className="mb-3"
                  onClick={this.addBreakdown}>
                  Ajouter un graphique
                </ButtonCustom>
              </FormGroup>
            )}
          </>
        ) : (
          <>
            <Label for="comment.breakdown">
              Détail des contributions
            </Label>
            <small className="form-text text-muted mb-1 mt-0">
              <TruncateCustom
                collapse={collapse.breakdown}
                tooltip={Tooltip.FinancingHistory.breakdown}
                handleCollapse={this.handleCollapse('breakdown')}
              />
            </small>
            <Table striped className="table-borderless">
              <thead className="border-bottom border-primary">
                <tr>
                  <th>Type</th>
                  <th>Montant</th>
                </tr>
              </thead>
              <tbody>{this.renderBreakdownInputs()}</tbody>
            </Table>
            <ButtonCustom
              color="primary"
              className="mb-3"
              onClick={this.addBreakdown}>
              Ajouter une valeur
            </ButtonCustom>
            {displayBreakdown && (
              <div className="d-flex justify-content-center mb-3">
                <BreakdownComponent
                  width={350}
                  height={350}
                  values={breakdown}
                  colors={bootstrapTheme}
                />
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData
});

export default withRouter(
  connect(mapStateToProps)(FinancingHistoryInfos)
);
