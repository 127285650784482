// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Template from '../../Components/Template/TemplateComponent';
// Utils
import Routes from '../../Resources/Common/Routes';
import ChecklistContent from '../../Components/Components/Dashboard/ChecklistContent';

class DashboardChecklistScene extends Component<Props, State> {
  render() {
    return (
      <Template
        title="Checklist"
        subtitle="La checklist vous permet de consulter les prochaines tâches à réaliser par vous ou par Sowefund"
        menu={Routes.routeDashboard}>
        <ChecklistContent />
      </Template>
    );
  }
}

export default connect()(DashboardChecklistScene);
