// @flow

import Common from '../../Resources/Common';
import {
  GET_CGU,
  ACCEPT_CGU,
  DID_NOT_ACCEPT_CGU,
  DID_ACCEPT_CGU,
  DISCONNECT_ACCEPT_CGU
} from '../actions/LegalActions';

const defaultState = {
  isLoadingCgu: false,
  isLoadingCguAcceptance: false,
  cguData: '',
  needCguAcceptance: undefined
};

export default function LegalReducer(
  state: Object = defaultState,
  action: Object = {}
) {
  switch (action.type) {
    case GET_CGU:
      return {
        ...state,
        error: action.payload.error,
        cguData: Array.isArray(action.payload.aggregate)
          ? action.payload.aggregate[0].content
          : null,
        isLoadingCgu:
          action.payload.status === Common.Constants.PENDING_STATUS
      };
    case DID_NOT_ACCEPT_CGU:
      return {
        ...state,
        needCguAcceptance: true
      };
    case DID_ACCEPT_CGU:
      return {
        ...state,
        needCguAcceptance: false
      };
    case DISCONNECT_ACCEPT_CGU:
      return {
        ...state,
        needCguAcceptance: undefined
      };
    case ACCEPT_CGU:
      return {
        ...state,
        error: action.payload.error,
        cguData: '',
        isLoadingCguAcceptance:
          action.payload.status === Common.Constants.PENDING_STATUS,
        // While the status is still pending we consider needCguAcceptance true
        needCguAcceptance:
          action.payload.status !== Common.Constants.SUCCESS_STATUS
      };
    default:
      return state;
  }
}
