// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import Autocomplete from 'react-autocomplete';
import uniqWith from 'lodash/fp/uniqWith';
import _ from 'lodash';
// Components
import {
  Col,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Row
} from 'reactstrap';
import ReactPlaceholder from 'react-placeholder';
import InputCustom from '../InputCustom';
import AlertCustom from '../AlertCustom';
import ButtonCustom from '../ButtonCustom';
// Utils
import { getVisibleObject } from '../../../Resources/Common/Utilities';
// Actions
import { getContainerDataAction } from '../../../Business/actions/DashboardSettingsActions';
import {
  getChecklistAction,
  patchTodoAction
} from '../../../Business/actions/DashboardChecklistActions';

type Props = {
  activeContainer: string,
  todolists: Array<any>,
  containerData: Object,
  dispatch: Function,
  getTodolistError: string,
  patchTodoError: string,
  patchTodoSuccess: string,
  userRights: Array<any>,
  dashboardView: boolean
};

type State = {
  filterCompleted: boolean,
  filterTodo: boolean,
  sortAsc: boolean,
  assignation: string,
  visibleObject: {
    getTodolistError: boolean,
    patchTodoError: boolean,
    patchTodoSuccess: boolean
  }
};

class ChecklistContent extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      visibleObject: {
        getTodolistError: false,
        patchTodoError: false,
        patchTodoSuccess: false
      },
      filterCompleted: false,
      filterTodo: true,
      sortAsc: true,
      assignation: ''
    };
  }

  componentDidMount() {
    const { activeContainer, dispatch, containerData } = this.props;
    if (
      activeContainer &&
      containerData &&
      Object.entries(containerData).length === 0
    ) {
      dispatch(getContainerDataAction(activeContainer));
    }
    if (containerData && containerData.todolists && activeContainer) {
      const activeTodolist = containerData.todolists.private;
      dispatch(
        getChecklistAction({
          containerId: activeContainer,
          todoListId: activeTodolist
        })
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { containerData, dispatch, activeContainer } = this.props;
    if (activeContainer !== prevProps.activeContainer) {
      dispatch(getContainerDataAction(activeContainer));
    }
    if (
      containerData &&
      containerData !== prevProps.containerData &&
      containerData.todolists &&
      activeContainer
    ) {
      const activeTodolist = containerData.todolists.private;
      dispatch(
        getChecklistAction({
          containerId: activeContainer,
          todoListId: activeTodolist
        })
      );
    }
    this.setVisibleAlerts(prevProps, prevState);
  }

  refreshCheckList = () => {
    const { activeContainer, dispatch, containerData } = this.props;
    const activeTodolist = containerData.todolists.private;
    dispatch(
      getChecklistAction({
        containerId: activeContainer,
        todoListId: activeTodolist
      })
    );
  };

  renderChecklists = () => {
    let { todolists } = this.props;
    const { userRights, getTodolistError } = this.props;
    let hasRights = false;

    if (todolists && userRights) {
      todolists = this.sortChecklistsByDeadline(todolists);
      todolists = this.filterChecklistsByStatus(todolists);
      todolists = this.filterChecklistsByAssignation(todolists);
      hasRights =
        userRights.includes('CHECKLIST_CALENDAR') ||
        userRights.includes('ADMINISTRATION');
    }

    const todolistsComponent = (
      <>
        <ReactPlaceholder
          className="my-3"
          ready={!!todolists || !!getTodolistError}
          type="text"
          showLoadingAnimation
          rows={8}
          color={window
            .getComputedStyle(document.body)
            .getPropertyValue('--light')}>
          {!!todolists && (
            <ListGroup>
              {todolists.map(todo => (
                <ListGroupItem
                  action
                  key={todo.id}
                  className="border-top-0 border-left-0 border-right-0 border-bottom"
                  style={{ marginBottom: '1px' }}>
                  <Row>
                    <Col
                      md={{ size: 1 }}
                      className="d-flex custom-control custom-checkbox">
                      <InputCustom
                        type="checkbox"
                        id={todo.id}
                        className="align-self-center custom-control-input"
                        defaultChecked={todo.status !== 'needsAction'}
                        onChange={
                          hasRights
                            ? e =>
                                this.handleChecked(
                                  e,
                                  todo.id,
                                  todo.status === 'needsAction'
                                )
                            : () => {}
                        }
                        disabled={!hasRights}
                      />
                      <Label
                        className="custom-control-label no-label"
                        for={todo.id}
                      />
                    </Col>
                    <Col md={{ size: 9 }} className="text-left">
                      <div className="d-flex w-100 justify-content-between">
                        <p>{todo.title}</p>
                      </div>
                      <p className="mb-1">
                        <span className="text-muted text-uppercase">
                          {todo.notes ? todo.notes : 'sowefund'}
                        </span>
                      </p>
                    </Col>
                    <Col md={{ size: 2 }} className="text-left">
                      <small className="text-muted">
                        {todo.due && format(todo.due, 'DD/MM/YYYY')}
                      </small>
                    </Col>
                  </Row>
                </ListGroupItem>
              ))}
            </ListGroup>
          )}
        </ReactPlaceholder>
      </>
    );
    return todolistsComponent;
  };

  sortChecklistsByDeadline = todolists => {
    const { sortAsc } = this.state;
    const sortedTodolists = todolists
      .map(todo => {
        const returnTodo = todo;
        returnTodo.sortedDate = todo.due
          ? todo.due
          : new Date(9999, 1, 1);
        return returnTodo;
      })
      .sort((a, b) =>
        sortAsc
          ? new Date(a.sortedDate) - new Date(b.sortedDate)
          : new Date(b.sortedDate) - new Date(a.sortedDate)
      );
    return sortedTodolists;
  };

  filterChecklistsByStatus = todolists => {
    const { filterCompleted, filterTodo } = this.state;
    let filteredTodolists = [];
    if (filterCompleted) {
      if (!filterTodo) {
        filteredTodolists = todolists.filter(
          value => value.status === 'completed'
        );
      } else {
        filteredTodolists = todolists;
      }
    } else if (filterTodo) {
      filteredTodolists = todolists.filter(
        value => value.status === 'needsAction'
      );
    }
    return filteredTodolists;
  };

  filterChecklistsByAssignation = todolists => {
    const { assignation } = this.state;
    const filteredTodolists = todolists
      .map(todo => ({
        ...todo,
        sortedNotes: todo.notes
          ? todo.notes.toLowerCase()
          : 'sowefund'
      }))
      .filter(
        value =>
          !value.sortedNotes ||
          (value.sortedNotes &&
            value.sortedNotes
              .toLowerCase()
              .includes(assignation.toLowerCase()))
      );
    return filteredTodolists;
  };

  renderFilters = () => {
    const {
      filterCompleted,
      filterTodo,
      sortAsc,
      assignation
    } = this.state;
    const { todolists } = this.props;
    const filteredTodolists = todolists
      ? todolists.map(todo => ({
          ...todo,
          sortedNotes: todo.notes
            ? todo.notes.toLowerCase()
            : 'sowefund'
        }))
      : null;
    const renderFiltersComponent = (
      <>
        <Row>
          <Col md={{ size: 12 }}>
            <p>Filter par :</p>
            <FormGroup className="custom-control custom-checkbox">
              <InputCustom
                type="checkbox"
                id="todoCheck"
                className="align-self-center custom-control-input"
                defaultChecked={filterTodo}
                onChange={this.handleCheckedTodo}
              />
              <Label className="custom-control-label" for="todoCheck">
                A faire
              </Label>
            </FormGroup>
            <FormGroup className="custom-control custom-checkbox">
              <InputCustom
                type="checkbox"
                id="completedCheck"
                className="align-self-center custom-control-input"
                defaultChecked={filterCompleted}
                onChange={this.handleCheckedCompleted}
              />
              <Label
                className="custom-control-label"
                for="completedCheck">
                Completé
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }}>
            <p>Tier par :</p>
            <FormGroup className="custom-control custom-radio">
              <InputCustom
                type="radio"
                id="dateSortingAsc"
                className="align-self-center custom-control-input"
                name="dateSorting"
                defaultChecked={sortAsc}
                value="1"
                onChange={this.handleSortDate}
              />
              <Label
                className="form-check-label d-flex custom-control-label"
                for="dateSortingAsc">
                Deadline ascendante
              </Label>
            </FormGroup>
            <FormGroup className="custom-control custom-radio">
              <InputCustom
                className="align-self-center custom-control-input"
                type="radio"
                id="dateSortingDesc"
                name="dateSorting"
                defaultChecked={!sortAsc}
                value="0"
                onChange={this.handleSortDate}
              />
              <Label
                className="form-check-label d-flex custom-control-label"
                for="dateSortingDesc">
                Deadline descendante
              </Label>
            </FormGroup>
            <FormGroup className="form-group d-flex flex-column">
              <Label className="mb-3" for="assignation">
                Assigné à :
              </Label>
              {filteredTodolists && filteredTodolists.length > 0 && (
                <Autocomplete
                  getItemValue={item => item.sortedNotes}
                  items={uniqWith(
                    (a, b) =>
                      a.sortedNotes.toLowerCase() ===
                      b.sortedNotes.toLowerCase(),
                    filteredTodolists
                  )}
                  renderItem={(item, isHighlighted) =>
                    isHighlighted ? (
                      <mark
                        className="d-block p-1 pl-3 text-capitalize"
                        key={item.sortedNotes}>
                        {item.sortedNotes}
                      </mark>
                    ) : (
                      <div
                        className="p-1 pl-3 text-capitalize"
                        key={item.sortedNotes}>
                        <span>{item.sortedNotes}</span>
                      </div>
                    )
                  }
                  menuStyle={{
                    borderRadius: '3px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 12px',
                    background:
                      'rgba(255, 255, 255, 0.9) none repeat scroll 0% 0%',
                    padding: '2px 0px',
                    fontSize: '90%',
                    position: 'inherit',
                    overflow: 'auto',
                    maxHeight: '50%',
                    minWidth: '330px'
                  }}
                  value={assignation}
                  onChange={this.handleChangeFilterAssignation}
                  onSelect={this.handleSelectFilterAssignation}
                  inputProps={{
                    id: 'assignation',
                    className: 'text-capitalize form-control'
                  }}
                />
              )}
            </FormGroup>
          </Col>
        </Row>
      </>
    );
    return renderFiltersComponent;
  };

  handleChecked = (e, todoId, completed) => {
    this.patchTodo(todoId, completed);
  };

  handleCheckedTodo = () => {
    const { filterTodo } = this.state;
    this.setState({
      filterTodo: !filterTodo
    });
  };

  handleCheckedCompleted = () => {
    const { filterCompleted } = this.state;
    this.setState({
      filterCompleted: !filterCompleted
    });
  };

  handleSortDate = e => {
    this.setState({ sortAsc: e.target.value === '1' });
  };

  handleChangeFilterAssignation = e => {
    this.setState({
      assignation: e.target.value
    });
  };

  handleSelectFilterAssignation = filter => {
    this.setState({
      assignation: filter
    });
  };

  patchTodo = (todoId, completed) => {
    const { activeContainer, containerData, dispatch } = this.props;
    if (containerData && containerData.todolists) {
      const activeTodolist = containerData.todolists.private;
      dispatch(
        patchTodoAction({
          completed,
          todoId,
          containerId: activeContainer,
          todoListId: activeTodolist
        })
      );
    }
  };

  onDismiss = target => {
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        [target]: false
      }
    }));
  };

  displayAlert = () => {
    const {
      getTodolistError,
      patchTodoError,
      patchTodoSuccess
    } = this.props;
    const { visibleObject } = this.state;
    const alert = [];
    let key = 0;

    if (getTodolistError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="getTodolistError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={getTodolistError}
          visible={visibleObject.getTodolistError}
        />
      );
    }
    if (patchTodoError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="patchTodoError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={patchTodoError}
          visible={visibleObject.patchTodoError}
        />
      );
    }
    if (patchTodoSuccess) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="patchTodoSuccess"
          color="success"
          onDismiss={this.onDismiss}
          errorMessage={patchTodoSuccess}
          visible={visibleObject.patchTodoSuccess}
        />
      );
    }
    return alert;
  };

  setVisibleAlerts = (prevProps, prevState) => {
    const errorPropsArray = [
      'getTodolistError',
      'patchTodoError',
      'patchTodoSuccess'
    ];
    const { props, state } = this;
    let newState = Object.assign({}, this.state);
    const visibleObject = getVisibleObject(
      props,
      prevProps,
      state,
      prevState,
      errorPropsArray
    );
    newState = {
      ...newState,
      visibleObject: {
        ...newState.visibleObject,
        ...visibleObject
      }
    };
    if (
      Object.values(visibleObject).includes(true) &&
      !_.isEqual(newState, state)
    ) {
      this.setState(newState);
    }
  };

  render() {
    const { dashboardView } = this.props;
    return (
      <>
        {this.displayAlert()}
        <Row>
          {dashboardView ? (
            <Col md={{ size: 12 }}>{this.renderChecklists()}</Col>
          ) : (
            <>
              <Col md={{ size: 9 }}>
                <>
                  {!dashboardView && (
                    <ButtonCustom
                      color="primary"
                      className="mb-3"
                      onClick={this.refreshCheckList}>
                      Rafraichir la checklist
                    </ButtonCustom>
                  )}
                  {this.renderChecklists()}
                </>
              </Col>
              <Col md={{ size: 3 }}>{this.renderFilters()}</Col>
            </>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeContainer: state.container.activeContainer.id,
  todolists: state.dashboardChecklist.todolists,
  containers: state.container.containers,
  containerData: state.dashboardSettings.containerData,
  getTodolistError: state.dashboardChecklist.getTodolistError,
  patchTodoError: state.dashboardChecklist.patchTodoError,
  patchTodoSuccess: state.dashboardChecklist.patchTodoSuccess,
  userRights: state.container.userRights.rights
});

export default connect(mapStateToProps)(ChecklistContent);
