// @flow
// @todo récupèrer tous les membres

// React Libs
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import update from 'immutability-helper';
// Components
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PeoplesInfo from './PeoplesInfo';
import ButtonCustom from '../../../../ButtonCustom';
import Tooltip from '../../../../../../Resources/Common/Tooltip';
import TruncateCustom from '../../../../TruncateCustom';

type TeamMember = {
  firstName: string,
  lastName: string,
  job: string,
  smallBiography: string,
  biography: string,
  photo: string,
  kind: 0 | 1,
  isFeatured: boolean,
  url: string,
  company?: string,
  newElement?: boolean
};

type Props = {
  peoples?: TeamMember[],
  hasRights?: boolean,
  changeProposalsData: Array<any>,
  blocked: boolean,
  newElement?: boolean,
  proposalUid?: string
};

type State = {
  peoples: TeamMember[],
  collapse: {
    proposal: boolean
  }
};

class People extends React.Component<Props, State> {
  static NOBODY = {
    firstName: '',
    lastName: '',
    job: '',
    smallBiography: '',
    biography: '',
    photo: '',
    kind: 0,
    isFeatured: false,
    url: '',
    newElement: true
  };

  static defaultProps = {
    peoples: [People.NOBODY],
    hasRights: false,
    newElement: false,
    proposalUid: undefined
  };

  constructor(props: Props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = {
      peoples: _.get(
        changeProposalsFiltered,
        '[0].content.people'
      ) || [People.NOBODY],
      collapse: {
        proposal: true
      }
    };
  }

  addTeamMember = () => {
    const { peoples } = this.state;
    this.setState({
      peoples: peoples.concat(People.NOBODY)
    });
  };

  deleteTeamMember = index => () => {
    const { peoples } = this.state;
    this.setState({
      peoples: update(peoples, { $splice: [[index, 1]] })
    });
  };

  handleCollapse = tooltip => () => {
    const { collapse } = this.state;
    this.setState(prevState => ({
      collapse: {
        ...prevState.collapse,
        [tooltip]: !collapse[tooltip]
      }
    }));
  };

  render() {
    const {
      hasRights,
      newElement,
      blocked,
      proposalUid
    } = this.props;
    const { peoples, collapse } = this.state;
    const peoplesInfo = peoples.map((people: TeamMember, index) => (
      <section
        className={
          index > 0 ? `border-top border-secondary mt-4 pt-4` : ``
        }
        key={`section${people.firstName} ${people.lastName}`}>
        <ButtonCustom
          disabled={blocked}
          onClick={this.deleteTeamMember(index)}
          key={`button${people.firstName} ${people.lastName}`}
          className="mb-3">
          Supprimer ce membre
        </ButtonCustom>
        <PeoplesInfo
          key={`people${people.firstName} ${people.lastName}`}
          index={index}
          hasRights={hasRights}
          newElement={newElement}
          proposalUid={proposalUid}
          blocked={blocked}
          {...people}
        />
      </section>
    ));

    return (
      <>
        <div className="mb-3">
          <small>
            <TruncateCustom
              collapse={collapse.proposal}
              tooltip={Tooltip.ProjectOfferPeople.proposal}
              handleCollapse={this.handleCollapse('proposal')}
            />
          </small>
        </div>
        {peoplesInfo}
        <Button
          disabled={blocked}
          color="secondary"
          className="mx-5 mb-3"
          onClick={this.addTeamMember}>
          ajouter un membre
        </Button>
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData,
  containerDocument: state.folder.getContainerDocument,
  activeContainer: state.container.activeContainer.id,
  isLoadingGetContainerDocument:
    state.folder.isLoadingGetContainerDocument
});

export default withRouter(connect(mapStateToProps)(People));
