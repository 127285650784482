// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
// Components
import Template from '../../Components/Template/TemplateComponent';
import LogContent from '../../Components/Components/Dashboard/LogContent';
// Utils
import Routes from '../../Resources/Common/Routes';

type State = {
  visibleObject: {
    getContainerChangeError: boolean,
    getChangeProposalsError: boolean
  }
};

type Props = {
  match: Object
};

class DashboardLogsScene extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      visibleObject: {
        getContainerChangeError: false,
        getChangeProposalsError: false
      }
    };
  }

  onDismiss = target => {
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        [target]: false
      }
    }));
  };

  render() {
    const { match } = this.props;
    return (
      <Template
        title="Historique"
        subtitle="Les logs vous permettent de consulter les dernières actions réalisées sur votre projet, et de restaurer une version précédente."
        menu={Routes.routeDashboard}>
        <LogContent kind={match.params.kind} />
      </Template>
    );
  }
}

const mapStateToProps = state => ({
  activeContainer: state.container.activeContainer.id,
  containers: state.container.containers,
  containerChangeData: state.folder.getContainerChangeData,
  changeProposalsData: state.folder.getChangeProposalsData,
  isLoadingGetAllChangeProposals:
    state.folder.isLoadingGetAllChangeProposals,
  getContainerChangeError: state.folder.getContainerChangeError,
  getChangeProposalsError: state.folder.getChangeProposalsError
});

export default connect(mapStateToProps)(DashboardLogsScene);
