// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { format } from 'date-fns';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
// Components
import {
  Table,
  FormGroup,
  Label,
  Col,
  Row,
  Card,
  CardBody,
  CardText
} from 'reactstrap';
import ReactPlaceholder from 'react-placeholder';
import { Link } from 'react-router-dom';
import AlertCustom from '../AlertCustom';
import ButtonCustom from '../ButtonCustom';
// Utils
import Routes from '../../../Resources/Common/Routes';
import Constants from '../../../Resources/Common/Constants';
import {
  getProposalsObject,
  getVisibleObject
} from '../../../Resources/Common/Utilities';
import BreakdownComponent from '../Charts/BreakdownComponent';
import BarComponent from '../Charts/BarComponent';
// Actions
import {
  getContainerChangeAction,
  setUndefinedRemainingKindsAction,
  getAllChangeProposals
} from '../../../Business/actions/FolderActions';
import InputCustom from '../InputCustom';

type State = {
  changeArray: Array<any>,
  rejectedArray: Array<any>,
  draftArray: Array<any>,
  folderArray: Array<any>,
  changePaginateList: Array<any>,
  pageCount: Number,
  offset: Number,
  isSortedList: Boolean,
  isSortedProgression: Boolean,
  title: string,
  kindArray: Array<any>,
  kinds: [],
  status: string,
  statusArray: Array<any>,
  progressionArray: Array<Object>,
  progressionBySectionArray: Array<Object>,
  filteredChangePaginateList: Array<any>,
  filteredChangeArray: Array<any>,
  visibleObject: {
    getContainerChangeError: boolean,
    getChangeProposalsError: boolean
  }
};

type Props = {
  activeContainer: string,
  dispatch: Function,
  changeProposalsData: Object,
  containerChangeData: Object,
  getContainerChangeError: boolean,
  getChangeProposalsError: boolean,
  dashboardView: boolean,
  folderView: boolean,
  getChangeProposalsSuccess: boolean,
  changeProposalKind: string | typeof undefined,
  kind: string | typeof undefined
};

class LogContent extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      changeArray: [],
      rejectedArray: [],
      draftArray: [],
      folderArray: [],
      changePaginateList: [],
      pageCount: 0,
      offset: 0,
      isSortedList: false,
      isSortedProgression: false,
      title: '',
      status: '',
      visibleObject: {
        getContainerChangeError: false,
        getChangeProposalsError: false
      },
      kindArray: [],
      kinds: [],
      statusArray: Object.values(Constants.PROPOSAL_STATUS)
        .filter(status => typeof status === 'string')
        .slice(0, -1),
      progressionArray: [
        { name: 'Vide', value: 0 },
        { name: 'En brouillon', value: 0 },
        { name: 'Soumise', value: 0 },
        { name: 'Acceptée', value: 0 },
        { name: 'Rejetée', value: 0 }
      ],
      progressionBySectionArray: [],
      filteredChangePaginateList: [],
      filteredChangeArray: []
    };
  }

  componentDidMount() {
    const {
      activeContainer,
      dispatch,
      kind,
      containerChangeData,
      changeProposalKind,
      dashboardView
    } = this.props;

    dispatch(setUndefinedRemainingKindsAction());
    if (
      !containerChangeData ||
      activeContainer !== containerChangeData.uuid
    ) {
      dispatch(getContainerChangeAction(activeContainer));
    }
    dispatch(
      getAllChangeProposals({
        containerId: activeContainer,
        kinds: undefined,
        status: undefined
      })
    );
    if (
      containerChangeData &&
      containerChangeData.proposals &&
      !changeProposalKind &&
      dashboardView
    ) {
      this.setKindArray();
      this.setChangeArray();
      this.getProposalsStatusArray();
    }
    if (
      kind &&
      containerChangeData &&
      containerChangeData.proposals
    ) {
      const defaultTitle =
        kind &&
        containerChangeData.proposals.filter(p => p.kind === kind) &&
        containerChangeData.proposals.filter(p => p.kind === kind)[0]
          .title;
      this.setState({ title: defaultTitle });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      containerChangeData,
      folderView,
      changeProposalsData,
      getChangeProposalsSuccess,
      changeProposalKind
    } = this.props;
    const {
      isSortedList,
      title,
      kinds,
      status,
      filteredChangeArray,
      changeArray,
      kindArray
    } = this.state;
    if (
      (kinds && !_.isEqual(kinds, prevState.kinds)) ||
      (status && !_.isEqual(status, prevState.status)) ||
      (containerChangeData &&
        containerChangeData.proposals &&
        _.isEmpty(prevProps.containerChangeData) &&
        !folderView)
    ) {
      this.setKindArray();
    }
    if (
      !_.isEqual(
        changeProposalsData,
        prevProps.changeProposalsData
      ) &&
      !_.isEmpty(changeProposalsData) &&
      _.isEmpty(changeArray) &&
      !changeProposalKind
    ) {
      if (_.isEmpty(kindArray)) {
        this.setKindArray();
      }
      this.setChangeArray();
      this.setRejectedArray();
      this.getProposalsStatusArray();
      this.filterChangeList();
    }
    if (
      !isSortedList &&
      getChangeProposalsSuccess &&
      _.isEmpty(changeProposalsData)
    ) {
      this.sortChangeArray();
      this.getProposalsStatusArray();
    }
    if (title !== prevState.title || status !== prevState.status) {
      this.filterChangeList();
    }
    if (
      !_.isEqual(filteredChangeArray, prevState.filteredChangeArray)
    ) {
      this.updateList();
    }
    this.setVisibleAlerts(prevProps, prevState);
  }

  setKindArray = () => {
    const { containerChangeData } = this.props;
    this.setState({
      kindArray: containerChangeData.proposals.map(item => item.kind)
    });
  };

  getProposalsStatusArray = () => {
    const { changeProposalsData, containerChangeData } = this.props;
    const {
      progressionArray,
      progressionBySectionArray
    } = this.state;
    const tempProgressionObjectArray = progressionArray.slice();
    const tempProgressionBySectionArray = progressionBySectionArray.slice();
    const proposalsObject = getProposalsObject();

    if (containerChangeData.proposals) {
      const proposalsArray = this.getCompleteProposalsArray(
        changeProposalsData,
        containerChangeData
      );
      proposalsArray.forEach(p => {
        if (p.isMandatory) {
          const statusObject = tempProgressionObjectArray.find(
            tempProp =>
              tempProp.name === Constants.PROPOSAL_STATUS[p.status]
          );

          if (statusObject) {
            statusObject.value += 1;
          } else {
            tempProgressionObjectArray.push({
              name: p
                ? Constants.PROPOSAL_STATUS[p.status]
                : Constants.PROPOSAL_STATUS[
                    Constants.PROPOSAL_STATUS.EMPTY
                  ],
              value: 1
            });
          }
          const sessionObject = tempProgressionBySectionArray.find(
            tempSection => tempSection.value === p.section
          );
          if (sessionObject) {
            sessionObject.total += 1;
            sessionObject.validated =
              p.status === Constants.PROPOSAL_STATUS.ACCEPTED
                ? sessionObject.validated + 1
                : sessionObject.validated;
          } else {
            // Update session object
            tempProgressionBySectionArray.push({
              value: proposalsObject[p.kind].section,
              validated:
                p.status === Constants.PROPOSAL_STATUS.ACCEPTED
                  ? 1
                  : 0,
              total: 1
            });
          }
        }
      });
    }
    this.setState({
      progressionArray: tempProgressionObjectArray,
      progressionBySectionArray: tempProgressionBySectionArray,
      isSortedProgression: true
    });
  };

  getCompleteProposalsArray = (
    changeProposalsData,
    containerChangeData
  ) => {
    const kindArray = [];
    const tempProposals =
      containerChangeData &&
      containerChangeData.proposals &&
      containerChangeData.proposals.slice();
    const proposalsObject = getProposalsObject();
    if (!containerChangeData || !containerChangeData.proposals) {
      return null;
    }
    return containerChangeData.proposals
      .reduce((agregator, proposal, i) => {
        if (!kindArray.includes(proposal.kind)) {
          kindArray.push(proposal.kind);
          tempProposals[i].title =
            proposalsObject[proposal.kind].title;
          tempProposals[i].section =
            proposalsObject[proposal.kind].section;
          const proposalTemp = changeProposalsData.find(
            containerProposal =>
              containerProposal.kind === proposal.kind
          );
          tempProposals[i].status = proposalTemp
            ? proposalTemp.status
            : 4;
          agregator.push(tempProposals[i]);
        }
        return agregator;
      }, [])
      .sort((a, b) => a.title.localeCompare(b.title))
      .sort(
        (a, b) =>
          Constants.sections.indexOf(a.section) -
          Constants.sections.indexOf(b.section)
      );
  };

  reinitializeListChanges = () => {
    this.setState(
      {
        changeArray: [],
        filteredChangeArray: [],
        rejectedArray: [],
        draftArray: [],
        isSortedList: false,
        isSortedProgression: false,
        changePaginateList: []
      },
      () => {
        this.setChangeArray(true);
        this.setRejectedArray();
      }
    );
  };

  setChangeArray = doNotSort => {
    const { changeArray } = this.state;
    const { changeProposalsData } = this.props;
    const tempChangeArray = changeArray.slice();
    changeProposalsData.forEach(proposal => {
      tempChangeArray.push(proposal);
    });
    this.setState(
      {
        changeArray: tempChangeArray
      },
      () => {
        if (!doNotSort) {
          this.sortChangeArray();
        }
      }
    );
  };

  sortChangeArray = () => {
    const { changeArray, filteredChangeArray } = this.state;
    if (!_.isEmpty(filteredChangeArray)) {
      this.setState({
        filteredChangeArray: filteredChangeArray.sort(
          (a, b) =>
            new Date(b.creationDate) - new Date(a.creationDate)
        )
      });
    }
    this.setState({
      changeArray: changeArray.sort(
        (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
      ),
      isSortedList: true
    });
    this.updateList();
  };

  setRejectedArray = () => {
    const { rejectedArray, draftArray } = this.state;
    const { changeProposalsData, containerChangeData } = this.props;
    const tempRejectedArray = rejectedArray.slice();
    const tempDraftArray = draftArray.slice();
    if (_.isEmpty(changeProposalsData)) {
      return null;
    }
    const proposalsArray = this.getCompleteProposalsArray(
      changeProposalsData,
      containerChangeData
    );
    if (proposalsArray) {
      proposalsArray.forEach(proposal => {
        if (proposal.status === Constants.PROPOSAL_STATUS.REJECTED) {
          tempRejectedArray.push(proposal);
        }
        if (
          proposal.status === Constants.PROPOSAL_STATUS.DRAFT &&
          containerChangeData &&
          containerChangeData.proposals &&
          proposal.isMandatory
        ) {
          tempDraftArray.push(proposal);
        }
      });
    }
    this.setState(
      {
        rejectedArray: tempRejectedArray,
        draftArray: tempDraftArray
      },
      () => {
        this.sortRejectedArray();
      }
    );
    return null;
  };

  sortRejectedArray = () => {
    const { rejectedArray, draftArray } = this.state;
    this.setState(
      {
        rejectedArray: rejectedArray.sort(
          (a, b) =>
            new Date(b.creationDate) - new Date(a.creationDate)
        ),
        draftArray: draftArray.sort(
          (a, b) =>
            new Date(b.creationDate) - new Date(a.creationDate)
        )
      },
      () => {
        const tempFolderArray = rejectedArray.slice(0, 6);
        const draftArrayLength = 6 - tempFolderArray.length;
        if (rejectedArray.length < 6) {
          for (let i = 0; i < draftArrayLength; i += 1) {
            if (draftArray[i]) {
              tempFolderArray.push(draftArray[i]);
            }
          }
        }
        this.setState({
          folderArray: tempFolderArray
        });
      }
    );
  };

  updateList = offsetVar => {
    const { offset, changeArray, filteredChangeArray } = this.state;
    const { dashboardView } = this.props;
    const paginate = dashboardView
      ? Constants.paginateDashboardNumber
      : Constants.paginateLogNumber;
    const offsetToUse = offsetVar === undefined ? offset : offsetVar;
    const start: number = offsetToUse;
    const end: number = offsetToUse + paginate;
    const pageCount = Math.ceil(
      filteredChangeArray.length > 0
        ? filteredChangeArray.length / paginate
        : changeArray.length / paginate
    );
    this.setState({
      changePaginateList: changeArray.slice(
        start,
        end < changeArray.length ? end : changeArray.length
      ),
      filteredChangePaginateList: filteredChangeArray.slice(
        start,
        end < filteredChangeArray.length
          ? end
          : filteredChangeArray.length
      ),
      offset: offsetToUse,
      pageCount
    });
  };

  handlePageClick = data => {
    const { selected } = data;
    const { dashboardView } = this.props;
    const paginate = dashboardView
      ? Constants.paginateDashboardNumber
      : Constants.paginateLogNumber;
    const offset = selected * paginate;
    this.updateList(offset);
  };

  handleSelectFilterTitle = e => {
    this.setState({
      title: e.target.value
    });
  };

  filterChangeList = () => {
    const proposalsObject = getProposalsObject();
    const { title, status } = this.state;
    const { changeProposalsData } = this.props;
    let tempFilteredArray = title
      ? changeProposalsData.filter(
          list => proposalsObject[list.kind].title === title
        )
      : changeProposalsData;
    const statusStr = status.toString();
    tempFilteredArray = status
      ? tempFilteredArray.filter(
          list => list.status.toString() === statusStr
        )
      : tempFilteredArray;
    this.setState({
      filteredChangeArray: tempFilteredArray
    });
  };

  handleSelectFilterStatus = e => {
    this.setState({
      status: e.target.value
    });
  };

  onDismiss = target => {
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        [target]: false
      }
    }));
  };

  displayAlert = () => {
    const {
      getContainerChangeError,
      getChangeProposalsError
    } = this.props;
    const { visibleObject } = this.state;
    const alert = [];
    let key = 0;

    if (getContainerChangeError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="getContainerChangeError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={getContainerChangeError}
          visible={visibleObject.getContainerChangeError}
        />
      );
    }
    if (getChangeProposalsError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="getChangeProposalsError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={getChangeProposalsError}
          visible={visibleObject.getChangeProposalsError}
        />
      );
    }
    return alert;
  };

  setVisibleAlerts = (prevProps, prevState) => {
    const errorPropsArray = [
      'getContainerChangeError',
      'getChangeProposalsError'
    ];
    const { props, state } = this;
    let newState = Object.assign({}, this.state);
    const visibleObject = getVisibleObject(
      props,
      prevProps,
      state,
      prevState,
      errorPropsArray
    );
    newState = {
      ...newState,
      visibleObject: {
        ...newState.visibleObject,
        ...visibleObject
      }
    };
    if (
      Object.values(visibleObject).includes(true) &&
      !_.isEqual(newState, state)
    ) {
      this.setState(newState);
    }
  };

  render() {
    const { activeContainer, dashboardView, folderView } = this.props;
    const {
      changePaginateList,
      filteredChangePaginateList,
      pageCount,
      title,
      kindArray,
      status,
      statusArray,
      progressionArray,
      progressionBySectionArray,
      folderArray,
      isSortedList,
      isSortedProgression
    } = this.state;
    const list =
      title || status
        ? filteredChangePaginateList
        : changePaginateList;
    const proposalsObject = getProposalsObject();
    const style = getComputedStyle(document.body);
    const bootstrapTheme = [
      style.getPropertyValue('--secondary'),
      style.getPropertyValue('--warning'),
      style.getPropertyValue('--info'),
      style.getPropertyValue('--primary'),
      style.getPropertyValue('--danger'),
      style.getPropertyValue('--success'),
      style.getPropertyValue('--light'),
      style.getPropertyValue('--dark')
    ];
    const STATUS_CLASS = {
      [Constants.PROPOSAL_STATUS.DRAFT]: 'border-warning',
      [Constants.PROPOSAL_STATUS.REJECTED]: 'border-danger'
    };
    return (
      <>
        {(dashboardView || folderView) && (
          <Row className="my-5">
            <Col md={{ size: 12 }}>
              {!folderView && (
                <Link
                  to={`/${Routes.routeFolder}/${activeContainer}`}>
                  <h2>{`Dossier >`}</h2>
                </Link>
              )}
              <ReactPlaceholder
                className="my-5"
                ready={
                  progressionBySectionArray && isSortedProgression
                }
                type="text"
                showLoadingAnimation
                rows={8}
                color={window
                  .getComputedStyle(document.body)
                  .getPropertyValue('--light')}>
                {!folderView && progressionArray.length > 0 && (
                  <div className="mb-3 text-center">
                    {progressionArray.reduce(
                      (a, b) => a + b.value,
                      0
                    ) > 0 ? (
                      <BreakdownComponent
                        width={350}
                        height={250}
                        values={progressionArray}
                        colors={bootstrapTheme}
                        activeContainer={activeContainer}
                      />
                    ) : (
                      <span>Pas de progression en cours</span>
                    )}
                  </div>
                )}
                {progressionBySectionArray.length > 0 && (
                  <div className="mb-3 text-center">
                    <BarComponent
                      width={350}
                      height={250}
                      colors={bootstrapTheme}
                      values={progressionBySectionArray}
                      activeContainer={activeContainer}
                    />
                  </div>
                )}
                {folderView && (
                  <>
                    {folderArray.length > 0 && (
                      <Row>
                        <Col md={{ size: 12 }}>
                          <p className="lead">
                            Accès rapide: ces sections ont besoin de
                            votre attention
                          </p>
                        </Col>
                        <Row />
                        {folderArray.map(p => (
                          <React.Fragment key={p.kind}>
                            <Col md={{ size: 6 }}>
                              <Link
                                to={`/${
                                  Routes.routeFolder
                                }/${activeContainer}/${
                                  Routes.routeProposals
                                }/${p.kind}`}
                                className="card-link">
                                <Card
                                  className={`mb-3 ${
                                    STATUS_CLASS[p.status]
                                  }`}>
                                  <CardBody>
                                    <CardText>
                                      {`${
                                        proposalsObject[p.kind]
                                          .section
                                      } - ${
                                        proposalsObject[p.kind].title
                                      }`}
                                    </CardText>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                          </React.Fragment>
                        ))}
                      </Row>
                    )}
                  </>
                )}
              </ReactPlaceholder>
            </Col>
          </Row>
        )}
        {kindArray &&
          kindArray.length > 0 &&
          !dashboardView &&
          !folderView && (
            <Row>
              <Col md={{ size: 6 }}>
                <FormGroup className="form-group d-flex flex-column">
                  <Label className="mb-3" for="title">
                    Filtrer par propositions :
                  </Label>
                  <InputCustom
                    placeholder="Toutes les propositions"
                    type="select"
                    id="title"
                    value={title}
                    onChange={this.handleSelectFilterTitle}>
                    <option key="nothing" value="">
                      Toutes les propositions
                    </option>
                    {kindArray.map(item => (
                      <option
                        key={item}
                        value={proposalsObject[item].title}>
                        {proposalsObject[item].title}
                      </option>
                    ))}
                  </InputCustom>
                </FormGroup>
              </Col>
              <Col md={{ size: 6 }}>
                <FormGroup className="form-group d-flex flex-column">
                  <Label className="mb-3" for="status">
                    Filtrer par statut :
                  </Label>
                  <InputCustom
                    placeholder="Tous les statuts"
                    type="select"
                    id="status"
                    value={status}
                    onChange={this.handleSelectFilterStatus}>
                    <option key="nothing" value="">
                      Tous les statuts
                    </option>
                    {statusArray.map((item, index) => (
                      <option key={item} value={index}>
                        {item}
                      </option>
                    ))}
                  </InputCustom>
                </FormGroup>
              </Col>
            </Row>
          )}
        <Row className="my-5">
          <Col md={{ size: 12 }}>
            {dashboardView && (
              <Link
                to={`/${Routes.routeDashboard}/${activeContainer}/${
                  Routes.routeLogs
                }`}>
                <h2>{`Historique > `}</h2>
              </Link>
            )}
            {!folderView && (
              <ReactPlaceholder
                className="my-5"
                ready={isSortedList}
                type="text"
                showLoadingAnimation
                rows={8}
                color={window
                  .getComputedStyle(document.body)
                  .getPropertyValue('--light')}>
                {list && list.length > 0 ? (
                  <>
                    <Table striped className="table-borderless">
                      <thead className="border-bottom border-primary">
                        <tr>
                          <th>Section</th>
                          <th>Element</th>
                          <th>Etat</th>
                          <th>Auteur</th>
                          {!dashboardView && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {list.map(proposal => (
                          <React.Fragment key={proposal.uuid}>
                            <tr>
                              <td>
                                {
                                  proposalsObject[proposal.kind]
                                    .section
                                }
                              </td>
                              <td>
                                {proposalsObject[proposal.kind].title}
                              </td>
                              <td>
                                {
                                  Constants.PROPOSAL_STATUS[
                                    proposal.status
                                  ]
                                }
                              </td>
                              <td className="text-uppercase">
                                <span>
                                  {`${proposal.author.firstName} ${
                                    proposal.author.lastName
                                  }`}
                                </span>
                                <br />
                                <span>
                                  {format(
                                    proposal.creationDate,
                                    'DD/MM/YYYY HH:mm'
                                  )}
                                </span>
                              </td>
                              {!dashboardView && (
                                <td>
                                  <Link
                                    to={`/${
                                      Routes.routeFolder
                                    }/${activeContainer}/${
                                      Routes.routeProposals
                                    }/${proposal.kind}/${
                                      proposal.uuid
                                    }`}>
                                    <ButtonCustom color="primary">
                                      Voir / Restaurer
                                    </ButtonCustom>
                                  </Link>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </Table>
                    {!dashboardView && (
                      <ReactPaginate
                        previousLabel="Précédent"
                        nextLabel="Suivant"
                        breakLabel="..."
                        pageCount={pageCount}
                        onPageChange={this.handlePageClick}
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                      />
                    )}
                  </>
                ) : (
                  <span>Pas de résultats</span>
                )}
              </ReactPlaceholder>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeContainer: state.container.activeContainer.id,
  containers: state.container.containers,
  containerChangeData: state.folder.getContainerChangeData,
  changeProposalsData: state.folder.getChangeProposalsData,
  changeProposalKind: state.folder.getChangeProposalKind,
  getContainerChangeError: state.folder.getContainerChangeError,
  getChangeProposalsError: state.folder.getChangeProposalsError,
  cachedSidebarProposals: state.folder.setSidebarProposals,
  getChangeProposalsSuccess: state.folder.getChangeProposalsSuccess
});

export default connect(mapStateToProps)(LogContent);
