// @flow

import {
  GET_USER_RIGHTS,
  GET_CONTAINERS,
  SET_ACTIVE_CONTAINER,
  CLEAR_ACTIVE_CONTAINER,
  SET_SIDEBAR_TOGGLE
} from '../actions/ContainerActions';
import Common from '../../Resources/Common';

const defaultState = {
  loadingContainers: false,
  containerSuccess: false,
  containers: [],
  activeContainer: {
    id: '',
    name: '',
    operatorEmail: ''
  },
  userRights: {
    loading: false,
    success: false,
    rights: [],
    error: ''
  },
  sidebarToggle: false
};

const ContainerReducer = (
  state: Object = defaultState,
  action: Object = {}
) => {
  switch (action.type) {
    case GET_USER_RIGHTS:
      return {
        ...state,
        userRights: {
          error: action.payload.error,
          rights: action.payload.aggregate,
          loading:
            action.payload.status === Common.Constants.PENDING_STATUS,
          success:
            action.payload.status === Common.Constants.SUCCESS_STATUS
        }
      };
    case GET_CONTAINERS:
      return {
        ...state,
        containers: action.payload.aggregate,
        loadingContainers:
          action.payload.status === Common.Constants.PENDING_STATUS,
        containerSuccess:
          action.payload.status === Common.Constants.SUCCESS_STATUS
      };
    case SET_ACTIVE_CONTAINER:
      return {
        ...state,
        activeContainer: {
          id: action.payload.aggregate.uuid,
          name: action.payload.aggregate.name,
          operatorEmail: action.payload.aggregate.operator.email
        }
      };
    case CLEAR_ACTIVE_CONTAINER:
      return {
        ...state,
        activeContainer: { id: '', name: '' }
      };
    case SET_SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarToggle: action.payload.aggregate
      };
    default:
      return state;
  }
};

export default ContainerReducer;
