// @flow

// Sentry.io
import * as Sentry from '@sentry/browser';
// React libs
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import LocalStorage from './Layer/Storage/LocalStorage';
import { hideLoaderAction } from './Business/actions/InitialLoaderActions';
// UI
import App from './UI/AppScene';
// Style
import './index.css';
import './Resources/assets/scss/components/main.css';

// Worker
import * as serviceWorker from './serviceWorker';

const root = document.getElementById('root');

Sentry.init({
  dsn: LocalStorage.get('GLOBAL.SENTRY_DSN')
});

if (root) {
  fetch('/config.json')
    .then(res => {
      if (!res.ok) {
        ReactDOM.render(
          <Alert
            color="danger"
            errorMessage="Impossible de charger la configuration applicative. Merci de réessayer ultérieurement."
            isOpen
          />,
          root,
          // We can use the action since it's a standard function
          hideLoaderAction()
        );
      }

      return res.json();
    })
    .then(configuration => {
      Object.entries(configuration).forEach(([key, value]) => {
        localStorage.setItem(`GLOBAL.${key}`, String(value));
      });
      ReactDOM.render(
        <BrowserRouter>
          <App />
        </BrowserRouter>,
        root
      ); // We don't need to hide #loader, since App will do it for us.
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
