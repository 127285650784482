// @flow

import {
  RESET_PASSWORD,
  RECOVER_PASSWORD,
  POST_RECOVER_PASSWORD,
  POST_USER_REGISTER,
  POST_USER_PRE_REGISTER
} from '../actions/UserActions';

import Constants from '../../Resources/Common/Constants';

const defaultState = {
  isLoadingPostRecoverPassword: false,
  isLoadingResetPassword: false,
  isLoadingRecoverPassword: false,
  isLoadingPostUserRegister: false,
  isLoadingPostUserPreRegister: false,
  postUserRegisterSuccess: false,
  error: '',
  postRecoverError: '',
  postResetError: '',
  getRecoverError: '',
  preRegisterError: '',
  postForgotPassword: null,
  hasMatchedCode: false,
  hasSendEmail: false,
  hasChangedPassword: false
};

export default function UserReducer(
  state: Object = defaultState,
  action: Object
) {
  switch (action.type) {
    case RESET_PASSWORD:
      return {
        ...state,
        postResetError: action.payload.error,
        hasSendEmail: action.payload.hasSendEmail,
        isLoadingResetPassword:
          action.payload.status === Constants.PENDING_STATUS
      };
    case RECOVER_PASSWORD:
      return {
        ...state,
        getRecoverError: action.payload.error,
        hasMatchedCode: action.payload.hasMatchedCode,
        isLoadingRecoverPassword:
          action.payload.status === Constants.PENDING_STATUS
      };
    case POST_RECOVER_PASSWORD:
      return {
        ...state,
        postRecoverError: action.payload.error,
        hasChangedPassword: action.payload.hasChangedPassword,
        isLoadingPostRecoverPassword:
          action.payload.status === Constants.PENDING_STATUS
      };
    case POST_USER_REGISTER:
      return {
        ...state,
        createAccountError: action.payload.error,
        createAccountData: action.payload.hasCreatedAccount,
        isLoadingPostUserRegister:
          action.payload.status === Constants.PENDING_STATUS,
        postUserRegisterSuccess:
          action.payload.status === Constants.SUCCESS_STATUS
      };
    case POST_USER_PRE_REGISTER:
      return {
        ...state,
        preRegisterError: action.payload.error,
        isLoadingPostUserPreRegister:
          action.payload.status === Constants.PENDING_STATUS
      };
    default:
      return state;
  }
}
