// @flow

import React from 'react';
import PropTypes from 'prop-types';
import ProjectContentDescription from '../ContentDescription';
import Tooltip from '../../../../../Resources/Common/Tooltip';

export default function UseOfFunds(props) {
  const { proposalUid, blocked, hasRights } = props;
  return (
    <ProjectContentDescription
      title="Utilisation des fonds"
      tooltip={Tooltip.ProjectOfferUseOfFunds.description}
      proposalUid={proposalUid}
      hasRights={hasRights}
      blocked={blocked}
    />
  );
}

UseOfFunds.propTypes = {
  hasRights: PropTypes.bool.isRequired,
  blocked: PropTypes.bool.isRequired,
  proposalUid: PropTypes.string
};

UseOfFunds.defaultProps = {
  proposalUid: undefined
};
