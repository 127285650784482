// @flow
// React Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isoCountries from 'i18n-iso-countries';
import { ClipLoader } from 'react-spinners';
import _ from 'lodash';
// Components
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import InputCustom from '../../InputCustom';
import FrontValidation from '../../../../Resources/Common/FrontValidation';
import Tooltip from '../../../../Resources/Common/Tooltip';
import TruncateCustom from '../../TruncateCustom';
import { isImage } from '../../../../Resources/Common/Utilities';

// Actions
import { getChangeContainerDocumentAction } from '../../../../Business/actions/FolderActions';
import Constants from '../../../../Resources/Common/Constants';

isoCountries.registerLocale(
  require('i18n-iso-countries/langs/fr.json')
);

isoCountries.registerLocale(
  require('i18n-iso-countries/langs/fr.json')
);

type Props = {
  changeProposalsData: Array<any>,
  containerDocument: Object,
  dispatch: Function,
  activeContainer: string,
  hasRights: boolean,
  blocked: boolean,
  isLoadingGetContainerDocument: boolean,
  proposalUid: string,
  operatorEmail: string
};

type State = {
  name: string,
  structure: string,
  siret: string,
  capitalAmount: number,
  actionPrice: number,
  address: {
    street: string,
    city: string,
    zipcode: string,
    country: string
  },
  document: {
    url: string,
    text: string
  },
  documentRequired: string,
  changeProposalsFiltered: Object,
  collapse: {
    name: boolean,
    structure: boolean,
    siret: boolean,
    capitalAmount: boolean,
    actionPrice: boolean,
    addressStreet: boolean,
    addressCity: boolean,
    addressZipCode: boolean,
    addressCountry: boolean,
    logo: boolean
  }
};

class BasicInfo extends Component<Props, State> {
  constructor(props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = _.pick(
      _.defaultsDeep(
        _.get(changeProposalsFiltered, '[0].content', {}),
        {
          name: '',
          structure: '',
          siret: '',
          capitalAmount: 0,
          actionPrice: 0,
          address: { street: '', city: '', zipcode: '', country: '' },
          document: { url: '', text: '' }
        }
      ),
      [
        'name',
        'structure',
        'siret',
        'capitalAmount',
        'actionPrice',
        'address.street',
        'address.city',
        'address.zipcode',
        'address.country',
        'document'
      ]
    );
    this.state.documentRequired = !_.get(
      changeProposalsFiltered,
      '[0].content.logo'
    );
    this.state.changeProposalsFiltered = changeProposalsFiltered;
    this.state.collapse = {
      name: true,
      structure: true,
      siret: true,
      capitalAmount: true,
      actionPrice: true,
      addressStreet: true,
      addressCity: true,
      addressZipCode: true,
      addressCountry: true,
      logo: true
    };
  }

  componentDidMount = () => {
    const { dispatch, activeContainer, operatorEmail } = this.props;
    const { changeProposalsFiltered } = this.state;
    if (
      changeProposalsFiltered &&
      changeProposalsFiltered[0] &&
      _.has(changeProposalsFiltered[0], 'content.logo')
    ) {
      dispatch(
        getChangeContainerDocumentAction({
          containerId: activeContainer,
          docUuid: _.get(changeProposalsFiltered, '[0].content.logo'),
          operatorEmail
        })
      );
    }
  };

  componentDidUpdate = prevProps => {
    const {
      containerDocument,
      dispatch,
      activeContainer,
      changeProposalsData,
      operatorEmail
    } = this.props;
    if (
      containerDocument &&
      !_.isEqual(containerDocument, prevProps.containerDocument)
    ) {
      this.updateDocument();
    }
    if (
      changeProposalsData &&
      changeProposalsData[0] &&
      !_.isEqual(changeProposalsData, prevProps.changeProposalsData)
    ) {
      dispatch(
        getChangeContainerDocumentAction({
          containerId: activeContainer,
          docUuid: _.get(changeProposalsData, '[0].content.logo'),
          operatorEmail
        })
      );
    }
  };

  handleName = e => {
    this.setState({ name: e.target.value });
  };

  handleStructure = e => {
    this.setState({ structure: e.target.value });
  };

  handleSiret = e => {
    this.setState({ siret: e.target.value });
  };

  handleCapitalAmount = e => {
    this.setState({ capitalAmount: e.target.value });
  };

  handleActionPrice = e => {
    this.setState({ actionPrice: e.target.value });
  };

  handleAddressStreet = e => {
    e.persist();
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        street: e.target.value
      }
    }));
  };

  handleAdressCity = e => {
    e.persist();
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        city: e.target.value
      }
    }));
  };

  handleAdressZipcode = e => {
    e.persist();
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        zipcode: e.target.value
      }
    }));
  };

  handleAddressCountry = e => {
    e.persist();
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        country: e.target.value
      }
    }));
  };

  updateDocument = () => {
    const { containerDocument } = this.props;
    this.setState(prevState => ({
      document: {
        ...prevState.document,
        url: containerDocument.url
      }
    }));
  };

  handleDocument = e => {
    e.persist();
    this.setState(prevState => ({
      document: {
        ...prevState.document,
        text: e.target.value.replace(/C:\\fakepath\\/i, '')
      }
    }));
  };

  handleCollapse = tooltip => () => {
    const { collapse } = this.state;
    this.setState(prevState => ({
      collapse: {
        ...prevState.collapse,
        [tooltip]: !collapse[tooltip]
      }
    }));
  };

  render() {
    const {
      name,
      structure,
      siret,
      capitalAmount,
      actionPrice,
      address,
      document,
      documentRequired,
      changeProposalsFiltered,
      collapse
    } = this.state;
    const {
      hasRights,
      blocked,
      isLoadingGetContainerDocument
    } = this.props;
    const countries = isoCountries.getNames('fr');
    return (
      <>
        <FormGroup>
          <Label for="name">Dénomination sociale officielle</Label>
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse.name}
              tooltip={Tooltip.EntityBasicInfoComponent.name}
              handleCollapse={this.handleCollapse('name')}
            />
          </small>
          <InputCustom
            placeholder="Dénomination sociale officielle"
            id="name"
            name="name"
            value={name}
            onChange={this.handleName}
            minLength="1"
            maxLength="100"
            pattern={Constants.pattern.internationalName}
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.EntityBasicInfo.name}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="structure">Forme légale de la société</Label>
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse.structure}
              tooltip={Tooltip.EntityBasicInfoComponent.structure}
              handleCollapse={this.handleCollapse('structure')}
            />
          </small>
          <InputCustom
            type="select"
            placeholder="Forme légale de la société"
            id="structure"
            name="structure"
            value={structure}
            pattern={Constants.pattern.structure}
            onChange={this.handleStructure}
            disabled={!hasRights || blocked}
            required>
            <option value="">--Choisir--</option>
            <option value="SARL">SARL</option>
            <option value="SAS">SAS</option>
            <option value="Association">Association</option>
            <option value="SA">SA</option>
            <option value="SNC">SNC</option>
            <option value="Autre">Autre</option>
          </InputCustom>
          <FormFeedback>
            {FrontValidation.EntityBasicInfo.structure}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="siret">SIRET</Label>
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse.siret}
              tooltip={Tooltip.EntityBasicInfoComponent.siret}
              handleCollapse={this.handleCollapse('siret')}
            />
          </small>
          <InputCustom
            placeholder="SIRET"
            id="siret"
            name="siret"
            value={siret}
            onChange={this.handleSiret}
            pattern={Constants.pattern.siret}
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.EntityBasicInfo.siret}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="capitalAmount">
            Capital social de l'entreprise
          </Label>
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse.capitalAmount}
              tooltip={Tooltip.EntityBasicInfoComponent.capitalAmount}
              handleCollapse={this.handleCollapse('capitalAmount')}
            />
          </small>
          <InputCustom
            placeholder="Capital social de l'entreprise"
            id="capitalAmount"
            name="capitalAmount"
            type="number"
            value={capitalAmount}
            onChange={this.handleCapitalAmount}
            min="0.01"
            max="9999999.99"
            step="0.01"
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.EntityBasicInfo.capitalAmount}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="actionPrice">
            Coût nominal d'une action (€)
          </Label>
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse.actionPrice}
              tooltip={Tooltip.EntityBasicInfoComponent.actionPrice}
              handleCollapse={this.handleCollapse('actionPrice')}
            />
          </small>
          <InputCustom
            placeholder="Coût nominal d'une action (€)"
            id="actionPrice"
            name="actionPrice"
            type="number"
            value={actionPrice}
            onChange={this.handleActionPrice}
            min="0.001"
            max="99999.999"
            step="0.001"
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.EntityBasicInfo.actionPrice}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="address.street">Numéro et rue</Label>
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse.addressStreet}
              tooltip={
                Tooltip.EntityBasicInfoComponent.address.street
              }
              handleCollapse={this.handleCollapse('addressStreet')}
            />
          </small>
          <InputCustom
            placeholder="Numéro et rue"
            id="address.street"
            name="address.street"
            value={address.street}
            onChange={this.handleAddressStreet}
            minLength="3"
            maxLength="100"
            pattern={Constants.pattern.internationalName}
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.EntityBasicInfo.address.street}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="address.city">Ville</Label>
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse.addressCity}
              tooltip={Tooltip.EntityBasicInfoComponent.address.city}
              handleCollapse={this.handleCollapse('addressCity')}
            />
          </small>
          <InputCustom
            placeholder="Ville"
            id="address.city"
            name="address.city"
            value={address.city}
            onChange={this.handleAdressCity}
            minLength="1"
            maxLength="100"
            pattern={Constants.pattern.internationalName}
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.EntityBasicInfo.address.city}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="address.zipcode">Code Postal</Label>
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse.addressZipCode}
              tooltip={
                Tooltip.EntityBasicInfoComponent.address.zipcode
              }
              handleCollapse={this.handleCollapse('addressZipCode')}
            />
          </small>
          <InputCustom
            placeholder="Code Postal"
            id="address.zipcode"
            name="address.zipcode"
            value={address.zipcode}
            onChange={this.handleAdressZipcode}
            pattern={Constants.pattern.zipCode}
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.EntityBasicInfo.address.zipcode}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="address.country">Pays</Label>
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse.addressCountry}
              tooltip={
                Tooltip.EntityBasicInfoComponent.address.country
              }
              handleCollapse={this.handleCollapse('addressCountry')}
            />
          </small>
          <InputCustom
            type="select"
            placeholder="Pays"
            id="address.country"
            name="address.country"
            value={address.country}
            onChange={this.handleAddressCountry}
            pattern={Constants.pattern.country}
            disabled={!hasRights || blocked}
            required>
            <option value="">--Choisir--</option>
            {Object.entries(countries).map(([key, value]) => (
              <option key={`${key}_option`} value={key}>
                {value}
              </option>
            ))}
          </InputCustom>
          <FormFeedback>
            {FrontValidation.EntityBasicInfo.address.country}
          </FormFeedback>
        </FormGroup>
        <span className="mb-2">Logo</span>
        <small className="form-text text-muted mb-1 mt-0">
          <TruncateCustom
            collapse={collapse.logo}
            tooltip={Tooltip.EntityBasicInfoComponent.logo}
            handleCollapse={this.handleCollapse('logo')}
          />
        </small>
        <FormGroup>
          <InputCustom
            type="file"
            className="custom-file-input"
            id="logo"
            name="document.logo"
            onChange={e => {
              this.handleDocument(e);
            }}
            disabled={!hasRights || blocked}
            required={documentRequired}
          />
          <Label className="custom-file-label" for="document">
            {document.text !== ''
              ? document.text
              : 'Choisir un fichier'}
          </Label>
          <FormFeedback>
            {FrontValidation.documentFrontValidation}
          </FormFeedback>
          <div className="mt-3">
            {!document.url && isLoadingGetContainerDocument ? (
              <div className="sweet-loading">
                <ClipLoader
                  css="margin: 'O auto';"
                  sizeUnit="px"
                  loading={
                    !document.url && isLoadingGetContainerDocument
                  }
                />
              </div>
            ) : (
              <>
                {document.url && (
                  <>
                    {isImage(document.url) ? (
                      <img
                        src={document.url}
                        className="mw-100"
                        alt="document"
                      />
                    ) : (
                      <a href={document.url}>
                        Télécharger le document
                      </a>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <InputCustom
            type="hidden"
            name="logo"
            value={
              documentRequired
                ? ''
                : changeProposalsFiltered[0].content.logo
            }
          />
        </FormGroup>
      </>
    );
  }
}

const mapStateToProps = state => ({
  operatorEmail: state.container.activeContainer.operatorEmail,
  changeProposalsData: state.folder.getChangeProposalsData,
  containerDocument: state.folder.getContainerDocument,
  activeContainer: state.container.activeContainer.id,
  isLoadingGetContainerDocument:
    state.folder.isLoadingGetContainerDocument
});

export default withRouter(connect(mapStateToProps)(BasicInfo));
