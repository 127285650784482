// @flow
// React Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
// Components
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import InputCustom from '../../InputCustom';
import FrontValidation from '../../../../Resources/Common/FrontValidation';
import Tooltip from '../../../../Resources/Common/Tooltip';

type Props = {
  changeProposalsData: Array<any>,
  hasRights: boolean,
  blocked: boolean,
  proposalUid: string
};

type State = { vimeoId: string, comment: string };

class MediaVideo extends Component<Props, State> {
  constructor(props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = _.pick(
      _.defaults(_.get(changeProposalsFiltered, '[0].content'), {
        vimeoId: '',
        comment: ''
      }),
      ['vimeoId', 'comment']
    );
  }

  render() {
    const { vimeoId, comment } = this.state;
    const { hasRights, blocked } = this.props;
    return (
      <>
        <FormGroup>
          <Label for="vimeoId">ID de la vidéo VIMEO</Label>
          <small className="form-text text-muted mb-1 mt-0">
            {Tooltip.ProjectMediaVideo.vimeoId}
          </small>
          <InputCustom
            placeholder="ID de la vidéo  VIMEO"
            id="vimeoId"
            name="vimeoId"
            defaultValue={vimeoId}
            minLength="3"
            maxLength="100"
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.ProjectMediaVideo.vimeoId}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="comment">Description courte de la vidéo</Label>
          <small className="form-text text-muted mb-1 mt-0">
            {Tooltip.ProjectMediaVideo.comment}
          </small>
          <textarea
            className="form-control"
            id="comment"
            name="comment"
            type="textarea"
            rows={5}
            minLength="20"
            maxLength="400"
            required
            defaultValue={comment}
            disabled={!hasRights || blocked}
          />
          <FormFeedback>
            {FrontValidation.ProjectMediaVideo.comment}
          </FormFeedback>
        </FormGroup>
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData
});

export default withRouter(connect(mapStateToProps)(MediaVideo));
