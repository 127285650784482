// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// Components
import { Form, Label, FormGroup, FormFeedback } from 'reactstrap';
import Template from '../../Components/Template/TemplateComponent';
import InputCustom from '../../Components/Components/InputCustom';
import ButtonCustom from '../../Components/Components/ButtonCustom';
import AlertCustom from '../../Components/Components/AlertCustom';
// Utils
import {
  GetRecoverPasswordAction,
  PostRecoverPasswordAction
} from '../../Business/actions/UserActions';
import { getVisibleObject } from '../../Resources/Common/Utilities';
import Messages from '../../Resources/Common/Messages';
import Routes from '../../Resources/Common/Routes';

type State = {
  newPassword: string,
  newPasswordRepeat: string,
  validationError: string,
  visibleObject: {
    hasChangedPassword: boolean,
    getRecoverError: boolean,
    postRecoverError: boolean,
    validationError: boolean
  }
};

type Props = {
  dispatch: Function,
  postRecoverError: string,
  hasChangedPassword: boolean,
  hasMatchedCode: boolean,
  isLoadingRecoverPassword: boolean,
  isLoadingPostRecoverPassword: boolean,
  getRecoverError: string,
  match: Object
};

class RecoverPasswordScene extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      newPasswordRepeat: '',
      valdationError: '',
      visibleObject: {
        hasChangedPassword: false,
        getRecoverError: false,
        postRecoverError: false,
        validationError: false
      }
    };
    this.onDismiss = this.onDismiss.bind(this);
  }

  componentDidMount() {
    const { match, dispatch, hasMatchedCode } = this.props;
    const { uuid, code } = match.params;

    if (uuid && code && !hasMatchedCode) {
      dispatch(GetRecoverPasswordAction(uuid, code));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.setVisibleAlerts(prevProps, prevState);
  }

  handleNewPassword = e => {
    this.setState({ newPassword: e.target.value });
    const repeatPassword = document.getElementById(
      'repeat-new-password'
    );
    const { newPasswordRepeat } = this.state;
    if (repeatPassword instanceof HTMLInputElement) {
      if (newPasswordRepeat !== e.target.value) {
        e.target.setCustomValidity("Passwords Don't Match");
        repeatPassword.setCustomValidity("Passwords Don't Match");
      } else {
        e.target.setCustomValidity('');
        repeatPassword.setCustomValidity('');
      }
    }
  };

  handleRepeatNewPassword = e => {
    this.setState({ newPasswordRepeat: e.target.value });
    const password = document.getElementById('new-password');
    const { newPassword } = this.state;
    if (password instanceof HTMLInputElement) {
      if (newPassword !== e.target.value) {
        e.target.setCustomValidity("Passwords Don't Match");
        password.setCustomValidity("Passwords Don't Match");
      } else {
        e.target.setCustomValidity('');
        password.setCustomValidity('');
      }
    }
  };

  submitNewPasswordForm = e => {
    e.preventDefault();
    const { dispatch, match } = this.props;
    const { newPassword } = this.state;
    const { uuid, code } = match.params;

    if (e.target.checkValidity()) {
      this.setState({ validationError: '' });
      dispatch(PostRecoverPasswordAction(uuid, newPassword, code));
      e.currentTarget.reset();
      if (e.target.classList.contains('was-validated')) {
        e.target.classList.remove('was-validated');
      }
    } else {
      this.setState({ validationError: Messages.validationError });
      e.stopPropagation();
      e.target.classList.add('was-validated');
    }
  };

  onDismiss = target => {
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        [target]: false
      }
    }));
  };

  displayAlert = () => {
    const {
      hasChangedPassword,
      getRecoverError,
      postRecoverError
    } = this.props;
    const { visibleObject, validationError } = this.state;
    const alert = [];
    let key = 0;

    if (hasChangedPassword) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="hasChangedPassword"
          color="success"
          onDismiss={this.onDismiss}
          errorMessage={
            <>
              {`${Messages.recoverPasswordChanged} `}
              <Link to={Routes.routeLogin}>
                {Messages.connectHere}
              </Link>
            </>
          }
          visible={visibleObject.hasChangedPassword}
        />
      );
    }
    if (getRecoverError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="getRecoverError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={getRecoverError}
          visible={visibleObject.getRecoverError}
        />
      );
    }
    if (postRecoverError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="postRecoverError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={postRecoverError}
          visible={visibleObject.postRecoverError}
        />
      );
    }
    if (validationError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="validationError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={validationError}
          visible={visibleObject.validationError}
        />
      );
    }
    return alert;
  };

  setVisibleAlerts = (prevProps, prevState) => {
    const errorPropsArray = [
      'hasChangedPassword',
      'getRecoverError',
      'postRecoverError',
      'validationError'
    ];
    const { props, state } = this;
    const {
      hasChangedPassword,
      getRecoverError,
      postRecoverError
    } = props;
    const { validationError } = state;
    if (
      hasChangedPassword !== prevProps.hasChangedPassword ||
      getRecoverError !== prevProps.getRecoverError ||
      postRecoverError !== prevProps.postRecoverError ||
      validationError !== prevState.validationError
    ) {
      let newState = Object.assign({}, this.state);
      const visibleObject = getVisibleObject(
        props,
        prevProps,
        state,
        prevState,
        errorPropsArray
      );
      newState = {
        ...newState,
        visibleObject: {
          ...newState.visibleObject,
          ...visibleObject
        }
      };
      if (Object.values(visibleObject).includes(true)) {
        this.setState(newState);
      }
    }
  };

  render() {
    const {
      hasMatchedCode,
      isLoadingRecoverPassword,
      isLoadingPostRecoverPassword
    } = this.props;
    const { newPassword, newPasswordRepeat } = this.state;
    return (
      <Template
        className="login-form"
        title="Middle-Office"
        subtitle="Réinitialisation du mot de passe"
        width="412px">
        {this.displayAlert()}
        {hasMatchedCode && (
          <Form
            className="d-flex flex-column"
            onSubmit={this.submitNewPasswordForm}
            noValidate>
            <FormGroup>
              <Label for="new-password">Nouveau mot de passe</Label>
              <InputCustom
                id="new-password"
                onChange={this.handleNewPassword}
                value={newPassword}
                type="password"
                minLength="6"
                required
              />
              <FormFeedback>
                {Messages.newPasswordFrontValidation}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="repeat-new-password">
                Répeter le mot de passe
              </Label>
              <InputCustom
                id="repeat-new-password"
                onChange={this.handleRepeatNewPassword}
                value={newPasswordRepeat}
                type="password"
                minLength="6"
                required
              />
              <FormFeedback>
                {Messages.repeatPasswordFrontValidation}
              </FormFeedback>
            </FormGroup>
            <ButtonCustom
              type="submit"
              color="primary"
              disabled={
                isLoadingRecoverPassword ||
                isLoadingPostRecoverPassword
              }
              className="align-self-end mb-3">
              Changer le mot de passe
            </ButtonCustom>
          </Form>
        )}
      </Template>
    );
  }
}

const mapStateToProps = state => ({
  getRecoverError: state.user.getRecoverError,
  postRecoverError: state.user.postRecoverError,
  hasMatchedCode: state.user.hasMatchedCode,
  hasChangedPassword: state.user.hasChangedPassword,
  isLoadingRecoverPassword: state.user.isLoadingRecoverPassword,
  isLoadingPostRecoverPassword:
    state.user.isLoadingPostRecoverPassword
});

export default connect(mapStateToProps)(RecoverPasswordScene);
