// @flow
// Services
import DashboardCalendarService from '../../Layer/Network/Service/DashboardCalendarService';
// Common
import Common from '../../Resources/Common';
// Messages
import Messages from '../../Resources/Common/Messages';
import Constants from '../../Resources/Common/Constants';

export const GET_CALENDAR_EVENTS = 'GET_CALENDAR_EVENTS';
export const POST_CALENDAR_EVENTS = 'POST_CALENDAR_EVENTS';
export const PATCH_CALENDAR_EVENTS = 'PATCH_CALENDAR_EVENTS';
export const DELETE_CALENDAR_EVENTS = 'DELETE_CALENDAR_EVENTS';

const {
  NOT_FOUND,
  CALENDAR_NOT_FOUND,
  INSUFFICIENT_RIGHTS
} = Constants.API_ERRORS.CHANGE_CONTAINER;

// Get calendar events
const getCalendarEventsPending = () => ({
  type: GET_CALENDAR_EVENTS,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: null,
    error: ''
  }
});
const getCalendarEventsSuccess = data => ({
  type: GET_CALENDAR_EVENTS,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: ''
  }
});
const getCalendarEventsFailure = error => ({
  type: GET_CALENDAR_EVENTS,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: null,
    error
  }
});

// Post calendar events
const postCalendarEventsPending = () => ({
  type: POST_CALENDAR_EVENTS,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    success: false,
    error: ''
  }
});
const postCalendarEventsSuccess = () => ({
  type: POST_CALENDAR_EVENTS,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    success: true,
    error: ''
  }
});
const postCalendarEventsFailure = error => ({
  type: POST_CALENDAR_EVENTS,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    success: false,
    error
  }
});

// Patch calendar events
const patchCalendarEventsPending = () => ({
  type: PATCH_CALENDAR_EVENTS,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    success: false,
    error: ''
  }
});
const patchCalendarEventsSuccess = () => ({
  type: PATCH_CALENDAR_EVENTS,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    success: true,
    error: ''
  }
});
const patchCalendarEventsFailure = error => ({
  type: PATCH_CALENDAR_EVENTS,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    success: false,
    error
  }
});

// Delete calendar events
const deleteCalendarEventsPending = () => ({
  type: DELETE_CALENDAR_EVENTS,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    success: false,
    error: ''
  }
});
const deleteCalendarEventsSuccess = () => ({
  type: DELETE_CALENDAR_EVENTS,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    success: true,
    error: ''
  }
});
const deleteCalendarEventsFailure = error => ({
  type: DELETE_CALENDAR_EVENTS,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    success: false,
    error
  }
});

export function getCalendarEventsAction({
  containerId,
  calendarUuid,
  timeMin = 0,
  timeMax = 9999999999
}: {
  containerId: string,
  calendarUuid: string,
  timeMin: number,
  timeMax: number
}) {
  return (dispatch: Function) => {
    dispatch(getCalendarEventsPending());
    return DashboardCalendarService.getCalendarEvents({
      containerId,
      calendarUuid,
      timeMin,
      timeMax
    })
      .then(data => {
        dispatch(getCalendarEventsSuccess(data));
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case NOT_FOUND:
            error = Messages.dashboardSettingsNoContainers;
            break;
          case CALENDAR_NOT_FOUND:
            error = Messages.dashboardCalendarNoCalendar;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(getCalendarEventsFailure(error));
      });
  };
}

export function postCalendarEventsAction({
  containerId,
  calendarUuid,
  startDate,
  endDate,
  title,
  description
}: {
  containerId: string,
  calendarUuid: string,
  startDate: string,
  endDate: string,
  title: string,
  description: string
}) {
  return (dispatch: Function) => {
    dispatch(postCalendarEventsPending());
    return DashboardCalendarService.postCalendarEvents({
      containerId,
      calendarUuid,
      startDate,
      endDate,
      title,
      description
    })
      .then(() => {
        dispatch(postCalendarEventsSuccess());
        dispatch(
          getCalendarEventsAction({
            containerId,
            calendarUuid,
            timeMax: 9999999999,
            timeMin: 0
          })
        );
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case NOT_FOUND:
            error = Messages.dashboardSettingsNoContainers;
            break;
          case CALENDAR_NOT_FOUND:
            error = Messages.dashboardCalendarNoCalendar;
            break;
          case INSUFFICIENT_RIGHTS:
            error = Messages.dashboardCalendarNoPushEventsRight;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(postCalendarEventsFailure(error));
      });
  };
}

export function patchCalendarEventsAction({
  containerId,
  calendarUuid,
  eventId,
  startDate,
  endDate,
  title,
  description
}: {
  containerId: string,
  calendarUuid: string,
  eventId: string,
  startDate: string,
  endDate: string,
  title: string,
  description: string
}) {
  return (dispatch: Function) => {
    dispatch(patchCalendarEventsPending());
    return DashboardCalendarService.patchCalendarEvents({
      containerId,
      calendarUuid,
      eventId,
      startDate,
      endDate,
      title,
      description
    })
      .then(() => {
        dispatch(patchCalendarEventsSuccess());
        dispatch(
          getCalendarEventsAction({
            containerId,
            calendarUuid,
            timeMax: 9999999999,
            timeMin: 0
          })
        );
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case NOT_FOUND:
            error = Messages.dashboardSettingsNoContainers;
            break;
          case CALENDAR_NOT_FOUND:
            error = Messages.dashboardCalendarNoCalendar;
            break;
          case INSUFFICIENT_RIGHTS:
            error = Messages.dashboardCalendarNoPushEventsRight;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(patchCalendarEventsFailure(error));
      });
  };
}

export function deleteCalendarEventsAction({
  containerId,
  calendarUuid,
  eventId,
  operatorEmail
}: {
  containerId: string,
  calendarUuid: string,
  eventId: string
}) {
  return (dispatch: Function) => {
    dispatch(deleteCalendarEventsPending());
    return DashboardCalendarService.deleteCalendarEvents({
      containerId,
      calendarUuid,
      eventId
    })
      .then(() => {
        dispatch(deleteCalendarEventsSuccess());
        dispatch(
          getCalendarEventsAction({
            containerId,
            calendarUuid,
            timeMax: 9999999999,
            timeMin: 0
          })
        );
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case NOT_FOUND:
            error = Messages.dashboardSettingsNoContainers;
            break;
          case CALENDAR_NOT_FOUND:
            error = Messages.dashboardCalendarNoCalendar;
            break;
          case INSUFFICIENT_RIGHTS:
            error = Messages.dashboardCalendarNoPushEventsRight;
            break;
          default:
            error = Messages.deleteEventError(operatorEmail);
            break;
        }
        dispatch(deleteCalendarEventsFailure(error));
      });
  };
}
