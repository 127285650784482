// @flow
// Services
import DashboardChecklistService from '../../Layer/Network/Service/DashboardChecklistService';
// Common
import Common from '../../Resources/Common';
// Messages
import Messages from '../../Resources/Common/Messages';
import Constants from '../../Resources/Common/Constants';

export const GET_CHECKLIST = 'GET_CHECKLIST';
export const PATCH_TODO = 'PATCH_TODO';

// Get checklists
const getChecklistPending = () => ({
  type: GET_CHECKLIST,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: null,
    error: ''
  }
});
const getChecklistSuccess = data => ({
  type: GET_CHECKLIST,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: ''
  }
});
const getChecklistFailure = error => ({
  type: GET_CHECKLIST,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: null,
    error
  }
});

// Patch todos
const patchTodoPending = () => ({
  type: PATCH_TODO,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: null,
    error: ''
  }
});
const patchTodoSuccess = message => ({
  type: PATCH_TODO,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: message,
    error: ''
  }
});
const patchTodoFailure = error => ({
  type: PATCH_TODO,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: null,
    error
  }
});

export function getChecklistAction({
  containerId,
  todoListId
}: {
  containerId: string,
  todoListId: string
}) {
  return (dispatch: Function) => {
    dispatch(getChecklistPending());
    return DashboardChecklistService.getChecklist({
      containerId,
      todoListId
    })
      .then(data => {
        dispatch(getChecklistSuccess(data));
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.CHANGE_CONTAINER.NOT_FOUND:
            error = Messages.dashboardSettingsNoContainers;
            break;
          case Constants.API_ERRORS.CHANGE_CONTAINER
            .TODOLIST_NOT_FOUND:
            error = Messages.dashboardChecklistTodoListNotFound;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(getChecklistFailure(error));
      });
  };
}

export function patchTodoAction({
  containerId,
  todoListId,
  todoId,
  completed
}: {
  containerId: string,
  todoListId: string,
  todoId: string,
  completed: boolean
}) {
  return (dispatch: Function) => {
    dispatch(patchTodoPending());
    return DashboardChecklistService.patchTodo({
      completed,
      containerId,
      todoListId,
      todoId
    })
      .then(() => {
        const successMessage =
          Messages.dashboardChecklistPatchTodoSuccess;
        dispatch(patchTodoSuccess(successMessage));
        dispatch(
          getChecklistAction({
            containerId,
            todoListId
          })
        );
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.CHANGE_CONTAINER.NOT_FOUND:
            error = Messages.dashboardSettingsNoContainers;
            break;
          case Constants.API_ERRORS.CHANGE_CONTAINER
            .TODOLIST_NOT_FOUND:
            error = Messages.dashboardChecklistTodoListNotFound;
            break;
          case Constants.API_ERRORS.CHANGE_CONTAINER
            .INSUFFICIENT_RIGHTS:
            error = Messages.dashboardCalendarNoPushEventsRight;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(patchTodoFailure(error));
      });
  };
}
