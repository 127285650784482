// @flow
// Services
import DashboardSettingsService from '../../Layer/Network/Service/DashboardSettingsService';
// Common
import Common from '../../Resources/Common';
// Messages
import Messages from '../../Resources/Common/Messages';
import Constants from '../../Resources/Common/Constants';

export const GET_CONTAINER_RIGHTS = 'GET_CONTAINER_RIGHTS';
export const POST_CONTAINER_INVITATION = 'POST_CONTAINER_INVITATION';
export const GET_CONTAINER_INVITATIONS = 'GET_CONTAINER_INVITATIONS';
export const DELETE_CONTAINER_INVITATIONS =
  'DELETE_CONTAINER_INVITATIONS';
export const PATCH_USER_RIGHTS = 'PATCH_USER_RIGHTS';
export const DELETE_USER_RIGHTS = 'DELETE_USER_RIGHTS';
export const GET_CONTAINER_DATA = 'GET_CONTAINER_DATA';

// Get container rights
const getContainerRightsPending = () => ({
  type: GET_CONTAINER_RIGHTS,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: null,
    error: ''
  }
});
const getContainerRightsSuccess = data => ({
  type: GET_CONTAINER_RIGHTS,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: ''
  }
});
const getContainerRightsFailure = error => ({
  type: GET_CONTAINER_RIGHTS,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: null,
    error
  }
});

// Send container invitations
const postContainerInvitationPending = () => ({
  type: POST_CONTAINER_INVITATION,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: null,
    error: '',
    hasSuccess: false
  }
});
const postContainerInvitationSuccess = data => ({
  type: POST_CONTAINER_INVITATION,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: '',
    hasSuccess: true
  }
});
const postContainerInvitationFailure = error => ({
  type: POST_CONTAINER_INVITATION,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: null,
    error,
    hasSuccess: false
  }
});

// Get container invitations
const getContainerInvitationsPending = () => ({
  type: GET_CONTAINER_INVITATIONS,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: null,
    error: ''
  }
});
const getContainerInvitationsSuccess = data => ({
  type: GET_CONTAINER_INVITATIONS,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: ''
  }
});
const getContainerInvitationsFailure = error => ({
  type: GET_CONTAINER_INVITATIONS,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: null,
    error
  }
});

// Dele container invitation

// Get container invitations
const deleteContainerInvitationsPending = () => ({
  type: DELETE_CONTAINER_INVITATIONS,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    hasSuccess: false,
    error: ''
  }
});
const deleteContainerInvitationsSuccess = () => ({
  type: DELETE_CONTAINER_INVITATIONS,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    hasSuccess: true,
    error: ''
  }
});
const deleteContainerInvitationsFailure = error => ({
  type: DELETE_CONTAINER_INVITATIONS,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    hasSuccess: false,
    error
  }
});

// Patch containeruser rights
const patchUserRightsPending = () => ({
  type: PATCH_USER_RIGHTS,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    hasSuccess: false,
    error: ''
  }
});
const patchUserRightsSuccess = () => ({
  type: PATCH_USER_RIGHTS,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    hasSuccess: true,
    error: ''
  }
});
const patchUserRightsFailure = error => ({
  type: PATCH_USER_RIGHTS,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    hasSuccess: false,
    error
  }
});

// Delete User from container
const deleteUserRightsPending = () => ({
  type: DELETE_USER_RIGHTS,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    hasSuccess: false,
    error: ''
  }
});
const deleteUserRightsSuccess = () => ({
  type: DELETE_USER_RIGHTS,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    hasSuccess: true,
    error: ''
  }
});
const deleteUserRightsFailure = error => ({
  type: DELETE_USER_RIGHTS,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    hasSuccess: false,
    error
  }
});

// Get container data
const getContainerDataPending = () => ({
  type: GET_CONTAINER_DATA,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: null,
    error: ''
  }
});
const getContainerDataSuccess = data => ({
  type: GET_CONTAINER_DATA,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: ''
  }
});
const getContainerDataFailure = error => ({
  type: GET_CONTAINER_DATA,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: null,
    error
  }
});

export function getContainerRightsAction(uuid: string) {
  return (dispatch: Function) => {
    dispatch(getContainerRightsPending());
    return DashboardSettingsService.getContainerRights(uuid)
      .then(data => {
        dispatch(getContainerRightsSuccess(data));
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.CHANGE_CONTAINER.NOT_FOUND:
            error = Messages.dashboardSettingsNoContainers;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(getContainerRightsFailure(error));
      });
  };
}

export function postContainerInvitationAction(
  email: string,
  rights: Array<any>,
  uuid: string
) {
  return (dispatch: Function) => {
    dispatch(postContainerInvitationPending());
    return DashboardSettingsService.postContainerInvitation(
      email,
      rights,
      uuid
    )
      .then(data => {
        dispatch(postContainerInvitationSuccess(data));
      })
      .catch(err => {
        let error = Messages.defaultError;
        if (err.pointers) {
          err.pointers.forEach(pointer => {
            switch (pointer) {
              case '/rights':
                error +=
                  Messages.dashboardSettingsInvitationsWrongRights;
                break;
              case '/email':
                error +=
                  Messages.dashboardSettingsInvitationsWrongEmail;
                break;
              default:
                error = Messages.defaultError;
                break;
            }
          });
        }
        dispatch(postContainerInvitationFailure(error));
      });
  };
}

export function getInvitationsAction(
  uuid: string,
  operatorEmail: string
) {
  return (dispatch: Function) => {
    dispatch(getContainerInvitationsPending());
    return DashboardSettingsService.getContainerInvitations(uuid)
      .then(data => {
        dispatch(getContainerInvitationsSuccess(data));
      })
      .catch(() => {
        const message = Messages.getInvitationsNotFound(
          operatorEmail
        );
        dispatch(getContainerInvitationsFailure(message));
      });
  };
}

export function deleteInvitationsAction(
  containerId: string,
  invitationUuid: string,
  operatorEmail: string
) {
  return (dispatch: Function) => {
    dispatch(deleteContainerInvitationsPending());
    return DashboardSettingsService.deleteContainerInvitations(
      containerId,
      invitationUuid
    )
      .then(() => {
        dispatch(deleteContainerInvitationsSuccess());
      })
      .catch(() => {
        const message = Messages.deleteInvitationsNotFound(
          operatorEmail
        );
        dispatch(deleteContainerInvitationsFailure(message));
      });
  };
}

export function patchUserRightsAction(
  rightArray: Array<{
    userId: string,
    newRights: [],
    activeContainer: string
  }>,
  operatorEmail: string
) {
  return (dispatch: Function) => {
    dispatch(patchUserRightsPending());
    Promise.all(
      rightArray.map(right =>
        DashboardSettingsService.patchUserRights({
          uuid: right.activeContainer,
          userId: right.userId,
          rights: right.newRights
        })
      )
    )
      .then(() => {
        dispatch(patchUserRightsSuccess());
      })
      .catch(() => {
        dispatch(
          patchUserRightsFailure(
            Messages.patchUserRightError(operatorEmail)
          )
        );
      });
  };
}

export function deleteUserRightsAction(
  uuid: string,
  userId: string,
  rights: Array<any>,
  operatorEmail: string
) {
  return (dispatch: Function) => {
    dispatch(deleteUserRightsPending());
    return DashboardSettingsService.patchUserRights({
      uuid,
      userId,
      rights
    })
      .then(() => {
        dispatch(deleteUserRightsSuccess());
      })
      .catch(() => {
        dispatch(
          deleteUserRightsFailure(
            Messages.deleteUserRightError(operatorEmail)
          )
        );
      });
  };
}

export function getContainerDataAction(uuid: string) {
  return (dispatch: Function) => {
    dispatch(getContainerDataPending());
    return DashboardSettingsService.getContainerData(uuid)
      .then(data => {
        dispatch(getContainerDataSuccess(data));
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.CHANGE_CONTAINER.NOT_FOUND:
            error = Messages.dashboardSettingsNoContainers;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(getContainerDataFailure(error));
      });
  };
}
