// @flow
// React Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import _pick from 'lodash/fp/pick';
import _get from 'lodash/fp/get';
import _merge from 'lodash/fp/merge';
import _pullAt from 'lodash/fp/pullAt';
// Components
import { Table, FormFeedback } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import InputCustom from '../../../InputCustom';
import ButtonCustom from '../../../ButtonCustom';
import FrontValidation from '../../../../../Resources/Common/FrontValidation';
import Constants from '../../../../../Resources/Common/Constants';

type Props = {
  changeProposalsData: Array<Object>,
  hasRights: boolean,
  blocked: boolean,
  proposalUid: string
};

type State = {
  breakdown: Array<{
    name: string,
    value: number
  }>
};

class InfoStructureCap extends Component<Props, State> {
  constructor(props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = _pick(
      ['breakdown'],
      _merge(
        {
          breakdown: [{ name: '', value: 0 }]
        },
        _get('[0].content', changeProposalsFiltered)
      )
    );
  }

  handleName = (e, itemNumber) => {
    e.persist();
    const { breakdown } = this.state;
    this.setState({
      breakdown: update(breakdown, {
        [itemNumber]: { name: { $set: e.target.value } }
      })
    });
  };

  handleValue = (e, itemNumber) => {
    e.persist();
    const { breakdown } = this.state;
    this.setState({
      breakdown: update(breakdown, {
        [itemNumber]: { value: { $set: e.target.value } }
      })
    });
  };

  addTd = () => {
    const { breakdown } = this.state;
    this.setState({
      breakdown: breakdown.concat({ name: '', value: 0 })
    });
  };

  deleteTd = index => {
    const { breakdown } = this.state;
    this.setState({
      breakdown: _pullAt([index], breakdown)
    });
  };

  renderInputs = () => {
    const { breakdown } = this.state;
    const { hasRights, blocked } = this.props;
    return breakdown.map((item, iterator) => (
      <tr key={`${iterator + 1}_tr`}>
        <td key={`${iterator + 1}_name_td`}>
          <InputCustom
            placeholder="Nom"
            id={`breakdown.${iterator}.name`}
            name={`breakdown.${iterator}.name`}
            value={item.name}
            onChange={e => {
              this.handleName(e, iterator);
            }}
            min="1"
            max="30"
            pattern={Constants.pattern.internationalName}
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.ProjectBasicInfoStructureCap.name}
          </FormFeedback>
        </td>
        <td key={`${iterator + 1}_value_td`}>
          <InputCustom
            placeholder="Valeur"
            id={`breakdown.${iterator}.value`}
            name={`breakdown.${iterator}.value`}
            type="number"
            value={item.value}
            onChange={e => {
              this.handleValue(e, iterator);
            }}
            min="0"
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.ProjectBasicInfoStructureCap.value}
          </FormFeedback>
        </td>
        <td>
          {hasRights && !blocked && (
            <ButtonCustom
              className="close"
              aria-label="Close"
              onClick={() => this.deleteTd(iterator)}>
              <span aria-hidden="true">&times;</span>
            </ButtonCustom>
          )}
        </td>
      </tr>
    ));
  };

  render() {
    const { hasRights, blocked } = this.props;
    return (
      <>
        <Table striped className="table-borderless">
          <thead className="border-bottom border-primary">
            <tr>
              <th>Nom</th>
              <th>Valeur</th>
            </tr>
          </thead>
          <tbody>{this.renderInputs()}</tbody>
        </Table>
        {hasRights && !blocked && (
          <ButtonCustom
            color="primary"
            className="mb-3"
            onClick={this.addTd}>
            Ajouter une ligne
          </ButtonCustom>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData
});

export default withRouter(connect(mapStateToProps)(InfoStructureCap));
