// @flow

import React from 'react';
import { NavItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import NavlinkCustom from '../NavlinkCustom';

import Utilities from './Utilities';
import Routes from '../../../Resources/Common/Routes';
import ProposalStatusBadge from './ProposalStatusBadge';

type Proposal = {
  status: number,
  kind: string,
  title: string,
  isMandatory: boolean
};

type Props = {
  proposal: Proposal,
  location: Object,
  activeContainer: string
};

function FolderSectionNavLink({
  proposal,
  location,
  activeContainer
}: Props) {
  return (
    <NavItem>
      <NavlinkCustom
        tag={Link}
        to={`/${Routes.routeFolder}/${activeContainer}/${
          Routes.routeProposals
        }/${proposal.kind}`}
        active={Utilities.manageMenuActiveItem(
          location,
          `${Routes.routeFolder}/${activeContainer}/${
            Routes.routeProposals
          }/${proposal.kind}`
        )}>
        <ProposalStatusBadge proposal={proposal} asTitle={false} />
      </NavlinkCustom>
    </NavItem>
  );
}

export default withRouter(FolderSectionNavLink);
