// @flow

import React from 'react';
import PropTypes from 'prop-types';
import FinancialData from './FinancialData';
import Tooltip from '../../../../../Resources/Common/Tooltip';

export default function ClientsB2B({
  hasRights,
  blocked,
  proposalUid
}) {
  return (
    <FinancialData
      title="Clients B2B"
      tooltip={Tooltip.ProjectFinancialDataB2B}
      fieldNames={{
        figures: 'Clients B2B',
        comment: 'Commentaire Clients B2B',
        highlight: 'Valeur mise en avant Clients B2B',
        breakdown: 'Graph Camembert Clients B2B'
      }}
      hasRights={hasRights}
      blocked={blocked}
      proposalUid={proposalUid}
    />
  );
}

ClientsB2B.propTypes = {
  hasRights: PropTypes.bool.isRequired,
  blocked: PropTypes.bool.isRequired,
  proposalUid: PropTypes.string
};

ClientsB2B.defaultProps = {
  proposalUid: undefined
};
