// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
// Components
import {
  Col,
  Form,
  Label,
  FormGroup,
  FormFeedback,
  Row
} from 'reactstrap';
import Template from '../../Components/Template/TemplateComponent';
import InputCustom from '../../Components/Components/InputCustom';
import ButtonCustom from '../../Components/Components/ButtonCustom';
import AlertCustom from '../../Components/Components/AlertCustom';
// Utils
import { ChangePasswordAction } from '../../Business/actions/AccountSecurityActions';
import Messages from '../../Resources/Common/Messages';
import { getVisibleObject } from '../../Resources/Common/Utilities';

type State = {
  newPassword: string,
  password: string,
  validationError: string,
  visibleObject: {
    hasSuccess: boolean,
    changePasswordError: boolean,
    validationError: boolean
  }
};
type Props = {
  dispatch: Function,
  changePasswordError: string,
  isLoadingChangePassword: boolean,
  hasSuccess: boolean,
  firstname: string,
  lastname: string
};

class AccountSecurityScene extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      visibleObject: {
        changePasswordError: false,
        hasSuccess: false,
        validationError: false
      },
      password: '',
      newPassword: '',
      validationError: ''
    };
    this.onDismiss = this.onDismiss.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    this.setVisibleAlerts(prevProps, prevState);
  }

  handlePasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  handleNewPasswordChange = e => {
    this.setState({ newPassword: e.target.value });
    const repeatPassword = document.getElementById(
      'repeat-new-password'
    );
    if (repeatPassword instanceof HTMLInputElement) {
      if (repeatPassword.value !== e.target.value) {
        e.target.setCustomValidity("Passwords Don't Match");
        repeatPassword.setCustomValidity("Passwords Don't Match");
      } else {
        e.target.setCustomValidity('');
        repeatPassword.setCustomValidity('');
      }
    }
  };

  handleNewPasswordRepeatChange = e => {
    const password = document.getElementById('new-password');
    if (password instanceof HTMLInputElement) {
      if (password.value !== e.target.value) {
        e.target.setCustomValidity("Passwords Don't Match");
        password.setCustomValidity("Passwords Don't Match");
      } else {
        e.target.setCustomValidity('');
        password.setCustomValidity('');
      }
    }
  };

  submitResetPassword = e => {
    const { dispatch } = this.props;
    const { password, newPassword } = this.state;
    e.preventDefault();
    if (e.target.checkValidity()) {
      this.setState({
        validationError: ''
      });
      dispatch(ChangePasswordAction(password, newPassword));
    } else {
      this.setState({
        validationError: Messages.validationError
      });
      e.stopPropagation();
    }
    e.target.classList.add('was-validated');
  };

  onDismiss = target => {
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        [target]: false
      }
    }));
  };

  displayAlert = () => {
    const { hasSuccess, changePasswordError } = this.props;
    const { visibleObject, validationError } = this.state;
    const alert = [];
    let key = 0;

    if (hasSuccess) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="hasSuccess"
          color="success"
          onDismiss={this.onDismiss}
          errorMessage={Messages.accountSecuritySuccessChangePassword}
          visible={visibleObject.hasSuccess}
        />
      );
    }
    if (changePasswordError) {
      key += 1;
      alert.push(
        <AlertCustom
          target="changePasswordError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={changePasswordError}
          key={key}
          visible={visibleObject.changePasswordError}
        />
      );
    }
    if (validationError) {
      key += 1;
      alert.push(
        <AlertCustom
          target="validationError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={validationError}
          key={key}
          visible={visibleObject.validationError}
        />
      );
    }
    return alert;
  };

  setVisibleAlerts = (prevProps, prevState) => {
    const errorPropsArray = [
      'changePasswordError',
      'hasSuccess',
      'validationError'
    ];
    const { props, state } = this;
    const { changePasswordError, hasSuccess } = props;
    const { validationError } = state;
    if (
      changePasswordError !== prevProps.changePasswordError ||
      hasSuccess !== prevProps.hasSuccess ||
      validationError !== prevState.validationError
    ) {
      let newState = Object.assign({}, this.state);
      const visibleObject = getVisibleObject(
        props,
        prevProps,
        state,
        prevState,
        errorPropsArray
      );
      newState = {
        ...newState,
        visibleObject: {
          ...newState.visibleObject,
          ...visibleObject
        }
      };
      if (
        Object.values(visibleObject).includes(true) &&
        !_.isEqual(newState, state)
      ) {
        this.setState(newState);
      }
    }
  };

  render() {
    const {
      firstname,
      lastname,
      isLoadingChangePassword
    } = this.props;
    return (
      <Template
        title={`${firstname} ${lastname}`}
        subtitle="Sécurité du compte Sowefund"
        menu="account">
        {this.displayAlert()}
        <Row>
          <Col md={{ size: 6 }} className="mx-auto">
            <Form
              onSubmit={this.submitResetPassword}
              className="d-flex flex-column"
              noValidate>
              <FormGroup className="form-group">
                <Label for="password">Mot de passe actuel</Label>
                <InputCustom
                  id="password"
                  onChange={this.handlePasswordChange}
                  type="password"
                  minLength="6"
                  required
                />
                <FormFeedback>
                  {Messages.passwordFrontValidation}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="new-password">Nouveau mot de passe</Label>
                <InputCustom
                  id="new-password"
                  onChange={this.handleNewPasswordChange}
                  type="password"
                  minLength="6"
                  required
                />
                <FormFeedback>
                  {Messages.newPasswordFrontValidation}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="repeat-new-password">
                  Répeter le mot de passe
                </Label>
                <InputCustom
                  id="repeat-new-password"
                  onChange={this.handleNewPasswordRepeatChange}
                  type="password"
                  minLength="6"
                  required
                />
                <FormFeedback>
                  {Messages.newPasswordFrontValidation}
                </FormFeedback>
              </FormGroup>
              <ButtonCustom
                type="submit"
                color="primary"
                className="align-self-end"
                disabled={isLoadingChangePassword}>
                Changer le mot de passe
              </ButtonCustom>
            </Form>
          </Col>
        </Row>
      </Template>
    );
  }
}

const mapStateToProps = state => ({
  email: state.account.accountInfos.email,
  changePasswordError: state.accountSecurity.changePasswordError,
  hasSuccess: state.accountSecurity.hasSuccess,
  firstname: state.account.accountInfos.firstName,
  lastname: state.account.accountInfos.lastName,
  isLoadingChangePassword:
    state.accountSecurity.isLoadingChangePassword
});

export default connect(mapStateToProps)(AccountSecurityScene);
