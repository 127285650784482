// @flow

import Common from '../../Resources/Common';
import { CHANGE_PASSWORD } from '../actions/AccountSecurityActions';

const defaultState = {
  postLoginData: null,
  isLoadingChangePassword: false,
  changePasswordError: '',
  token: ''
};

const AccountSecurityReducer = (
  state: Object = defaultState,
  action: Object = {}
) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return Object.assign({}, state, {
        postLoginData: action.payload.aggregate,
        changePasswordError: action.payload.error,
        hasSuccess: action.payload.hasSuccess,
        isLoadingChangePassword:
          action.payload.status === Common.Constants.PENDING_STATUS
      });
    default:
      return state;
  }
};

export default AccountSecurityReducer;
