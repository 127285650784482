// @flow

// Utils
import NetworkUrls from '../NetworkUrls';
import NetworkUtils from '../NetworkUtils';

/**
 * This service concerns every aspect of the account lifecycle.
 * A user can update its password, or any information stored in the application.
 */
export default class AccountService {
  static patchAvatar(file: FormData) {
    return NetworkUtils.patch({
      url: NetworkUrls.patchAvatar(),
      data: file
    });
  }

  static patchPassword(old: string, newPassword: string) {
    return NetworkUtils.patch({
      url: NetworkUrls.patchPassword(),
      data: {
        old,
        new: newPassword
      }
    });
  }

  static getAccountInfos() {
    return NetworkUtils.get({
      url: NetworkUrls.getAccountInfos()
    });
  }
}
