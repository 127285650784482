// @flow

// React libs
import React from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import downarrow from '../../Resources/assets/img/main/downarrow.svg';
import uparrow from '../../Resources/assets/img/main/uparrow.svg';
import Constants from '../../Resources/Common/Constants';

export default function TruncateCustom(props) {
  const { handleCollapse, tooltip, collapse } = props;
  return collapse ? (
    <Truncate
      lines={Constants.tootltipNbLines}
      ellipsis={
        <>
          <>... </>
          <div className="w-100 my-3">
            <img
              alt="read-more"
              src={downarrow}
              style={{ cursor: 'pointer' }}
              className="d-block m-auto"
              width="16px"
              onClick={handleCollapse}
            />
          </div>
        </>
      }>
      {tooltip}
    </Truncate>
  ) : (
    <>
      {tooltip}
      <div className="w-100 my-3">
        <img
          alt="read-less"
          style={{ cursor: 'pointer' }}
          className="d-block m-auto"
          width="16px"
          src={uparrow}
          onClick={handleCollapse}
        />
      </div>
    </>
  );
}

TruncateCustom.propTypes = {
  handleCollapse: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  collapse: PropTypes.bool.isRequired
};

TruncateCustom.defaultProps = {
  tooltip: undefined
};
