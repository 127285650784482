// @flow

import React from 'react';
import PropTypes from 'prop-types';
import ProjectContentDescription from '../ContentDescription';
import Tooltip from '../../../../../Resources/Common/Tooltip';

export default function Goals(props) {
  const { proposalUid, blocked, hasRights } = props;
  return (
    <ProjectContentDescription
      title="Le futur / objectifs"
      tooltip={Tooltip.ProjectOfferGoals.description}
      proposalUid={proposalUid}
      blocked={blocked}
      hasRights={hasRights}
    />
  );
}

Goals.propTypes = {
  hasRights: PropTypes.bool.isRequired,
  blocked: PropTypes.bool.isRequired,
  proposalUid: PropTypes.string
};

Goals.defaultProps = {
  proposalUid: undefined
};
