// @flow

import React from 'react';
import PropTypes from 'prop-types';
import FinancialData from './FinancialData';
import Tooltip from '../../../../../Resources/Common/Tooltip';

export default function Users({ hasRights, blocked, proposalUid }) {
  return (
    <>
      <FinancialData
        title="Utilisateurs"
        tooltip={Tooltip.ProjectFinancialDataUsers}
        fieldNames={{
          figures: 'Utilisateurs',
          comment: 'Commentaire Utilisateurs',
          highlight: 'Valeur mise en avant Utilisateurs',
          breakdown: 'Graph Camembert Utilisateurs'
        }}
        hasRights={hasRights}
        blocked={blocked}
        proposalUid={proposalUid}
      />
    </>
  );
}

Users.propTypes = {
  hasRights: PropTypes.bool.isRequired,
  blocked: PropTypes.bool.isRequired,
  proposalUid: PropTypes.string
};

Users.defaultProps = {
  proposalUid: undefined
};
