// @flow

import React from 'react';
import PropTypes from 'prop-types';
import FinancialData from './FinancialData';
import Tooltip from '../../../../../Resources/Common/Tooltip';

export default function ProfitLoss({
  hasRights,
  blocked,
  proposalUid
}) {
  return (
    <>
      <FinancialData
        title="Bénéfice net"
        tooltip={Tooltip.ProjectFinancialDataProfitLoss}
        fieldNames={{
          figures: 'Résultat net',
          comment: 'Commentaire Résultat net',
          highlight: 'Valeur mise en avant Résultat net',
          breakdown: 'Graph Camembert Résultat net'
        }}
        hasRights={hasRights}
        blocked={blocked}
        proposalUid={proposalUid}
      />
    </>
  );
}

ProfitLoss.propTypes = {
  hasRights: PropTypes.bool.isRequired,
  blocked: PropTypes.bool.isRequired,
  proposalUid: PropTypes.string
};

ProfitLoss.defaultProps = {
  proposalUid: undefined
};
