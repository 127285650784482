// @flow

/**
 * All networking url used in this app
 */
export default class NetworkUrls {
  static get apiPath(): string {
    return localStorage.getItem('GLOBAL.host');
  }

  // Login
  static postLogin() {
    return `${NetworkUrls.apiPath}/user/login`;
  }

  static postSocialLogin() {
    return `${NetworkUrls.apiPath}/user/social-login`;
  }

  static logout() {
    return `${NetworkUrls.apiPath}/user/logout`;
  }

  static getCgu(kind: number) {
    return `${NetworkUrls.apiPath}/platform/legal?kind=${kind}`;
  }

  static acceptCgu() {
    return `${NetworkUrls.apiPath}/user/prerequisites`;
  }

  static postResetPassword() {
    return `${NetworkUrls.apiPath}/user/reset-password`;
  }

  static getRecoverPassword(uuid: string, code: string) {
    return `${
      NetworkUrls.apiPath
    }/user/recover-password/${uuid}/${code}`;
  }

  static postRecoverPassword() {
    return `${NetworkUrls.apiPath}/user/recover-password`;
  }

  static getContainers() {
    return `${NetworkUrls.apiPath}/change-containers`;
  }

  static postUserRegister() {
    return `${NetworkUrls.apiPath}/user/register`;
  }

  static postUserPreRegister() {
    return `${NetworkUrls.apiPath}/user/pre-register`;
  }

  // Account
  static getAccountInfos() {
    return `${NetworkUrls.apiPath}/context/user`;
  }

  static patchAvatar() {
    return `${NetworkUrls.apiPath}/account/avatar`;
  }

  static patchPassword() {
    return `${NetworkUrls.apiPath}/account/password`;
  }

  // Dashboard
  static getContainerRights(uuid: string) {
    return `${NetworkUrls.apiPath}/change-containers/${uuid}/rights`;
  }

  static getUserRights(containerId: string) {
    return `${
      NetworkUrls.apiPath
    }/context/rights/change-containers/${containerId}`;
  }

  static postContainerInvitation(uuid: string) {
    return `${
      NetworkUrls.apiPath
    }/change-containers/${uuid}/invitations`;
  }

  static getContainerInvitations(uuid: string) {
    return `${
      NetworkUrls.apiPath
    }/change-containers/${uuid}/invitations`;
  }

  static deleteContainerInvitations(
    containerId: string,
    invitationUuid: string
  ) {
    return `${
      NetworkUrls.apiPath
    }/change-containers/${containerId}/invitations/${invitationUuid}`;
  }

  static getContainerData(uuid: string) {
    return `${NetworkUrls.apiPath}/change-containers/${uuid}`;
  }

  static patchUserRights(uuid: string) {
    return `${NetworkUrls.apiPath}/change-containers/${uuid}/rights`;
  }

  static getCalendarEvents({
    containerId,
    calendarUuid,
    timeMin,
    timeMax
  }: {
    containerId: string,
    calendarUuid: string,
    timeMin: number,
    timeMax: number
  }) {
    return `${
      NetworkUrls.apiPath
    }/change-containers/${containerId}/calendars/${calendarUuid}/events?${timeMin}&${timeMax}`;
  }

  static postCalendarEvents(
    containerId: string,
    calendarUuid: string
  ) {
    return `${
      NetworkUrls.apiPath
    }/change-containers/${containerId}/calendars/${calendarUuid}/events`;
  }

  static patchCalendarEvents(
    containerId: string,
    calendarUuid: string,
    eventId: string
  ) {
    return `${
      NetworkUrls.apiPath
    }/change-containers/${containerId}/calendars/${calendarUuid}/events/${eventId}`;
  }

  static deleteCalendarEvents(
    containerId: string,
    calendarUuid: string,
    eventId: string
  ) {
    return `${
      NetworkUrls.apiPath
    }/change-containers/${containerId}/calendars/${calendarUuid}/events/${eventId}`;
  }

  static getChecklist({
    containerId,
    todoListId
  }: {
    containerId: string,
    todoListId: string
  }) {
    return `${
      NetworkUrls.apiPath
    }/change-containers/${containerId}/todo-lists/${todoListId}/todos`;
  }

  static patchTodo(
    containerId: string,
    todoListId: string,
    todoId: string
  ) {
    return `${
      NetworkUrls.apiPath
    }/change-containers/${containerId}/todo-lists/${todoListId}/todos/${todoId}`;
  }

  // Folder
  static getContainerChange(containerId: string) {
    return `${NetworkUrls.apiPath}/change-containers/${containerId}`;
  }

  static getChangeProposals({
    containerId,
    kind,
    status
  }: {
    containerId: string,
    kind: string | typeof undefined,
    status: number | typeof undefined
  }) {
    const statusString = status ? `?status=${status}` : '';
    let kindString = '';
    if (kind) {
      kindString = status ? `&kind=${kind}` : `?kind=${kind}`;
    }
    return `${
      NetworkUrls.apiPath
    }/change-containers/${containerId}/change-proposals${statusString}${kindString}`;
  }

  static postChangeProposals(containerId: string) {
    return `${
      NetworkUrls.apiPath
    }/change-containers/${containerId}/change-proposals`;
  }

  static getContextManifest() {
    return `${NetworkUrls.apiPath}/context/manifest`;
  }

  static postChangeContainerDocument(containerId: string) {
    return `${
      NetworkUrls.apiPath
    }/change-containers/${containerId}/documents`;
  }

  static getChangeContainerDocument(
    containerId: string,
    docUuid: string
  ) {
    return `${
      NetworkUrls.apiPath
    }/change-containers/${containerId}/documents/${docUuid}`;
  }
}
