// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
// Scenes
import AccountInfosScene from '../../UI/Account/AccountInfosScene';
import AccountSecurityScene from '../../UI/Account/AccountSecurityScene';
import DashboardCalendarScene from '../../UI/Dashboard/DashboardCalendarScene';
import DashboardChoseScene from '../../UI/Dashboard/DashboardChoseScene';
import DashboardChecklistScene from '../../UI/Dashboard/DashboardChecklistScene';
import DashboardScene from '../../UI/Dashboard/DashboardScene';
import DashboardSettingsScene from '../../UI/Dashboard/DashboardSettingsScene';
import DashboardSettingsInvitationScene from '../../UI/Dashboard/DashboardSettingsInvitationScene';
import FolderScene from '../../UI/Folder/FolderScene';
import FolderProposalScene from '../../UI/Folder/FolderProposalScene';
import ErrorScene from '../../UI/Error/ErrorScene';
// Common
import Routes from '../../Resources/Common/Routes';
import Constants from '../../Resources/Common/Constants';
import DashboardLogsScene from '../../UI/Dashboard/DashboardLogsScene';
import LocalStorage from '../../Layer/Storage/LocalStorage';

type Props = {
  isValidToken: ?boolean,
  needAcceptToS: ?boolean,
  isLoading: boolean,
  userRights: Array<any>,
  containers: Array<any>,
  location: Object,
  dispatch: Function,
  match: Object,
  activeContainer: string
};

class PrivateRouterComponent extends React.Component<Props> {
  // eslint-disable-next-line class-methods-use-this
  shouldComponentUpdate(nextProps) {
    return !nextProps.isLoading;
  }

  publicRender() {
    const {
      isValidToken,
      needAcceptToS,
      isLoading,
      location
    } = this.props;
    if (!isValidToken) {
      if (location.pathname !== `/${Routes.routeChoseDashboard}`) {
        LocalStorage.set('redirect', location);
      }
      return (
        <Redirect
          to={{
            pathname: `/${Routes.routeLogin}`,
            isRedirected: true
          }}
        />
      );
    }
    if (needAcceptToS && !isLoading) {
      return <Redirect to={`/${Routes.acceptTos}`} />;
    }
    return null;
  }

  isPublicAccess() {
    const { isValidToken, needAcceptToS, isLoading } = this.props;
    return !isValidToken || (needAcceptToS && !isLoading);
  }

  render() {
    const { userRights } = this.props;
    /**
     * @param {string[]} userRights the array of rights given to the current user
     * @param {string[]} rightsACL all the rights mandatory to display the component
     * @param {DOM} Component the private Component
     * @param {any} props the props to pass to `Component`
     * @return {DOM}
     */
    const privateRender = rights => (
      ...rightsACL
    ) => Component => props => {
      if (this.isPublicAccess()) {
        return this.publicRender();
      }
      const userRightsWithReading = rights.concat(
        Constants.USER_RIGHTS.READING
      );
      return _.every(rightsACL, right =>
        userRightsWithReading.includes(right)
      ) ? ( // each required right is included in available `userRights`
        <Component {...props} />
      ) : (
        <Redirect to={`/${Routes.ErrorScene}`} />
      );
    };
    const [renderAsAdmin, renderAsReader] = [
      Constants.USER_RIGHTS.ADMINISTRATION,
      Constants.USER_RIGHTS.READING
    ].map(right => privateRender(userRights)(right));

    const render = Component => props =>
      this.isPublicAccess() ? (
        this.publicRender()
      ) : (
        <Component {...props} />
      );

    return (
      <>
        <Switch>
          {/* DASHBOARD CHOSE */}
          <Route
            exact
            path={`/${Routes.routeChoseDashboard}/:access?`}
            render={render(DashboardChoseScene)}
          />
          {/* ACCOUNT */}
          <Route
            exact
            path={`/${Routes.routeInfosAccount}`}
            render={render(AccountInfosScene)}
          />
          <Route
            exact
            path={`/${Routes.routeSecurityAccount}`}
            render={render(AccountSecurityScene)}
          />
          {/* DASHBOARD */}
          <Route
            exact
            path={`/${Routes.routeDashboard}/:uuid`}
            render={renderAsReader(DashboardScene)}
          />
          <Route
            exact
            path={`/${Routes.routeDashboard}/:uuid/${
              Routes.routeChecklist
            }`}
            render={renderAsReader(DashboardChecklistScene)}
          />
          <Route
            exact
            path={`/${Routes.routeDashboard}/:uuid/${
              Routes.routeCalendar
            }`}
            render={renderAsReader(DashboardCalendarScene)}
          />
          <Route
            exact
            path={`/${Routes.routeDashboard}/:uuid/${
              Routes.routeLogs
            }/:kind?`}
            render={renderAsReader(DashboardLogsScene)}
          />
          {/* FOLDER */}
          <Route
            exact
            path={`/${Routes.routeFolder}/:uuid`}
            render={renderAsReader(FolderScene)}
          />
          <Route
            exact
            path={`/${Routes.routeFolder}/:uuid/${
              Routes.routeProposals
            }/:kind?/:proposalUid?/:status?`}
            render={renderAsReader(FolderProposalScene)}
          />
          {/* ADMIN */}
          <Route
            exact
            path={`/${Routes.routeDashboard}/:uuid/${
              Routes.routeDashboardSettings
            }`}
            render={renderAsAdmin(DashboardSettingsScene)}
          />
          <Route
            exact
            path={`/${Routes.routeDashboard}/:uuid/${
              Routes.routeDashboardSettingsInvitation
            }`}
            render={renderAsAdmin(DashboardSettingsInvitationScene)}
          />
          <Route component={ErrorScene} />
        </Switch>
      </>
    );
  }
}

export default connect(state => ({
  isValidToken: state.token.isValidToken,
  needAcceptToS: state.legal.needCguAcceptance,
  isLoading:
    state.login.isLoading ||
    state.token.isLoading ||
    state.legal.isLoadingCguAcceptance,
  userRights: state.container.userRights.rights,
  activeContainer: state.container.activeContainer.id,
  containers: state.container.containers
}))(PrivateRouterComponent);
