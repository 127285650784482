// @flow

import Common from '../../Resources/Common';
import {
  GET_CONTAINER_CHANGE,
  GET_CHANGE_PROPOSALS,
  GET_CHANGE_PROPOSALS_SIDEBAR,
  SET_SIDEBAR_PROPOSALS,
  POST_CHANGE_PROPOSALS,
  GET_CONTEXT_MANIFEST,
  POST_CONTAINER_DOCUMENT,
  GET_CONTAINER_DOCUMENT,
  GET_ALL_CHANGE_PROPOSALS,
  SET_SIDEBAR_COLLAPSE,
  SET_UNDEFINED_REMAINING_KINDS
} from '../actions/FolderActions';

const defaultState = {
  isLoading: false,
  getContainerChangeData: {},
  getContainerChangeError: '',
  getChangeProposalsData: [],
  getChangeProposalsError: '',
  getChangeProposalsSidebarData: [],
  getChangeProposalsSidebarError: '',
  getChangeProposalKind: '',
  setSidebarProposals: [],
  postChangeProposalsError: '',
  isLoadingPostChangeProposalsData: false,
  isLoadingGetChangeProposals: false,
  getChangeProposalsSuccess: false,
  postChangeProposalsSuccess: false,
  postContainerDocumentError: '',
  postContainerDocumentSuccess: false,
  isLoadingPostContainerDocument: false,
  postContainerDocument: {},
  getContainerDocumentError: '',
  getContainerDocumentSuccess: false,
  isLoadingGetContainerDocument: false,
  getContainerDocument: {},
  getContextManifestData: {},
  expectedKinds: [],
  remainingKinds: undefined,
  isLoadingGetAllChangeProposals: false,
  currentKind: '',
  getChangeProposalsErrors: [],
  setSidebarCollapse: [],
  getContainerDocumentUuid: '',
  getContainerChangeSuccess: false,
  setSidebarProposalsSuccess: false
};

const FolderReducer = (
  state: Object = defaultState,
  action: Object = {}
) => {
  switch (action.type) {
    case GET_CONTAINER_CHANGE:
      return Object.assign({}, state, {
        getContainerChangeError: action.payload.error,
        getContainerChangeData: action.payload.aggregate,
        isLoading:
          action.payload.status === Common.Constants.PENDING_STATUS,
        getContainerChangeSuccess:
          action.payload.status === Common.Constants.SUCCESS_STATUS
      });
    case GET_CHANGE_PROPOSALS:
      return Object.assign({}, state, {
        getChangeProposalsError: action.payload.error,
        getChangeProposalsData: action.payload.aggregate,
        isLoadingGetChangeProposals:
          action.payload.status === Common.Constants.PENDING_STATUS,
        getChangeProposalsSuccess:
          action.payload.status === Common.Constants.SUCCESS_STATUS,
        postContainerDocument: {},
        getContainerDocument: {},
        getChangeProposalKind: action.payload.kind
      });
    case GET_CHANGE_PROPOSALS_SIDEBAR:
      return Object.assign({}, state, {
        getChangeProposalsSidebarError: action.payload.error,
        getChangeProposalsSidebarData: action.payload.aggregate,
        isLoading:
          action.payload.status === Common.Constants.PENDING_STATUS
      });
    case SET_UNDEFINED_REMAINING_KINDS:
      return Object.assign({}, state, {
        remainingKinds: undefined
      });
    case GET_ALL_CHANGE_PROPOSALS:
      return Object.assign({}, state, {
        expectedKinds:
          action.payload.expectedKinds || state.expectedKinds || [],
        remainingKinds: action.payload.kind
          ? (
              state.remainingKinds ||
              state.expectedKinds ||
              action.payload.expectedKinds ||
              []
            ).filter(k => k !== action.payload.kind)
          : state.remainingKinds ||
            action.payload.expectedKinds ||
            state.expectedKinds ||
            [],
        currentKind: action.payload.kind,
        isLoadingGetAllChangeProposals:
          action.payload.status === Common.Constants.PENDING_STATUS,
        getChangeProposalsErrors: action.payload.error
          ? state.getChangeProposalsErrors.concat(
              action.payload.error
            )
          : state.getChangeProposalsErrors
      });
    case SET_SIDEBAR_PROPOSALS:
      return Object.assign({}, state, {
        getChangeProposalsError: action.payload.error,
        setSidebarProposals: action.payload.aggregate,
        loading:
          action.payload.status === Common.Constants.PENDING_STATUS,
        setSidebarProposalsSuccess:
          action.payload.status === Common.Constants.SUCCESS_STATUS
      });
    case POST_CHANGE_PROPOSALS:
      return Object.assign({}, state, {
        postChangeProposalsError: action.payload.error,
        postChangeProposalsSuccess:
          action.payload.status === Common.Constants.SUCCESS_STATUS,
        isLoadingPostChangeProposalsData:
          action.payload.status === Common.Constants.PENDING_STATUS
      });
    case GET_CONTEXT_MANIFEST:
      return Object.assign({}, state, {
        getContextManifestError: action.payload.error,
        getContextManifestData: action.payload.aggregate,
        isLoading:
          action.payload.status === Common.Constants.PENDING_STATUS
      });
    case POST_CONTAINER_DOCUMENT:
      return Object.assign({}, state, {
        postContainerDocumentError: action.payload.error,
        postContainerDocumentSuccess:
          action.payload.status === Common.Constants.SUCCESS_STATUS,
        postContainerDocument: action.payload.aggregate,
        isLoadingPostContainerDocument:
          action.payload.status === Common.Constants.PENDING_STATUS
      });
    case GET_CONTAINER_DOCUMENT:
      return Object.assign({}, state, {
        getContainerDocumentError: action.payload.error,
        getContainerDocumentSuccess:
          action.payload.status === Common.Constants.SUCCESS_STATUS,
        getContainerDocument: action.payload.aggregate,
        isLoadingGetContainerDocument:
          action.payload.status === Common.Constants.PENDING_STATUS,
        getContainerDocumentUuid: action.payload.uuid
      });
    case SET_SIDEBAR_COLLAPSE:
      return Object.assign({}, state, {
        setSidebarCollapse: action.payload.collapse
      });
    default:
      return state;
  }
};

export default FolderReducer;
