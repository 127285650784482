// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
// Components
import { Form, Label, FormGroup, FormFeedback } from 'reactstrap';
import FacebookComponent from '../../Components/Components/Svgs/FacebookComponent';
import LinkedinComponent from '../../Components/Components/Svgs/LinkedinComponent';
import GoogleComponent from '../../Components/Components/Svgs/GoogleComponent';
import Template from '../../Components/Template/TemplateComponent';
import InputCustom from '../../Components/Components/InputCustom';
import ButtonCustom from '../../Components/Components/ButtonCustom';
import AlertCustom from '../../Components/Components/AlertCustom';
// Utils
import {
  postUserRegisterAction,
  postUserPreRegisterAction
} from '../../Business/actions/UserActions';
import {
  AcceptCgu,
  didNotAcceptCgu
} from '../../Business/actions/LegalActions';
import { getVisibleObject } from '../../Resources/Common/Utilities';
import Route from '../../Resources/Common/Routes';
import Constants from '../../Resources/Common/Constants';
import Messages from '../../Resources/Common/Messages';
import LocalStorage from '../../Layer/Storage/LocalStorage';

type State = {
  email: string,
  invitation: string,
  firstname: string,
  lastname: string,
  password: string,
  validationError: string,
  visibleObject: {
    validationError: boolean,
    postRecoverError: boolean,
    preRegisterError: boolean,
    createAccountError: boolean,
    postSocialLoginError: boolean
  }
};

type Props = {
  dispatch: Function,
  postRecoverError: string,
  preRegisterError: string,
  location: { search: string },
  createAccountError: string,
  postSocialLoginError: string,
  isValidToken: boolean,
  isLoadingPostUserRegister: boolean,
  hasAcceptedPrerequisites: boolean | typeof undefined,
  needCguAcceptance: boolean | typeof undefined,
  isLoadingToken: boolean,
  isLoadingPostLogin: boolean,
  containerSuccess: boolean,
  cguData: string
};

class LoginScene extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      invitation: '',
      firstname: '',
      lastname: '',
      password: '',
      validationError: '',
      visibleObject: {
        validationError: false,
        postRecoverError: false,
        preRegisterError: false,
        createAccountError: false,
        postSocialLoginError: false
      }
    };
    this.onDismiss = this.onDismiss.bind(this);
  }

  componentDidMount() {
    const { location, dispatch } = this.props;
    const values = queryString.parse(location.search);
    const invitation =
      values.invitation !== null ? values.invitation.toString() : '';
    this.invitationTokenStore(invitation);
    const email =
      values.email !== null ? values.email.toString() : '';
    dispatch(postUserPreRegisterAction(email, invitation));
    this.setState({ email, invitation });
  }

  componentDidUpdate(prevProps, prevState) {
    this.setVisibleAlerts(prevProps, prevState);
    this.getCguAcceptance(prevProps);
  }

  getCguAcceptance = prevProps => {
    const { hasAcceptedPrerequisites, dispatch } = this.props;
    if (
      hasAcceptedPrerequisites !== undefined &&
      hasAcceptedPrerequisites !== prevProps.hasAcceptedPrerequisites
    ) {
      if (hasAcceptedPrerequisites) {
        dispatch(AcceptCgu());
      } else {
        dispatch(didNotAcceptCgu());
      }
    }
  };

  onDismiss = target => {
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        [target]: false
      }
    }));
  };

  displayAlert = () => {
    const {
      postRecoverError,
      preRegisterError,
      createAccountError,
      postSocialLoginError
    } = this.props;
    const { visibleObject, validationError } = this.state;
    const alert = [];
    let key = 0;

    if (postRecoverError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="postRecoverError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={postRecoverError}
          visible={visibleObject.postRecoverError}
        />
      );
    }
    if (preRegisterError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="preRegisterError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={preRegisterError}
          visible={visibleObject.preRegisterError}
        />
      );
    }
    if (createAccountError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="createAccountError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={createAccountError}
          visible={visibleObject.createAccountError}
        />
      );
    }
    if (postSocialLoginError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="postSocialLoginError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={postSocialLoginError}
          visible={visibleObject.postSocialLoginError}
        />
      );
    }
    if (validationError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="validationError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={validationError}
          visible={visibleObject.validationError}
        />
      );
    }
    return alert;
  };

  setVisibleAlerts = (prevProps, prevState) => {
    const errorPropsArray = [
      'postRecoverError',
      'preRegisterError',
      'createAccountError',
      'postSocialLoginError',
      'validationError'
    ];
    const { props, state } = this;
    const {
      postRecoverError,
      preRegisterError,
      createAccountError,
      postSocialLoginError
    } = props;
    const { validationError } = state;
    if (
      postRecoverError !== prevProps.postRecoverError ||
      preRegisterError !== prevProps.preRegisterError ||
      createAccountError !== prevProps.createAccountError ||
      postSocialLoginError !== prevProps.postSocialLoginError ||
      validationError !== prevState.validationError
    ) {
      let newState = Object.assign({}, this.state);
      const visibleObject = getVisibleObject(
        props,
        prevProps,
        state,
        prevState,
        errorPropsArray
      );
      newState = {
        ...newState,
        visibleObject: {
          ...newState.visibleObject,
          ...visibleObject
        }
      };
      if (Object.values(visibleObject).includes(true)) {
        this.setState(newState);
      }
    }
  };

  handleLastname = e => {
    this.setState({ lastname: e.target.value });
  };

  handleFirstname = e => {
    this.setState({ firstname: e.target.value });
  };

  handlePassword = e => {
    this.setState({ password: e.target.value });
    const repeatPassword = document.getElementById(
      'repeat-new-password'
    );
    if (repeatPassword instanceof HTMLInputElement) {
      if (repeatPassword.value !== e.target.value) {
        e.target.setCustomValidity("Passwords Don't Match");
        repeatPassword.setCustomValidity("Passwords Don't Match");
      } else {
        e.target.setCustomValidity('');
        repeatPassword.setCustomValidity('');
      }
    }
  };

  handleRepeatNewPassword = e => {
    const password = document.getElementById('new-password');
    if (password instanceof HTMLInputElement) {
      if (password.value !== e.target.value) {
        e.target.setCustomValidity("Passwords Don't Match");
        password.setCustomValidity("Passwords Don't Match");
      } else {
        e.target.setCustomValidity('');
        password.setCustomValidity('');
      }
    }
  };

  submitInscriptionForm = e => {
    e.preventDefault();
    const { dispatch } = this.props;
    const {
      email,
      firstname,
      lastname,
      invitation,
      password
    } = this.state;
    const prerequisites = {
      ToS: false,
      risks: true
    };

    if (e.target.checkValidity()) {
      this.setState({ validationError: '' });
      const autoconnect = true;
      dispatch(
        postUserRegisterAction(
          email,
          password,
          prerequisites,
          firstname,
          lastname,
          invitation,
          autoconnect
        )
      );
      this.setState({ visible: true });
    } else {
      this.setState({ validationError: Messages.validationError });
      e.stopPropagation();
    }
    e.target.classList.add('was-validated');
  };

  invitationTokenStore = invitation => {
    const { location } = this.props;
    LocalStorage.set('invitationToken', invitation);
    LocalStorage.set('redirectRoute', location);
  };

  render() {
    const {
      preRegisterError,
      isLoadingPostUserRegister,
      isValidToken,
      needCguAcceptance,
      isLoadingToken,
      isLoadingPostLogin,
      containerSuccess,
      cguData
    } = this.props;
    const { email } = this.state;

    if (preRegisterError) {
      return <Redirect to={Route.routeLogin} />;
    }

    if (needCguAcceptance !== undefined) {
      if (
        // If we go to LoginScene while connected we should redirect
        (!isLoadingPostLogin &&
          !isLoadingToken &&
          isValidToken &&
          !needCguAcceptance) ||
        (isValidToken && !needCguAcceptance && containerSuccess)
      ) {
        return <Redirect to={Route.routeChoseDashboard} />;
      }

      if (cguData && needCguAcceptance && isValidToken) {
        return <Redirect to={Route.acceptTos} />;
      }
    }
    return (
      <Template
        className="login-form"
        title="Middle-Office"
        subtitle="Inscription"
        width="412px">
        <>
          {this.displayAlert()}
          <Form
            className="d-flex flex-column"
            onSubmit={this.submitInscriptionForm}
            noValidate>
            <FormGroup>
              <Label for="email">Email</Label>
              <InputCustom
                id="email"
                readOnly
                type="email"
                value={email}
                required
              />
              <FormFeedback>
                {Messages.emailFrontValidation}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="lastname">Nom de famille</Label>
              <InputCustom
                id="lastname"
                onChange={this.handleLastname}
                required
              />
              <FormFeedback>
                {Messages.lastnameFrontValidation}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="firstname">Prénom</Label>
              <InputCustom
                id="firstname"
                onChange={this.handleFirstname}
                type="firstname"
                required
              />
              <FormFeedback>
                {Messages.firstnameFrontValidation}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="new-password">Mot de passe</Label>
              <InputCustom
                id="new-password"
                onChange={this.handlePassword}
                type="password"
                pattern={Constants.pattern.password}
                required
              />
              <FormFeedback>
                {Messages.newPasswordFrontValidation}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="repeat-new-password">
                Répeter le mot de passe
              </Label>
              <InputCustom
                id="repeat-new-password"
                onChange={this.handleRepeatNewPassword}
                type="password"
                pattern={Constants.pattern.password}
                required
              />
              <FormFeedback>
                {Messages.repeatPasswordFrontValidation}
              </FormFeedback>
            </FormGroup>
            <ButtonCustom
              type="submit"
              color="primary"
              className="align-self-end mb-3"
              disabled={isLoadingPostUserRegister}>
              Se connecter
            </ButtonCustom>
            <FormGroup>
              <a href={Constants.googleUri}>
                <ButtonCustom className="mr-3" color="primary">
                  <GoogleComponent />
                  Google
                </ButtonCustom>
              </a>
              <a href={Constants.facebookUri}>
                <ButtonCustom className="mr-3" color="primary">
                  <FacebookComponent />
                  Facebook
                </ButtonCustom>
              </a>
              <a href={Constants.linkedinUri}>
                <ButtonCustom color="primary">
                  <LinkedinComponent />
                  Linkedin
                </ButtonCustom>
              </a>
            </FormGroup>
          </Form>
        </>
      </Template>
    );
  }
}

const mapStateToProps = state => ({
  postRecoverError: state.user.postRecoverError,
  preRegisterError: state.user.preRegisterError,
  createAccountError: state.login.createAccountError,
  isValidToken: state.token.isValidToken,
  isLoadingPostUserRegister: state.user.isLoadingPostUserRegister,
  postSocialLoginError: state.login.postSocialLoginError,
  isLoadingPostLogin: state.login.isLoadingPostLogin,
  cguData: state.legal.cguData,
  isLoadingToken: state.token.isLoading,
  containerSuccess: state.container.containerSuccess,
  needCguAcceptance: state.legal.needCguAcceptance
});

export default connect(mapStateToProps)(LoginScene);
