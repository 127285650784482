// @flow

import { clearActiveContainer } from './ContainerActions';
import LocalStorage from '../../Layer/Storage/LocalStorage';
import Constants from '../../Resources/Common/Constants';
import AccountService from '../../Layer/Network/Service/AccountService';

export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const GET_TOKEN_VALIDITY = 'GET_TOKEN_VALIDITY';
export const DISCONNECT_TOKEN = 'DISCONNECT_TOKEN';
export const CLEAR_ALERT_MESSAGE = 'CLEAR_ALERT_MESSAGE';

export const authSetToken = (token: string | null) => ({
  type: AUTH_SET_TOKEN,
  token
});

export const clearAlertMessage = () => ({
  type: CLEAR_ALERT_MESSAGE
});

type OptionalAlert = { alert: ?string };

// DisconnectToken
const disconnectToken = ({ alert }: OptionalAlert) => ({
  type: DISCONNECT_TOKEN,
  payload: { alertMessage: alert }
});

export function SetTokenAction(token: string | null) {
  authSetToken(token);
  return (dispatch: Function) => {
    dispatch(authSetToken(token));
  };
}

type getTokenValidityInput = {
  // eslint-disable-next-line
  status:
    | Constants.PENDING_STATUS
    | Constants.SUCCESS_STATUS
    | Constants.FAILURE_STATUS,
  err?: Error | string | typeof undefined,
  isValidToken?: boolean
};

export const getTokenValidity = ({
  status,
  isValidToken = false,
  err
}: getTokenValidityInput) => ({
  type: GET_TOKEN_VALIDITY,
  payload: {
    isValidToken,
    status,
    error: err
  }
});

export function checkTokenValidityAction() {
  return (dispatch: Function) => {
    dispatch(getTokenValidity({ status: Constants.PENDING_STATUS }));

    return AccountService.getAccountInfos()
      .then(() => {
        dispatch(
          getTokenValidity({
            status: Constants.SUCCESS_STATUS,
            isValidToken: true
          })
        );
      })
      .catch(err => {
        dispatch(
          getTokenValidity({
            err,
            isValidToken: false,
            status: Constants.FAILURE_STATUS
          })
        );
      });
  };
}

export function logoutTokenAction({ alert }: OptionalAlert) {
  return (dispatch: Function) => {
    LocalStorage.delete('persist:root');
    dispatch(clearActiveContainer());
    dispatch(disconnectToken({ alert }));
  };
}
