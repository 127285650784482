// @flow
export default class Messages {
  static defaultError =
    'Une erreur est survenue. Veuillez recharger la page, et contacter Sowefund si ce bug se reproduit.';

  static shouldBeLoggedError =
    'Veuillez vous connecter pour accéder à cette page.';

  static validationError =
    'Certains champs ne sont pas valides. Veuillez les vérifier et essayer à nouveau.';

  static tokenInvalid =
    'Veuillez vous identifier pour accèder à cette page.';

  static loginWrongPassword =
    "Votre nom d'utilisateur est inconnu ou le mot de passe que vous avez entré est erroné. Si vous avez oublié votre mot de passe, vous pouvez le réinitialiser en cliquant sur <a href='/reset-password'>mot de passe oublié</a>.";

  static loginRefusedAccess =
    "Votre compte ne vous donne pas accès au Middle-Office. Veuillez <a href='mailto:support@sowefund.com'>contacter Sowefund</a> afin d'obtenir un accès.";

  static loginAccesTimeout =
    'Vous avez été déconnecté pour inactivité. Veuillez vous connecter à nouveau.';

  static loginNoValidPassword =
    'Le mot de passe que vous avez entré est erroné. Le mot de passe doit contenir au minimum 8 caractères, dont au moins une majusucle, un chiffre et un caractère spécial.';

  static loginFrozenAccount =
    "Votre compte a été bloqué. Veuillez <a href='mailto:support@sowefund.com'>contacter Sowefund</a> pour plus de détails.";

  static loginInvalidLink =
    "Le lien que vous utilisez n'est plus valide. Merci de recommencer la procédure de récupération de mot de passe.";

  static loginMustBeConnected =
    'Veuillez vous connecter pour accéder à cette page.';

  static mandatoryInvitations = 'no_messages/mandatoryInvitations';

  static alreadyEnrolled =
    'Vous avez déjà un compte Sowefund. Veuillez utiliser ce compte pour accéder au Middle-Office.';

  static recoverPasswordEmailSend =
    'Un email vous a été envoyé avec les instructions pour réinitialiser votre mot de passe.';

  static recoverPasswordChanged =
    'Votre mot de passe a bien été changé.';

  static registerUserAlreadyEnrolled =
    'Vous avez déjà un compte Sowefund. Veuillez utiliser ce compte pour accéder au Middle-Office.';

  static connectHere = 'Connectez-vous ici.';

  static preregisterUserWrongInvitationToken =
    "Votre invitation n'est plus valide. Veuillez demander une nouvelle invitation.";

  static avatarFrontValidation =
    'Veuillez utiliser une photo en format PNG, JPG ou GIF pour votre avatar.';

  static accountSecurityNoValidPassword =
    'Les mots de passe doivent contenir au moins 8 caractères, dont au moins une majuscule, un chiffre et un caractère spécial.';

  static accountSecuritySuccessChangePassword =
    'Votre mot de passe a bien été modifié !';

  static accountSecurityInvalidCredentails =
    'Votre mot de passe est erroné !';

  static dashboardSettingsNoContainers =
    "Aucun projet n'a pu être trouvé pour cet utilisateur.";

  static dashboardSettingsInvitationsWrongRights =
    'Vous devez séléctionner des droits pour cet utilisateur.';

  static dashboardSettingsInvitationsWrongEmail =
    "L'adresse email que vous avez saisi n'est pas valide.";

  static dashboardSettingsInvitationsSuccess = (
    redirectLink: string
  ) =>
    `Un email viens d'être envoyé à votre invité. <a href='${redirectLink}'>Retour au dashboard`;

  static getInvitationsNotFound = (operatorEmail: string) =>
    `Les invitations n'ont pas pu être récupérées. Veuillez recharger la page et <a href='mailto:${operatorEmail}'>contacter Sowefund</a> si ce bug ce reproduit.`;

  static registerUserWrongDataSameNames =
    'Veuillez entrer un prénom et un nom différents.';

  static newPasswordFrontValidation =
    'Veuillez entrer un mot de passe contenant au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial';

  static repeatPasswordFrontValidation =
    'Ce mot de passe doit être identique à celui que vous avez entré ci-dessus';

  static passwordFrontValidation =
    'Veuillez entrer votre mot de passe';

  static emailFrontValidation =
    'Veuillez entrer une adresse email valide';

  static firstnameFrontValidation = 'Veuillez entrer votre prénom';

  static lastnameFrontValidation =
    'Veuillez entrer votre nom de famille';

  static recoverPasswordSamePassword =
    'Votre nouveau mot de passe doit être différent de votre ancien mot de passe';

  static passwordLinkExpired =
    "Ce lien permettant de réinitialiser votre mot de passe n'est plus valide. Veuillez faire une nouvelle demande de <a href='/reset-password'>réinitialisation de mot de passe</a>.";

  static frozenAccount =
    "Votre compte a été bloqué temporairement. Veuillez <a href='mailto:support@sowefund.com'>contacter Sowefund</a> pour plus de détails.";

  static tooManyRequest =
    'Vous avez réalisé trop de tentatives. Veuillez essayer à nouveau plus tard.';

  static avatarWrongFormat =
    'Veuillez utiliser une photo en format PNG, JPG ou GIF pour votre avatar.';

  static changeAvatarSuccess = 'Votre avatar a bien été mis à jour';

  static deniedAccessError =
    "Vous n'avez pas les droits adéquats pour accéder à cette page. Veuillez les demander aux administrateurs du projet.";

  static patchUserRightError = (operatorEmail: string) =>
    `Une erreur s'est produite lors de la mise à jour des droits. Veuillez essayer à nouveau, et <a href='mailto:${operatorEmail}'>contacter Sowefund</a> si ce bug se reproduit.`;

  static deleteUserRightError = (operatorEmail: string) =>
    `Une erreur s'est produite et l'utilisateur n'a pas pu être retiré du projet. Veuillez essayer à nouveau, et <a href='mailto:${operatorEmail}'>contacter Sowefund</a> si ce bug se reproduit.`;

  static patchUserRightSuccess =
    'Les droits des utilisateurs ont bien été mis à jour';

  static deleteUserRightSuccess =
    "L'utilisateur a bien été retiré du projet.";

  static dashboardDeleteUserConfirmMessage =
    'Êtes-vous sûr de vouloir retirer cet utilisateur de votre projet ?';

  static dashboardDeleteInvitationConfirmMessage =
    'Êtes-vous sûr de vouloir supprimer cette invitation ?';

  static deleteInvitationsNotFound = (operatorEmail: string) =>
    `Une erreur s'est produite lors de la suppression de l'invitation. Veuillez essayer à nouveau, et <a href='mailto:${operatorEmail}'>contacter Sowefund</a> si ce bug se reproduit.`;

  static deleteInvitationSuccess =
    "L'invitation a bien été supprimée";

  static dashboardCalendarNoCalendar =
    "Le calendrier n'a pas pu être récupéré. Veuillez recharger la page, et contacter Sowefund si ce bug se reproduit.";

  static postEventSuccess = 'Votre événement a bien été créé.';

  static dashboardCalendarNoPushEventsRight =
    "Vous n'avez pas les droits nécessaires pour créer de nouveaux événements. Veuillez demander le droit correspondant à l'administrateur du projet.";

  static patchEventSuccess = 'Votre événement a bien été modifié.';

  static dateStartFrontValidation =
    'Veuillez entrer une date dans un format valide.';

  static dateEndFrontValidation =
    'Veuillez entrer une date dans un format valide.';

  static titleFrontValidation =
    'Ce champs est obligatoire. Veuillez entrer un titre pour cet événement.';

  static deleteEventError = (operatorEmail: string) =>
    `Une erreur s'est produite lors de la suppression de l'événement. Veuillez essayer à nouveau, et <a href='mailto:${operatorEmail}'>contacter Sowefund</a> si ce bug se reproduit.`;

  static deleteEventSuccess = "L'événement a bien été supprimé.";

  static dashboardDeleteEventConfirmMessage =
    'Êtes-vous sûr de vouloir supprimer cet événement ?';

  static dashboardChecklistTodoListNotFound =
    "La checklist n'a pas pu être récupérée. Veuillez recharger la page et contacter Sowefund si ce bug persiste.";

  static dashboardChecklistPatchTodoSuccess =
    'Votre modification sur la checklist a bien été effectuée.';

  static postChangeProposalsSuccess =
    'Vos modifications ont bien été enregistrées';

  static unblockProposalConfirmMessage =
    'Êtes-vous sûr de vouloir débloquer cette section? Débloquer des sections peut rallonger le temps de validation de votre dossier.';

  static proposalNotAllowed =
    "Cette proposition de changement n'est pas autorisée.";

  static userInsufficientRights =
    "Vous n'avez pas les droits adéquats pour effectuer cette action. Veuillez demander le droit correspondant à un administrateur du projet.";

  static missing =
    "Un problème s'est produit dans la communication avec le BackOffice Sowefund : 'error.missing'";

  static documentNotFound = (operatorEmail: string) =>
    `Un document n'a pas pu être récupéré. Veuillez recharger la page, et <a href='mailto:${operatorEmail}'>contacter Sowefund</a> si cela se reproduit.`;

  static documentFormatError =
    "Veuillez uploader un autre document d'un type accepté. En général, les PDFs sont acceptés partout !";

  static changeContainerNotFound =
    "Le container n'a pas pu être trouvé. Veuillez recharger la page, et <a href='mailto:support@sowefund.com'>contacter Sowefund</a> si cela se reproduit.";

  static errorTitle404 = 'Erreur 404';

  static errorTitle500 = 'Erreur 500';

  static errorCode404 =
    "La page que vous cherchez n'a pas pu être trouvée";

  static errorCode500 = "Une erreur s'est produite côté serveur";
}
