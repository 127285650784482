// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, Redirect } from 'react-router-dom';
// Boostrap Components
import { Alert, Col, Container, Row } from 'reactstrap';
// Assets
import logo from '../../Resources/assets/img/main/logo.png';
// Components
import Menu from '../Components/Menu/Menu';
import Sidebar from '../Components/Menu/Sidebar';
import Route from '../../Resources/Common/Routes';
import Constants from '../../Resources/Common/Constants';
import { clearAlertMessage } from '../../Business/actions/TokenActions';
import LocalStorage from '../../Layer/Storage/LocalStorage';
// Actions
import { getUserRightsAction } from '../../Business/actions/ContainerActions';

type Props = {
  alertMessage: ?string,
  children: any,
  title?: string,
  menu?: string,
  subtitle?: string,
  width?: string,
  location: {
    pathname: string,
    search: string,
    hash: string,
    state: any,
    key: string | typeof undefined
  },
  sidebarToggle: boolean,
  activeContainer: string,
  userRights: Array<any>,
  dispatch: Function,
  match: Object,
  containers: Array<any>,
  isLoadingUserRights: boolean,
  cguData: string,
  needAcceptToS: boolean | typeof undefined
};

type State = {
  visible: boolean,
  redirectPath: string
};

class TemplateComponent extends Component<Props, State> {
  static defaultProps = {
    title: '',
    menu: '',
    subtitle: '',
    width: '600px'
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      redirectPath: ''
    };
  }

  componentDidMount = () => {
    const { userRights, activeContainer, dispatch } = this.props;
    if (!userRights && activeContainer) {
      dispatch(getUserRightsAction(activeContainer));
    }
    this.setContainerRedirection();
  };

  componentDidUpdate = prevProps => {
    const { activeContainer, dispatch } = this.props;
    if (
      activeContainer &&
      activeContainer !== prevProps.activeContainer
    ) {
      dispatch(getUserRightsAction(activeContainer));
    }
  };

  setContainerRedirection = () => {
    const {
      activeContainer,
      containers,
      match,
      location,
      needAcceptToS
    } = this.props;
    const { pathname } = location;
    if (
      /(container|folder)/.test(pathname.split('/')[1]) &&
      !/(containers)/.test(pathname.split('/')[1])
    ) {
      const { uuid } = match.params;
      const containerMatch = uuid === activeContainer;
      const containerExists = containers.some(c => c.uuid === uuid);
      if (!containerMatch) {
        if (!containerExists) {
          this.setState({
            redirectPath: `/${Route.routeChoseDashboard}/denied`
          });
        } else if (needAcceptToS) {
          this.setState({
            redirectPath: `/${Route.routeAcceptTos}`
          });
        } else {
          LocalStorage.set('redirect', location);
          this.setState({
            redirectPath: `/${Route.routeChoseDashboard}`
          });
        }
      }
    }
    return '';
  };

  onAlertMessageDismiss = () => {
    const { dispatch } = this.props;
    this.setState({ visible: false });

    dispatch(clearAlertMessage());
  };

  deleteRedirect = () => {
    const { location } = this.props;
    if (LocalStorage.get('redirect') === location) {
      LocalStorage.delete('redirect');
    }
  };

  isNotHome() {
    const {
      location: { pathname }
    } = this.props;
    const path = pathname.split('/')[1];
    return (
      path !== Route.routeLogin &&
      path !== Route.routeRecoverPassword &&
      path !== Route.routeRegister
    );
  }

  render() {
    const { visible, redirectPath } = this.state;
    const {
      children,
      menu,
      title,
      subtitle,
      width,
      alertMessage,
      sidebarToggle
    } = this.props;
    const visibleClass = sidebarToggle
      ? ''
      : `d-none ${Constants.SIDEBAR_VISIBLE_CLASS}`;
    const titleElement =
      title !== '' ? <h1 className="my-3">{title}</h1> : '';
    const subtitleElement =
      subtitle !== '' ? <p className="lead mb-3">{subtitle}</p> : '';
    const DEFAULT_VERTICAL_SPACING = 59;
    return (
      <>
        {redirectPath ? (
          <Redirect to={redirectPath} />
        ) : (
          <>
            {menu ? (
              <>
                {menu === 'logoutMenu' ? (
                  <>
                    <Menu type={menu} />
                    <Container
                      className="pb-3"
                      style={{ paddingTop: '58px' }}>
                      <Row className="text-center mb-3">
                        <Col md={{ size: 12 }}>
                          <header>
                            {titleElement}
                            {subtitleElement}
                          </header>
                        </Col>
                      </Row>
                      {children}
                    </Container>
                  </>
                ) : (
                  <>
                    <Menu toggler type={menu} />
                    <Container fluid className="pb-3">
                      <Row>
                        <Col
                          md={{ size: 3 }}
                          className={`${visibleClass} col-md-3 bg-white shadow rounded position-fixed w-100 d-md-block`}
                          id="sidebar"
                          style={{
                            bottom: '0',
                            overflowY: 'scroll',
                            zIndex: 99,
                            top: DEFAULT_VERTICAL_SPACING
                          }}>
                          <Sidebar type={menu} />
                        </Col>
                        <Col
                          md={{ size: 9 }}
                          className="ml-sm-auto margin-content">
                          <Row className="text-center mb-3">
                            <Col md={{ size: 12 }}>
                              <header>
                                {titleElement}
                                {subtitleElement}
                              </header>
                            </Col>
                          </Row>
                          {alertMessage && (
                            <Alert
                              color="danger"
                              toggle={this.onAlertMessageDismiss}
                              isOpen={visible}>
                              {alertMessage}
                            </Alert>
                          )}
                          {children}
                        </Col>
                      </Row>
                    </Container>
                  </>
                )}
              </>
            ) : (
              <Container
                fluid
                className="d-flex flex-column justify-content-center mw-100"
                style={{
                  width,
                  minHeight: '100vh'
                }}>
                <div className="text-center">
                  <Link to={Route.routeLogin}>
                    <img
                      src={logo}
                      className="my-3 mw-100"
                      alt="Sowefund"
                    />
                  </Link>
                  <header>
                    {titleElement}
                    {subtitleElement}
                  </header>
                </div>
                {children}
              </Container>
            )}
          </>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(state => ({
    alertMessage: state.token.alertMessage,
    activeContainer: state.container.activeContainer.id,
    userRights: state.container.userRights.rights,
    isLoadingUserRights: state.container.userRights.loading,
    sidebarToggle: state.container.sidebarToggle,
    containers: state.container.containers,
    cguData: state.legal.cguData,
    needAcceptToS: state.legal.needCguAcceptance
  }))(TemplateComponent)
);
