// @flow

import NetworkUtils from '../NetworkUtils';
import NetworkUrls from '../NetworkUrls';

/**
 * Handles every request linked to user lifecycle.
 * It concerns user registration, password updating or recovering.
 */
export default class UserService {
  static postUserRegister(
    email: string,
    password: string,
    prerequisites: Object,
    firstName: string,
    lastName: string,
    invitationToken: string,
    autoconnect: boolean
  ) {
    return NetworkUtils.post({
      url: NetworkUrls.postUserRegister(),
      data: {
        email,
        password,
        prerequisites,
        firstName,
        lastName,
        invitationToken,
        autoconnect
      }
    });
  }

  static postResetPassword(email: string) {
    return NetworkUtils.post({
      url: NetworkUrls.postResetPassword(),
      data: { email }
    });
  }

  static getRecoverPassword(uuid: string, code: string) {
    return NetworkUtils.get({
      url: NetworkUrls.getRecoverPassword(uuid, code)
    });
  }

  static postRecoverPassword(
    uuid: string,
    newPassword: string,
    code: string
  ) {
    return NetworkUtils.post({
      url: NetworkUrls.postRecoverPassword(),
      data: {
        uuid,
        newPassword,
        code
      }
    });
  }

  static postUserPreRegister(email: string, invitationToken: string) {
    return NetworkUtils.post({
      url: NetworkUrls.postUserPreRegister(),
      data: {
        email,
        invitationToken
      }
    });
  }
}
