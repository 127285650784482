// @flow

import NetworkUrls from '../NetworkUrls';
import NetworkUtils from '../NetworkUtils';

export default class LoginService {
  static postLogin(
    email: string,
    password: string,
    remember: boolean
  ) {
    return NetworkUtils.post({
      url: NetworkUrls.postLogin(),
      data: {
        email,
        password,
        remember
      }
    });
  }

  static postSocialLogin({
    network,
    code,
    accessToken,
    invitationToken
  }: {
    network: string,
    code: string,
    accessToken: string,
    invitationToken: string
  }) {
    return NetworkUtils.post({
      url: NetworkUrls.postSocialLogin(),
      data: {
        network,
        code,
        accessToken,
        invitationToken
      }
    });
  }

  static logout() {
    return NetworkUtils.delete({ url: NetworkUrls.logout() });
  }
}
