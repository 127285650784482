// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FullCalendar from 'sardius-fullcalendar-wrapper';
import { confirmAlert } from 'react-confirm-alert';
import {
  parseFromTimeZone,
  formatToTimeZone,
  convertToTimeZone
} from 'date-fns-timezone';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/fr';
import _ from 'lodash';
// Components
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  FormFeedback
} from 'reactstrap';
import ReactPlaceholder from 'react-placeholder';
import InputCustom from '../../Components/Components/InputCustom';
import ButtonCustom from '../../Components/Components/ButtonCustom';
import AlertCustom from '../../Components/Components/AlertCustom';
import Template from '../../Components/Template/TemplateComponent';
// Utils
import Routes from '../../Resources/Common/Routes';
import Messages from '../../Resources/Common/Messages';
import { getVisibleObject } from '../../Resources/Common/Utilities';
// Actions
import {
  getCalendarEventsAction,
  postCalendarEventsAction,
  patchCalendarEventsAction,
  deleteCalendarEventsAction
} from '../../Business/actions/DashboardCalendarActions';
import { getContainerDataAction } from '../../Business/actions/DashboardSettingsActions';

type State = {
  visibleObject: {
    getContainerRightsError: boolean,
    getEventsError: boolean,
    postEventError: boolean,
    postEventSuccess: boolean,
    patchEventSuccess: boolean,
    patchEventError: boolean,
    deleteEventError: boolean,
    deleteEventSuccess: boolean,
    validationError: boolean
  },
  currentEventOpened: boolean,
  newEvent: Object,
  newEventOpened: boolean,
  events: Array<any>,
  currentEvent: Object,
  validationError: string
};

type Props = {
  activeContainer: string,
  containers: Array<any>,
  containerData: Object,
  dispatch: Function,
  getContainerRightsError: string,
  match: Object,
  isLoadingPatchCalendarEvents: boolean,
  isLoadingPostCalendarEvents: boolean,
  isLoadingDeleteCalendarEvents: boolean,
  deleteEventError: boolean,
  deleteEventSuccess: boolean,
  getEventsError: string,
  eventsData: Array<any>,
  postEventSuccess: boolean,
  postEventError: string,
  patchEventSuccess: boolean,
  patchEventError: string,
  userRights: Array<any>,
  getCalendarEventsSuccess: boolean,
  operatorEmail: string
};

class DashboardCalendarScene extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      currentEvent: {
        start: '',
        startCalendarFormat: '',
        end: '',
        title: '',
        description: '',
        id: ''
      },
      currentEventOpened: false,
      events: [],
      newEvent: {
        start: '',
        end: '',
        title: '',
        description: ''
      },
      newEventOpened: false,
      validationError: '',
      visibleObject: {
        getContainerRightsError: false,
        getEventsError: false,
        postEventError: false,
        postEventSuccess: false,
        patchEventSuccess: false,
        patchEventError: false,
        deleteEventError: false,
        deleteEventSuccess: false,
        validationError: false
      }
    };
    this.onDismiss = this.onDismiss.bind(this);
  }

  componentDidMount() {
    const { activeContainer, dispatch, containerData } = this.props;
    if (
      activeContainer &&
      containerData &&
      Object.entries(containerData).length === 0
    ) {
      dispatch(getContainerDataAction(activeContainer));
    }
    if (containerData && activeContainer && containerData.calendars) {
      const activeCalendar = containerData.calendars.private;
      dispatch(
        getCalendarEventsAction({
          calendarUuid: activeCalendar,
          containerId: activeContainer,
          timeMin: 0,
          timeMax: 9999999999
        })
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      activeContainer,
      containerData,
      dispatch,
      eventsData
    } = this.props;
    if (activeContainer !== prevProps.activeContainer) {
      dispatch(getContainerDataAction(activeContainer));
    }
    if (
      containerData &&
      containerData !== prevProps.containerData &&
      containerData.calendars
    ) {
      const activeCalendar = containerData.calendars.private;
      dispatch(
        getCalendarEventsAction({
          calendarUuid: activeCalendar,
          containerId: activeContainer,
          timeMin: 0,
          timeMax: 9999999999
        })
      );
    }
    if (eventsData && eventsData !== prevProps.eventsData) {
      this.convertGoogleCalendarToFullCalendar(eventsData);
    }
    this.setVisibleAlerts(prevProps, prevState);
    this.displayEventsChanged(prevProps, prevState);
  }

  displayEventsChanged = prevProps => {
    const {
      deleteEventSuccess,
      patchEventSuccess,
      postEventSuccess
    } = this.props;
    if (
      (deleteEventSuccess &&
        deleteEventSuccess !== prevProps.deleteEventSuccess) ||
      (patchEventSuccess &&
        patchEventSuccess !== prevProps.patchEventSuccess)
    ) {
      this.setState({
        currentEventOpened: false,
        currentEvent: {
          start: '',
          startCalendarFormat: '',
          end: '',
          title: '',
          description: '',
          id: ''
        }
      });
    } else if (
      postEventSuccess &&
      postEventSuccess !== prevProps.postEventSuccess
    ) {
      this.setState({
        newEventOpened: false,
        newEvent: {
          start: '',
          end: '',
          title: '',
          description: '',
          id: ''
        }
      });
    }
  };

  convertGoogleCalendarToFullCalendar = eventsData => {
    const eventsArray = eventsData.map(event => {
      const returnEvent = {};
      if (event.summary) {
        returnEvent.title = event.summary;
      }
      if (event.description) {
        returnEvent.description = event.description;
      }
      if (event.start) {
        returnEvent.start = event.start.dateTime;
      }
      if (event.end) {
        returnEvent.end = event.end.dateTime;
      }
      if (event.id) {
        returnEvent.id = event.id;
      }
      return returnEvent;
    });

    this.setState({ events: eventsArray });
  };

  onDismiss = target => {
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        [target]: false
      }
    }));
  };

  displayAlert = () => {
    const {
      getContainerRightsError,
      getEventsError,
      postEventError,
      postEventSuccess,
      patchEventSuccess,
      patchEventError,
      deleteEventError,
      deleteEventSuccess
    } = this.props;
    const { validationError, visibleObject } = this.state;
    const alert = [];
    let key = 0;

    if (validationError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="validationError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={validationError}
          visible={visibleObject.validationError}
        />
      );
    }
    if (getContainerRightsError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="getContainerRightsError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={getContainerRightsError}
          visible={visibleObject.getContainerRightsError}
        />
      );
    }
    if (getEventsError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="getEventsError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={getEventsError}
          visible={visibleObject.getEventsError}
        />
      );
    }
    if (postEventError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="postEventError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={postEventError}
          visible={visibleObject.postEventError}
        />
      );
    }
    if (patchEventError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="patchEventError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={patchEventError}
          visible={visibleObject.patchEventError}
        />
      );
    }
    if (deleteEventError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="deleteEventError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={deleteEventError}
          visible={visibleObject.deleteEventError}
        />
      );
    }
    if (deleteEventSuccess) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="deleteEventSuccess"
          color="success"
          onDismiss={this.onDismiss}
          errorMessage={Messages.deleteEventSuccess}
          visible={visibleObject.deleteEventSuccess}
        />
      );
    }
    if (postEventSuccess) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="postEventSuccess"
          color="success"
          onDismiss={this.onDismiss}
          errorMessage={Messages.postEventSuccess}
          visible={visibleObject.postEventSuccess}
        />
      );
    }
    if (patchEventSuccess) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="patchEventSuccess"
          color="success"
          onDismiss={this.onDismiss}
          errorMessage={Messages.patchEventSuccess}
          visible={visibleObject.patchEventSuccess}
        />
      );
    }
    return alert;
  };

  setVisibleAlerts = (prevProps, prevState) => {
    const errorArray = [
      'getContainerRightsError',
      'getEventsError',
      'postEventError',
      'postEventSuccess',
      'patchEventSuccess',
      'patchEventError',
      'deleteEventError',
      'deleteEventSuccess',
      'validationError'
    ];
    const { props, state } = this;
    const {
      getContainerRightsError,
      getEventsError,
      postEventError,
      postEventSuccess,
      patchEventSuccess,
      patchEventError,
      deleteEventError,
      deleteEventSuccess
    } = this.props;
    const { validationError } = this.state;
    if (
      getContainerRightsError !== prevProps.getContainerRightsError ||
      getEventsError !== prevProps.getEventsError ||
      postEventError !== prevProps.postEventError ||
      postEventSuccess !== prevProps.postEventSuccess ||
      patchEventSuccess !== prevProps.patchEventSuccess ||
      patchEventError !== prevProps.patchEventError ||
      deleteEventError !== prevProps.deleteEventError ||
      deleteEventSuccess !== prevProps.deleteEventSuccess ||
      validationError !== prevState.validationError
    ) {
      let newState = Object.assign({}, this.state);
      const visibleObject = getVisibleObject(
        props,
        prevProps,
        state,
        prevState,
        errorArray
      );
      newState = {
        ...newState,
        visibleObject: {
          ...newState.visibleObject,
          ...visibleObject
        }
      };
      if (
        Object.values(visibleObject).includes(true) &&
        !_.isEqual(newState, state)
      ) {
        this.setState(newState);
      }
    }
  };

  createEvent = e => {
    const { currentEventOpened } = this.state;
    const { userRights } = this.props;
    if (userRights.includes('CHECKLIST_CALENDAR')) {
      this.setState(prevState => ({
        currentEventOpened: currentEventOpened
          ? false
          : currentEventOpened,
        newEventOpened: true,
        newEvent: {
          ...prevState.newEvent,
          start: e.start,
          end: e.end
        }
      }));
    }
  };

  createEventSubmit = e => {
    const { dispatch, activeContainer, containerData } = this.props;
    e.preventDefault();
    if (e.target.checkValidity()) {
      this.setState({ validationError: '' });
      const format = 'DD/MM/YYYY HH:mm';
      const timeZone = { timeZone: 'Europe/Paris' };
      const startDate = parseFromTimeZone(
        e.target.elements.start.value,
        format,
        timeZone
      );
      const endDate = parseFromTimeZone(
        e.target.elements.end.value,
        format,
        timeZone
      );
      const title = e.target.elements.title.value;
      const description = e.target.elements.description.value;
      const calendarUuid = containerData.calendars.private;
      if (containerData && containerData.calendars) {
        dispatch(
          postCalendarEventsAction({
            containerId: activeContainer,
            calendarUuid,
            startDate,
            endDate,
            title,
            description
          })
        );
      }
    } else {
      e.stopPropagation();
      this.setState({
        validationError: Messages.validationError
      });
    }
    e.target.classList.add('was-validated');
  };

  changeEventSubmit = e => {
    const { dispatch, activeContainer, containerData } = this.props;

    e.preventDefault();
    if (e.target.checkValidity()) {
      this.setState({ validationError: '' });
      const eventId = e.target.elements.eventId.value;
      const format = 'DD/MM/YYYY HH:mm';
      const timeZone = { timeZone: 'Europe/Paris' };
      const startDate = parseFromTimeZone(
        e.target.elements.start.value,
        format,
        timeZone
      );
      const endDate = parseFromTimeZone(
        e.target.elements.end.value,
        format,
        timeZone
      );
      const title = e.target.elements.title.value;
      const description = e.target.elements.description.value;
      if (containerData && containerData.calendars) {
        const calendarUuid = containerData.calendars.private;
        dispatch(
          patchCalendarEventsAction({
            containerId: activeContainer,
            calendarUuid,
            eventId,
            startDate,
            endDate,
            title,
            description
          })
        );
      }
    } else {
      e.stopPropagation();
      this.setState({
        validationError: Messages.validationError
      });
    }
    e.target.classList.add('was-validated');
  };

  deleteEvent = eventId => {
    const {
      dispatch,
      activeContainer,
      containerData,
      operatorEmail
    } = this.props;

    if (containerData && containerData.calendars) {
      const calendarUuid = containerData.calendars.private;
      dispatch(
        deleteCalendarEventsAction({
          containerId: activeContainer,
          calendarUuid,
          eventId,
          operatorEmail
        })
      );
    }
  };

  deleteEventConfirmationBox = eventId => () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="modal d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog mt-5" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <ButtonCustom
                  className="close"
                  onClick={onClose}
                  aria-label="Close">
                  <span aria-hidden="true">x</span>
                </ButtonCustom>
                <p>{Messages.dashboardDeleteEventConfirmMessage}</p>
              </div>
              <div className="modal-footer">
                <ButtonCustom
                  color="primary"
                  className="btn btn-primary"
                  onClick={() => {
                    this.deleteEvent(eventId);
                    onClose();
                  }}>
                  Oui
                </ButtonCustom>
                <ButtonCustom
                  className="btn btn-secondary"
                  onClick={onClose}
                  data-dismiss="modal">
                  Non
                </ButtonCustom>
              </div>
            </div>
          </div>
        </div>
      )
    });
  };

  selectEvent = e => {
    const { newEventOpened } = this.state;
    this.setState({
      newEventOpened: newEventOpened ? false : newEventOpened
    });
    const title =
      e.event.def.title !== undefined ? e.event.def.title : '';
    const description =
      e.event.def.extendedProps.description !== undefined
        ? e.event.def.extendedProps.description
        : '';
    const formatType = 'DD/MM/YYYY HH:mm';
    const timeZone = { timeZone: 'Europe/London' };
    this.setState({
      currentEventOpened: true,
      currentEvent: {
        start: formatToTimeZone(
          convertToTimeZone(e.event.instance.range.start, timeZone),
          formatType,
          timeZone
        ),
        end: formatToTimeZone(
          convertToTimeZone(e.event.instance.range.end, timeZone),
          formatType,
          timeZone
        ),
        startCalendarFormat: e.event.instance.range.start,
        id: e.event.def.publicId,
        title,
        description
      }
    });
  };

  dragEvent = e => {
    const { dispatch, activeContainer, containerData } = this.props;
    const timeZone = { timeZone: 'Europe/London' };
    const startDate = convertToTimeZone(
      convertToTimeZone(e.event.instance.range.start, timeZone),
      timeZone
    );
    const endDate = convertToTimeZone(
      convertToTimeZone(e.event.instance.range.end, timeZone),
      timeZone
    );
    const eventId = e.event.def.publicId;
    const { title, description } = e.event.def;
    const calendarUuid = containerData.calendars.private;
    if (containerData && containerData.calendars) {
      dispatch(
        patchCalendarEventsAction({
          containerId: activeContainer,
          calendarUuid,
          eventId,
          startDate,
          endDate,
          title,
          description
        })
      );
    }
  };

  handleStartChange = (date, eventType) => {
    switch (eventType) {
      case 'newEvent':
        this.setState(prevState => ({
          newEvent: {
            ...prevState.newEvent,
            start: date
          }
        }));
        break;
      case 'currentEvent':
        this.setState(prevState => ({
          currentEvent: {
            ...prevState.currentEvent,
            start: date
          }
        }));
        break;
      default:
        break;
    }
  };

  handleEndChange = (date, eventType) => {
    switch (eventType) {
      case 'newEvent':
        this.setState(prevState => ({
          newEvent: {
            ...prevState.newEvent,
            end: date
          }
        }));
        break;
      case 'currentEvent':
        this.setState(prevState => ({
          currentEvent: {
            ...prevState.currentEvent,
            end: date
          }
        }));
        break;
      default:
        break;
    }
  };

  handleTitleChange = (e, eventType) => {
    e.persist();
    switch (eventType) {
      case 'newEvent':
        this.setState(prevState => ({
          newEvent: {
            ...prevState.newEvent,
            title: e.target.value
          }
        }));
        break;
      case 'currentEvent':
        this.setState(prevState => ({
          currentEvent: {
            ...prevState.currentEvent,
            title: e.target.value
          }
        }));
        break;
      default:
        break;
    }
  };

  handleDescriptionChange = (e, eventType) => {
    e.persist();
    switch (eventType) {
      case 'newEvent':
        this.setState(prevState => ({
          newEvent: {
            ...prevState.newEvent,
            description: e.target.value
          }
        }));
        break;
      case 'currentEvent':
        this.setState(prevState => ({
          currentEvent: {
            ...prevState.currentEvent,
            description: e.target.value
          }
        }));
        break;
      default:
        break;
    }
  };

  createInput = (rights, field, type, event) => {
    const { currentEvent, newEvent } = this.state;
    let value = '';
    let onChange: Function;
    let required;
    switch (field) {
      case 'start':
        value =
          event === 'newEvent' ? newEvent.start : currentEvent.start;
        onChange = e => this.handleStartChange(e, event);
        required = true;
        break;
      case 'end':
        value =
          event === 'newEvent' ? newEvent.end : currentEvent.end;
        onChange = e => this.handleEndChange(e, event);
        required = true;
        break;
      case 'title':
        value =
          event === 'newEvent' ? newEvent.title : currentEvent.title;
        onChange = e => this.handleTitleChange(e, event);
        required = true;
        break;
      case 'description':
        value =
          event === 'newEvent'
            ? newEvent.description
            : currentEvent.description;
        onChange = e => this.handleDescriptionChange(e, event);
        required = false;
        break;
      default:
        break;
    }

    const input = (
      <InputCustom
        id={field}
        name={field}
        type={type}
        value={value}
        required={required}
        onChange={
          rights.includes('CHECKLIST_CALENDAR') ? onChange : () => {}
        }
        readOnly={!rights.includes('CHECKLIST_CALENDAR')}
      />
    );
    return input;
  };

  renderNewEventOpenedElement = () => {
    const { newEvent, newEventOpened } = this.state;
    const { userRights, isLoadingPostCalendarEvents } = this.props;
    const newEventOpenedElement = newEventOpened ? (
      <Col md={{ size: 5 }}>
        <h2>Nouvel événement</h2>
        <Form
          className="d-flex flex-column"
          id="avatar-form"
          onSubmit={this.createEventSubmit}
          noValidate>
          <FormGroup>
            <Label for="start">Date de debut</Label>
            <Datetime
              renderInput={props => (
                <>
                  <input {...props} />
                  <FormFeedback>
                    {Messages.dateStartFrontValidation}
                  </FormFeedback>
                </>
              )}
              inputProps={{
                id: 'start',
                name: 'start',
                required: true
              }}
              type="date"
              dateFormat="DD/MM/YYYY"
              timeFormat="LT"
              locale="fr"
              value={newEvent.start}
              onChange={e => this.handleStartChange(e, 'newEvent')}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="end">Date de fin</Label>
            <Datetime
              renderInput={props => (
                <>
                  <input {...props} />
                  <FormFeedback>
                    {Messages.dateEndFrontValidation}
                  </FormFeedback>
                </>
              )}
              inputProps={{ id: 'end', name: 'end', required: true }}
              dateFormat="DD/MM/YYYY"
              timeFormat="LT"
              locale="fr"
              value={newEvent.end}
              onChange={e => this.handleEndChange(e, 'newEvent')}
            />
          </FormGroup>
          <FormGroup>
            <Label for="title">Titre de l'événement</Label>
            {this.createInput(
              userRights,
              'title',
              'text',
              'newEvent'
            )}
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            {this.createInput(
              userRights,
              'description',
              'textarea',
              'newEvent'
            )}
          </FormGroup>
          <ButtonCustom
            className="align-self-end"
            color="primary"
            type="submit"
            disabled={isLoadingPostCalendarEvents}>
            Créer un évenement
          </ButtonCustom>
        </Form>
      </Col>
    ) : null;
    return newEventOpenedElement;
  };

  renderCurrentEventOpenedElement = () => {
    const {
      userRights,
      isLoadingPatchCalendarEvents,
      isLoadingDeleteCalendarEvents
    } = this.props;
    const { currentEventOpened, currentEvent } = this.state;
    const currentEventOpenedElement = currentEventOpened ? (
      <Col md={{ size: 5 }}>
        {userRights.includes('CHECKLIST_CALENDAR') ? (
          <h2>Modifier l'événement</h2>
        ) : (
          <h2>Evénement</h2>
        )}
        <Form
          className="d-flex flex-column"
          id="avatar-form"
          onSubmit={this.changeEventSubmit}
          noValidate>
          <FormGroup>
            <Label for="start">Date de debut</Label>
            {userRights.includes('CHECKLIST_CALENDAR') ? (
              <>
                <Datetime
                  renderInput={props => (
                    <>
                      <input {...props} />
                      <FormFeedback>
                        {Messages.dateStartFrontValidation}
                      </FormFeedback>
                    </>
                  )}
                  inputProps={{
                    id: 'start',
                    name: 'start',
                    required: true
                  }}
                  dateFormat="DD/MM/YYYY"
                  timeFormat="LT"
                  locale="fr"
                  value={currentEvent.start}
                  onChange={e =>
                    this.handleStartChange(e, 'currentEvent')
                  }
                />
              </>
            ) : (
              <>
                {this.createInput(
                  userRights,
                  'start',
                  'text',
                  'currentEvent'
                )}
              </>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="end">Date de fin</Label>
            {userRights.includes('CHECKLIST_CALENDAR') ? (
              <>
                <Datetime
                  renderInput={props => (
                    <>
                      <input {...props} />
                      <FormFeedback>
                        {Messages.dateEndFrontValidation}
                      </FormFeedback>
                    </>
                  )}
                  inputProps={{
                    id: 'end',
                    name: 'end',
                    required: true
                  }}
                  dateFormat="DD/MM/YYYY"
                  timeFormat="LT"
                  locale="fr"
                  value={currentEvent.end}
                  onChange={e =>
                    this.handleEndChange(e, 'currentEvent')
                  }
                />
              </>
            ) : (
              <>
                {this.createInput(
                  userRights,
                  'end',
                  'text',
                  'currentEvent'
                )}
              </>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="title">Titre de l'événement</Label>
            {this.createInput(
              userRights,
              'title',
              'text',
              'currentEvent'
            )}
            <FormFeedback>
              {Messages.titleFrontValidation}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            {this.createInput(
              userRights,
              'description',
              'textarea',
              'currentEvent'
            )}
          </FormGroup>
          <FormGroup>
            <InputCustom
              type="hidden"
              id="eventId"
              name="eventId"
              value={currentEvent.id}
            />
          </FormGroup>
          {userRights.includes('CHECKLIST_CALENDAR') && (
            <div className="d-flex justify-content-between">
              <ButtonCustom
                color="danger"
                className="align-self-end"
                onClick={this.deleteEventConfirmationBox(
                  currentEvent.id
                )}
                disabled={isLoadingDeleteCalendarEvents}>
                Supprimer l'évenement
              </ButtonCustom>
              <ButtonCustom
                type="submit"
                color="primary"
                className="align-self-end"
                disabled={isLoadingPatchCalendarEvents}>
                Modifier l'évenement
              </ButtonCustom>
            </div>
          )}
        </Form>
      </Col>
    ) : null;
    return currentEventOpenedElement;
  };

  render() {
    const { getCalendarEventsSuccess } = this.props;
    const {
      events,
      newEventOpened,
      newEvent,
      currentEventOpened,
      currentEvent
    } = this.state;
    let calendarDate = new Date();
    if (newEventOpened && newEvent.start) {
      calendarDate = newEvent.start;
    } else if (
      currentEventOpened &&
      currentEvent.startCalendarFormat
    ) {
      calendarDate = currentEvent.startCalendarFormat;
    }

    return (
      <Template
        title="Calendrier"
        subtitle="Consultez et partagez les prochains évenements auxquels vous participerez"
        menu={Routes.routeDashboard}>
        {this.displayAlert()}
        <Row>
          <Col md={{ size: 7 }}>
            <ReactPlaceholder
              className="my-3"
              ready={getCalendarEventsSuccess}
              type="text"
              showLoadingAnimation
              rows={8}
              color={window
                .getComputedStyle(document.body)
                .getPropertyValue('--light')}>
              <FullCalendar
                editable
                eventLimit
                events={events}
                eventClick={e => {
                  this.selectEvent(e);
                }}
                eventDrop={e => {
                  this.dragEvent(e);
                }}
                defaultDate={calendarDate}
                header={{
                  right: 'prev,next',
                  center: 'title',
                  left: ''
                }}
                id="calendar"
                themeSystem="bootstrap4"
                selectable
                select={e => {
                  this.createEvent(e);
                }}
                locale="fr"
                displayEventTime={false}
              />
            </ReactPlaceholder>
          </Col>
          {this.renderNewEventOpenedElement()}
          {this.renderCurrentEventOpenedElement()}
        </Row>
      </Template>
    );
  }
}

const mapStateToProps = state => ({
  activeContainer: state.container.activeContainer.id,
  operatorEmail: state.container.activeContainer.operatorEmail,
  containerData: state.dashboardSettings.containerData,
  containers: state.container.containers,
  getContainerRightsError:
    state.dashboardSettings.getContainerRightsError,
  eventsData: state.dashboardCalendar.eventsData,
  getCalendarEventsSuccess:
    state.dashboardCalendar.getCalendarEventsSuccess,
  getContainerDataError:
    state.dashboardSettings.getContainerDataError,
  getEventsError: state.dashboardCalendar.getEventsError,
  postEventError: state.dashboardCalendar.postEventError,
  postEventSuccess: state.dashboardCalendar.postEventSuccess,
  patchEventError: state.dashboardCalendar.patchEventError,
  patchEventSuccess: state.dashboardCalendar.patchEventSuccess,
  isLoadingPostCalendarEvents:
    state.dashboardCalendar.isLoadingPostCalendarEvents,
  isLoadingPatchCalendarEvents:
    state.dashboardCalendar.isLoadingPatchCalendarEvents,
  isLoadingDeleteCalendarEvents:
    state.dashboardCalendar.isLoadingDeleteCalendarEvents,
  deleteEventSuccess: state.dashboardCalendar.deleteEventSuccess,
  deleteEventError: state.dashboardCalendar.deleteEventError,
  userRights: state.container.userRights.rights
});

export default connect(mapStateToProps)(DashboardCalendarScene);
