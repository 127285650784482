// @flow

// Utils
import NetworkUrls from '../NetworkUrls';
import NetworkUtils from '../NetworkUtils';

export default class DashboardChecklistService {
  static getChecklist({
    containerId,
    todoListId
  }: {
    containerId: string,
    todoListId: string
  }) {
    return NetworkUtils.get({
      url: NetworkUrls.getChecklist({
        containerId,
        todoListId
      })
    });
  }

  static patchTodo({
    completed,
    containerId,
    todoListId,
    todoId
  }: {
    completed: boolean,
    containerId: string,
    todoListId: string,
    todoId: string
  }) {
    return NetworkUtils.patch({
      url: NetworkUrls.patchTodo(containerId, todoListId, todoId),
      data: {
        completed
      }
    });
  }
}
