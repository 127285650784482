// @flow
// React Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
// Components
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import DateTime from 'react-datetime';
import { withRouter } from 'react-router-dom';
import InputCustom from '../../../InputCustom';
import FrontValidation from '../../../../../Resources/Common/FrontValidation';
import Tooltip from '../../../../../Resources/Common/Tooltip';
import Constants from '../../../../../Resources/Common/Constants';

type Props = {
  changeProposalsData: Array<any>,
  hasRights: boolean,
  blocked: boolean,
  index: number,
  proposalUid?: string
};

type State = {
  title: string,
  amount: number,
  status: number,
  date: string
};

class OngoingFundraiseInfos extends Component<Props, State> {
  static defaultProps = {
    proposalUid: ''
  };

  constructor(props) {
    super(props);
    this.state = _.pick(
      _.defaults(
        _.get(
          props.proposalUid
            ? props.changeProposalsData.find(
                proposal => proposal.uuid === props.proposalUid
              )
            : props.changeProposalsData[0],
          `content.fundraisings[${props.index}]`
        ),
        {
          title: '',
          amount: 0,
          status: 0,
          date: new Date()
        }
      ),
      ['title', 'amount', 'status', 'date']
    );
  }

  render() {
    const { hasRights, blocked, index } = this.props;
    const { title, amount, status, date } = this.state;
    return (
      <>
        <FormGroup>
          <Label for={`fundraisings.${index}.title`}>
            Provenance des fonds
          </Label>
          <small className="form-text text-muted mb-1 mt-0">
            {Tooltip.ProjectOngoingFundraise.title}
          </small>
          <InputCustom
            type="text"
            id={`fundraisings.${index}.title`}
            name={`fundraisings.${index}.title`}
            defaultValue={title}
            minLength="1"
            maxLength="100"
            pattern={Constants.pattern.internationalName}
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.ProjectOngoingFundraise.title}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for={`fundraisings.${index}.amount`}>
            Montant levé avec les partenaires
          </Label>
          <InputCustom
            type="number"
            id={`fundraisings.${index}.amount`}
            name={`fundraisings.${index}.amount`}
            defaultValue={amount}
            min="0"
            max="9999999.99"
            step="0.01"
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.ProjectOngoingFundraise.amount}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for={`fundraisings.${index}.status`}>
            Statut avec les partenaires
          </Label>
          <small className="form-text text-muted mb-1 mt-0">
            {Tooltip.ProjectOngoingFundraise.status}
          </small>
          <InputCustom
            type="select"
            id={`fundraisings.${index}.status`}
            name={`fundraisings.${index}.status`}
            defaultValue={status}
            disabled={!hasRights || blocked}
            required>
            <option value="">--Choisir--</option>
            <option value="0">En discussion</option>
            <option value="1">Promis</option>
            <option value="2">Confirmé</option>
          </InputCustom>
        </FormGroup>
        <FormGroup>
          <Label for={`fundraisings.${index}.date`}>
            Date de l'opération
          </Label>
          <DateTime
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
            defaultValue={date}
            renderInput={props => (
              <InputCustom
                id={`fundraisings.${index}.date`}
                type="date"
                name={`fundraisings.${index}.date`}
                autoComplete="false"
                disabled={!hasRights || blocked}
                required
                {...props}
              />
            )}
          />
          <FormFeedback>
            {FrontValidation.ProjectOngoingFundraise.date}
          </FormFeedback>
        </FormGroup>
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData
});

export default withRouter(
  connect(mapStateToProps)(OngoingFundraiseInfos)
);
