// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormFeedback, FormGroup } from 'reactstrap';
import TruncateCustom from '../../TruncateCustom';
import MarkdownPreview from '../../TextEditor/MarkdownPreview';
import FrontValidation from '../../../../Resources/Common/FrontValidation';

type Props = {
  description: string,
  title: string,
  tooltip: string,
  maxLength?: number,
  minLength?: number,
  name?: string,
  hasRights: boolean,
  blocked: boolean
};

type State = {
  collapse: boolean
};

class ProjectOfferDescription extends Component<Props, State> {
  static MIN_LENGTH = 20;

  static defaultProps = {
    minLength: ProjectOfferDescription.MIN_LENGTH,
    maxLength: undefined,
    name: 'description'
  };

  state = {
    collapse: true
  };

  handleCollapse = e => {
    e.preventDefault();
    const { collapse } = this.state;
    this.setState({ collapse: !collapse });
  };

  render() {
    const {
      description,
      tooltip,
      title,
      maxLength,
      minLength,
      name,
      hasRights,
      blocked
    } = this.props;
    const { collapse } = this.state;
    return (
      <>
        <span className="mb-2">{title}</span>
        <small className="form-text text-muted mb-1 mt-0">
          <TruncateCustom
            collapse={collapse}
            tooltip={tooltip}
            handleCollapse={this.handleCollapse}
          />
        </small>

        <FormGroup>
          <MarkdownPreview
            name={name}
            style={{
              minHeight: '10em',
              backgroundColor: window
                .getComputedStyle(document.body)
                .getPropertyValue('--light'),
              marginTop: '0.8em',
              padding: '1em'
            }}
            value={description}
            readOnly={!hasRights || blocked}
            maxLength={maxLength}
            minLength={minLength}
            className={`markdown markdown_${name}`}
          />
          <FormFeedback
            id={`validation_${name}`}
            className="validation">
            {FrontValidation.ProjectOfferStengths.description}
          </FormFeedback>
        </FormGroup>
      </>
    );
  }
}

export default connect((state, props) => ({
  description: _.get(
    props.proposalUid
      ? state.folder.getChangeProposalsData.find(
          proposal => proposal.uuid === props.proposalUid
        )
      : state.folder.getChangeProposalsData[0],
    'content.description',
    ''
  )
}))(ProjectOfferDescription);
