import React from 'react';
import { Bar } from '@vx/shape';
import { Group } from '@vx/group';
import { LegendOrdinal } from '@vx/legend';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Routes from '../../../Resources/Common/Routes';

export default function BarComponent({
  values,
  width,
  height,
  colors,
  activeContainer
}) {
  const x = d => d.value;
  const y = d => Math.round((d.validated / d.total) * 1000) / 10;

  // bounds
  const xMax = width;
  const yMax = height - 120;

  // scales
  const xScale = scaleBand({
    rangeRound: [0, xMax],
    domain: values.map(x),
    padding: 0.4
  });
  const yScale = scaleLinear({
    rangeRound: [yMax, 0],
    domain: [0, Math.max(...values.map(y))]
  });
  const colorScale = scaleOrdinal({
    domain: values.map(v => v.value),
    range: colors
  });

  return (
    <>
      <svg width={width} height={height}>
        <rect
          width={width}
          height={height}
          fill="transparent"
          rx={14}
        />
        <Group top={40}>
          {values.map((d, i) => {
            const value = x(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - yScale(y(d));

            const barX = xScale(value);
            const barY = yMax - barHeight;
            const color = colors[i % colors.length];
            return (
              <React.Fragment key={`bargroup-${value}`}>
                <Link
                  to={`/${Routes.routeFolder}/${activeContainer}`}>
                  <Bar
                    key={`bar-${value}`}
                    x={barX}
                    y={barY}
                    width={barWidth}
                    height={barHeight > 0 ? barHeight : 1}
                    fill={color}
                  />
                </Link>
                <text x={barX} y={barY - 10} fill={colors[0]}>
                  {`${Math.round((d.validated / d.total) * 1000) /
                    10}%`}
                </text>
              </React.Fragment>
            );
          })}
        </Group>
      </svg>
      <LegendOrdinal
        style={{ width, margin: 'auto' }}
        className="mb-3"
        direction="column"
        scale={colorScale}
        shape="rect"
      />
    </>
  );
}

BarComponent.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      validated: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      total: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeContainer: PropTypes.string.isRequired
};
