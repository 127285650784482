// @flow

import React from 'react';
import PropTypes from 'prop-types';
import FinancialData from './FinancialData';
import Tooltip from '../../../../../Resources/Common/Tooltip';

export default function Workforce({
  hasRights,
  blocked,
  proposalUid
}) {
  return (
    <>
      <FinancialData
        title="Employés"
        tooltip={Tooltip.ProjectFinancialDataWorkforce}
        fieldNames={{
          figures: 'Effectifs',
          comment: 'Commentaire Effectifs',
          highlight: 'Valeur mise en avant Effectifs',
          breakdown: 'Graph Camembert Effectifs'
        }}
        hasRights={hasRights}
        blocked={blocked}
        proposalUid={proposalUid}
      />
    </>
  );
}

Workforce.propTypes = {
  hasRights: PropTypes.bool.isRequired,
  blocked: PropTypes.bool.isRequired,
  proposalUid: PropTypes.string
};

Workforce.defaultProps = {
  proposalUid: undefined
};
