// @flow
// React Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
// Components
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import InputCustom from '../../../InputCustom';
import FrontValidation from '../../../../../Resources/Common/FrontValidation';
import Tooltip from '../../../../../Resources/Common/Tooltip';
import TruncateCustom from '../../../TruncateCustom';

type Props = {
  changeProposalsData: Array<any>,
  hasRights: boolean,
  blocked: boolean,
  proposalUid: string
};

type State = { description: string, collapse: boolean };

class Description extends Component<Props, State> {
  constructor(props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = _.pick(
      _.defaults(_.get(changeProposalsFiltered, '[0].content'), {
        description: ''
      }),
      ['description']
    );
    this.state.collapse = true;
  }

  handleDescription = e => {
    this.setState({ description: e.target.value });
  };

  handleCollapse = () => {
    const { collapse } = this.state;
    this.setState({ collapse: !collapse });
  };

  render() {
    const { description, collapse } = this.state;
    const { hasRights, blocked } = this.props;
    return (
      <>
        <FormGroup>
          <Label for="name">Description Minifiche</Label>
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse}
              tooltip={Tooltip.ProjectOfferDescription.description}
              handleCollapse={this.handleCollapse}
            />
          </small>
          <InputCustom
            placeholder="Description Minifiche"
            id="description"
            name="description"
            value={description}
            onChange={this.handleDescription}
            minLength="20"
            maxLength="400"
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.ProjectOfferDescription.description}
          </FormFeedback>
        </FormGroup>
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData
});

export default withRouter(connect(mapStateToProps)(Description));
