// @flow
// @todo récupèrer tous les membres

// React Libs
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import update from 'immutability-helper';
// Components
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import OngoingFundraiseInfos from './OngoingFundraiseInfos';
import ButtonCustom from '../../../ButtonCustom';

type FundraisingItem = {
  title: string,
  amount: number,
  status: number,
  date: string
};

type Props = {
  fundraisings?: FundraisingItem[],
  hasRights?: boolean,
  blocked: boolean,
  changeProposalsData: Array<any>,
  proposalUid?: string
};

type State = {
  fundraisings: FundraisingItem[]
};

class OngoingFundraise extends React.Component<Props, State> {
  static NOTHING = {
    title: '',
    amount: 0,
    status: 0,
    date: new Date()
  };

  static defaultProps = {
    fundraisings: [OngoingFundraise.NOTHING],
    hasRights: false,
    proposalUid: undefined
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      fundraisings: _.get(
        props.proposalUid
          ? props.changeProposalsData.find(
              proposal => proposal.uuid === props.proposalUid
            )
          : props.changeProposalsData[0],
        'content.fundraisings'
      ) || [OngoingFundraise.NOTHING]
    };
  }

  addFundraisingItem = () => {
    const { fundraisings } = this.state;
    this.setState({
      fundraisings: fundraisings.concat(OngoingFundraise.NOTHING)
    });
  };

  deleteFundraisingItem = index => () => {
    const { fundraisings } = this.state;
    this.setState({
      fundraisings: update(fundraisings, { $splice: [[index, 1]] })
    });
  };

  render() {
    const { hasRights, blocked, proposalUid } = this.props;
    const { fundraisings } = this.state;
    const fundraisingsInfo = fundraisings.map(
      (fundraising, index) => (
        <section
          className={
            index > 0 ? `border-top border-secondary mt-4 pt-4` : ``
          }
          key={`section${index + 1}`}>
          <ButtonCustom
            disabled={blocked}
            onClick={this.deleteFundraisingItem(index)}
            key={`button${index + 1}`}
            className="mb-3">
            Supprimer ce financement
          </ButtonCustom>
          <OngoingFundraiseInfos
            key={`fundraisings${index + 1}`}
            index={index}
            hasRights={hasRights}
            blocked={blocked}
            proposalUid={proposalUid}
            {...fundraising}
          />
        </section>
      )
    );

    return (
      <>
        {fundraisingsInfo}
        <Button
          color="secondary"
          className="mx-5 mb-3"
          onClick={this.addFundraisingItem}
          disabled={blocked}>
          ajouter un financement
        </Button>
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData,
  containerDocument: state.folder.getContainerDocument,
  activeContainer: state.container.activeContainer.id,
  isLoadingGetContainerDocument:
    state.folder.isLoadingGetContainerDocument
});

export default withRouter(connect(mapStateToProps)(OngoingFundraise));
