import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import _ from 'lodash';
import Tos from '../../Components/Tos/Tos';
import ButtonCustom from '../../Components/Components/ButtonCustom';
import { clearAlertMessage } from '../../Business/actions/TokenActions';
import {
  AcceptCguAction,
  GetCguAction
} from '../../Business/actions/LegalActions';
import { getContainersAction } from '../../Business/actions/ContainerActions';
import Template from '../../Components/Template/TemplateComponent';

import Routes from '../../Resources/Common/Routes';

class AcceptTosScene extends React.Component {
  /* eslint-disable react/prop-types */
  constructor(props) {
    super(props);
    this.state = {
      acceptedTos: false
    };
  }

  componentDidMount = () => {
    const { content, dispatch, isLoading } = this.props;
    if (!content && !isLoading) {
      dispatch(GetCguAction(), dispatch(getContainersAction()));
    }
  };

  shouldComponentUpdate(nextProps) {
    const { acceptedTos } = this.state;
    return !acceptedTos || !nextProps.isLoading;
  }

  componentDidUpdate = prevProps => {
    const { needCguAcceptance, dispatch } = this.props;
    if (
      !needCguAcceptance &&
      needCguAcceptance !== prevProps.needCguAcceptance
    ) {
      dispatch(getContainersAction());
    }
  };

  acceptTos = () => {
    const { dispatch } = this.props;
    dispatch(AcceptCguAction());
    dispatch(clearAlertMessage());
    this.setState({ acceptedTos: true });
  };

  render() {
    const { acceptedTos } = this.state;
    const { content, isValidToken, containers } = this.props;
    if (
      // eslint-disable-next-line eqeqeq
      acceptedTos &&
      !_.isEmpty(containers)
    ) {
      return <Redirect to={Routes.routeChoseDashboard} />;
    }

    const shouldAccept = isValidToken && !acceptedTos;
    const SUBTITLE = 'Veuillez accepter les CGU';

    return (
      <Template
        className="login-form"
        title="Middle-Office"
        subtitle={shouldAccept && SUBTITLE}
        width="600px">
        <ReactPlaceholder
          className="my-3"
          ready={!!content}
          type="text"
          showLoadingAnimation
          rows={12}
          color={window
            .getComputedStyle(document.body)
            .getPropertyValue('--light')}>
          <Tos content={content || ''} />
        </ReactPlaceholder>
        {shouldAccept && (
          <ButtonCustom
            color="primary"
            onClick={this.acceptTos}
            className="align-self-end mb-3">
            {"J'accepte les CGU"}
          </ButtonCustom>
        )}
      </Template>
    );
  }
}

export default connect(state => ({
  content: state.legal.cguData,
  isValidToken: state.token.isValidToken,
  isLoading:
    state.token.isLoading ||
    state.login.isLoading ||
    state.legal.isLoadingCgu,
  needCguAcceptance: state.legal.needCguAcceptance,
  containers: state.container.containers
}))(AcceptTosScene);
