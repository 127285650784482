// @flow

// React libs
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Collapse, NavItem, Nav } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
// Utils
import isEqual from 'lodash/fp/isEqual';
// Components
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';
import Utilities from './Utilities';
import FolderSectionNavLink from './FolderSectionNavLink';
import ButtonCustom from '../ButtonCustom';
import NavlinkCustom from '../NavlinkCustom';
// Common data
import Routes from '../../../Resources/Common/Routes';
import { getProposalsObject } from '../../../Resources/Common/Utilities';
// Actions
import {
  setSidebarProposalsAction,
  getChangeProposalsSidebarAction,
  setSidebarCollapseAction,
  setUndefinedRemainingKindsAction,
  getContainerChangeAction
} from '../../../Business/actions/FolderActions';
import Constants from '../../../Resources/Common/Constants';

type ChangeProposal = {
  proposals: { status: boolean }[],
  kind: string,
  section: string,
  status: 0 | 1 | 2 | 3 | 4
};

type ContainerChange = {
  proposals: ChangeProposal[]
};

type MergeChangeProposalInput = {
  sidebarChangeProposals: ChangeProposal[]
};

type Props = {
  activeContainer: string,
  location: { pathname: string },
  dispatch: Function,
  type: string,
  containerData: Object,
  containerChangeData: ContainerChange,
  changeProposalsData: ChangeProposal[],
  sidebarChangeProposals: Array<any>,
  cachedSidebarProposals: Array<Object>,
  userRights: Array<any>,
  sidebarCollapse: Array<any>,
  match: Object
};

type State = {
  collapse: Array<any>,
  proposalsInfos: ChangeProposal[],
  proposalsReady: boolean
};

class Sidebar extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      collapse:
        props.sidebarCollapse && props.sidebarCollapse.length > 0
          ? props.sidebarCollapse
          : Array(
              // We find each unique section and count the size, to get a dynamic menu
              new Set(
                props.cachedSidebarProposals.map(p => p.section)
              ).size
            ).fill(false),
      proposalsInfos: [],
      proposalsReady: false
    };
  }

  componentDidMount = () => {
    const {
      changeProposalsData,
      containerChangeData,
      activeContainer,
      cachedSidebarProposals,
      sidebarCollapse,
      sidebarChangeProposals,
      type,
      dispatch
    } = this.props;

    if (_.isEmpty(containerChangeData)) {
      dispatch(getContainerChangeAction(activeContainer));
    }
    if (
      changeProposalsData &&
      containerChangeData &&
      containerChangeData.proposals &&
      type === Routes.routeFolder
    ) {
      dispatch(setUndefinedRemainingKindsAction);
      dispatch(
        getChangeProposalsSidebarAction({
          containerId: activeContainer,
          status: undefined
        })
      );
    }
    if (cachedSidebarProposals) {
      dispatch(
        setSidebarCollapseAction({
          collapse:
            sidebarCollapse && sidebarCollapse.length > 0
              ? sidebarCollapse
              : Array(
                  // We find each unique section and count the size, to get a dynamic menu
                  new Set(cachedSidebarProposals.map(p => p.section))
                    .size
                ).fill(false)
        })
      );
    }
    if (containerChangeData && containerChangeData.proposals) {
      this.setState({ proposalsReady: true });
      dispatch(
        setSidebarProposalsAction({
          proposalsData: containerChangeData.proposals,
          proposals: sidebarChangeProposals,
          proposalsObject: getProposalsObject()
        })
      );
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      activeContainer,
      dispatch,
      changeProposalsData,
      sidebarChangeProposals,
      containerChangeData,
      cachedSidebarProposals,
      sidebarCollapse,
      match
    } = this.props;

    const { proposalsInfos, proposalsReady, collapse } = this.state;
    const { kind } = match.params;

    const lastChangeProposal: ?ChangeProposal = Array.isArray(
      changeProposalsData
    )
      ? changeProposalsData[0]
      : null;

    if (
      !isEqual(changeProposalsData, prevProps.changeProposalsData) &&
      lastChangeProposal &&
      containerChangeData &&
      containerChangeData.proposals &&
      lastChangeProposal.status !==
        (
          containerChangeData.proposals.find(
            (proposal: ChangeProposal) =>
              proposal.kind === lastChangeProposal.kind
          ) || { status: undefined }
        ).status
    ) {
      dispatch(
        getChangeProposalsSidebarAction({
          containerId: activeContainer,
          status: undefined
        })
      );
    }
    if (
      !isEqual(
        sidebarChangeProposals,
        prevProps.sidebarChangeProposals
      )
    ) {
      const newArrayInfos = this.mergeChangeProposals({
        sidebarChangeProposals,
        proposalsInfos
      });
      if (!isEqual(newArrayInfos, proposalsInfos)) {
        this.setState({
          proposalsInfos: newArrayInfos
        });
      }
    }
    if (
      (sidebarChangeProposals.length > 0 &&
        (containerChangeData &&
          containerChangeData.proposals &&
          !isEqual(proposalsInfos, prevState.proposalsInfos))) ||
      (containerChangeData &&
        containerChangeData.proposals &&
        !isEqual(
          containerChangeData,
          prevProps.containerChangeData
        ) &&
        proposalsInfos) ||
      (sidebarChangeProposals.length === 0 &&
        containerChangeData &&
        containerChangeData.proposals &&
        !proposalsReady)
    ) {
      this.setState({ proposalsReady: true });
      dispatch(
        setSidebarProposalsAction({
          proposalsData: containerChangeData.proposals,
          proposals: sidebarChangeProposals,
          proposalsObject: getProposalsObject()
        })
      );
      if (kind) {
        const sectionKey = Constants.sections.indexOf(
          containerChangeData.proposals.filter(
            p => kind === p.kind
          )[0].section
        );
        if (sectionKey && collapse[sectionKey] === false) {
          this.toggle(sectionKey);
        }
      }
    }
    if (
      cachedSidebarProposals &&
      !isEqual(
        cachedSidebarProposals,
        prevProps.cachedSidebarProposals
      )
    ) {
      this.setState({
        collapse:
          sidebarCollapse && sidebarCollapse.length > 0
            ? sidebarCollapse
            : Array(
                // We find each unique section and count the size, to get a dynamic menu
                new Set(cachedSidebarProposals.map(p => p.section))
                  .size
              ).fill(false)
      });
    }
  };

  mergeChangeProposals = ({
    sidebarChangeProposals,
    proposalsInfos
  }: MergeChangeProposalInput): ChangeProposal[] => {
    const firstSidebarChangeProposal = sidebarChangeProposals[0];

    if (!firstSidebarChangeProposal) {
      return proposalsInfos;
    }

    let newProposals = proposalsInfos.slice();
    if (
      newProposals.some(
        proposalInfo =>
          proposalInfo.kind === firstSidebarChangeProposal.kind
      )
    ) {
      newProposals = newProposals.filter(
        proposal => proposal.kind !== firstSidebarChangeProposal.kind
      );
    }
    newProposals.push(firstSidebarChangeProposal);
    return newProposals;
  };

  renderAccountNav = () => {
    const { location } = this.props;
    return (
      <Nav vertical className="nav-pills">
        <p className="lead ml-3 mt-3">Informations du compte</p>
        <ButtonCustom
          active={Utilities.manageMenuActiveItem(
            location,
            Routes.routeInfosAccount
          )}
          className="p-0">
          <NavItem>
            <NavlinkCustom
              tag={Link}
              to={`/${Routes.routeInfosAccount}`}>
              Informations du compte
            </NavlinkCustom>
          </NavItem>
        </ButtonCustom>
        <ButtonCustom
          active={Utilities.manageMenuActiveItem(
            location,
            Routes.routeSecurityAccount
          )}
          className="p-0">
          <NavItem>
            <NavlinkCustom
              tag={Link}
              to={`/${Routes.routeSecurityAccount}`}>
              Sécurité
            </NavlinkCustom>
          </NavItem>
        </ButtonCustom>
      </Nav>
    );
  };

  renderDashboardNav = () => {
    const {
      activeContainer,
      location,
      userRights,
      containerData
    } = this.props;
    return (
      <>
        <Nav vertical className="nav-pills">
          <NavlinkCustom
            tag={Link}
            to={`/${Routes.routeDashboard}/${activeContainer}`}>
            <p
              className="lead ml-3 mt-3"
              style={{ color: 'initial' }}>
              Dashboard
            </p>
          </NavlinkCustom>
          {containerData &&
            containerData.todolists &&
            Object.entries(containerData.todolists).length > 0 && (
              <ButtonCustom className="p-0">
                <NavItem>
                  <NavlinkCustom
                    tag={Link}
                    to={`/${
                      Routes.routeDashboard
                    }/${activeContainer}/${Routes.routeChecklist}`}
                    active={Utilities.manageMenuActiveItem(
                      location,
                      `${Routes.routeDashboard}/${activeContainer}/${
                        Routes.routeChecklist
                      }`
                    )}>
                    Checklist
                  </NavlinkCustom>
                </NavItem>
              </ButtonCustom>
            )}
          {containerData &&
            containerData.calendars &&
            Object.entries(containerData.calendars).length > 0 && (
              <ButtonCustom className="p-0">
                <NavItem>
                  <NavlinkCustom
                    tag={Link}
                    to={`/${
                      Routes.routeDashboard
                    }/${activeContainer}/${Routes.routeCalendar}`}
                    active={Utilities.manageMenuActiveItem(
                      location,
                      `${Routes.routeDashboard}/${activeContainer}/${
                        Routes.routeCalendar
                      }`
                    )}>
                    Calendrier
                  </NavlinkCustom>
                </NavItem>
              </ButtonCustom>
            )}
          <ButtonCustom className="p-0">
            <NavItem>
              <NavlinkCustom
                tag={Link}
                to={`/${Routes.routeDashboard}/${activeContainer}/${
                  Routes.routeLogs
                }`}
                active={Utilities.manageMenuActiveItem(
                  location,
                  `${Routes.routeDashboard}/${activeContainer}/${
                    Routes.routeLogs
                  }`
                )}>
                Historique
              </NavlinkCustom>
            </NavItem>
          </ButtonCustom>
          {userRights && userRights.includes('ADMINISTRATION') && (
            <ButtonCustom className="p-0">
              <NavItem>
                <NavlinkCustom
                  tag={Link}
                  to={`/${Routes.routeDashboard}/${activeContainer}/${
                    Routes.routeDashboardSettings
                  }`}
                  active={Utilities.manageMenuActiveItem(
                    location,
                    `${Routes.routeDashboard}/${activeContainer}/${
                      Routes.routeDashboardSettings
                    }`
                  )}>
                  Paramètres
                </NavlinkCustom>
              </NavItem>
            </ButtonCustom>
          )}
        </Nav>
      </>
    );
  };

  renderFolderNav = () => {
    const { collapse, proposalsReady } = this.state;
    const { cachedSidebarProposals, activeContainer } = this.props;

    const sections: {
      [string]: ChangeProposal[]
    } = cachedSidebarProposals.reduce(
      (sectionsAggregator, proposal: ChangeProposal) => ({
        ...sectionsAggregator,
        [proposal.section]: (
          sectionsAggregator[proposal.section] || []
        ).concat(proposal)
      }),
      {}
    );
    const sectionsNav = Object.entries(sections).map(
      ([category, changeProposals], key) => {
        const keyPortion = category.replace(/\s+/g, '');
        return (
          <React.Fragment key={`${keyPortion}_CategoryFragment`}>
            <ButtonCustom
              key={`${keyPortion}_ToggleButton`}
              onClick={() => this.toggle(key)}
              style={{ whiteSpace: 'normal' }}>
              <NavItem>{category}</NavItem>
            </ButtonCustom>
            <Collapse
              key={`${keyPortion}_CollapseContainer`}
              isOpen={collapse[key]}>
              {[].map.call(changeProposals, proposal => (
                <FolderSectionNavLink
                  proposal={proposal}
                  key={`${keyPortion}_${proposal.kind}_Badge`}
                  activeContainer={activeContainer}
                />
              ))}
            </Collapse>
          </React.Fragment>
        );
      }
    );

    const placeholder = (
      <>
        {/* Can't use collapse for an unknown reason */}
        {Array.from({ length: 4 }).map((_, i) => (
          <RectShape
            key={`${i + 1}_sidenav_placeholder`}
            color={window
              .getComputedStyle(document.body)
              .getPropertyValue('--light')}
            style={{
              height: 38,
              width: '100%',
              marginBottom: 2
            }}
          />
        ))}
      </>
    );

    return (
      <>
        <Nav vertical className="nav-pills flex-nowrap">
          <NavlinkCustom
            tag={Link}
            to={`/${Routes.routeFolder}/${activeContainer}`}>
            <p className="lead ml-3 mt-3">Dossier</p>
          </NavlinkCustom>
          <ReactPlaceholder
            ready={proposalsReady}
            showLoadingAnimation
            delay={100}
            customPlaceholder={placeholder}>
            {sectionsNav}
          </ReactPlaceholder>
        </Nav>
      </>
    );
  };

  toggle = key => {
    const { dispatch } = this.props;
    const { collapse } = this.state;
    const newCollapse = collapse.map((col, i) =>
      i === key ? !col : false
    );
    dispatch(setSidebarCollapseAction({ collapse: newCollapse }));
    this.setState({ collapse: newCollapse });
  };

  render() {
    const { type } = this.props;
    return (
      <div className="position-sticky">
        {type === Routes.routeAccount && this.renderAccountNav()}
        {type === Routes.routeDashboard && this.renderDashboardNav()}
        {type === Routes.routeFolder && this.renderFolderNav()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  containerChangeData: state.folder.getContainerChangeData,
  activeContainer: state.container.activeContainer.id,
  userRights: state.container.userRights.rights,
  postChangeProposalsSuccess: state.folder.postChangeProposalsSuccess,
  changeProposalsData: state.folder.getChangeProposalsData,
  sidebarChangeProposals: state.folder.getChangeProposalsSidebarData,
  cachedSidebarProposals: state.folder.setSidebarProposals,
  isLoadingAllChangeProposals:
    state.folder.isLoadingGetAllChangeProposals,
  sidebarCollapse: state.folder.setSidebarCollapse,
  containerData: state.dashboardSettings.containerData,
  remainingKinds: state.folder.remainingKinds
});

export default withRouter(connect(mapStateToProps)(Sidebar));
