// @flow
// React Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
// Components
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import InputCustom from '../../../InputCustom';
import FrontValidation from '../../../../../Resources/Common/FrontValidation';
import TruncateCustom from '../../../TruncateCustom';
import Tooltip from '../../../../../Resources/Common/Tooltip';
import Constants from '../../../../../Resources/Common/Constants';

type Props = {
  changeProposalsData: Array<any>,
  hasRights: boolean,
  blocked?: boolean,
  proposalUid?: string
};

type State = {
  name: string,
  shortName: string,
  collapse: {
    name: boolean,
    shortName: boolean
  }
};

class InfoName extends Component<Props, State> {
  constructor(props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = _.pick(
      _.defaults(_.get(changeProposalsFiltered, '[0].content'), {
        name: '',
        shortName: ''
      }),
      ['name', 'shortName']
    );
    this.state.collapse = {
      name: true,
      shortName: true
    };
  }

  handleName = e => {
    this.setState({ name: e.target.value });
  };

  handleShortName = e => {
    this.setState({ shortName: e.target.value });
  };

  handleCollapse = tooltip => () => {
    const { collapse } = this.state;
    this.setState(prevState => ({
      collapse: {
        ...prevState.collapse,
        [tooltip]: !collapse[tooltip]
      }
    }));
  };

  render() {
    const { name, shortName, collapse } = this.state;
    const { hasRights, blocked } = this.props;
    return (
      <>
        <FormGroup>
          <Label for="name">Nom du projet</Label>
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse.name}
              tooltip={Tooltip.InfoName.name}
              handleCollapse={this.handleCollapse('name')}
            />
          </small>
          <InputCustom
            placeholder="Nom du projet"
            id="name"
            name="name"
            value={name}
            onChange={this.handleName}
            minLength="3"
            maxLength="100"
            pattern={Constants.pattern.internationalName}
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.ProjetBasicInfoName.name}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="shortName">Diminutif du nom du projet</Label>
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse.shortName}
              tooltip={Tooltip.InfoName.shortName}
              handleCollapse={this.handleCollapse('shortName')}
            />
          </small>
          <InputCustom
            id="shortName"
            name="shortName"
            minLength="3"
            maxLength="20"
            pattern={Constants.pattern.internationalName}
            value={shortName}
            onChange={this.handleShortName}
            required
            disabled={!hasRights || blocked}
            autoComplete="email"
          />
          <FormFeedback>
            {FrontValidation.ProjetBasicInfoName.shortName}
          </FormFeedback>
        </FormGroup>
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData
});

export default withRouter(connect(mapStateToProps)(InfoName));
