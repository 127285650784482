import memoize from 'lodash/fp/memoize';
import _ from 'lodash';

/* eslint-disable no-param-reassign */
function objectToProposalObject(
  obj,
  blacklist = [],
  prefix = undefined,
  newObj = {}
) {
  Object.keys(obj).forEach(key => {
    if (prefix && blacklist.includes(key)) {
      newObj[prefix] = { ...newObj[prefix], [key]: obj[key] };
      return;
    }

    const value = obj[key];
    const newPrefix = !prefix ? key : `${prefix}:${key}`;
    if (typeof value === 'object') {
      objectToProposalObject(value, blacklist, newPrefix, newObj);
    } else {
      newObj[newPrefix] = value;
    }
  });
  return newObj;
}
/* eslint-enable no-param-reassign */

const memoObjectToProposalObject = memoize(objectToProposalObject);

export function getProposalsObject() {
  const sectionNames = {
    BASIC_INFO: 'Informations basiques',
    OFFER: "L'offre",
    MEDIA: 'Média',
    FINANCIAL_DATA: 'Informations financières'
  };

  const proposalObject = {
    DOCUMENT: {
      PROJECT: {
        ARTICLES_OF_INCORPORATION: {
          title: 'Status Pré-levée',
          section: sectionNames.BASIC_INFO,
          TagName: 'DocumentProjectArticlesOfIncorporation'
        },
        GENERAL_ASSEMBLY_REPORT: {
          title: "Compte-rendu d'AG",
          section: sectionNames.BASIC_INFO,
          TagName: 'DocumentProjectGeneralAssemblyReport'
        },
        FINANCIAL_BP: {
          title: 'BP Financier sur 5 ans',
          section: sectionNames.OFFER,
          TagName: 'DocumentProjectFinancialBp'
        },
        FINANCIAL_STATEMENTS: {
          title: 'Liasse fiscale',
          section: sectionNames.BASIC_INFO,
          TagName: 'DocumentProjectFinancialStatements'
        },
        FOUNDER_CV: {
          title: 'CV des fondateurs',
          section: sectionNames.BASIC_INFO,
          TagName: 'DocumentProjectFounderCv'
        },
        KBIS: {
          title: 'KBIS',
          section: sectionNames.BASIC_INFO,
          TagName: 'DocumentProjectKbis'
        },
        PITCH_DECK: {
          title: 'Pitch deck',
          section: sectionNames.BASIC_INFO,
          TagName: 'DocumentProjectPitchDeck'
        },
        SHAREHOLDERS_AGREEMENT: {
          title: "Pacte d'actionnaires",
          section: sectionNames.BASIC_INFO,
          TagName: 'DocumentProjectShareholdersAgreement'
        },
        SHAREHOLDERS_LIST: {
          title: 'Table de capitalisation',
          section: sectionNames.BASIC_INFO,
          TagName: 'DocumentProjectShareholdersList'
        }
      }
    },
    ENTITY: {
      BASIC_INFO: {
        title: 'Détails entreprise',
        section: sectionNames.BASIC_INFO,
        TagName: 'EntityBasicInfo'
      }
    },
    PROJECT: {
      FEEDBACK: {
        title: 'Avis',
        section: sectionNames.OFFER,
        TagName: 'ProjectFeedback'
      },
      BASIC_INFO: {
        NAME: {
          title: 'Nom du projet',
          section: sectionNames.BASIC_INFO,
          TagName: 'ProjectBasicInfoName'
        },
        SECTOR: {
          title: 'Secteur',
          section: sectionNames.BASIC_INFO,
          TagName: 'ProjectBasicInfoSector'
        },
        STRUCTURE_CAP: {
          title: 'Structure du capital',
          section: sectionNames.BASIC_INFO,
          TagName: 'ProjectBasicInfoStructureCap'
        }
      },
      FINANCIAL_DATA: {
        CLIENTS_B2B: {
          title: 'Clients B2B',
          section: sectionNames.FINANCIAL_DATA,
          TagName: 'ProjectFinancialDataClientsB2B'
        },
        CLIENTS_B2C: {
          title: 'Clients B2C',
          section: sectionNames.FINANCIAL_DATA,
          TagName: 'ProjectFinancialDataClientsB2C'
        },
        EBITDA: {
          title: 'EBE',
          section: sectionNames.FINANCIAL_DATA,
          TagName: 'ProjectFinancialDataEbitda'
        },
        PROFIT_LOSS: {
          title: 'Bénéfice net',
          section: sectionNames.FINANCIAL_DATA,
          TagName: 'ProjectFinancialDataProfitLoss'
        },
        REVENUE: {
          title: "Chiffre d'affaires",
          section: sectionNames.FINANCIAL_DATA,
          TagName: 'ProjectFinancialDataRevenue'
        },
        USERS: {
          title: 'Utilisateurs',
          section: sectionNames.FINANCIAL_DATA,
          TagName: 'ProjectFinancialDataUsers'
        },
        WORKFORCE: {
          title: 'Employés',
          section: sectionNames.FINANCIAL_DATA,
          TagName: 'ProjectFinancialDataWorkforce'
        }
      },
      FINANCING_HISTORY: {
        title: 'Financements passés',
        section: sectionNames.FINANCIAL_DATA,
        TagName: 'ProjectFinancingHistory'
      },
      MEDIA: {
        DISPLAY: {
          title: 'Média',
          section: sectionNames.MEDIA,
          TagName: 'ProjectMediaDisplay'
        },
        SOCIAL_NETWORK: {
          title: 'Réseaux sociaux',
          section: sectionNames.MEDIA,
          TagName: 'ProjectMediaSocialNetwork'
        },
        VIDEO: {
          title: 'Vidéo',
          section: sectionNames.MEDIA,
          TagName: 'ProjectMediaVideo'
        }
      },
      OFFER: {
        ACTION_PLAN: {
          title: "Plan d'action",
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferActionPlan'
        },
        ASSETS: {
          title: "Actifs de l'entreprise",
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferAssets'
        },
        BARRIERS: {
          title: "Barrières à l'entrée",
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferBarriers'
        },
        BUSINESS_MODEL: {
          title: 'Business Model',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferBusinessModel'
        },
        COMMERCIAL_STRAT: {
          title: 'Stratégie commerciale',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferCommercialStrat'
        },
        COMPETITION: {
          title: 'Concurrence',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferCompetition'
        },
        CONCEPT: {
          title: 'Concept',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferConcept'
        },
        DESCRIPTION: {
          title: 'Description',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferDescription'
        },
        DIFFERENCIATION: {
          title: 'Facteurs de différentiation',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferDifferenciation'
        },
        GOALS: {
          title: 'Objectifs',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferGoals'
        },
        HISTORY: {
          title: 'Histoire',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferHistory'
        },
        MARKET: {
          title: 'Le marché',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferMarket'
        },
        PARTNERS: {
          title: 'Partenaires',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferPartners'
        },
        PEOPLE: {
          title: 'Équipe',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferPeople'
        },
        PROBLEM: {
          title: 'Le Problème',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferProblem'
        },
        PRODUCTS_SERVICES: {
          title: 'Produits et services',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferProductsServices'
        },
        RISKS: {
          title: 'Facteurs de risque',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferRisks'
        },
        STRENGHTS: {
          title: 'Forces',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferStrengths'
        },
        SUCCESSES: {
          title: 'Succès',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferSuccesses'
        },
        TAGLINE: {
          title: 'Tagline',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferTagline'
        },
        USE_OF_FUNDS: {
          title: 'Utilisation des fonds',
          section: sectionNames.OFFER,
          TagName: 'ProjectOfferUseOfFunds'
        }
      },
      ONGOING_FUNDRAISE: {
        title: 'Financements en cours',
        section: sectionNames.FINANCIAL_DATA,
        TagName: 'ProjectOngoingFundraise'
      }
    }
  };

  return memoObjectToProposalObject(proposalObject, [
    'title',
    'section',
    'TagName'
  ]);
}

/**
 * @param {Object} props
 * @param {Object} prevProps
 * @param {Object} state
 * @param {Object} prevState
 * @param {string[]} keys
 * @return {Object} filtered props on key list
 */
export function getVisibleObject(
  props,
  prevProps,
  state,
  prevState,
  keys
) {
  const { visibleObject } = state;
  const newVisibleObject = Object.assign({}, visibleObject);
  keys.forEach(errorKey => {
    newVisibleObject[errorKey] =
      props[errorKey] !== prevProps[errorKey] ||
      state[errorKey] !== prevState[errorKey]
        ? !!(props[errorKey] || state[errorKey])
        : visibleObject[errorKey];
  });
  return newVisibleObject;
}

export function parseField(kind, name, value) {
  if (kind.startsWith('PROJECT:FINANCIAL_DATA')) {
    if (_.includes(name, 'isAchieved')) {
      return value === 'true';
    }
    if (_.includes(name, 'value')) {
      return Number(value);
    }
    if (_.includes(name, 'comment.highlight')) {
      return (Number(value) || value).toString();
    }
  }
  if (kind === 'PROJECT:OFFER:PEOPLE') {
    if (_.includes(name, 'isFeatured')) {
      return value === 'true';
    }
    if (_.includes(name, 'kind')) {
      return Number(value);
    }
  }
  if (kind === 'PROJECT:MEDIA:VIDEO') {
    if (_.includes(name, 'vimeoId') && typeof value !== 'string') {
      return value.toString();
    }
  }
  if (_.includes(name, 'zipcode')) {
    return value.toString();
  }
  return Number(value) || value;
}

export function isImage(url) {
  return /\.(gif|jpg|jpeg|tiff|png)/i.test(url);
}
