// @flow
// @todo récupèrer tous les membres

// React Libs
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import update from 'immutability-helper';
// Components
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Tooltip from '../../../../../Resources/Common/Tooltip';
import TruncateCustom from '../../../TruncateCustom';
import FeedbackInfo from './FeedbackInfo';
import ButtonCustom from '../../../ButtonCustom';

type TeamMember = {
  firstName: string,
  lastName: string,
  company: string,
  job: string,
  authorPhoto: string,
  companyLogo: string,
  content: string,
  newElement: boolean
};

type Props = {
  feedbacks?: TeamMember[],
  hasRights?: boolean,
  blocked: boolean,
  newElement?: boolean,
  changeProposalsData: Array<any>,
  proposalUid?: string
};

type State = {
  feedbacks: TeamMember[],
  collapse: {
    proposal: boolean
  }
};

class Feedback extends React.Component<Props, State> {
  static NOTHING = {
    firstName: '',
    lastName: '',
    company: '',
    job: '',
    authorPhoto: '',
    companyLogo: '',
    content: '',
    newElement: true
  };

  static defaultProps = {
    feedbacks: [Feedback.NOTHING],
    hasRights: false,
    newElement: false,
    proposalUid: undefined
  };

  constructor(props: Props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = {
      feedbacks: _.get(
        changeProposalsFiltered,
        '[0].content.feedbacks'
      ) || [Feedback.NOTHING],
      collapse: {
        proposal: true
      }
    };
  }

  addFeedback = () => {
    const { feedbacks } = this.state;
    this.setState({
      feedbacks: update(feedbacks, { $push: [Feedback.NOTHING] })
    });
  };

  deleteFeedback = index => () => {
    const { feedbacks } = this.state;
    this.setState({
      feedbacks: update(feedbacks, { $splice: [[index, 1]] })
    });
  };

  handleCollapse = tooltip => () => {
    const { collapse } = this.state;
    this.setState(prevState => ({
      collapse: {
        ...prevState.collapse,
        [tooltip]: !collapse[tooltip]
      }
    }));
  };

  render() {
    const {
      hasRights,
      blocked,
      newElement,
      proposalUid
    } = this.props;
    const { feedbacks, collapse } = this.state;
    const feedbacksInfo = feedbacks.map(
      (feedback: TeamMember, index) => (
        <section
          className={
            index > 0 ? `border-top border-secondary mt-4 pt-4` : ``
          }
          key={`section${feedback.firstName} ${feedback.lastName}`}>
          <FeedbackInfo
            index={index}
            hasRights={hasRights}
            blocked={blocked}
            newElement={newElement}
            proposalUid={proposalUid}
            {...feedback}
          />
          <ButtonCustom
            disabled={blocked}
            onClick={this.deleteFeedback(index)}
            className="mb-3 ml-3">
            Supprimer cet avis
          </ButtonCustom>
        </section>
      )
    );

    return (
      <>
        <div className="mb-3">
          <small>
            <TruncateCustom
              collapse={collapse.proposal}
              tooltip={Tooltip.ProjectFeedback.proposal}
              handleCollapse={this.handleCollapse('proposal')}
            />
          </small>
        </div>
        {feedbacksInfo}
        <Button
          color="secondary"
          className="mx-5 mb-3"
          onClick={this.addFeedback}>
          ajouter un avis
        </Button>
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData,
  containerDocument: state.folder.getContainerDocument,
  activeContainer: state.container.activeContainer.id,
  isLoadingGetContainerDocument:
    state.folder.isLoadingGetContainerDocument
});

export default withRouter(connect(mapStateToProps)(Feedback));
