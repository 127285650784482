// @flow

// Utils
import NetworkUrls from '../NetworkUrls';
import NetworkUtils from '../NetworkUtils';

export default class DashboardCalendarService {
  static getCalendarEvents({
    containerId,
    calendarUuid,
    timeMin,
    timeMax
  }: {
    containerId: string,
    calendarUuid: string,
    timeMin: number,
    timeMax: number
  }) {
    return NetworkUtils.get({
      url: NetworkUrls.getCalendarEvents({
        containerId,
        calendarUuid,
        timeMin,
        timeMax
      })
    });
  }

  static postCalendarEvents({
    containerId,
    calendarUuid,
    startDate,
    endDate,
    title,
    description
  }: {
    containerId: string,
    calendarUuid: string,
    startDate: string,
    endDate: string,
    title: string,
    description: string
  }) {
    return NetworkUtils.post({
      url: NetworkUrls.postCalendarEvents(containerId, calendarUuid),
      data: {
        startDate,
        endDate,
        title,
        description
      }
    });
  }

  static patchCalendarEvents({
    containerId,
    calendarUuid,
    eventId,
    startDate,
    endDate,
    title,
    description
  }: {
    containerId: string,
    calendarUuid: string,
    eventId: string,
    startDate: string,
    endDate: string,
    title: string,
    description: string
  }) {
    return NetworkUtils.patch({
      url: NetworkUrls.patchCalendarEvents(
        containerId,
        calendarUuid,
        eventId
      ),
      data: {
        startDate,
        endDate,
        title,
        description
      }
    });
  }

  static deleteCalendarEvents({
    containerId,
    calendarUuid,
    eventId
  }: {
    containerId: string,
    calendarUuid: string,
    eventId: string
  }) {
    return NetworkUtils.delete({
      url: NetworkUrls.deleteCalendarEvents(
        containerId,
        calendarUuid,
        eventId
      )
    });
  }
}
