// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Components
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Template from '../../Components/Template/TemplateComponent';
// Utilities
import Routes from '../../Resources/Common/Routes';
import LogContent from '../../Components/Components/Dashboard/LogContent';
import ChecklistContent from '../../Components/Components/Dashboard/ChecklistContent';
import Constants from '../../Resources/Common/Constants';

type Props = {
  activeContainer: string,
  userRights: Array<any>,
  containers: Array<any>,
  containerData: Object
};

class DashboardScene extends Component<Props> {
  render() {
    const {
      userRights,
      activeContainer,
      containers,
      containerData
    } = this.props;
    return (
      <Template
        title="Dashboard"
        menu={Routes.routeDashboard}
        subtitle="Voici un aperçu de l'avancement de votre projet.">
        <Row>
          <Col md={{ size: 6 }} className="text-center">
            <LogContent dashboardView />
          </Col>
          <Col md={{ size: 6 }}>
            <Row className="my-5 text-center">
              <Col md={{ size: 12 }}>
                <Link
                  to={`/${Routes.routeDashboard}/${activeContainer}/${
                    Routes.routeChecklist
                  }`}>
                  <h2>{`Checklist >`}</h2>
                </Link>
                {containerData &&
                containerData.todolists &&
                Object.entries(containerData.todolists).length > 0 ? (
                  <>
                    <ChecklistContent dashboardView />
                  </>
                ) : (
                  <span>Pas de checklist</span>
                )}
              </Col>
            </Row>
            <Row className="my-5 text-center">
              <Col md={{ size: 12 }}>
                {userRights.includes(
                  Constants.USER_RIGHTS.ADMINISTRATION
                ) ? (
                  <Link
                    to={`/${
                      Routes.routeDashboard
                    }/${activeContainer}/${
                      Routes.routeDashboardSettings
                    }`}>
                    <h2>{`Vos droits >`}</h2>
                  </Link>
                ) : (
                  <h2>{`Vos droits >`}</h2>
                )}

                {userRights.map(right => (
                  <React.Fragment key={right}>
                    <div>{Constants.USER_RIGHTS_TRAD[right]}</div>
                  </React.Fragment>
                ))}
              </Col>
            </Row>
            <Row className="my-5 text-center">
              <Col md={{ size: 12 }}>
                <h2>Votre référent</h2>
                {containers &&
                  containers.map(c => {
                    if (c.uuid === activeContainer) {
                      return (
                        <React.Fragment key={c.uuid}>
                          <div>
                            <a href="mailto:{c.operator.email}">
                              {c.operator.email}
                            </a>
                          </div>
                          <div>
                            {`${c.operator.firstName} ${
                              c.operator.lastName
                            }`}
                          </div>
                        </React.Fragment>
                      );
                    }
                    return undefined;
                  })}
              </Col>
            </Row>
          </Col>
        </Row>
      </Template>
    );
  }
}

const mapStateToProps = state => ({
  activeContainer: state.container.activeContainer.id,
  containers: state.container.containers,
  userRights: state.container.userRights.rights,
  rights: state.dashboardSettings.rights,
  containerData: state.dashboardSettings.containerData
});

export default connect(mapStateToProps)(DashboardScene);
