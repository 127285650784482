// @flow

import UserService from '../../Layer/Network/Service/UserService';
import Common from '../../Resources/Common';
import Messages from '../../Resources/Common/Messages';
import Constants from '../../Resources/Common/Constants';
import { getContainersAction } from './ContainerActions';
import { getInfosAccountAction } from './AccountInfosActions';
import { GetCguAction } from './LegalActions';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const POST_RECOVER_PASSWORD = 'POST_RECOVER_PASSWORD';
export const POST_USER_REGISTER = 'POST_USER_REGISTER';
export const POST_USER_PRE_REGISTER = 'POST_USER_PRE_REGISTER';

// ForgotPassword
const postResetPasswordPending = () => ({
  type: RESET_PASSWORD,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    hasSendEmail: false
  }
});
const postResetPasswordSuccess = () => ({
  type: RESET_PASSWORD,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    hasSendEmail: true
  }
});
const postResetPasswordError = error => ({
  type: RESET_PASSWORD,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    hasSendEmail: false,
    error
  }
});

// Get Recover Password
const getRecoverPasswordPending = () => ({
  type: RECOVER_PASSWORD,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    hasMatchedCode: false,
    error: ''
  }
});
const getRecoverPasswordSuccess = () => ({
  type: RECOVER_PASSWORD,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    hasMatchedCode: true,
    error: ''
  }
});
const getRecoverPasswordError = error => ({
  type: RECOVER_PASSWORD,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    hasMatchedCode: false,
    error
  }
});

// Post recover password
const postRecoverPasswordPending = () => ({
  type: POST_RECOVER_PASSWORD,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: null,
    hasChangedPassword: false,
    error: ''
  }
});
const postRecoverPasswordSuccess = info => ({
  type: POST_RECOVER_PASSWORD,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: info,
    hasChangedPassword: true,
    error: ''
  }
});
const postRecoverPasswordError = error => ({
  type: POST_RECOVER_PASSWORD,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: null,
    hasChangedPassword: false,
    error
  }
});

// Post user register
const postUserRegisterPending = () => ({
  type: POST_USER_REGISTER,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    hasCreatedAccount: false,
    error: null,
    isLoading: true
  }
});
const postUserRegisterSuccess = () => ({
  type: POST_USER_REGISTER,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    hasCreatedAccount: true,
    error: null,
    isLoading: false
  }
});
const postUserRegisterFailure = error => ({
  type: POST_USER_REGISTER,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    hasCreatedAccount: false,
    error,
    isLoading: false
  }
});

// Post user pre-register
const postUserPreRegisterPending = () => ({
  type: POST_USER_PRE_REGISTER,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    error: null,
    isLoading: true
  }
});
const postUserPreRegisterSuccess = () => ({
  type: POST_USER_PRE_REGISTER,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    error: null,
    isLoading: false
  }
});
const postUserPreRegisterFailure = error => ({
  type: POST_USER_PRE_REGISTER,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    error,
    isLoading: false
  }
});

export function PostResetPasswordAction(email: string) {
  return (dispatch: Function) => {
    dispatch(postResetPasswordPending());
    return UserService.postResetPassword(email)
      .then(() => {
        dispatch(postResetPasswordSuccess());
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.USER.FROZEN:
            error = Messages.frozenAccount;
            break;
          case Constants.API_ERRORS.USER.TOO_MANY_REQUEST:
            error = Messages.loginAccesTimeout;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(postResetPasswordError(error));
      });
  };
}

export function GetRecoverPasswordAction(uuid: string, code: string) {
  return (dispatch: Function) => {
    dispatch(getRecoverPasswordPending());
    return UserService.getRecoverPassword(uuid, code)
      .then(() => {
        dispatch(getRecoverPasswordSuccess());
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.USER.FROZEN:
            error = Messages.frozenAccount;
            break;
          case Constants.API_ERRORS.USER.PASSWORD_LINK_EXPIRED:
            error = Messages.passwordLinkExpired;
            break;
          case Constants.API_ERRORS.USER.TOO_MANY_REQUEST:
            error = Messages.loginAccesTimeout;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(getRecoverPasswordError(error));
      });
  };
}

export function PostRecoverPasswordAction(
  uuid: string,
  newPassword: string,
  code: string
) {
  return (dispatch: Function) => {
    dispatch(postRecoverPasswordPending());
    return UserService.postRecoverPassword(uuid, newPassword, code)
      .then(response => {
        dispatch(postRecoverPasswordSuccess(response));
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.USER.FROZEN:
            error = Messages.loginFrozenAccount;
            break;
          case Constants.API_ERRORS.USER.PASSWORD_LINK_EXPIRED:
            error = Messages.loginInvalidLink;
            break;
          case Constants.API_ERRORS.USER.TOO_MANY_REQUEST:
            error = Messages.loginAccesTimeout;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(postRecoverPasswordError(error));
      });
  };
}

export function postUserRegisterAction(
  email: string,
  password: string,
  prerequisites: Object,
  firstname: string,
  lastname: string,
  invitation: string,
  autoconnect: boolean
) {
  return (dispatch: Function) => {
    dispatch(postUserRegisterPending());
    return UserService.postUserRegister(
      email,
      password,
      prerequisites,
      firstname,
      lastname,
      invitation,
      autoconnect
    )
      .then(() => {
        dispatch(postUserRegisterSuccess());
        dispatch(getInfosAccountAction());
        dispatch(GetCguAction());
        dispatch(getContainersAction());
      })
      .catch(err => {
        let error = '';
        if (err === 'user.wrongDataSameNames') {
          error = Messages.registerUserWrongDataSameNames;
        }
        if (err === 'user.alreadyEnrolled') {
          error = Messages.registerUserAlreadyEnrolled;
        }
        dispatch(postUserRegisterFailure(error));
      });
  };
}

export function postUserPreRegisterAction(
  email: string,
  invitation: string
) {
  return (dispatch: Function) => {
    dispatch(postUserPreRegisterPending());
    return UserService.postUserPreRegister(email, invitation)
      .then(() => {
        dispatch(postUserPreRegisterSuccess());
      })
      .catch(err => {
        const error = {
          'user.wrongInvitationToken':
            Messages.preregisterUserWrongInvitationToken,
          'user.alreadyEnrolled':
            Messages.registerUserAlreadyEnrolled,
          'user.tooManyRequests': Messages.tooManyRequest
        }[err.message];
        dispatch(postUserPreRegisterFailure(error));
      });
  };
}
