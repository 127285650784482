// @flow
// React Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import _ from 'lodash';
// Components
import {
  FormGroup,
  Label,
  FormFeedback,
  Table,
  InputGroup
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import TruncateCustom from '../../../TruncateCustom';
// Components
import BreakdownComponent from '../../../Charts/BreakdownComponent';
import InputCustom from '../../../InputCustom';
import ButtonCustom from '../../../ButtonCustom';
import FrontValidation from '../../../../../Resources/Common/FrontValidation';
import Constants from '../../../../../Resources/Common/Constants';

type Props = {
  changeProposalsData: Array<any>,
  hasRights: boolean,
  blocked: boolean,
  tooltip: Object,
  fieldNames: Object,
  title: string,
  proposalUid: string
};

type State = {
  figures: Array<{
    year: number,
    value: number,
    isAchieved: boolean
  }>,
  comment: {
    content: string,
    highlight: string,
    breakdown: Array<{
      name: string,
      value: number
    }>
  },
  collapse: {
    figures: boolean,
    comment: boolean,
    highlight: boolean,
    breakdown: boolean
  }
};

class FinancialData extends Component<Props, State> {
  constructor(props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = _.defaultsDeep(
      _.get(changeProposalsFiltered, '[0].content'),
      {
        figures: [
          {
            year: new Date().getFullYear(),
            value: 0,
            isAchieved: false
          }
        ],
        comment: {
          content: '',
          highlight: '',
          breakdown: [
            {
              name: '',
              value: 0
            }
          ]
        }
      }
    );
    this.state.collapse = {
      figures: true,
      comment: true,
      highlight: true,
      breakdown: true
    };
    this.breakdownParent = React.createRef();
  }

  handleYear = itemNumber => e => {
    e.persist();
    const { figures } = this.state;
    const value = parseInt(e.target.value, 10);
    if (figures.some(f => f.year === value)) {
      e.target.setCustomValidity('Error');
    } else {
      e.target.setCustomValidity('');
    }
    this.setState({
      figures: update(figures, {
        [itemNumber]: { year: { $set: value } }
      })
    });
  };

  handleValue = itemNumber => e => {
    e.persist();
    const { figures } = this.state;
    this.setState({
      figures: update(figures, {
        [itemNumber]: {
          value: { $set: e.target.value }
        }
      })
    });
  };

  handleIsAchieved = itemNumber => e => {
    e.persist();
    const { figures } = this.state;
    this.setState({
      figures: update(figures, {
        [itemNumber]: { isAchieved: { $set: e.target.checked } }
      })
    });
  };

  handleContent = e => {
    const { comment } = this.state;
    this.setState({
      comment: update(comment, {
        content: {
          $set: e.target.value
        }
      })
    });
  };

  handleHighlight = e => {
    const { comment } = this.state;
    this.setState({
      comment: update(comment, {
        highlight: {
          $set: e.target.value
        }
      })
    });
  };

  handleBreakdownName = itemNumber => e => {
    e.persist();
    const { comment } = this.state;
    const { breakdown } = comment;
    this.setState({
      comment: update(comment, {
        breakdown: {
          $set: update(breakdown, {
            [itemNumber]: { name: { $set: e.target.value } }
          })
        }
      })
    });
  };

  handleBreakdownValue = itemNumber => e => {
    e.persist();
    const { comment } = this.state;
    const { breakdown } = comment;
    this.setState({
      comment: update(comment, {
        breakdown: {
          $set: update(breakdown, {
            [itemNumber]: { value: { $set: e.target.value } }
          })
        }
      })
    });
  };

  addFiguresTd = () => {
    const { figures } = this.state;
    const maxYear =
      figures.length > 0
        ? figures.reduce((prev, current) =>
            prev.y > current.y ? prev : current
          ).year
        : new Date().getFullYear() - 1;
    this.setState({
      figures: figures.concat({
        year: maxYear + 1,
        value: 0,
        isAchieved: false
      })
    });
  };

  deleteFiguresTd = index => {
    const { figures } = this.state;
    _.pullAt(figures, [index]);
    this.setState({
      figures
    });
  };

  addBreakdown = () => {
    const { comment } = this.state;
    this.setState({
      comment: update(comment, {
        breakdown: { $push: [{ name: '', value: 0 }] }
      })
    });
  };

  deleteBreakdownTd = index => {
    const { comment } = this.state;
    const { breakdown } = comment;
    _.pullAt(breakdown, [index]);
    this.setState(prevState => ({
      comment: {
        ...prevState.comment,
        breakdown
      }
    }));
  };

  renderFiguresInputs = () => {
    const { figures } = this.state;
    const { hasRights, blocked } = this.props;
    return figures.map((item, iterator) => (
      <tr key={`${iterator + 1}_tr`}>
        <td>
          <InputCustom
            placeholder="Année"
            id={`figures.${iterator}.year`}
            name={`figures.${iterator}.year`}
            value={item.year || ''}
            onChange={this.handleYear(iterator)}
            type="number"
            min={2010}
            max={2030}
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.FinancialData.figures.year}
          </FormFeedback>
        </td>
        <td>
          <InputCustom
            placeholder="Chiffre d'affaires"
            id={`figures.${iterator}.value`}
            name={`figures.${iterator}.value`}
            type="number"
            value={item.value}
            onChange={this.handleValue(iterator)}
            max="999999999.99"
            step="0.01"
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.FinancialData.figures.value}
          </FormFeedback>
        </td>
        <td className="text-center">
          <InputGroup className="custom-control custom-checkbox">
            <InputCustom
              placeholder="Chiffre réalisé ?"
              id={`figures.${iterator}.isAchieved`}
              name={`figures.${iterator}.isAchieved`}
              className="custom-control-input"
              type="checkbox"
              checked={item.isAchieved}
              value={item.isAchieved}
              onChange={this.handleIsAchieved(iterator)}
              disabled={!hasRights || blocked}
            />
            <Label
              className="custom-control-label d-flex no-label"
              for={`figures.${iterator}.isAchieved`}
            />
          </InputGroup>
        </td>
        <td>
          {hasRights && !blocked && (
            <ButtonCustom
              className="close"
              aria-label="Close"
              onClick={() => {
                this.deleteFiguresTd(iterator);
              }}>
              <span aria-hidden="true">&times;</span>
            </ButtonCustom>
          )}
        </td>
      </tr>
    ));
  };

  renderBreakdownInputs = () => {
    const { comment } = this.state;
    const { breakdown } = comment;
    const { hasRights, blocked } = this.props;
    return breakdown.map((item, iterator) => (
      <tr key={`${iterator + 1}_breakdown_tr`}>
        <td key={`${iterator + 1}_breakdown_name_td`}>
          <InputCustom
            placeholder="Intitulé"
            id={`comment.breakdown.${iterator}.name`}
            name={`comment.breakdown.${iterator}.name`}
            value={item.name}
            onChange={this.handleBreakdownName(iterator)}
            minLength="1"
            maxLength="30"
            pattern={Constants.pattern.internationalName}
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.FinancialData.breakdown.name}
          </FormFeedback>
        </td>
        <td key={`${iterator + 1}_breakdown_value_td`}>
          <InputCustom
            placeholder="Valeur"
            id={`comment.breakdown.${iterator}.value`}
            name={`comment.breakdown.${iterator}.value`}
            type="number"
            value={item.value}
            onChange={this.handleBreakdownValue(iterator)}
            min="0"
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.FinancialData.breakdown.value}
          </FormFeedback>
        </td>
        <td>
          {hasRights && !blocked && (
            <ButtonCustom
              className="close"
              aria-label="Close"
              onClick={() => this.deleteBreakdownTd(iterator)}>
              <span aria-hidden="true">&times;</span>
            </ButtonCustom>
          )}
        </td>
      </tr>
    ));
  };

  handleCollapse = tooltip => () => {
    const { collapse } = this.state;
    this.setState(prevState => ({
      collapse: {
        ...prevState.collapse,
        [tooltip]: !collapse[tooltip]
      }
    }));
  };

  render() {
    const {
      hasRights,
      blocked,
      tooltip,
      fieldNames,
      title
    } = this.props;
    const { comment, collapse } = this.state;
    const { breakdown, content, highlight } = comment;
    const style = getComputedStyle(document.body);
    const bootstrapTheme = [
      style.getPropertyValue('--primary'),
      style.getPropertyValue('--secondary'),
      style.getPropertyValue('--info'),
      style.getPropertyValue('--warning'),
      style.getPropertyValue('--danger'),
      style.getPropertyValue('--success'),
      style.getPropertyValue('--light'),
      style.getPropertyValue('--dark')
    ];
    const displayBreakdown =
      Array.isArray(breakdown) &&
      breakdown[0] &&
      _.has(breakdown[0], 'name') &&
      breakdown[0].name !== '' &&
      breakdown[0].value !== 0;
    return (
      <>
        {fieldNames.figures && <Label>{fieldNames.figures}</Label>}
        {tooltip.figures && (
          <small className="form-text text-muted mb-1 mt-0">
            <TruncateCustom
              collapse={collapse.figures}
              tooltip={tooltip.figures}
              handleCollapse={this.handleCollapse('figures')}
            />
          </small>
        )}
        <Table striped className="table-borderless">
          <thead className="border-bottom border-primary">
            <tr>
              <th>Année</th>
              <th>{title}</th>
              <th>Chiffre réalisé ?</th>
            </tr>
          </thead>
          <tbody>{this.renderFiguresInputs()}</tbody>
        </Table>
        {hasRights && !blocked && (
          <ButtonCustom
            color="primary"
            className="mb-3"
            onClick={this.addFiguresTd}>
            Ajouter une année
          </ButtonCustom>
        )}
        <FormGroup>
          {fieldNames.comment && (
            <Label for="comment.content">{fieldNames.comment}</Label>
          )}
          {tooltip.comment && (
            <small className="form-text text-muted mb-1 mt-0">
              <TruncateCustom
                collapse={collapse.comment}
                tooltip={tooltip.comment}
                handleCollapse={this.handleCollapse('comment')}
              />
            </small>
          )}
          <InputCustom
            type="text"
            id="comment.content"
            name="comment.content"
            disabled={!hasRights || blocked}
            value={content}
            onChange={this.handleContent}
            minLength="20"
            maxLength="400"
            required
          />
          <FormFeedback>
            {FrontValidation.FinancialData.comment.content}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          {fieldNames.highlight && (
            <Label for="comment.highlight">
              {fieldNames.highlight}
            </Label>
          )}
          {tooltip.highlight && (
            <small className="form-text text-muted mb-1 mt-0">
              <TruncateCustom
                collapse={collapse.highlight}
                tooltip={tooltip.highlight}
                handleCollapse={this.handleCollapse('highlight')}
              />
            </small>
          )}
          <InputCustom
            type="text"
            id="comment.highlight"
            name="comment.highlight"
            disabled={!hasRights || blocked}
            value={highlight}
            onChange={this.handleHighlight}
            minLength="1"
            maxLength="30"
            pattern={Constants.pattern.internationalName}
          />
          <FormFeedback>
            {FrontValidation.FinancialData.comment.highlight}
          </FormFeedback>
        </FormGroup>
        {!Array.isArray(breakdown) || breakdown.length === 0 ? (
          <>
            {hasRights && !blocked && (
              <FormGroup>
                <ButtonCustom
                  color="primary"
                  className="mb-3"
                  onClick={this.addBreakdown}>
                  Ajouter un graphique
                </ButtonCustom>
              </FormGroup>
            )}
          </>
        ) : (
          <>
            {fieldNames.breakdown && (
              <Label for="comment.breakdown">
                {fieldNames.breakdown}
              </Label>
            )}
            {tooltip.breakdown && (
              <small className="form-text text-muted mb-1 mt-0">
                <TruncateCustom
                  collapse={collapse.breakdown}
                  tooltip={tooltip.breakdown}
                  handleCollapse={this.handleCollapse('breakdown')}
                />
              </small>
            )}
            <Table striped className="table-borderless">
              <thead className="border-bottom border-primary">
                <tr>
                  <th>Intitulé</th>
                  <th>Valeur</th>
                </tr>
              </thead>
              <tbody>{this.renderBreakdownInputs()}</tbody>
            </Table>
            {hasRights && !blocked && (
              <ButtonCustom
                color="primary"
                className="mb-3"
                onClick={this.addBreakdown}>
                Ajouter une valeur
              </ButtonCustom>
            )}
            {displayBreakdown && (
              <div
                className="d-flex justify-content-center mb-3"
                ref={this.breakdownParent}>
                <BreakdownComponent
                  width={350}
                  height={350}
                  values={breakdown}
                  colors={bootstrapTheme}
                  parent={this.breakdownParent.current}
                />
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData
});

export default withRouter(connect(mapStateToProps)(FinancialData));
