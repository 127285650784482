// @flow

// React libs
import React from 'react';
import { Route, Switch } from 'react-router-dom';
// Scenes
import AcceptTosScene from '../../UI/Tos/AcceptTosScene';
import ErrorScene from '../../UI/Error/ErrorScene';
import LoginScene from '../../UI/Login/LoginScene';
import SocialLoginScene from '../../UI/Login/SocialLoginScene';
import RegisterScene from '../../UI/Login/RegisterScene';
import RecoverPasswordScene from '../../UI/Login/RecoverPasswordScene';
import ResetPasswordScene from '../../UI/Login/ResetPasswordScene';
// Common
import Routes from '../../Resources/Common/Routes';
import PrivateRoutes from './PrivateRouterComponent';

function RouterComponent() {
  return (
    <main>
      <Switch>
        {/* LOGIN */}
        <Route
          exact
          path={`/${Routes.routeLogin}`}
          component={LoginScene}
        />
        <Route
          exact
          path={`/${Routes.routeRegister}`}
          component={RegisterScene}
        />
        <Route
          exact
          path={`/${Routes.routeSocialLogin}/:network`}
          component={SocialLoginScene}
        />
        {/* ERROR */}
        <Route
          exact
          path={`/${Routes.routeError}/:code`}
          component={ErrorScene}
        />
        {/* ToS */}
        <Route
          exact
          path={`/${Routes.acceptTos}`}
          component={AcceptTosScene}
        />
        {/* Password */}
        <Route
          exact
          path={`/${Routes.routeResetPassword}`}
          component={ResetPasswordScene}
        />
        <Route
          exact
          path={`/${Routes.routeRecoverPassword}/:uuid/:code`}
          component={RecoverPasswordScene}
        />
        {/* Private Routes */}
        <PrivateRoutes />

        <Route component={ErrorScene} />
      </Switch>
    </main>
  );
}

export default RouterComponent;
