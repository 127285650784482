import React from 'react';
import { Pie } from '@vx/shape';
import { Group } from '@vx/group';
import PropTypes from 'prop-types';
import { LegendOrdinal } from '@vx/legend';
import { scaleOrdinal } from '@vx/scale';
import { Link } from 'react-router-dom';
import Routes from '../../../Resources/Common/Routes';

const value = d => d.value;

export default function BreakdownComponent({
  values,
  height,
  colors,
  parent,
  width,
  activeContainer
}) {
  const finalWidth = parent ? parent.clientWidth / 2 : width;
  const finalHeight = parent ? parent.clientWidth / 2 : height;
  const radius = Math.min(finalWidth, finalHeight) / 2;
  const centerY = finalHeight / 2;
  const centerX = finalWidth / 2;
  const colorScale = scaleOrdinal({
    domain: values.map(v => v.name),
    range: colors
  });

  return (
    <>
      <svg
        width={finalWidth}
        height={finalHeight}
        style={{ flexBasis: '100%' }}>
        <rect
          rx={14}
          width={finalWidth}
          height={finalHeight}
          fill="transparent"
        />
        <Group top={centerY} left={centerX}>
          <Pie
            key={radius}
            data={values}
            pieValue={value}
            outerRadius={radius}
            innerRadius={0}
            cornerRadius={3}
            padAngle={0}>
            {pie =>
              pie.arcs.map((arc, i) => {
                const color = colors[i % colors.length];
                return activeContainer ? (
                  <Link
                    key={`link-${arc.data.name}-${color}`}
                    to={`/${Routes.routeFolder}/${activeContainer}`}>
                    <g
                      id={`browser-${arc.data.name}-${color}`}
                      key={`browser-${arc.data.name}-${color}`}>
                      <path d={pie.path(arc)} fill={color} />
                    </g>
                  </Link>
                ) : (
                  <g key={`browser-${arc.data.name}-${color}`}>
                    <path d={pie.path(arc)} fill={color} />
                  </g>
                );
              })
            }
          </Pie>
        </Group>
      </svg>
      <LegendOrdinal
        className="ml-3"
        direction="column"
        scale={colorScale}
        shape="rect"
      />
    </>
  );
}

BreakdownComponent.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  height: PropTypes.number.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  parent: PropTypes.any,
  width: PropTypes.number,
  activeContainer: PropTypes.string
};

BreakdownComponent.defaultProps = {
  width: undefined,
  parent: undefined,
  activeContainer: undefined
};
