// @flow

import Constants from './Constants';
import Routes from './Routes';

const Common = {
  Constants,
  Routes
};

export default Common;
