// @flow

// Services
import AccountService from '../../Layer/Network/Service/AccountService';
// Common
import Common from '../../Resources/Common';
// Actions
import Constants from '../../Resources/Common/Constants';
import Messages from '../../Resources/Common/Messages';

export const PATCH_AVATAR = 'PATCH_AVATAR';
export const GET_INFOS_ACCOUNT = 'GET_INFOS_ACCOUNT';

const accountInfosDefault = {
  email: '',
  firstName: '',
  lastName: '',
  avatar: '',
  status: {
    hasAcceptedPrerequisites: undefined
  }
};

// Get Account Infos
const getInfosAccountPending = () => ({
  type: GET_INFOS_ACCOUNT,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: accountInfosDefault,
    error: '',
    needAcceptToS: undefined
  }
});
const getInfosAccountSuccess = info => ({
  type: GET_INFOS_ACCOUNT,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: info,
    error: '',
    needAcceptToS: false
  }
});
const getInfosAccountFailure = error => ({
  type: GET_INFOS_ACCOUNT,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: accountInfosDefault,
    error,
    needAcceptToS: true
  }
});

export function getInfosAccountAction() {
  return (dispatch: Function) => {
    dispatch(getInfosAccountPending());
    return AccountService.getAccountInfos()
      .then(data => {
        dispatch(getInfosAccountSuccess(data));
      })
      .catch(err => {
        if (
          err.message ===
          Constants.API_ERRORS.USER.MUST_ACCEPT_PREREQUISITES
        ) {
          dispatch(getInfosAccountFailure('prerequisites'));
          return;
        }
        dispatch(getInfosAccountFailure(err.message));
      });
  };
}

const patchAvatarPending = () => ({
  type: PATCH_AVATAR,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    success: false,
    error: ''
  }
});
const patchAvatarSuccess = () => ({
  type: PATCH_AVATAR,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    success: true,
    error: ''
  }
});
const patchAvatarFailure = error => ({
  type: PATCH_AVATAR,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    success: false,
    error
  }
});

export function patchAvatarAction(file: FormData) {
  return (dispatch: Function) => {
    dispatch(patchAvatarPending());
    return AccountService.patchAvatar(file)
      .then(() => {
        dispatch(patchAvatarSuccess());
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.ACCOUNT.DOCUMENT_FORMAT_ERROR:
            error = Messages.avatarWrongFormat;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(patchAvatarFailure(error));
      });
  };
}
