// @flow

// Utils
import NetworkUrls from '../NetworkUrls';
import NetworkUtils from '../NetworkUtils';

export default class DashboardSettingsService {
  static getContainerRights(uuid: string) {
    return NetworkUtils.get({
      url: NetworkUrls.getContainerRights(uuid)
    });
  }

  static postContainerInvitation(
    email: string,
    rights: Array<any>,
    uuid: string
  ) {
    return NetworkUtils.post({
      url: NetworkUrls.postContainerInvitation(uuid),
      data: {
        email,
        rights
      }
    });
  }

  static getContainerInvitations(uuid: string) {
    return NetworkUtils.get({
      url: NetworkUrls.getContainerInvitations(uuid)
    });
  }

  static deleteContainerInvitations(
    containerId: string,
    invitationUuid: string
  ) {
    return NetworkUtils.delete({
      url: NetworkUrls.deleteContainerInvitations(
        containerId,
        invitationUuid
      )
    });
  }

  static patchUserRights({
    uuid,
    userId,
    rights
  }: {
    uuid: string,
    userId: string,
    rights: Array<string>
  }) {
    const data = [
      {
        userId,
        rights
      }
    ];
    return NetworkUtils.patch({
      url: NetworkUrls.patchUserRights(uuid),
      data
    });
  }

  static getContainerData(uuid: string) {
    return NetworkUtils.get({
      url: NetworkUrls.getContainerData(uuid)
    });
  }
}
