// @flow
// React Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
// Components
import {
  FormGroup,
  Label,
  FormFeedback,
  InputGroup
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import InputCustom from '../../../InputCustom';
import Messages from '../../../../../Resources/Common/Messages';
import Tooltip from '../../../../../Resources/Common/Tooltip';
import TruncateCustom from '../../../TruncateCustom';
// Actions
import { getContextManifestAction } from '../../../../../Business/actions/FolderActions';

type Props = {
  changeProposalsData: Array<any>,
  contextManifestData: {
    version: string,
    sectors: Array<{ id: number, name: string, color: string }>,
    taxItems: Array<{
      id: number,
      name: string,
      shortName: string,
      percentage: number,
      maxExempted: number,
      allowAttestation: boolean
    }>
  },
  dispatch: Function,
  hasRights: boolean,
  blocked: boolean,
  proposalUid: string
};

type State = {
  main: number,
  extra: Array<number>,
  collapse: {
    main: boolean,
    extra: boolean
  }
};

class InfoSector extends Component<Props, State> {
  constructor(props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = _.pick(
      _.defaults(_.get(changeProposalsFiltered, '[0].content'), {
        main: 0,
        extra: []
      }),
      ['main', 'extra']
    );
    this.state.collapse = {
      main: true,
      extra: true
    };
  }

  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(getContextManifestAction());
  };

  handleMain = e => {
    this.setState({ main: e.target.value });
  };

  handleExtra = e => {
    const { extra } = this.state;

    const extraValue = extra.slice();

    if (e.target.checked) {
      extraValue.push(e.target.value);
    } else {
      _.remove(extraValue, e.target.value);
    }
    this.setState({ extra: extraValue });
  };

  handleCollapse = tooltip => () => {
    const { collapse } = this.state;
    this.setState(prevState => ({
      collapse: {
        ...prevState.collapse,
        [tooltip]: !collapse[tooltip]
      }
    }));
  };

  render() {
    const { main, extra, collapse } = this.state;
    const { contextManifestData, hasRights, blocked } = this.props;
    return (
      <>
        {contextManifestData && contextManifestData.sectors ? (
          <>
            <FormGroup>
              <Label for="main">Secteur principal</Label>
              <small className="form-text text-muted mb-1 mt-0">
                <TruncateCustom
                  collapse={collapse.main}
                  tooltip={Tooltip.ProjectBasicInfoSector.main}
                  handleCollapse={this.handleCollapse('main')}
                />
              </small>
              <InputCustom
                placeholder="Nom du projet"
                type="select"
                id="main"
                name="main"
                value={main}
                onChange={this.handleMain}
                minLength="3"
                maxLength="100"
                disabled={!hasRights || blocked}
                required>
                <option value="">--Choisir--</option>
                {contextManifestData.sectors.map(sector => (
                  <option
                    key={`${sector.id}_option`}
                    value={sector.id}>
                    {sector.name}
                  </option>
                ))}
              </InputCustom>
              <FormFeedback>
                {Messages.passwordFrontValidation}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="extra">Secteur secondaire</Label>
              <small className="form-text text-muted mb-1 mt-0">
                <TruncateCustom
                  collapse={collapse.extra}
                  tooltip={Tooltip.ProjectBasicInfoSector.extra}
                  handleCollapse={this.handleCollapse('extra')}
                />
              </small>
              {contextManifestData.sectors.map((sector, i) => (
                <InputGroup
                  key={`${sector.id}_group`}
                  className="custom-control custom-checkbox">
                  <InputCustom
                    type="checkbox"
                    key={`${sector.id}_option`}
                    className="custom-control-input"
                    id={`${sector.id}_option`}
                    name={`extra_box[${i}]`}
                    value={sector.id}
                    onChange={this.handleExtra}
                    disabled={!hasRights || blocked}
                    defaultChecked={extra.some(e => e === sector.id)}
                  />
                  <Label
                    className="custom-control-label d-flex"
                    for={`${sector.id}_option`}>
                    {sector.name}
                  </Label>
                </InputGroup>
              ))}
              <InputCustom type="hidden" name="extra" value={extra} />
              <FormFeedback>
                {Messages.emailFrontValidation}
              </FormFeedback>
            </FormGroup>
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData,
  contextManifestData: state.folder.getContextManifestData
});

export default withRouter(connect(mapStateToProps)(InfoSector));
