// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import ReactPlaceholder from 'react-placeholder';
import { confirmAlert } from 'react-confirm-alert';
import { Redirect, Link } from 'react-router-dom';
import _ from 'lodash';
// Components
import { Form, FormGroup, Row, Col, Alert } from 'reactstrap';
import Template from '../../Components/Template/TemplateComponent';
import AlertCustom from '../../Components/Components/AlertCustom';
import ButtonCustom from '../../Components/Components/ButtonCustom';
// Actions
import {
  getChangeProposalsAction,
  postChangeProposalsAction,
  postChangeContainerDocumentAction,
  getContainerChangeAction
} from '../../Business/actions/FolderActions';
// Utilities
import Routes from '../../Resources/Common/Routes';
import Messages from '../../Resources/Common/Messages';
import {
  getProposalsObject,
  getVisibleObject,
  parseField
} from '../../Resources/Common/Utilities';
// Fields
import ProjectBasicInfoName from '../../Components/Components/Fields/Project/Basic/InfoName';
import ProposalStatusBadge from '../../Components/Components/Menu/ProposalStatusBadge';
import Constants from '../../Resources/Common/Constants';
import DocumentProjectArticlesOfIncorporation from '../../Components/Components/Fields/Document/Project/ArticlesOfIncorporation';
import DocumentProjectFinancialBp from '../../Components/Components/Fields/Document/Project/FinancialBp';
import DocumentProjectFinancialStatements from '../../Components/Components/Fields/Document/Project/FinancialStatements';
import DocumentProjectFounderCv from '../../Components/Components/Fields/Document/Project/FounderCv';
import DocumentProjectGeneralAssemblyReport from '../../Components/Components/Fields/Document/Project/GeneralAssemblyReport';
import DocumentProjectKbis from '../../Components/Components/Fields/Document/Project/Kbis';
import DocumentProjectPitchDeck from '../../Components/Components/Fields/Document/Project/PitchDeck';
import DocumentProjectShareholdersAgreement from '../../Components/Components/Fields/Document/Project/ShareholdersAgreement';
import DocumentProjectShareholdersList from '../../Components/Components/Fields/Document/Project/ShareholdersList';
import EntityBasicInfo from '../../Components/Components/Fields/Entity/BasicInfo';
import ProjectBasicInfoStructureCap from '../../Components/Components/Fields/Project/Basic/InfoStructureCap';
import ProjectBasicInfoSector from '../../Components/Components/Fields/Project/Basic/InfoSector';
import ProjectOfferActionPlan from '../../Components/Components/Fields/Project/Offer/ActionPlan';
import ProjectOfferAssets from '../../Components/Components/Fields/Project/Offer/Assets';
import ProjectOfferBarriers from '../../Components/Components/Fields/Project/Offer/Barriers';
import ProjectOfferBusinessModel from '../../Components/Components/Fields/Project/Offer/BusinessModel';
import ProjectOfferCommercialStrat from '../../Components/Components/Fields/Project/Offer/CommercialStrat';
import ProjectOfferCompetition from '../../Components/Components/Fields/Project/Offer/Competition';
import ProjectOfferConcept from '../../Components/Components/Fields/Project/Offer/Concept';
import ProjectOfferDescription from '../../Components/Components/Fields/Project/Offer/Description';
import ProjectOfferDifferenciation from '../../Components/Components/Fields/Project/Offer/Differenciation';
import ProjectOfferGoals from '../../Components/Components/Fields/Project/Offer/Goals';
import ProjectOfferHistory from '../../Components/Components/Fields/Project/Offer/History';
import ProjectOfferMarket from '../../Components/Components/Fields/Project/Offer/Market';
import ProjectOfferPartners from '../../Components/Components/Fields/Project/Offer/Partners';
import ProjectOfferProblem from '../../Components/Components/Fields/Project/Offer/Problem';
import ProjectOfferProductsServices from '../../Components/Components/Fields/Project/Offer/ProductsServices';
import ProjectOfferRisks from '../../Components/Components/Fields/Project/Offer/Risks';
import ProjectOfferStrengths from '../../Components/Components/Fields/Project/Offer/Strengths';
import ProjectOfferSuccesses from '../../Components/Components/Fields/Project/Offer/Successes';
import ProjectOfferUseOfFunds from '../../Components/Components/Fields/Project/Offer/UseOfFunds';
import ProjectOfferTagline from '../../Components/Components/Fields/Project/Offer/Tagline';
import ProjectFinancialDataClientsB2B from '../../Components/Components/Fields/Project/FinancialData/ClientsB2B';
import ProjectFinancialDataClientsB2C from '../../Components/Components/Fields/Project/FinancialData/ClientsB2C';
import ProjectOfferPeople from '../../Components/Components/Fields/Project/Offer/People/People';
import ProjectMediaSocialNetwork from '../../Components/Components/Fields/Project/MediaSocialNetwork';
import ProjectMediaDisplay from '../../Components/Components/Fields/Project/MediaDisplay';
import ProjectMediaVideo from '../../Components/Components/Fields/Project/MediaVideo';
import ProjectFinancialDataEbitda from '../../Components/Components/Fields/Project/FinancialData/Ebitda';
import ProjectFinancialDataProfitLoss from '../../Components/Components/Fields/Project/FinancialData/ProfitLoss';
import ProjectFinancialDataRevenue from '../../Components/Components/Fields/Project/FinancialData/Revenue';
import ProjectFinancialDataUsers from '../../Components/Components/Fields/Project/FinancialData/Users';
import ProjectFinancialDataWorkforce from '../../Components/Components/Fields/Project/FinancialData/Workforce';
import ProjectFeedback from '../../Components/Components/Fields/Project/Feedback/Feedback';
import ProjectFinancingHistory from '../../Components/Components/Fields/Project/FinancingHistory/FinancingHistory';
import ProjectOngoingFundraise from '../../Components/Components/Fields/Project/OngoingFundraise/OngoingFundraise';

type Props = {
  activeContainer: string,
  changeProposalsData: Array<any>,
  dispatch: Function,
  history: Object,
  location: Object,
  match: Object,
  getContainerChangeError: string,
  getChangeProposalsError: string,
  postChangeProposalsError: string,
  postChangeProposalsSuccess: boolean,
  isLoadingPostChangeProposalsData: boolean,
  getChangeProposalsSuccess: boolean,
  setSidebarProposals: Array<Object>,
  postContainerDocumentError: string,
  postContainerDocumentSuccess: boolean,
  postContainerDocument: Object,
  isLoadingPostContainerDocument: boolean,
  userRights: Array<any>,
  setSidebarProposalsSuccess: boolean,
  getContainerChangeData: Object
};

type State = {
  visibleObject: {
    getContainerChangeError: boolean,
    getChangeProposalsError: boolean,
    postChangeProposalsError: boolean,
    postChangeProposalsSuccess: boolean,
    postContainerDocumentError: boolean,
    validationError: boolean
  },
  submitStatus: number,
  blocked: boolean,
  canBeSubmitted: boolean,
  form: Object,
  documentsNames: Object,
  postedDocumentsUuid: Array<{ uuid: string }>,
  documentsToPost: Array<any>,
  formReadyToPost: boolean,
  firstDocumentSubmited: boolean,
  validationError: string,
  mustRedirect: boolean,
  currentProposal: Object | undefined
};

const components = {
  ProjectBasicInfoName,
  DocumentProjectArticlesOfIncorporation,
  DocumentProjectFinancialBp,
  DocumentProjectFinancialStatements,
  DocumentProjectFounderCv,
  DocumentProjectGeneralAssemblyReport,
  DocumentProjectKbis,
  DocumentProjectPitchDeck,
  DocumentProjectShareholdersAgreement,
  DocumentProjectShareholdersList,
  EntityBasicInfo,
  ProjectBasicInfoStructureCap,
  ProjectBasicInfoSector,
  ProjectOfferActionPlan,
  ProjectOfferAssets,
  ProjectOfferBarriers,
  ProjectOfferBusinessModel,
  ProjectOfferCommercialStrat,
  ProjectOfferCompetition,
  ProjectOfferConcept,
  ProjectOfferDescription,
  ProjectOfferDifferenciation,
  ProjectOfferGoals,
  ProjectOfferHistory,
  ProjectOfferMarket,
  ProjectOfferPartners,
  ProjectOfferProblem,
  ProjectOfferProductsServices,
  ProjectOfferRisks,
  ProjectOfferSuccesses,
  ProjectOfferUseOfFunds,
  ProjectOfferStrengths,
  ProjectOfferTagline,
  ProjectFinancialDataClientsB2B,
  ProjectFinancialDataClientsB2C,
  ProjectMediaSocialNetwork,
  ProjectMediaDisplay,
  ProjectMediaVideo,
  ProjectFinancialDataEbitda,
  ProjectFinancialDataProfitLoss,
  ProjectFinancialDataRevenue,
  ProjectFinancialDataUsers,
  ProjectFinancialDataWorkforce,
  ProjectOfferPeople,
  ProjectFeedback,
  ProjectFinancingHistory,
  ProjectOngoingFundraise
};

class FolderProposalScene extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      visibleObject: {
        getContainerChangeError: false,
        getChangeProposalsError: false,
        postChangeProposalsError: false,
        postChangeProposalsSuccess: false,
        postContainerDocumentError: false,
        validationError: false
      },
      submitStatus: 0,
      canBeSubmitted: false,
      blocked: false,
      form: {},
      documentsNames: {},
      postedDocumentsUuid: [],
      documentsToPost: [],
      formReadyToPost: false,
      firstDocumentSubmited: false,
      mustRedirect: false,
      validationError: '',
      currentProposal: undefined
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const {
      activeContainer,
      dispatch,
      match,
      changeProposalsData
    } = this.props;
    const { kind, proposalUid } = match.params;
    if (activeContainer) {
      dispatch(getContainerChangeAction(activeContainer));
      dispatch(
        getChangeProposalsAction({
          containerId: activeContainer,
          kind,
          status: undefined
        })
      );
    }
    if (
      Array.isArray(changeProposalsData) &&
      !_.isEmpty(changeProposalsData)
    ) {
      this.setState({
        currentProposal: proposalUid
          ? changeProposalsData.find(p => p.uuid === proposalUid)
          : changeProposalsData[0]
      });
    }
    this.setState({ validationError: false });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      activeContainer,
      dispatch,
      match,
      postChangeProposalsSuccess,
      postChangeProposalsError,
      postContainerDocument,
      postContainerDocumentSuccess,
      isLoadingPostContainerDocument,
      changeProposalsData
    } = this.props;
    const {
      documentsToPost,
      form,
      formReadyToPost,
      submitStatus,
      firstDocumentSubmited,
      currentProposal
    } = this.state;
    const { kind, proposalUid } = match.params;
    this.setVisibleAlerts(prevProps, prevState);
    if (
      !_.isEqual(changeProposalsData, prevProps.changeProposalsData)
    ) {
      if (changeProposalsData[0]) {
        this.setState({
          currentProposal: proposalUid
            ? changeProposalsData.find(p => p.uuid === proposalUid)
            : changeProposalsData[0]
        });
      } else {
        this.setState({
          currentProposal: undefined
        });
      }
    }
    if (!_.isEqual(currentProposal, prevState.currentProposal)) {
      this.setState({
        blocked: currentProposal
          ? currentProposal.status ===
              Constants.PROPOSAL_STATUS.SUBMITTED ||
            currentProposal.status ===
              Constants.PROPOSAL_STATUS.ACCEPTED
          : false
      });
    }
    if (
      postChangeProposalsSuccess &&
      postChangeProposalsSuccess !==
        prevProps.postChangeProposalsSuccess
    ) {
      this.setState({
        mustRedirect: !!proposalUid,
        documentsToPost: [],
        documentsNames: {},
        form: {}
      });
      dispatch(
        getChangeProposalsAction({
          containerId: activeContainer,
          kind,
          status: undefined
        })
      );
    }
    if (kind !== prevProps.match.params.kind) {
      this.setState({
        visibleObject: {
          getContainerChangeError: false,
          getChangeProposalsError: false,
          postChangeProposalsError: false,
          postChangeProposalsSuccess: false,
          postContainerDocumentError: false,
          validationError: false
        },
        validationError: '',
        mustRedirect: !!proposalUid,
        documentsToPost: [],
        documentsNames: {},
        form: {}
      });
      dispatch(
        getChangeProposalsAction({
          containerId: activeContainer,
          kind,
          status: undefined
        })
      );
    }
    if (
      postContainerDocumentSuccess &&
      postContainerDocument &&
      !_.isEqual(
        postContainerDocument,
        prevProps.postContainerDocument
      ) &&
      !_.isEmpty(documentsToPost)
    ) {
      this.updatePostedDocumentsUuid();
      this.updateDocumentsToPost();
    }
    if (
      !_.isEmpty(documentsToPost) &&
      !isLoadingPostContainerDocument &&
      (!firstDocumentSubmited ||
        !_.isEqual(documentsToPost, prevState.documentsToPost))
    ) {
      this.submitDocument(documentsToPost[0]);
    }
    if (
      (postChangeProposalsSuccess || postChangeProposalsError) &&
      documentsToPost.length === 0 &&
      formReadyToPost
    ) {
      this.setState({
        formReadyToPost: false,
        form: {},
        blocked:
          submitStatus === Constants.PROPOSAL_STATUS.SUBMITTED ||
          submitStatus === Constants.PROPOSAL_STATUS.ACCEPTED,
        documentsNames: {},
        postedDocumentsUuid: [],
        documentsToPost: [],
        firstDocumentSubmited: false
      });
    }
    if (
      !_.isEqual(form, prevState.form) &&
      Object.entries(form).length !== 0
    ) {
      this.setState({ formReadyToPost: true });
    }
    if (documentsToPost.length === 0 && formReadyToPost) {
      this.submitForm();
    }
  };

  updateDocumentsToPost = () => {
    const { documentsToPost } = this.state;
    const newDocumentsToPost = _.clone(documentsToPost);
    newDocumentsToPost.shift();
    this.setState({
      documentsToPost: newDocumentsToPost
    });
  };

  updatePostedDocumentsUuid = () => {
    const { postedDocumentsUuid } = this.state;
    const { postContainerDocument } = this.props;
    const newPostedDocumentsUuid = _.clone(postedDocumentsUuid);
    newPostedDocumentsUuid.push(postContainerDocument);
    this.setState({
      postedDocumentsUuid: newPostedDocumentsUuid
    });
  };

  unblockSubmit = e => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="modal d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog mt-5" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <ButtonCustom
                  className="close"
                  onClick={onClose}
                  aria-label="Close">
                  <span aria-hidden="true">x</span>
                </ButtonCustom>
                <p>{Messages.unblockProposalConfirmMessage}</p>
              </div>
              <div className="modal-footer">
                <ButtonCustom
                  color="primary"
                  className="btn btn-primary"
                  type="submit"
                  onClick={() => {
                    this.submitHandle(
                      Constants.PROPOSAL_STATUS.DRAFT,
                      true,
                      e
                    );
                    onClose();
                  }}>
                  Oui
                </ButtonCustom>
                <ButtonCustom
                  className="btn btn-secondary"
                  onClick={onClose}>
                  Non
                </ButtonCustom>
              </div>
            </div>
          </div>
        </div>
      )
    });
  };

  submitHandle = (status, canBeSubmitted, e = undefined) => {
    if (e) {
      this.setState(
        {
          submitStatus: status,
          canBeSubmitted
        },
        () => {
          this.handleForm(e);
        }
      );
    } else {
      this.setState({
        submitStatus: status,
        canBeSubmitted
      });
    }
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        validationError: false
      },
      validationError: ''
    }));
  };

  handleForm = e => {
    const {
      documentsNames,
      documentsToPost,
      canBeSubmitted
    } = this.state;
    e.persist();
    e.preventDefault();
    if (!canBeSubmitted) {
      this.unblockSubmit(e);
      return;
    }
    const { match } = this.props;
    const { kind } = match.params;
    let newDocumentsNames = _.clone(documentsNames);
    const newDocumentsToPost = documentsToPost;
    if (e.target.checkValidity()) {
      this.setState({ validationError: '' });
      let content = {};
      [].forEach.call(e.target.elements, el => {
        if (el.type === 'select-multiple') {
          content[el.name] = this.getMultipleValue(el);
        } else {
          // Documents Submit
          if (_.includes(el.name, 'document') && el.value !== '') {
            // get reals documents names
            const documentNameArray = this.getRealsDocumentsNames(
              el.name,
              e.target.elements
            );
            if (
              el.type === 'hidden' &&
              el.name !== 'defaultDocument' &&
              !_.includes(el.name, 'document')
            ) {
              content = _.set(
                content,
                el.name,
                parseField(kind, el.name, el.value)
              );
            }
            if (documentNameArray) {
              documentNameArray.forEach(documentName => {
                newDocumentsNames = _.set(
                  newDocumentsNames,
                  documentName,
                  el.value
                );
              });
            }
            newDocumentsToPost.push(el.name);
          }
          if (
            (el.name && el.value) ||
            el.name === 'defaultDocument'
          ) {
            content = _.set(
              content,
              el.name,
              parseField(kind, el.name, el.value)
            );
          }
        }
      });
      this.setState({
        documentsToPost: newDocumentsToPost,
        documentsNames: newDocumentsNames,
        form: content
      });
      // Financial Datas particular case
      if ('figures' in content) {
        if (content.figures.some(f => f.year)) {
          content.figures = content.figures.sort(
            (a, b) => a.year - b.year
          );
        }
      }
    } else {
      this.setState({
        validationError: Messages.validationError
      });
    }
    this.validateMarkdowns();
    e.target.classList.add('was-validated');
  };

  validateMarkdowns = () => {
    const markdownDivs = document.getElementsByClassName('markdown');
    Array.from(markdownDivs).forEach((markdownDiv, index) => {
      // check length of textarea, and maxLength and minLength
      if (!markdownDiv) {
        return;
      }
      const message = document.getElementsByClassName('validation')[
        index
      ];
      const textarea = document.getElementsByClassName(
        'markdown-textarea'
      )[index];
      const value = textarea.textContent;
      const minLength =
        textarea.minLength && textarea.minLength !== -1
          ? textarea.minLength
          : 0;
      const maxLength =
        textarea.maxLength && textarea.maxLength !== -1
          ? textarea.maxLength
          : 999999999;
      if (value.length >= minLength && value.length <= maxLength) {
        textarea.setCustomValidity('');
        message.style.display = 'none';
        markdownDiv.classList.add('border');
        markdownDiv.classList.add('border-success');
        markdownDiv.classList.remove('border-danger');
      } else {
        textarea.setCustomValidity('error');
        message.style.display = 'block';
        markdownDiv.classList.remove('border-success');
        markdownDiv.classList.add('border');
        markdownDiv.classList.add('border-danger');
      }
    });
  };

  getRealsDocumentsNames = (name, formElements) => {
    const n = [];
    [].forEach.call(formElements, el => {
      if (el.type === 'hidden') {
        if (_.includes(el.name, name.split('.')[1])) {
          n.push(el.name);
        }
      }
    });
    return n;
  };

  submitDocument = name => {
    const { dispatch, activeContainer } = this.props;
    const nameArray = name.split('.');
    const id = nameArray[2]
      ? `${nameArray[2]}${nameArray[1]}`
      : nameArray[1] || nameArray[0];
    const documentNode = document.querySelector(`#${id}`);
    if (documentNode && documentNode instanceof HTMLInputElement) {
      const selectedFile = documentNode.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append('document', selectedFile);
        dispatch(
          postChangeContainerDocumentAction({
            file: formData,
            containerId: activeContainer
          })
        );
      }
    }
    this.setState({ firstDocumentSubmited: true });
  };

  submitForm = () => {
    const { dispatch, activeContainer, match } = this.props;
    const {
      submitStatus,
      form,
      documentsNames,
      postedDocumentsUuid
    } = this.state;
    const { kind } = match.params;
    const content = {};
    let hasDefaultdocument = false;

    // __WARNING__: trouver les raisons aux bugs évoqués par
    // les tickets SWFS-178, SWFS-181 est devenu extrêmement difficile.
    // En l'état la solution la plus rapide est de gérer ces deux propositions
    // de changement directement, sans généricité.

    // SWFS-181
    if (kind === 'PROJECT:MEDIA:DISPLAY') {
      Object.keys(form.document).forEach((logoKey, i) => {
        content[logoKey] = postedDocumentsUuid[i].uuid;
      });
    } else {
      Object.entries(form).forEach(([formKey, formValue]) => {
        // SWFS-178
        if (
          kind === 'PROJECT:MEDIA:SOCIAL_NETWORK' &&
          formKey === 'document'
        ) {
          content.media = {};
          Object.keys(form.document).forEach((logoKey, i) => {
            content.media[logoKey] = postedDocumentsUuid[i].uuid;
          });
          return;
        }
        // SWFS-212
        if (kind === 'ENTITY:BASIC_INFO' && form.document) {
          Object.keys(form.document).forEach((logoKey, i) => {
            content[logoKey] = postedDocumentsUuid[i].uuid;
          });
        }
        // Documents gestion
        content[formKey] = formValue;
        Object.entries(documentsNames).forEach(
          ([namesKey, namesValue], documentsCounter) => {
            // Documents is an object
            if (namesValue && !_.isEmpty(postedDocumentsUuid)) {
              let j = 0;
              Object.entries(namesValue).forEach(
                ([namesItemKey, namesItemValue], i) => {
                  if (Object.keys(namesItemValue).length > 1) {
                    Object.keys(namesItemValue).forEach(
                      namesItemValueKey => {
                        content[formKey] = content[formKey] || {};
                        content[formKey][namesItemKey] =
                          content[formKey][namesItemKey] || {};
                        content[formKey][namesItemKey][
                          namesItemValueKey
                        ] = postedDocumentsUuid[j]
                          ? postedDocumentsUuid[j].uuid
                          : content[formKey][namesItemKey][
                              namesItemValueKey
                            ];
                        j += 1;
                      }
                    );
                  } else if (
                    _.isPlainObject(namesItemValue) &&
                    Object.keys(namesItemValue)[0]
                  ) {
                    content[formKey] = content[formKey] || {};
                    content[formKey][namesItemKey] =
                      content[formKey][namesItemKey] || {};
                    content[formKey][namesItemKey][
                      Object.keys(namesItemValue)[0]
                    ] =
                      postedDocumentsUuid[i].uuid ||
                      content[formKey][namesItemKey][
                        Object.keys(namesItemValue)[0]
                      ];
                  } else if (namesKey === formKey) {
                    if (postedDocumentsUuid[i]) {
                      content[formKey] = content[formKey] || {};
                      if (_.isPlainObject(content[formKey])) {
                        content[formKey][namesItemKey] =
                          postedDocumentsUuid[i].uuid ||
                          content[formKey][namesItemKey];
                      } else {
                        content[formKey] =
                          postedDocumentsUuid[i].uuid ||
                          content[formKey];
                      }
                    } else if (_.isPlainObject(content[formKey])) {
                      // Names key has correspondance but not associated postedDocument
                      content[formKey][namesItemKey] =
                        formValue[namesItemKey] ||
                        content[formKey][namesItemKey];
                    }
                  } else if (
                    formKey !== 'document' &&
                    formValue !== ''
                  ) {
                    content[formKey] = content[formKey] || {};
                    content[formKey] = formValue;
                  }
                }
              );
            } else if (
              namesKey === formKey &&
              !_.isEmpty(postedDocumentsUuid)
            ) {
              content[formKey] =
                (postedDocumentsUuid[documentsCounter] &&
                  postedDocumentsUuid[documentsCounter].uuid) ||
                content[formKey];
            } else if (
              formKey !== 'document' &&
              formValue !== '' &&
              (!content[formKey] || content[formKey] === undefined)
            ) {
              content[formKey] = formValue;
            }
          }
        );
        if (formKey === 'defaultDocument') {
          hasDefaultdocument = true;
          content.document = !_.isEmpty(postedDocumentsUuid)
            ? postedDocumentsUuid[0].uuid
            : formValue;
          delete content.defaultDocument;
        }
        // Special case of sector checkboxes
        if (formKey === 'extra') {
          content.extra = formValue.split(',').map(Number);
          delete content.extra_box;
        }
      });
      if (!hasDefaultdocument && content.document) {
        delete content.document;
      }
    }

    // There are cases where we submit multiple times
    // to prevent that we define `formReadyToPost` to false
    // it seems to do the job.
    // @see [SWFS-162]
    this.setState({ formReadyToPost: false });

    dispatch(
      postChangeProposalsAction({
        containerId: activeContainer,
        kind,
        status: submitStatus,
        content
      })
    );
  };

  getMultipleValue = select => {
    const options = select && select.options;

    const extraValue = [].filter
      .call(options, opt => opt.selected)
      .map(o => Number(o.value) || o.value || o.text);

    return extraValue;
  };

  getFields = (kind, hasRights, blocked, proposalUid?) => {
    const { TagName } = getProposalsObject()[kind];
    const fieldComponents =
      TagName &&
      React.createElement(components[TagName], {
        hasRights,
        blocked,
        proposalUid
      });
    return fieldComponents || null;
  };

  onDismiss = target => {
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        [target]: false
      },
      [target]: ''
    }));
  };

  displayAlert = () => {
    const {
      getContainerChangeError,
      getChangeProposalsError,
      postChangeProposalsError,
      postChangeProposalsSuccess,
      postContainerDocumentError
    } = this.props;
    const { validationError, visibleObject } = this.state;
    const alert = [];
    let key = 0;

    if (getContainerChangeError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="getContainerChangeError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={getContainerChangeError}
          visible={visibleObject.getContainerChangeError}
        />
      );
    }
    if (getChangeProposalsError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="getChangeProposalsError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={getChangeProposalsError}
          visible={visibleObject.getChangeProposalsError}
        />
      );
    }
    if (postChangeProposalsError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="postChangeProposalsError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={postChangeProposalsError}
          visible={visibleObject.postChangeProposalsError}
        />
      );
    }
    if (postChangeProposalsSuccess) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="postChangeProposalsSuccess"
          color="success"
          onDismiss={this.onDismiss}
          errorMessage={Messages.postChangeProposalsSuccess}
          visible={visibleObject.postChangeProposalsSuccess}
        />
      );
    }
    if (postContainerDocumentError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="postContainerDocumentError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={postContainerDocumentError}
          visible={visibleObject.postContainerDocumentError}
        />
      );
    }
    if (validationError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="validationError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={validationError}
          visible={visibleObject.validationError}
        />
      );
    }
    return alert;
  };

  setVisibleAlerts = (prevProps, prevState) => {
    const errorArray = [
      'getContainerChangeError',
      'getChangeProposalsError',
      'postChangeProposalsError',
      'postChangeProposalsSuccess',
      'postContainerDocumentError',
      'validationError'
    ];
    const { props, state } = this;
    const {
      getContainerChangeError,
      getChangeProposalsError,
      postChangeProposalsError,
      postChangeProposalsSuccess,
      postContainerDocumentError
    } = this.props;
    const { validationError } = this.state;
    if (
      getContainerChangeError !== prevProps.getContainerChangeError ||
      getChangeProposalsError !== prevProps.getChangeProposalsError ||
      postChangeProposalsError !==
        prevProps.postChangeProposalsError ||
      postChangeProposalsSuccess !==
        prevProps.postChangeProposalsSuccess ||
      postContainerDocumentError !==
        prevProps.postContainerDocumentError ||
      validationError !== prevState.validationError
    ) {
      const newVisibleObject = getVisibleObject(
        props,
        prevProps,
        state,
        prevState,
        errorArray
      );
      this.setState({ visibleObject: newVisibleObject });
    }
  };

  render() {
    const {
      location,
      history,
      match,
      getChangeProposalsSuccess,
      isLoadingPostChangeProposalsData,
      setSidebarProposals,
      setSidebarProposalsSuccess,
      activeContainer
    } = this.props;
    const { kind, proposalUid } = match.params;
    const { blocked, mustRedirect, currentProposal } = this.state;
    const { section } = getProposalsObject()[kind];
    const { userRights } = this.props;

    if (
      setSidebarProposalsSuccess &&
      _.isEmpty(setSidebarProposals.find(p => p.kind === kind))
    ) {
      return <Redirect to={`/${Routes.ErrorScene}`} />;
    }

    const hasRights = userRights.some(
      right =>
        right === Constants.SECTION_RIGHTS[section] ||
        right === Constants.RIGHTS_ARRAY[0]
    );
    const proposalToDisplay = setSidebarProposals.find(
      p => p.kind === kind
    );
    const status = currentProposal
      ? currentProposal.status
      : Constants.PROPOSAL_STATUS.EMPTY;
    const submitDisabled =
      (status === Constants.PROPOSAL_STATUS.SUBMITTED ||
        status === Constants.PROPOSAL_STATUS.ACCEPTED) &&
      blocked;
    const date =
      currentProposal &&
      format(
        new Date(currentProposal.creationDate),
        'DD/MM/YYYY HH:mm'
      );

    return (
      <>
        {mustRedirect && (
          <Redirect
            to={`/${Routes.routeFolder}/${activeContainer}/${
              Routes.routeProposals
            }/${kind}`}
          />
        )}
        <Template
          title={getProposalsObject()[kind].section}
          subtitle={getProposalsObject()[kind].title}
          menu={Routes.routeFolder}
          currentPath={location}
          history={history}>
          <ReactPlaceholder
            className="my-3"
            ready={getChangeProposalsSuccess}
            type="text"
            showLoadingAnimation
            rows={8}
            color={window
              .getComputedStyle(document.body)
              .getPropertyValue('--light')}>
            <Row className="text-center mb-5">
              <Col md={{ size: 6, offset: 3 }}>
                <ProposalStatusBadge
                  proposal={proposalToDisplay}
                  asTitle
                  currentProposal={currentProposal}
                />
              </Col>
              {currentProposal && date && (
                <Col md={{ size: 6, offset: 3 }}>
                  <span>
                    <small className="text-muted">
                      <Link
                        to={`/${
                          Routes.routeDashboard
                        }/${activeContainer}/${
                          Routes.routeLogs
                        }/${kind}`}>
                        {`Dernière version : ${
                          currentProposal.author.firstName
                        } ${currentProposal.author.lastName.charAt(
                          0
                        )}. ${date}`}
                      </Link>
                    </small>
                  </span>
                </Col>
              )}
            </Row>
            <Row className="mb-3">
              <Col md={{ size: 6, offset: 3 }}>
                {this.displayAlert()}
                <Form
                  className="d-flex flex-column mb-3"
                  onSubmit={e => {
                    this.handleForm(e);
                  }}
                  ref={this.form}
                  noValidate>
                  {currentProposal && currentProposal.moderation && (
                    <Alert color="danger" className="text-center">
                      <p className="mb-0">
                        {
                          Constants.moderationStrings[
                            currentProposal.moderation.rejectedFor
                          ]
                        }
                      </p>
                      <small>
                        {currentProposal.moderation.comment}
                      </small>
                    </Alert>
                  )}
                  {this.getFields(
                    kind,
                    hasRights,
                    blocked,
                    proposalUid
                  )}
                  <FormGroup className="align-items-center d-flex justify-content-between">
                    {!submitDisabled && hasRights && (
                      <>
                        <ButtonCustom
                          color="light"
                          type="submit"
                          disabled={isLoadingPostChangeProposalsData}
                          onClick={() => {
                            this.submitHandle(
                              Constants.PROPOSAL_STATUS.DRAFT,
                              true
                            );
                          }}
                          className="mb-3 text-dark">
                          Sauver le brouillon
                        </ButtonCustom>
                        <ButtonCustom
                          color="primary"
                          type="submit"
                          disabled={isLoadingPostChangeProposalsData}
                          onClick={() => {
                            this.submitHandle(
                              Constants.PROPOSAL_STATUS.SUBMITTED,
                              true
                            );
                          }}
                          className="mb-3">
                          Envoyer pour validation
                        </ButtonCustom>
                      </>
                    )}
                    {(status ===
                      Constants.PROPOSAL_STATUS.SUBMITTED ||
                      status ===
                        Constants.PROPOSAL_STATUS.ACCEPTED) &&
                      hasRights &&
                      blocked && (
                        <ButtonCustom
                          color="secondary"
                          type="submit"
                          className="mb-3"
                          onClick={() => {
                            this.submitHandle(
                              Constants.PROPOSAL_STATUS.DRAFT,
                              false
                            );
                          }}
                          // onClick={this.unblockSubmit}>
                        >
                          Débloquer
                        </ButtonCustom>
                      )}
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </ReactPlaceholder>
        </Template>
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeContainer: state.container.activeContainer.id,
  changeProposalsData: state.folder.getChangeProposalsData,
  getChangeProposalsSuccess: state.folder.getChangeProposalsSuccess,
  getContainerChangeError: state.folder.getContainerChangeError,
  getChangeProposalsError: state.folder.getChangeProposalsError,
  postChangeProposalsError: state.folder.postChangeProposalsError,
  postChangeProposalsSuccess: state.folder.postChangeProposalsSuccess,
  isLoadingPostChangeProposalsData:
    state.folder.isLoadingPostChangeProposalsData,
  setSidebarProposals: state.folder.setSidebarProposals,
  setSidebarProposalsSuccess: state.folder.setSidebarProposalsSuccess,
  postContainerDocumentError: state.folder.postContainerDocumentError,
  postContainerDocumentSuccess:
    state.folder.postContainerDocumentSuccess,
  postContainerDocument: state.folder.postContainerDocument,
  isLoadingPostContainerDocument:
    state.folder.isLoadingPostContainerDocument,
  getContainerDocument: state.folder.getContainerDocument,
  userRights: state.container.userRights.rights,
  getContainerChangeData: state.folder.getContainerChangeData
});

export default connect(mapStateToProps)(FolderProposalScene);
