// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import ImageLoader from 'react-load-image';
import _ from 'lodash';
// Components
import {
  Form,
  Label,
  FormGroup,
  Row,
  Col,
  FormFeedback
} from 'reactstrap';
import Template from '../../Components/Template/TemplateComponent';
import InputCustom from '../../Components/Components/InputCustom';
import ButtonCustom from '../../Components/Components/ButtonCustom';
import AlertCustom from '../../Components/Components/AlertCustom';
// Utils
import {
  patchAvatarAction,
  getInfosAccountAction
} from '../../Business/actions/AccountInfosActions';
import { getVisibleObject } from '../../Resources/Common/Utilities';
// Common
import Messages from '../../Resources/Common/Messages';

type State = {
  filename: string,
  validationError: string,
  visibleObject: {
    patchAvatarSuccess: boolean,
    patchAvatarError: boolean,
    validationError: boolean
  }
};

type Props = {
  avatar: string,
  dispatch: Function,
  email: string,
  isLoadingPatchAvatar: boolean,
  patchAvatarSuccess: boolean,
  patchAvatarError: string,
  firstname: string,
  lastname: string
};

class AccountInfosScene extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      visibleObject: {
        patchAvatarSuccess: false,
        patchAvatarError: false,
        validationError: false
      },
      filename: '',
      validationError: ''
    };
    this.onDismiss = this.onDismiss.bind(this);
  }

  componentDidMount = () => {
    const { dispatch, email } = this.props;
    if (!email || email === '') {
      dispatch(getInfosAccountAction());
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { dispatch, patchAvatarSuccess } = this.props;
    if (
      patchAvatarSuccess &&
      patchAvatarSuccess !== prevProps.patchAvatarSuccess
    ) {
      dispatch(getInfosAccountAction());
    }
    this.setVisibleAlerts(prevProps, prevState);
  };

  submitAvatar = e => {
    const { dispatch } = this.props;
    const avatarNode = document.querySelector('#avatar');
    if (!e.target.checkValidity()) {
      this.setState({
        validationError: Messages.validationError
      });
      e.preventDefault();
      e.stopPropagation();
      e.target.classList.add('was-validated');
    } else if (avatarNode && avatarNode instanceof HTMLInputElement) {
      e.preventDefault();
      const selectedFile = avatarNode.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append('avatar', selectedFile);
        dispatch(patchAvatarAction(formData));
        this.setState({ filename: '' });
        e.currentTarget.reset();
        if (e.target.classList.contains('was-validated')) {
          e.target.classList.remove('was-validated');
        }
      }
      this.setState({
        validationError: ''
      });
    }
  };

  handleAvatar = e => {
    this.setState({
      filename: e.target.value.replace(/C:\\fakepath\\/i, '')
    });
  };

  onDismiss = target => {
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        [target]: false
      }
    }));
  };

  displayAlert = () => {
    const { patchAvatarSuccess, patchAvatarError } = this.props;
    const { visibleObject, validationError } = this.state;
    const alert = [];
    let key = 0;

    if (patchAvatarSuccess) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="patchAvatarSuccess"
          color="success"
          onDismiss={this.onDismiss}
          errorMessage={Messages.changeAvatarSuccess}
          visible={visibleObject.patchAvatarSuccess}
        />
      );
    }
    if (patchAvatarError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="patchAvatarError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={patchAvatarError}
          visible={visibleObject.patchAvatarError}
        />
      );
    }
    if (validationError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="validationError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={validationError}
          visible={visibleObject.validationError}
        />
      );
    }
    return alert;
  };

  setVisibleAlerts = (prevProps, prevState) => {
    const errorPropsArray = [
      'patchAvatarSuccess',
      'patchAvatarError',
      'validationError'
    ];
    const { props, state } = this;
    let newState = Object.assign({}, this.state);
    const { patchAvatarSuccess, patchAvatarError } = props;
    const { validationError } = state;
    if (
      patchAvatarSuccess !== prevProps.patchAvatarSuccess ||
      patchAvatarError !== prevProps.patchAvatarError ||
      validationError !== prevState.validationError
    ) {
      const visibleObject = getVisibleObject(
        props,
        prevProps,
        state,
        prevState,
        errorPropsArray
      );
      newState = {
        ...newState,
        visibleObject: {
          ...newState.visibleObject,
          ...visibleObject
        }
      };
      if (
        Object.values(visibleObject).includes(true) &&
        !_.isEqual(newState, state)
      ) {
        this.setState(newState);
      }
    }
  };

  render() {
    const {
      avatar,
      email,
      isLoadingPatchAvatar,
      firstname,
      lastname
    } = this.props;
    const { filename } = this.state;
    return (
      <Template
        title={`${firstname} ${lastname}`}
        subtitle="Informations de votre compte Sowefund"
        menu="account"
        className="account-infos">
        {this.displayAlert()}
        <Row>
          <Col md={{ size: 6 }} className="mx-auto">
            <Form className="d-flex flex-column">
              <FormGroup>
                <Label for="email">Email</Label>
                <InputCustom id="email" readOnly value={email} />
              </FormGroup>
              <FormGroup>
                <Label for="lastname">Nom de famille</Label>
                <InputCustom
                  id="lastname"
                  readOnly
                  value={lastname}
                />
              </FormGroup>
              <FormGroup>
                <Label for="firstname">Prénom</Label>
                <InputCustom
                  id="firstname"
                  readOnly
                  value={firstname}
                />
              </FormGroup>
            </Form>
            <Form
              className="d-flex flex-column"
              id="avatar-form"
              onSubmit={this.submitAvatar}
              noValidate>
              <span className="mb-2">Avatar</span>
              <FormGroup>
                <InputCustom
                  type="file"
                  className="custom-file-input"
                  id="avatar"
                  name="avatar"
                  onChange={e => this.handleAvatar(e)}
                  required
                />
                <Label className="custom-file-label" for="avatar">
                  {filename !== '' ? filename : 'Choisir un fichier'}
                </Label>
                <FormFeedback>
                  {Messages.avatarFrontValidation}
                </FormFeedback>
                <div className="mt-3">
                  {isLoadingPatchAvatar ? (
                    <div className="sweet-loading">
                      <ClipLoader
                        css="margin: 'O auto';"
                        sizeUnit="px"
                        loading={isLoadingPatchAvatar}
                      />
                    </div>
                  ) : (
                    <ImageLoader src={avatar}>
                      <img alt="avatar" />
                      <div id="error">Error!</div>
                      <ClipLoader
                        css="margin: 'O auto';"
                        sizeUnit="px"
                        loading={isLoadingPatchAvatar}
                      />
                    </ImageLoader>
                  )}
                </div>
              </FormGroup>
              <ButtonCustom
                color="primary"
                disabled={isLoadingPatchAvatar}
                className="align-self-end">
                {"Sauvegarder l'avatar"}
              </ButtonCustom>
            </Form>
          </Col>
        </Row>
      </Template>
    );
  }
}

const mapStateToProps = state => ({
  avatar: state.account.accountInfos.avatar,
  email: state.account.accountInfos.email,
  patchAvatarError: state.account.patchAvatarError,
  patchAvatarSuccess: state.account.patchAvatarSuccess,
  isLoadingPatchAvatar: state.account.isLoadingPatchAvatar,
  firstname: state.account.accountInfos.firstName,
  lastname: state.account.accountInfos.lastName,
  needAcceptToS: state.legal.needCguAcceptance
});

export default connect(mapStateToProps)(AccountInfosScene);
