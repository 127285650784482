// @flow

import Common from '../../Resources/Common';
import {
  PATCH_AVATAR,
  GET_INFOS_ACCOUNT
} from '../actions/AccountInfosActions';

const defaultState = {
  accountInfos: {
    avatar: '',
    email: '',
    firstName: '',
    lastName: '',
    status: {
      hasAcceptedPrerequisites: undefined
    }
  },
  hasAcceptedPrerequisites: undefined,
  isLoadingPatchAvatar: false,
  patchAvatarError: '',
  patchAvatarSuccess: false
};

const accountInfosReducer = (
  state: Object = defaultState,
  action: Object = {}
) => {
  switch (action.type) {
    case PATCH_AVATAR:
      return {
        ...state,
        patchAvatarError: action.payload.error,
        isLoadingPatchAvatar:
          action.payload.status === Common.Constants.PENDING_STATUS,
        patchAvatarSuccess: action.payload.success
      };
    case GET_INFOS_ACCOUNT:
      return {
        ...state,
        accountInfos: action.payload.aggregate,
        accountError: action.payload.error,
        hasAcceptedPrerequisites:
          action.payload.aggregate.status.hasAcceptedPrerequisites,
        isLoading:
          action.payload.status === Common.Constants.PENDING_STATUS
      };
    default:
      return state;
  }
};

export default accountInfosReducer;
