// @flow
export default class FrontValidation {
  static documentFrontValidation =
    'Ce champs est obligatoire. Veuillez choisir un document à uploader.';

  static ProjectBasicInfoStructureCap = {
    name: 'Ce champs est obligatoire.',
    value:
      'Ce champs est obligatoire. Veuillez entrer un nombre entier.'
  };

  static ProjetBasicInfoName = {
    name: "Veuillez entrer un nom d'au moins trois caractères",
    shortName: "Veuillez entrer un nom d'au moins trois caractères"
  };

  static EntityBasicInfo = {
    name: "Veuillez entrer un nom d'au moins trois caractères",
    structure:
      "Ce champs est obligatoire. Veuillez sélectionner l'une des options.",
    siret:
      'Ce champs est obligatoire. Veuillez entrer un SIRET valide.',
    capitalAmount:
      'Ce champs est obligatoire. Veuillez entrer un chiffre, avec au maximum 2 décimales.',
    actionPrice:
      'Ce champs est obligatoire. Veuillez entrer un chiffre, avec au maximum 3 décimales.',
    address: {
      street: 'Ce champs est obligatoire.',
      city: 'Ce champs est obligatoire.',
      zipcode: 'Ce champs est obligatoire.',
      country: 'Ce champs est obligatoire. Veuillez choisir un pays.'
    }
  };

  static ProjectMediaSocialNetwork = {
    website:
      'Ce champs est obligatoire. Veuillez entrer une URL valide, incluant le "http://" ou "https://" au début',
    facebook:
      'Ce champs est obligatoire. Veuillez entrer une URL valide, incluant le "http://" ou "https://" au début',
    twitter:
      'Ce champs est obligatoire. Veuillez entrer une URL valide, incluant le "http://" ou "https://" au début'
  };

  static ProjectOfferStengths = {
    description:
      'Ce champs est obligatoire et doit faire au moins 20 caractères.'
  };

  static DocumentProjectGeneralAssemblyReport = {
    document:
      'Ce champs est obligatoire. Veuillez choisir un document à uploader.'
  };

  static DocumentProjectFinancialBp = {
    document:
      'Ce champs est obligatoire. Veuillez choisir un document à uploader.'
  };

  static DocumentProjectFinancialStatements = {
    document:
      'Ce champs est obligatoire. Veuillez choisir un document à uploader.'
  };

  static DocumentProjectFounderCv = {
    document:
      'Ce champs est obligatoire. Veuillez choisir un document à uploader.'
  };

  static DocumentProjectKbis = {
    document:
      'Ce champs est obligatoire. Veuillez choisir un document à uploader.'
  };

  static DocumentProjectPitchDeck = {
    document:
      'Ce champs est obligatoire. Veuillez choisir un document à uploader.'
  };

  static DocumentProjectShareholdersAgreement = {
    document:
      'Ce champs est obligatoire. Veuillez choisir un document à uploader.'
  };

  static DocumentProjectShareholdersList = {
    document:
      'Ce champs est obligatoire. Veuillez choisir un document à uploader.'
  };

  static ProjectOfferPeople = {
    firstName: 'Ce champs est obligatoire.',
    lastName: 'Ce champs est obligatoire.',
    job: 'Ce champs est obligatoire.',
    shortBiography:
      'Ce champs est obligatoire et doit faire au moins 20 caractères.',
    biography:
      'Ce champs est obligatoire et doit faire au moins 20 caractères.',
    joinDate: 'Ce champs est obligatoire.',
    photo:
      'Ce champs est obligatoire. Veuillez choisir une image PNG, JPG ou GIF.'
  };

  static FinancialData = {
    figures: {
      year:
        'Ce champs est obligatoire et doit être une année entre 2010 et 2030.',
      value:
        'Ce champs est obligatoire. Indiquez un chiffre avec au maximum 2 décimales.'
    },
    comment: {
      content:
        'Ce champs est obligatoire et doit faire au moins 20 caractères.',
      highlight:
        'Ce champs est obligatoire. Veuillez choisir une valeur à mettre en avant.'
    },
    breakdown: {
      name:
        'Veuillez entrer un nom de catégorie, sans caractères spéciaux',
      value: 'Ce champs est obligatoire.'
    }
  };

  static FinancingHistory = {
    title: 'Ce champs est obligatoire.',
    amount:
      'Ce champs est obligatoire. Indiquez un chiffre avec au maximum 2 décimales.',
    breakdown: 'Ce champs est obligatoire.',
    date: 'Ce champs est obligatoire.'
  };

  static ProjectMediaVideo = {
    vimeoId: 'Ce champs est obligatoire.',
    comment:
      'Ce champs est obligatoire et doit faire au moins 20 caractères.'
  };

  static ProjectOfferTagline = {
    tagline: 'Ce champs est obligatoire.'
  };

  static ProjectOngoingFundraise = {
    title: 'Ce champs est obligatoire.',
    amount:
      'Ce champs est obligatoire. Indiquez un chiffre avec au maximum 2 décimales.',
    status:
      "Ce champs est obligatoire. Veuillez sélectionner l'une des options.",
    date: 'Veuillez entrer une date valide.'
  };

  static ProjectFeedback = {
    content:
      'Ce champs est obligatoire et doit faire entre 20 et 600 caractères.',
    firstName: 'Ce champs est obligatoire.',
    lastName: 'Ce champs est obligatoire.',
    job: 'Ce champs est obligatoire.'
  };

  static ProjectOfferDescription = {
    description:
      'Ce champs est obligatoire et doit faire au moins 20 caractères.'
  };
}
