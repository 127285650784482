// @flow

// React libs
import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
// Components
import { persistStore, persistReducer } from 'redux-persist';
import { type PersistState } from 'redux-persist/src/types';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundary from '../Components/Error/ErrorBoundaryComponent';
import Router from '../Components/Router/RouterComponent';
import LocalStorage from '../Layer/Storage/LocalStorage';
// Reducer
import rootReducer from '../Business/reducers';
// Actions
import { checkTokenValidityAction } from '../Business/actions/TokenActions';
import { hideLoaderAction } from '../Business/actions/InitialLoaderActions';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'token',
    'account',
    'container',
    'legal',
    'folder',
    'dashboardSettings'
  ]
};

const middlewares = [reduxThunk];

type State = {
  _persist: PersistState
};

const persistedReducer: (
  state: any,
  action: any
) => State = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);

class AppScene extends React.Component<{}> {
  componentDidMount() {
    // We want to check that the existing token is still valid.
    // This is mainly useful when a user starts a new session (e.g. new tab),
    // and was previously connected.
    document.title = LocalStorage.get('GLOBAL.title');
    store.dispatch(checkTokenValidityAction()).then(() => {
      // We can't use componentDidUpdate since we are not connected to the store
      // Moreover, this is more efficient, since we have 2 state transitions for
      // the request.
      store.dispatch(hideLoaderAction());
    });
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ErrorBoundary>
            <Router />
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    );
  }
}

export default AppScene;
