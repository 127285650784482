// @flow

import MarkdownImageService from '../../Layer/Network/Service/MarkdownImageService';

/**
 * Will upload a `File` to the remote storage service.
 * @param {File} file a file to send to the storage service.
 * @return {Promise<Response>}
 */
// eslint-disable-next-line import/prefer-default-export
export function uploadMarkdownImage(file: File) {
  return () => MarkdownImageService.uploadFile(file);
}
