// @flow
// Services
import FolderService from '../../Layer/Network/Service/FolderService';
// Common
import Common from '../../Resources/Common';
// Messages
import Messages from '../../Resources/Common/Messages';
import Constants from '../../Resources/Common/Constants';

export const GET_CONTAINER_CHANGE = 'GET_CONTAINER_CHANGE';
export const GET_CHANGE_PROPOSALS = 'GET_CHANGE_PROPOSALS';
export const SET_SIDEBAR_PROPOSALS = 'SET_SIDEBAR_PROPOSALS';
export const POST_CHANGE_PROPOSALS = 'POST_CHANGE_PROPOSALS';
export const GET_CONTEXT_MANIFEST = 'GET_CONTEXT_MANIFEST';
export const GET_CHANGE_PROPOSALS_SIDEBAR =
  'GET_CHANGE_PROPOSALS_SIDEBAR';
export const POST_CONTAINER_DOCUMENT = 'POST_CONTAINER_DOCUMENT';
export const GET_CONTAINER_DOCUMENT = 'GET_CONTAINER_DOCUMENT';
export const SET_SIDEBAR_COLLAPSE = 'SET_SIDEBAR_COLLAPSE';
export const SET_UNDEFINED_REMAINING_KINDS =
  'SET_UNDEFINED_REMAINING_KINDS';

// Get container rights
const getContainerChangePending = () => ({
  type: GET_CONTAINER_CHANGE,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: {},
    error: ''
  }
});
const getContainerChangeSuccess = data => ({
  type: GET_CONTAINER_CHANGE,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: ''
  }
});
const getContainerChangeFailure = error => ({
  type: GET_CONTAINER_CHANGE,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: {},
    error
  }
});

// Get change proposals
const getChangeProposalsPending = () => ({
  type: GET_CHANGE_PROPOSALS,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: [],
    error: '',
    kind: ''
  }
});
const getChangeProposalsSuccess = (data, kind) => ({
  type: GET_CHANGE_PROPOSALS,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: '',
    kind
  }
});
const getChangeProposalsFailure = ({ error, kind }) => ({
  type: GET_CHANGE_PROPOSALS,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: [],
    error,
    kind
  }
});

// Get change proposals for sidebar
const getChangeProposalsSidebarPending = () => ({
  type: GET_CHANGE_PROPOSALS_SIDEBAR,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: [],
    error: ''
  }
});
const getChangeProposalsSidebarSuccess = data => ({
  type: GET_CHANGE_PROPOSALS_SIDEBAR,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: ''
  }
});
const getChangeProposalsSidebarFailure = error => ({
  type: GET_CHANGE_PROPOSALS_SIDEBAR,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: [],
    error
  }
});

// Set custom proposals object for sidebar
const setSidebarProposalsSuccess = data => ({
  type: SET_SIDEBAR_PROPOSALS,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: ''
  }
});

// Post change proposals
const postChangeProposalsPending = () => ({
  type: POST_CHANGE_PROPOSALS,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    error: ''
  }
});
const postChangeProposalsSuccess = () => ({
  type: POST_CHANGE_PROPOSALS,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    error: ''
  }
});
const postChangeProposalsFailure = error => ({
  type: POST_CHANGE_PROPOSALS,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    error
  }
});

// Get context manifest
const getContextManifestPending = () => ({
  type: GET_CONTEXT_MANIFEST,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: [],
    error: ''
  }
});
const getContextManifestSuccess = data => ({
  type: GET_CONTEXT_MANIFEST,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: ''
  }
});
const getContextManifestFailure = error => ({
  type: GET_CONTEXT_MANIFEST,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: [],
    error
  }
});

// Post documents
const postChangeContainerDocumentPending = () => ({
  type: POST_CONTAINER_DOCUMENT,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: [],
    error: ''
  }
});
const postChangeContainerDocumentSuccess = data => ({
  type: POST_CONTAINER_DOCUMENT,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: ''
  }
});
const postChangeContainerDocumentFailure = error => ({
  type: POST_CONTAINER_DOCUMENT,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: [],
    error
  }
});

// Get documents
const getChangeContainerDocumentPending = () => ({
  type: GET_CONTAINER_DOCUMENT,
  payload: {
    status: Common.Constants.PENDING_STATUS,
    aggregate: [],
    error: ''
  }
});
const getChangeContainerDocumentSuccess = (data, uuid?) => ({
  type: GET_CONTAINER_DOCUMENT,
  payload: {
    status: Common.Constants.SUCCESS_STATUS,
    aggregate: data,
    error: '',
    uuid
  }
});
const getChangeContainerDocumentFailure = error => ({
  type: GET_CONTAINER_DOCUMENT,
  payload: {
    status: Common.Constants.FAILURE_STATUS,
    aggregate: [],
    error
  }
});

const setSidebarCollapse = collapse => ({
  type: SET_SIDEBAR_COLLAPSE,
  payload: {
    collapse
  }
});

const setUndefinedRemainingKinds = () => ({
  type: SET_UNDEFINED_REMAINING_KINDS
});

export function getContainerChangeAction(uuid: string) {
  return (dispatch: Function) => {
    dispatch(getContainerChangePending());
    return FolderService.getContainerChange(uuid)
      .then(response => {
        dispatch(getContainerChangeSuccess(response));
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.CHANGE_CONTAINER.NOT_FOUND:
            error = Messages.changeContainerNotFound;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(getContainerChangeFailure(error));
      });
  };
}

export function getChangeProposalsAction({
  containerId,
  kind,
  status
}: {
  containerId: string,
  kind: string | typeof undefined,
  status: number | typeof undefined
}) {
  return (dispatch: Function) => {
    dispatch(getChangeProposalsPending());
    return FolderService.getChangeProposals({
      containerId,
      kind,
      status
    })
      .then(response => {
        dispatch(getChangeProposalsSuccess(response, kind));
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.CHANGE_CONTAINER.NOT_FOUND:
            error = Messages.changeContainerNotFound;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(getChangeProposalsFailure({ error, kind }));
      });
  };
}

export function getChangeProposalsSidebarAction({
  containerId,
  status
}: {
  containerId: string,
  status: number | typeof undefined
}) {
  return (dispatch: Function) => {
    dispatch(getChangeProposalsSidebarPending());
    return FolderService.getChangeProposals({
      containerId,
      status
    })
      .then(response => {
        dispatch(getChangeProposalsSidebarSuccess(response));
      })
      .catch(err => {
        let error = '';
        switch (err) {
          case Constants.API_ERRORS.CHANGE_CONTAINER.NOT_FOUND:
            error = Messages.changeContainerNotFound;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(getChangeProposalsSidebarFailure(error));
      });
  };
}

export function setSidebarProposalsAction({
  proposalsData,
  proposals,
  proposalsObject
}: {
  proposalsData: Array<any>,
  proposals: Array<any>,
  proposalsObject: Object
}) {
  return (dispatch: Function) => {
    const kindArray = [];
    const tempProposals = proposalsData.slice();
    const proposalCopy = proposalsData
      .reduce((agregator, proposal, i) => {
        if (!kindArray.includes(proposal.kind)) {
          kindArray.push(proposal.kind);
          tempProposals[i].title =
            proposalsObject[proposal.kind].title;
          tempProposals[i].section =
            proposalsObject[proposal.kind].section;
          const proposalTemp = proposals.find(
            containerProposal =>
              containerProposal.kind === proposal.kind
          );
          tempProposals[i].status = proposalTemp
            ? proposalTemp.status
            : 4;
          agregator.push(tempProposals[i]);
        }
        return agregator;
      }, [])
      .sort((a, b) => a.title.localeCompare(b.title))
      .sort(
        (a, b) =>
          Constants.sections.indexOf(a.section) -
          Constants.sections.indexOf(b.section)
      );
    dispatch(setSidebarProposalsSuccess(proposalCopy));
  };
}

export function postChangeProposalsAction({
  containerId,
  kind,
  status,
  content
}: {
  containerId: string,
  kind: string,
  status: number,
  content: Object
}) {
  return (dispatch: Function) => {
    dispatch(postChangeProposalsPending());
    return FolderService.postChangeProposals({
      containerId,
      kind,
      status,
      content
    })
      .then(() => {
        dispatch(postChangeProposalsSuccess());
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.CHANGE_CONTAINER
            .INSUFFICIENT_RIGHTS:
            error += Messages.userInsufficientRights;
            break;
          case Constants.API_ERRORS.CHANGE_CONTAINER
            .PROPOSAL_NOT_ALLOWED:
            error += Messages.proposalNotAllowed;
            break;
          case Constants.API_ERRORS.ERROR.MISSING:
            error += Messages.missing;
            break;
          default:
            error = error === '' ? Messages.defaultError : error;
            break;
        }
        dispatch(postChangeProposalsFailure(error));
      });
  };
}

export function getContextManifestAction() {
  return (dispatch: Function) => {
    dispatch(getContextManifestPending());
    return FolderService.getContextManifest()
      .then(response => {
        dispatch(getContextManifestSuccess(response));
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.CHANGE_CONTAINER.NOT_FOUND:
            error = Messages.changeContainerNotFound;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(getContextManifestFailure(error));
      });
  };
}

export function postChangeContainerDocumentAction({
  containerId,
  file
}: {
  containerId: string,
  file: FormData
}) {
  return (dispatch: Function) => {
    dispatch(postChangeContainerDocumentPending());
    return FolderService.postChangeContainerDocument({
      containerId,
      file
    })
      .then(response => {
        dispatch(postChangeContainerDocumentSuccess(response));
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.CHANGE_CONTAINER
            .INSUFFICIENT_RIGHTS:
            error = Messages.userInsufficientRights;
            break;
          case Constants.API_ERRORS.CHANGE_CONTAINER
            .DOCUMENT_FORMAT_ERROR:
            error = Messages.documentFormatError;
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(postChangeContainerDocumentFailure(error));
      });
  };
}

export function getChangeContainerDocumentAction({
  containerId,
  docUuid,
  operatorEmail
}: {
  containerId: string,
  docUuid: string,
  operatorEmail: string
}) {
  return (dispatch: Function) => {
    dispatch(getChangeContainerDocumentPending());
    return FolderService.getChangeContainerDocument({
      containerId,
      docUuid
    })
      .then(response => {
        dispatch(
          getChangeContainerDocumentSuccess(response, docUuid)
        );
      })
      .catch(err => {
        let error = '';
        switch (err.message) {
          case Constants.API_ERRORS.CHANGE_CONTAINER.NOT_FOUND:
            error = Messages.changeContainerNotFound;
            break;
          case Constants.API_ERRORS.CHANGE_CONTAINER
            .DOCUMENT_NOT_FOUND:
            error = Messages.documentNotFound(operatorEmail);
            break;
          default:
            error = Messages.defaultError;
            break;
        }
        dispatch(getChangeContainerDocumentFailure(error));
      });
  };
}

export const GET_ALL_CHANGE_PROPOSALS = 'GET_ALL_CHANGE_PROPOSALS';

const getAllChangeProposalsPending = () => ({
  type: GET_ALL_CHANGE_PROPOSALS,
  payload: {
    status: Constants.PENDING_STATUS,
    error: ''
  }
});

export const getAllChangeProposalsInProgress = ({
  failure = false
}: {
  failure: boolean
}) => ({
  type: GET_ALL_CHANGE_PROPOSALS,
  payload: {
    status: Constants.PENDING_STATUS,
    error: failure
  }
});

export const getAllChangeProposalsSuccess = () => ({
  type: GET_ALL_CHANGE_PROPOSALS,
  payload: {
    status: Constants.SUCCESS_STATUS,
    error: ''
  }
});

export function getAllChangeProposals({
  containerId,
  status
}: {
  containerId: string,
  status: number | typeof undefined
}) {
  return (dispatch: Function) => {
    dispatch(getAllChangeProposalsPending());
    dispatch(
      getChangeProposalsAction({ containerId, undefined, status })
    );
  };
}

export function setUndefinedRemainingKindsAction() {
  return (dispatch: Function) => {
    dispatch(setUndefinedRemainingKinds());
  };
}

export function setSidebarCollapseAction({
  collapse
}: {
  collapse: Array<any>
}) {
  return (dispatch: Function) => {
    dispatch(setSidebarCollapse(collapse));
  };
}
