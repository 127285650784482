// @flow

export default class Routes {
  static routeLogin = '';

  static routeSocialLogin = 'social-login';

  // Account
  static routeAccount = 'account';

  static routeInfosAccount = `${Routes.routeAccount}/infos`;

  static routeSecurityAccount = `${Routes.routeAccount}/security`;

  static routeRecoverPassword = 'recover-password';

  static routeResetPassword = 'reset-password';

  // Dashboard
  static routeDashboard = 'container';

  static routeChoseDashboard = 'containers';

  static routeDashboardSettings = 'settings';

  static routeDashboardSettingsInvitation = `${
    Routes.routeDashboardSettings
  }/invitation`;

  static routeCalendar = 'calendar';

  static routeChecklist = 'checklist';

  static routeLogs = 'logs';

  // Folders
  static routeFolder = 'folder';

  static routeProposals = 'change-proposals';

  // Register
  static routeRegister = 'register';

  // Error
  static routeError = 'erreur';

  // ToS
  static acceptTos = 'accept-tos';
}
