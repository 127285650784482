// @flow

import React from 'react';

import _ from 'lodash';
import Constants from '../../../Resources/Common/Constants';

require('./ProposalStatusBadge.scss');

type Proposal = {
  status: number,
  kind: string,
  title: string,
  isMandatory: boolean
};

type Props = {
  proposal: Proposal | typeof undefined,
  asTitle: boolean,
  currentProposal: Object
};

const defaultProposal: Proposal = {
  status: Constants.PROPOSAL_STATUS.EMPTY,
  isMandatory: false,
  title: 'PAS DE TITRE',
  kind: 'KIND:ABSENT'
};

export default function ProposalStatusBadge({
  proposal = defaultProposal,
  asTitle = false,
  currentProposal = {}
}: Props) {
  let status = Constants.PROPOSAL_STATUS.EMPTY;
  if (_.has(currentProposal, 'uuid')) {
    // eslint-disable-next-line prefer-destructuring
    status = currentProposal.status;
  } else {
    status =
      proposal.status >= Constants.PROPOSAL_STATUS.DRAFT
        ? proposal.status
        : Constants.PROPOSAL_STATUS.EMPTY;
  }

  const STATUS_CLASS_AND_TEXT = {
    [Constants.PROPOSAL_STATUS.DRAFT]: {
      className: 'draft',
      text: 'En brouillon'
    },
    [Constants.PROPOSAL_STATUS.SUBMITTED]: {
      className: 'submitted',
      text: 'En validation'
    },
    [Constants.PROPOSAL_STATUS.REJECTED]: {
      className: 'refused',
      text: 'Rejeté'
    },
    [Constants.PROPOSAL_STATUS.ACCEPTED]: {
      className: 'accepted',
      text: 'Accepté'
    },
    [Constants.PROPOSAL_STATUS.EMPTY]: {
      className: 'empty',
      text: 'Vide'
    }
  };

  const badgeClass = asTitle ? 'mr-2' : 'ml-2';
  const badgeContent = asTitle
    ? `${STATUS_CLASS_AND_TEXT[status].text} - Section ${
        proposal.isMandatory ? 'obligatoire' : 'optionelle'
      }`
    : `${proposal.title}${proposal.isMandatory ? '*' : ''}`;

  return (
    <small>
      <span
        className={`status-badge ${badgeClass} ${
          STATUS_CLASS_AND_TEXT[status].className
        }`}
      />
      <span className="text-muted">{badgeContent}</span>
    </small>
  );
}
