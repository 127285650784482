// @flow

export default class Tooltip {
  static InfoName = {
    name:
      'Nom court devant être utilisé dans les communications lors de la campagne',
    shortName:
      'Sigle ou nom très court du projet à utiliser dans certaines communications'
  };

  static DocumentProjectArticlesOfIncorporation = {
    document:
      "Téléversez les statuts de l'entreprise paraphés et signés"
  };

  static ProjectOfferStrengths = {
    description:
      'Décrivez les points forts de votre équipe, de votre proposition, ' +
      'de vos compétences, de votre organisation, vos technologies. ' +
      'Ajoutez au moins 3 et au plus 5 points forts.'
  };

  static ProjectOfferSuccesses = {
    description:
      "Indiquez les principaux succès que votre projet a connu jusqu'à " +
      "présent. Il peut s'agir du lancement d'un produit, de résultats de " +
      'R&D, de signature de contrats, de recrutements particulièrement ' +
      'importants, etc.'
  };

  static ProjectOfferAssets = {
    description:
      'Décrivez les actifs de votre société, et indiquez une estimation du ' +
      "coût d'acquisition ou de développement de ces actifs. Les actifs " +
      "peuvent être de nature physique ('corporels'), tels que des " +
      'terrains, bâtiments, machines et outils, ou bien non physiques ' +
      '(incorporels), comme des sites internet, noms de domaine, etc.'
  };

  static ProjectOfferPartners = {
    description:
      'Présentez les principaux partenaires de votre entreprise. Il peut ' +
      "s'agir de fournisseurs avec lesquels vous travailler, des " +
      "distributeurs, ou d'autres types de partenaires qui vous aident à " +
      'développer votre produit.'
  };

  static ProjectOfferHistory = {
    description:
      "Présentez la genèse du projet : d'où vient l'idée ? Quels sont les " +
      'membres fondateurs ?\nExpliquez également le développement du ' +
      'projet : comment avez-vous géré le développement du produit et de ' +
      "votre offre ? Comment avez-vous agrandit l'équipe ? Comment avez-vous " +
      'développé vos ventes ?\nIndiquez les principales étapes (avec leurs ' +
      'dates) de votre projet.'
  };

  static ProjectOfferRisks = {
    description:
      'Indiquez les principaux facteurs de risque auxquels votre projet est ' +
      "exposé. Il peut s'agir de risques environnementaux dans le cadre de " +
      'projets industriels, de risques légaux, concurrentiels, ou autres. ' +
      'Ces indications servent à remplir les documents règlements (DIR AMF), ' +
      'et ne sont pas mis en avant sur la page de campagne de votre projet'
  };

  static ProjectOfferPeople = {
    firstName: "Prénom d'usage",
    lastName: 'Nom de famille',
    job: "Rôle dans l'entreprise et dans le conseil d'administration",
    url: 'Indiquez le lien vers le profil linkedin',
    photo:
      "Téléversez une photo du membre de l'équipe ou du CA. " +
      'Faites attention à choisir un cadrage, et si possible un fonds ' +
      'identique pour toutes les photos. Contactez Sowefund si vous ' +
      'souhaitez organiser un photoshooting',
    company:
      'Indiquez la société principale dans laquelle le membre ' +
      'du board est engagé',
    smallBiography:
      'Courte biographie. Indiquez les objectifs, compétences, ' +
      'et expériences professionnelles pertinentes',
    biography: 'no_tooltip/Biographie plus longue',
    kind:
      "Indiquez si la personne est un membre de l'équipe ou " +
      "du conseil d'administration",
    isFeatured: 'Indiquez si la personne doit être mise en avant',
    proposal:
      "Présentez ici les membres clés de votre équipe, ainsi que les personalités notables de votre conseil d'administration qui vous aident et vous conseillent."
  };

  static ProjectOfferProblem = {
    description:
      'Quel problème votre service ou produit résout-il ? ' +
      'Quel est le "pourquoi" de votre projet ?'
  };

  static ProjectOfferConcept = {
    description:
      'Quel est la réponse que vous apportez à la problématique ? ' +
      'En quoi vos produits/services permettent-ils de résoudre le problème ' +
      'identifié ?'
  };

  static ProjectOfferProductsServices = {
    description:
      'Décrivez les gammes et les produits et services que vous offrez.'
  };

  static ProjectOfferMarket = {
    description:
      'Présentez le marché que vous souhaitez attaquer. Est-ce B2B ? B2C ? ' +
      "Autre configuration ? Etes-vous une plateforme ? Est-ce qu'il y a " +
      "beaucoup d'offres ou de demande ? Etes-vous substituable ou " +
      "complémentaire d'autres produits ou service ? A quelle taille " +
      "estimez-vous le marché aujourd'hui et dans les prochaines années ?"
  };

  static ProjectOfferCompetition = {
    description:
      'Quels sont vos concurrents ? Y-a-t-il des produits substituables ' +
      'à votre offre ? Quelle est la taille de vos concurrents ?'
  };

  static ProjectOfferBarriers = {
    description:
      "Y-a-t-il des barrières à l'entrée pour vous ou de potentiels " +
      'concurrents ? Décrivez les barrières légales, fiscales, liées aux ' +
      'effets de réseau, aux investissements et compétences requises pour ' +
      'entrer le secteur, etc.'
  };

  static ProjectOfferDifferenciation = {
    description:
      'Quels sont les éléments différenciants de votre produit par ' +
      'rapport à la concurrence ?'
  };

  static ProjectOfferDescription = {
    description:
      'Renseignez une description courte de votre entreprise ' +
      'et de votre activité.'
  };

  static ProjectOfferActionPlan = {
    description:
      "Décrivez votre plan d'action post-levée de fonds. Comment " +
      'souhaitez-vous vous développer ? Sur quelle période allez-vous ' +
      'utiliser les fonds ?'
  };

  static ProjectOfferGoals = {
    description:
      'Quels sont vos prochains objectifs/milestones ? Quelle est votre ' +
      'vision à moyen et long terme de votre entreprise ?'
  };

  static ProjectOfferBusinessModel = {
    description:
      'Quel est votre modèle économique ? Comment générez-vous du chiffre ' +
      "d'affaires ? D'où viennent vos marges ?"
  };

  static ProjectOfferCommercialStrat = {
    description:
      "Quelle est votre stratégie d'acquisition de clients ?"
  };

  static ProjectOfferUseOfFunds = {
    description:
      "Expliquez l'utilisation que vous souhaiter faire des fonds. Vont-ils " +
      "vous permettre d'embaucher ? Quels types d'activités allez-vous " +
      'développer ? Sur quelle aspect du projet souhaitez-vous investir ? ' +
      'Allez-vous acquérir de nouveaux actifs grâce à ces fonds ?'
  };

  static ProjectFeedback = {
    proposal:
      'Obtenez des témoignages de personalités clés avec une forte légitimité vis-à-vis de votre activité afin de mettre en avant les forces de votre projet.',
    opinion:
      'Sous forme de citation, rédigé à la première personne. ' +
      "L'idée est d'avoir un genre d'interview.",
    content:
      "Sous forme de citation, rédigé à la première personne. L'idée est d'avoir un genre d'interview."
  };

  static EntityBasicInfoComponent = {
    name:
      "Dénomination officielle de l'entreprise, telle qu'indiquée sur le Kbis",
    structure:
      'Indiquez le statut de la société (SARL, SAS, Association, SA, SNC, Autre)',
    siret: 'Numéro de SIRET',
    capitalAmount:
      "Indiquez le capital social de l'entreprise tel que mentionné sur le dernier K-Bis & dans les statuts",
    actionPrice:
      "Renseignez le coût nominal d'une action, tel que décrit dans les statuts",
    address: {
      street: 'Numéro et nom de la rue du siège social',
      city: 'Ville du siège social',
      zipcode: 'Code postal du siège social',
      country: 'Pays du siège social'
    },
    logo: "Logo de l'entreprise"
  };

  static ProjectBasicInfoStructureCap = {
    breakdown: 'Mini-tableau de capitalisation'
  };

  static ProjectBasicInfoSector = {
    main:
      "Choisissez le secteur principal de l'entreprise. Cette information est importante car elle permet de catégoriser le projet et d'optimiser la communication autour du projet avec la communauté sowefund.",
    extra: "Choisissez le ou les secteurs secondaire de l'entreprise."
  };

  static ProjectMediaSocialNetwork = {
    website: "URL du site web de l'entreprise",
    facebook: "Lien vers le facebook de l'entreprise",
    twitter: "Lien vers le compte twitter de l'entreprise"
  };

  static DocumentProjectGeneralAssemblyReport = {
    document:
      "PV de l'AG actant l'ouverture du capital aux nouveaux investisseurs."
  };

  static DocumentProjectGeneralAssemblyReport = {
    document:
      "PV de l'AG actant l'ouverture du capital aux nouveaux investisseurs."
  };

  static DocumentProjectFinancialBp = {
    document:
      'Document présentant le business plan sur les 5 prochaines années'
  };

  static DocumentProjectFinancialStatements = {
    document: 'Téléversez la dernière liasse fiscale.'
  };

  static DocumentProjectFounderCv = {
    document: 'Téléversez le CV de tous les fondateurs de la société.'
  };

  static DocumentProjectKbis = {
    document: 'Téléversez un K-bis daté de moins de 3 mois.'
  };

  static DocumentProjectPitchDeck = {
    document:
      "Pitch Deck présentant l'ouverture de capital. Ce document servira notamment de base de travail pour démarcher des co-investisseurs."
  };

  static DocumentProjectShareholdersAgreement = {
    document:
      "Téléversez le pacte d'actionnaire pré-levée, s'il y en a un."
  };

  static DocumentProjectShareholdersList = {
    document:
      "Téléversez la table de capitalisation reprenant la liste des actionnaires, le nombre de parts et la proportion du capital qu'ils détiennent. Ce document doit être signé par un dirigeant de l'enterprise."
  };

  static ProjectFinancialDataB2B = {
    figures:
      "Nombre de clients B2B de l'entreprise sur l'année considérée. Le tableau se présente sous la forme suivante : CHIFFRE | ANNEE | TYPE (p = prévisionnel, r = réalisé)",
    comment:
      "Présentez l'évolution générale de votre clientèle Clients B2B",
    breakdown:
      'Indiquez la part de CA de vos principaux clients B2B, ou bien les types de clients que vous avez.'
  };

  static ProjectFinancialDataB2C = {
    figures:
      "Nombre de clients finaux de vos produits et services sur l'année considéré. Le tableau se présente sous la forme suivante : CHIFFRE | ANNEE | TYPE (p = prévisionnel, r = réalisé)",
    comment:
      "Présentez l'évolution générale de votre clientèle Clients B2C",
    breakdown:
      'Indiquez la part de CA de vos principaux clients B2C, ou bien les types de clients que vous avez.'
  };

  static ProjectFinancialDataRevenue = {
    figures:
      "Total des ventes de l'année considérée. Liasse régime simplifiée : 210 + 214 + 218 . Le tableau se présente sous la forme suivante : CHIFFRE | ANNEE | TYPE (p = prévisionnel, r = réalisé)	",
    comment:
      "Présentez l'évolution générale de votre Chiffre d'affaires",
    breakdown:
      "Les données entrées ici permettent de créer un graphique camembert présentant la décomposition du chiffre d'affaires. Entrez le montant ou bien la proportion de votre chiffre d'affaire apporté par chaque produit, gamme de produit ou type d'activité."
  };

  static ProjectFinancialDataEbitda = {
    figures:
      "Exédent Brut d'Exploitation de l'année considérée. L'excédent brut d'exploitation est la marge opérationelle (EBITDA en anglais).Le tableau se présente sous la forme suivante : CHIFFRE | ANNEE | TYPE (p = prévisionnel, r = réalisé)",
    comment: "Présentez l'évolution générale de votre EBE",
    breakdown:
      "Présentez les principaux contributeurs de l'EBE (lignes de produit, zones géographiques, etc.) sous forme d'une graphique camembert"
  };

  static ProjectFinancialDataProfitLoss = {
    figures:
      "Il s'agit du profit ou perte de l'année considérée Liasse régime simplifiée : 310. Le tableau se présente sous la forme suivante : CHIFFRE | ANNEE | TYPE (p = prévisionnel, r = réalisé)"
  };

  static ProjectFinancialDataUsers = {
    figures:
      "Nombre d'utilisateurs de votre produit ou service sur la dernière année. Le tableau se présente sous la forme suivante : CHIFFRE | ANNEE | TYPE (p = prévisionnel, r = réalisé)",
    comment:
      "Présentez l'évolution générale du nombre de vos utilisateurs Utilisateurs",
    breakdown:
      'Présentez une information sur vos utilisateurs, par exemple la plateforme via laquelle ils accèdent à votre produit, ou bien des données démographiques.'
  };

  static ProjectFinancialDataWorkforce = {
    comment:
      "Présentez l'évolution générale de vos effectifs et leur répartition par activité",
    breakdown:
      'Présentez la répartition de vos effectif entre les différentes activités (commerciales, développement, admin, opérations, etc)'
  };

  static FinancingHistory = {
    title:
      "Indiquez le titre de l'opération. Première levée ? Contribution au capital des fondateurs ? Seed money ? Love Money ? Pre-seed ?",
    amount: 'Valeur post-money.',
    breakdown:
      "Tableau présentant la contribution en € et le type d'investisseurs ayant contribué : MONTANT | TYPE"
  };

  static ProjectMediaVideo = {
    vimeoId: 'ID de la vidéo  VIMEO',
    comment: "Description courte d'introduction de la vidéo"
  };

  static ProjectOfferTagline = {
    tagline:
      'Indiquez le slogan, tagline ou petite phrase choc qui accompagne le nom de votre entreprise'
  };

  static ProjectOngoingFundraise = {
    title: 'Ex: nom du fonds, etc.',
    status: 'Liste: en discussion, promis, confirmé.'
  };
}
