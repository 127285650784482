// @flow

import Common from '../../Resources/Common';
import {
  POST_LOGIN,
  AUTH_SET_DISCONNECT,
  POST_SOCIAL_LOGIN
} from '../actions/LoginActions';

const defaultState = {
  postLoginData: null,
  isLoading: false,
  isLoadingPostLogin: false,
  postLoginSuccess: false,
  error: ''
};

const LoginReducer = (
  state: Object = defaultState,
  action: Object = {}
) => {
  switch (action.type) {
    case AUTH_SET_DISCONNECT:
      return {
        ...state,
        error: action.payload.error
      };
    case POST_LOGIN:
      return Object.assign({}, state, {
        postLoginData: action.payload.aggregate,
        error: action.payload.error,
        isLoadingPostLogin:
          action.payload.status === Common.Constants.PENDING_STATUS,
        postLoginSuccess:
          action.payload.status === Common.Constants.SUCCESS_STATUS
      });
    case POST_SOCIAL_LOGIN:
      return Object.assign({}, state, {
        postSocialLoginData: action.payload.aggregate,
        postSocialLoginError: action.payload.error,
        isLoadingPostSocialLogin:
          action.payload.status === Common.Constants.PENDING_STATUS,
        postSocialLoginSuccess:
          action.payload.status === Common.Constants.SUCCESS_STATUS
      });
    default:
      return state;
  }
};

export default LoginReducer;
