// @flow

import React from 'react';
import { connect } from 'react-redux';
import ReactPlaceholder from 'react-placeholder';
import { Row, Col, Table } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';

import UserRights from './UserRights';
import {
  patchUserRightsAction,
  deleteUserRightsAction,
  getContainerRightsAction
} from '../../Business/actions/DashboardSettingsActions';
import Constants from '../../Resources/Common/Constants';
import Messages from '../../Resources/Common/Messages';
import ButtonCustom from '../Components/ButtonCustom';

type Right =
  | Constants.USER_RIGHTS.PROJECT_BASIC_INFO
  | Constants.USER_RIGHTS.PROJECT_OFFER
  | Constants.USER_RIGHTS.PROJECT_MEDIA
  | Constants.USER_RIGHTS.PROJECT_FINANCE
  | Constants.USER_RIGHTS.SEND_EMAIL
  | Constants.USER_RIGHTS.SEND_COMMUNICATION
  | Constants.USER_RIGHTS.CHECKLIST_CALENDAR
  | Constants.USER_RIGHTS.ADMINISTRATION;

type UserRight = {
  email: string,
  userId: string,
  firstName: string,
  lastName: string,
  rights: Right[]
};

type State = {
  changedRights: Array<{
    userId: string,
    newRights: Right[],
    activeContainer: string
  }>
};

type Props = {
  rights: UserRight[],
  isLoadingPatchUserRights: boolean,
  email: string,
  dispatch: Function,
  activeContainer: string,
  patchRightsError: boolean,
  operatorEmail: string
};

class UserRightsTable extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      changedRights: []
    };
  }

  componentDidMount() {
    const { rights } = this.props;
    if (!rights) {
      this.getRights();
    }
  }

  shouldComponentUpdate(nextProps) {
    const { rights } = this.props;
    return !_.isEqual(rights, nextProps.rights);
  }

  componentDidUpdate(prevProps) {
    const { patchRightsError } = this.props;
    if (prevProps.patchRightsError !== patchRightsError) {
      this.getRights();
    }
  }

  getRights = () => {
    const { activeContainer, dispatch } = this.props;
    dispatch(getContainerRightsAction(activeContainer));
  };

  updateRights = () => {
    const { dispatch, operatorEmail } = this.props;
    const { changedRights } = this.state;
    dispatch(patchUserRightsAction(changedRights, operatorEmail));
  };

  updateUserRights = (userId: string) => (newRights: Right[]) => {
    const { activeContainer } = this.props;
    const { changedRights } = this.state;
    const tempChangedRights = changedRights.slice();
    const existsUser = _.find(changedRights, {
      userId,
      activeContainer
    });
    if (_.isEmpty(newRights)) {
      newRights.push(Constants.READING_RIGHT);
    }
    if (existsUser) {
      existsUser.newRights = newRights;
    } else {
      tempChangedRights.push({ userId, newRights, activeContainer });
    }
    this.setState({
      changedRights: tempChangedRights
    });
  };

  deleteUser = (userId: string) => {
    const { dispatch, activeContainer, operatorEmail } = this.props;
    dispatch(
      deleteUserRightsAction(
        activeContainer,
        userId,
        [],
        operatorEmail
      )
    );
  };

  deleteUserConfirmation = (userId: string) => () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="modal d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog mt-5" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <ButtonCustom
                  className="close"
                  onClick={onClose}
                  aria-label="Close">
                  <span aria-hidden="true">x</span>
                </ButtonCustom>
                <p>{Messages.dashboardDeleteUserConfirmMessage}</p>
              </div>
              <div className="modal-footer">
                <ButtonCustom
                  color="primary"
                  className="btn btn-primary"
                  onClick={() => {
                    this.deleteUser(userId);
                    onClose();
                  }}>
                  Oui
                </ButtonCustom>
                <ButtonCustom
                  className="btn btn-secondary"
                  onClick={onClose}
                  data-dismiss="modal">
                  Non
                </ButtonCustom>
              </div>
            </div>
          </div>
        </div>
      )
    });
  };

  render() {
    const { rights, isLoadingPatchUserRights, email } = this.props;
    const userComponent = (
      <>
        <h2>Les utilisateurs</h2>
        <ReactPlaceholder
          className="my-3"
          ready={!!rights}
          type="text"
          showLoadingAnimation
          rows={8}
          delay={100}
          color={window
            .getComputedStyle(document.body)
            .getPropertyValue('--light')}>
          <Row>
            <Col md={{ size: 12 }}>
              <p className="lead">Détails des droits</p>
              <Table striped id="change-rights-table">
                <thead className="border-bottom border-primary">
                  <tr>
                    <th>Nom</th>
                    <th>Administrateur</th>
                    <th>Info basiques</th>
                    <th>Offre</th>
                    <th>Média</th>
                    <th>Finance</th>
                    <th>Email</th>
                    <th>Comm</th>
                    <th>Calendrier checkliste</th>
                  </tr>
                </thead>
                <tbody className="border-bottom border-primary">
                  {rights &&
                    rights.map(right => (
                      <UserRights
                        key={`rights_${right.userId}`}
                        userRight={right}
                        activeUserEmail={email}
                        onRightsUpdate={this.updateUserRights(
                          right.userId
                        )}
                        deleteUser={this.deleteUserConfirmation(
                          right.userId
                        )}
                      />
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 12 }}>
              <ButtonCustom
                color="primary"
                className="float-right"
                disabled={isLoadingPatchUserRights}
                onClick={this.updateRights}>
                Mettre les droits à jour
              </ButtonCustom>
            </Col>
          </Row>
        </ReactPlaceholder>
      </>
    );
    return userComponent;
  }
}

export default connect(state => ({
  email: state.account.accountInfos.email,
  rights: state.dashboardSettings.rights,
  isLoadingPatchUserRights:
    state.dashboardSettings.isLoadingPatchUserRights,
  activeContainer: state.container.activeContainer.id,
  operatorEmail: state.container.activeContainer.operatorEmail,
  patchRightsError: state.dashboardSettings.patchRightsError
}))(UserRightsTable);
