// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import qs from 'query-string';
// Utils
import { PostSocialLoginAction } from '../../Business/actions/LoginActions';
import Routes from '../../Resources/Common/Routes';
import LocalStorage from '../../Layer/Storage/LocalStorage';

type State = {
  stateError: string
};

type Props = {
  dispatch: Function,
  location: Object,
  match: Object,
  socialLoginSuccess: boolean,
  isLoadingPostSocialLogin: boolean
};

class SocialLoginScene extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      stateError: ''
    };
  }

  componentDidMount() {
    const { dispatch, location, match } = this.props;
    const { search } = location;
    const { code, state } = qs.parse(search, {
      ignoreQueryPrefix: true
    });
    const { network } = match.params;
    if (state === LocalStorage.get('oauthState')) {
      this.setState({ stateError: true });
    }
    const invitationToken = LocalStorage.get('invitationToken');
    dispatch(
      PostSocialLoginAction({
        network,
        code,
        accessToken: undefined,
        invitationToken
      })
    );
  }

  getRedirectRoute = () => LocalStorage.get('redirectRoute');

  render() {
    const {
      isLoadingPostSocialLogin,
      socialLoginSuccess
    } = this.props;
    const { stateError } = this.state;
    const redirectCondition =
      (!isLoadingPostSocialLogin && !socialLoginSuccess) ||
      stateError;
    return (
      <>
        {!isLoadingPostSocialLogin && socialLoginSuccess && (
          <Redirect to={`/${Routes.routeLogin}`} />
        )}
        {redirectCondition && (
          <>
            {this.getRedirectRoute() ? (
              <Redirect to={this.getRedirectRoute()} />
            ) : (
              <Redirect to={`/${Routes.routeLogin}`} />
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  socialLoginSuccess: state.login.postLoginSuccess,
  isLoadingPostSocialLogin: state.login.isLoadingPostSocialLogin
});

export default connect(mapStateToProps)(SocialLoginScene);
