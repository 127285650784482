// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Boostrap components
import { NavLink } from 'reactstrap';
// Actions
import { setSidebarToggleAction } from '../../Business/actions/ContainerActions';
import Constants from '../../Resources/Common/Constants';

type Props = {
  tag: string,
  children: string | Element<*>,
  to: string,
  active: boolean,
  sidebarToggle: boolean,
  dispatch: Function
};

class NavlinkCustom extends Component<Props> {
  componentDidMount = () => {};

  toggleSidebar = () => {
    const { dispatch, sidebarToggle } = this.props;
    if (
      document.getElementsByClassName(Constants.SIDEBAR_VISIBLE_CLASS)
    ) {
      dispatch(
        setSidebarToggleAction(
          sidebarToggle !== undefined ? !sidebarToggle : false
        )
      );
    }
  };

  render() {
    const { tag, to, active, children } = this.props;
    return (
      <NavLink
        tag={tag}
        to={to}
        active={active}
        onClick={this.toggleSidebar}>
        {children}
      </NavLink>
    );
  }
}

const mapStateToProps = state => ({
  sidebarToggle: state.container.sidebarToggle
});

export default withRouter(connect(mapStateToProps)(NavlinkCustom));
