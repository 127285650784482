// @flow

// React Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { ClipLoader } from 'react-spinners';
import _ from 'lodash';
// Components
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import InputCustom from '../../InputCustom';
import FrontValidation from '../../../../Resources/Common/FrontValidation';
import Tooltip from '../../../../Resources/Common/Tooltip';
// Actions
import { getChangeContainerDocumentAction } from '../../../../Business/actions/FolderActions';
import Constants from '../../../../Resources/Common/Constants';

type Props = {
  changeProposalsData: Array<any>,
  hasRights: boolean,
  blocked: boolean,
  containerDocument: Object,
  activeContainer: string,
  dispatch: Function,
  isLoadingGetContainerDocument: boolean,
  proposalUid: string,
  operatorEmail: string
};

type State = {
  network: {
    website: string,
    facebook: string,
    twitter: string
  },
  media: {
    smallLight: { url: string, text: string },
    smallDark: { url: string, text: string },
    bigLight: { url: string, text: string },
    bigDark: { url: string, text: string }
  },
  smallLightRequired: boolean,
  smallDarkRequired: boolean,
  bigLightRequired: boolean,
  bigDarkRequired: boolean,
  containerDocumentArray: Array<any>,
  documentsToGet: Array<any>,
  changeProposalsFiltered: Object
};

class MediaSocialNetwork extends Component<Props, State> {
  constructor(props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = _.pick(
      _.defaultsDeep(_.get(changeProposalsFiltered, '[0].content'), {
        network: {
          website: '',
          facebook: '',
          twitter: ''
        },
        media: {
          smallLight: { url: '', text: '' },
          smallDark: { url: '', text: '' },
          bigLight: { url: '', text: '' },
          bigDark: { url: '', text: '' }
        }
      }),
      [
        'network.website',
        'network.facebook',
        'network.twitter',
        'media.smallLight',
        'media.smallDark',
        'media.bigLight',
        'media.bigDark'
      ]
    );
    this.state.smallLightRequired = !_.get(
      changeProposalsFiltered,
      '[0].content.media.smallLight'
    );
    this.state.smallDarkRequired = false;
    this.state.bigLightRequired = !_.get(
      changeProposalsFiltered,
      '[0].content.media.bigLight'
    );
    this.state.bigDarkRequired = false;

    this.state.containerDocumentArray = [];
    this.state.documentsToGet = _.has(
      changeProposalsFiltered,
      '[0].content.media'
    )
      ? Object.values(
          _.get(changeProposalsFiltered, '[0].content.media')
        )
      : [];
    this.state.changeProposalsFiltered = changeProposalsFiltered;
  }

  componentDidMount = () => {
    const { dispatch, activeContainer, operatorEmail } = this.props;
    const { documentsToGet } = this.state;
    if (
      documentsToGet.length > 0 &&
      typeof documentsToGet[0] === 'string'
    ) {
      dispatch(
        getChangeContainerDocumentAction({
          containerId: activeContainer,
          docUuid: documentsToGet[0],
          operatorEmail
        })
      );
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      containerDocument,
      dispatch,
      activeContainer,
      isLoadingGetContainerDocument,
      operatorEmail
    } = this.props;
    const { documentsToGet } = this.state;
    if (
      containerDocument &&
      _.isPlainObject(containerDocument) &&
      !_.isEmpty(containerDocument) &&
      !_.isEqual(containerDocument, prevProps.containerDocument)
    ) {
      this.prepareDocuments();
    }
    if (
      !isLoadingGetContainerDocument &&
      documentsToGet.length > 0 &&
      !_.isEqual(documentsToGet, prevState.documentsToGet) &&
      typeof documentsToGet[0] === 'string'
    ) {
      dispatch(
        getChangeContainerDocumentAction({
          containerId: activeContainer,
          docUuid: documentsToGet[0],
          operatorEmail
        })
      );
    }
  };

  prepareDocuments = () => {
    const { containerDocument } = this.props;
    const { containerDocumentArray, documentsToGet } = this.state;
    if (
      _.isPlainObject(containerDocument) &&
      !_.isEmpty(containerDocument)
    ) {
      this.setState({
        containerDocumentArray: containerDocumentArray.concat(
          containerDocument
        ),
        documentsToGet: documentsToGet.slice(1)
      });
    }
  };

  handleWebsite = e => {
    e.persist();
    const { network } = this.state;
    this.setState({
      network: update(network, {
        website: { $set: e.target.value }
      })
    });
  };

  handleFacebook = e => {
    e.persist();
    const { network } = this.state;
    this.setState({
      network: update(network, {
        facebook: { $set: e.target.value }
      })
    });
  };

  handleTwitter = e => {
    e.persist();
    const { network } = this.state;
    this.setState({
      network: update(network, {
        twitter: { $set: e.target.value }
      })
    });
  };

  handleDocument = fieldName => e => {
    e.persist();
    this.setState(prevState => ({
      media: {
        ...prevState.media,
        [fieldName]: {
          text: e.target.value.replace(/C:\\fakepath\\/i, '')
        }
      }
    }));
  };

  render() {
    const {
      network,
      media,
      smallLightRequired,
      smallDarkRequired,
      bigLightRequired,
      bigDarkRequired,
      containerDocumentArray,
      documentsToGet,
      changeProposalsFiltered
    } = this.state;
    const { hasRights, blocked } = this.props;
    return (
      <>
        <span className="mb-2">
          Logo de l'entreprise (petit, fonds clair)
        </span>
        <FormGroup>
          <InputCustom
            type="file"
            className="custom-file-input document"
            id="smallLight"
            name="document.smallLight"
            onChange={this.handleDocument('smallLight')}
            disabled={!hasRights || blocked}
            required={smallLightRequired}
          />
          <Label className="custom-file-label" for="plop">
            {media.smallLight.text !== ''
              ? media.smallLight.text
              : 'Choisir un fichier'}
          </Label>
          <FormFeedback>
            {FrontValidation.documentFrontValidation}
          </FormFeedback>
          <div className="mt-3">
            {documentsToGet.length > 0 ? (
              <div className="sweet-loading">
                <ClipLoader
                  css="margin: 'O auto';"
                  sizeUnit="px"
                  loading={documentsToGet.length > 0}
                />
              </div>
            ) : (
              <>
                {_.has(containerDocumentArray, '[0].url') && (
                  <img
                    src={_.get(containerDocumentArray, '[0].url')}
                    className="mw-100"
                    alt="document"
                  />
                )}
              </>
            )}
          </div>
          <InputCustom
            type="hidden"
            name="media.smallLight"
            value={
              smallLightRequired
                ? ''
                : _.get(
                    changeProposalsFiltered,
                    '[0].content.media.smallLight'
                  )
            }
          />
        </FormGroup>
        <span className="mb-2">
          Logo de l'entreprise (petit, fonds sombre)
        </span>
        <FormGroup>
          <InputCustom
            type="file"
            className="custom-file-input document"
            id="smallDark"
            name="document.smallDark"
            onChange={this.handleDocument('smallDark')}
            disabled={!hasRights || blocked}
            required={smallDarkRequired}
          />
          <Label className="custom-file-label" for="document">
            {media.smallDark.text !== ''
              ? media.smallDark.text
              : 'Choisir un fichier'}
          </Label>
          <FormFeedback>
            {FrontValidation.documentFrontValidation}
          </FormFeedback>
          <div className="mt-3">
            {documentsToGet.length > 0 ? (
              <div className="sweet-loading">
                <ClipLoader
                  css="margin: 'O auto';"
                  sizeUnit="px"
                  loading={documentsToGet.length > 0}
                />
              </div>
            ) : (
              <>
                {_.has(containerDocumentArray, '[1].url') && (
                  <img
                    src={_.get(containerDocumentArray, '[1].url')}
                    className="mw-100"
                    alt="document"
                  />
                )}
              </>
            )}
          </div>
          <InputCustom
            type="hidden"
            name="media.smallDark"
            value={
              smallDarkRequired
                ? ''
                : _.get(
                    changeProposalsFiltered,
                    '[0].content.media.smallDark'
                  )
            }
          />
        </FormGroup>
        <span className="mb-2">
          Logo de l'entreprise (grand, fonds clair)
        </span>
        <FormGroup>
          <InputCustom
            type="file"
            className="custom-file-input document"
            id="bigLight"
            name="document.bigLight"
            onChange={this.handleDocument('bigLight')}
            disabled={!hasRights || blocked}
            required={bigLightRequired}
          />
          <Label className="custom-file-label" for="document">
            {media.bigLight.text !== ''
              ? media.bigLight.text
              : 'Choisir un fichier'}
          </Label>
          <FormFeedback>
            {FrontValidation.documentFrontValidation}
          </FormFeedback>
          <div className="mt-3">
            {documentsToGet.length > 0 ? (
              <div className="sweet-loading">
                <ClipLoader
                  css="margin: 'O auto';"
                  sizeUnit="px"
                  loading={documentsToGet.length > 0}
                />
              </div>
            ) : (
              <>
                {_.has(containerDocumentArray, '[2].url') && (
                  <img
                    src={_.get(containerDocumentArray, '[2].url')}
                    className="mw-100"
                    alt="document"
                  />
                )}
              </>
            )}
          </div>
          <InputCustom
            type="hidden"
            name="media.bigLight"
            value={
              bigLightRequired
                ? ''
                : _.get(
                    changeProposalsFiltered,
                    '[0].content.media.bigLight'
                  )
            }
          />
        </FormGroup>
        <span className="mb-2">
          Logo de l'entreprise (grand, fonds sombre)
        </span>
        <FormGroup>
          <InputCustom
            type="file"
            className="custom-file-input document"
            id="bigDark"
            name="document.bigDark"
            onChange={this.handleDocument('bigDark')}
            disabled={!hasRights || blocked}
            required={bigDarkRequired}
          />
          <Label className="custom-file-label" for="document">
            {media.bigDark.text !== ''
              ? media.bigDark.text
              : 'Choisir un fichier'}
          </Label>
          <FormFeedback>
            {FrontValidation.documentFrontValidation}
          </FormFeedback>
          <div className="mt-3">
            {documentsToGet.length > 0 ? (
              <div className="sweet-loading">
                <ClipLoader
                  css="margin: 'O auto';"
                  sizeUnit="px"
                  loading={documentsToGet.length > 0}
                />
              </div>
            ) : (
              <>
                {_.has(containerDocumentArray, '[3].url') && (
                  <img
                    src={_.get(containerDocumentArray, '[3].url')}
                    className="mw-100"
                    alt="document"
                  />
                )}
              </>
            )}
          </div>
          <InputCustom
            type="hidden"
            name="media.bigDark"
            value={
              bigDarkRequired
                ? ''
                : _.get(
                    changeProposalsFiltered,
                    '[0].content.media.bigDark'
                  )
            }
          />
        </FormGroup>

        <FormGroup>
          <Label for="website">Site web</Label>
          <small className="form-text text-muted mb-1 mt-0">
            {Tooltip.ProjectMediaSocialNetwork.website}
          </small>
          <InputCustom
            placeholder="Site Web"
            id="network.website"
            name="network.website"
            value={network.website || ''}
            onChange={this.handleWebsite}
            minLength="1"
            maxLength="300"
            pattern={Constants.pattern.uri}
            disabled={!hasRights || blocked}
            required
          />
          <FormFeedback>
            {FrontValidation.ProjectMediaSocialNetwork.website}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="facebook">Facebook entreprise</Label>
          <small className="form-text text-muted mb-1 mt-0">
            {Tooltip.ProjectMediaSocialNetwork.facebook}
          </small>
          <InputCustom
            placeholder="Facebook entreprise"
            id="network.facebook"
            name="network.facebook"
            minLength="10"
            maxLength="200"
            pattern={Constants.pattern.uri}
            value={network.facebook || ''}
            onChange={this.handleFacebook}
            required
            disabled={!hasRights || blocked}
          />
          <FormFeedback>
            {FrontValidation.ProjectMediaSocialNetwork.facebook}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="twitter">Twitter entreprise</Label>
          <small className="form-text text-muted mb-1 mt-0">
            {Tooltip.ProjectMediaSocialNetwork.twitter}
          </small>
          <InputCustom
            placeholder="Twitter entreprise"
            id="network.twitter"
            name="network.twitter"
            minLength="10"
            maxLength="200"
            pattern={Constants.pattern.uri}
            value={network.twitter || ''}
            onChange={this.handleTwitter}
            required
            disabled={!hasRights || blocked}
          />
          <FormFeedback>
            {FrontValidation.ProjectMediaSocialNetwork.twitter}
          </FormFeedback>
        </FormGroup>
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData,
  containerDocument: state.folder.getContainerDocument,
  activeContainer: state.container.activeContainer.id,
  isLoadingGetContainerDocument:
    state.folder.isLoadingGetContainerDocument,
  operatorEmail: state.container.activeContainer.operatorEmail
});

export default withRouter(
  connect(mapStateToProps)(MediaSocialNetwork)
);
