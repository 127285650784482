// @flow
import NetworkUtils from '../NetworkUtils';
import NetworkUrls from '../NetworkUrls';
import Constants from '../../../Resources/Common/Constants';

export default class LegalService {
  static getCgu() {
    return NetworkUtils.get({
      url: NetworkUrls.getCgu(Constants.LEGAL_TYPE_TOS)
    });
  }

  static acceptCgu() {
    const prerequisites = {
      ToS: true,
      risks: true
    };
    return NetworkUtils.patch({
      url: NetworkUrls.acceptCgu(),
      data: { prerequisites }
    });
  }
}
