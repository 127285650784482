// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Label, FormGroup, FormFeedback } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import 'moment/locale/fr';
import _ from 'lodash';
import update from 'immutability-helper';

import InputCustom from '../../../InputCustom';
import Tooltip from '../../../../../Resources/Common/Tooltip';
import { isImage } from '../../../../../Resources/Common/Utilities';
import MarkdownPreview from '../../../TextEditor/MarkdownPreview';
import FrontValidation from '../../../../../Resources/Common/FrontValidation';
import TruncateCustom from '../../../TruncateCustom';
import Constants from '../../../../../Resources/Common/Constants';

// Actions
import { getChangeContainerDocumentAction } from '../../../../../Business/actions/FolderActions';
import ButtonCustom from '../../../ButtonCustom';

type Props = {
  hasRights: boolean,
  blocked: boolean,
  index: number,
  changeProposalsData: Array<any>,
  containerDocument: Object,
  dispatch: Function,
  activeContainer: string,
  containerDocumentUuid: string,
  newElement: boolean,
  proposalUid: string
};

type State = {
  currentKind: 0 | 1,
  document: {
    authorPhoto: {
      url: string,
      text: string
    },
    companyLogo: {
      url: string,
      text: string
    }
  },
  firstName: string,
  lastName: string,
  company: string,
  job: string,
  authorPhoto: string,
  companyLogo: string,
  content: string,
  containerDocumentObject: Object,
  changeProposalsFiltered: Object,
  collapse: {
    firstName: boolean,
    lastName: boolean,
    company: boolean,
    job: boolean,
    authorPhoto: boolean,
    logo: boolean,
    content: boolean,
    containerDocumentObject: boolean,
    changeProposalsFiltered: boolean
  },
  collapseFeedback: boolean
};

const FormFieldDescription = ({
  label,
  className = '',
  collapse,
  handleCollapse
}: {
  label: string,
  className?: string,
  collapse: boolean,
  handleCollapse: Function
}) => (
  <small
    className={`form-text text-muted mb-1 mt-0 ${className || ''}`}>
    <TruncateCustom
      collapse={collapse[label]}
      tooltip={Tooltip.ProjectFeedback[label]}
      handleCollapse={handleCollapse([label])}
    />
  </small>
);

FormFieldDescription.defaultProps = { className: '' };

class FeedbackInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const changeProposalsFiltered = props.proposalUid
      ? props.changeProposalsData.filter(
          proposal => proposal.uuid === props.proposalUid
        )
      : props.changeProposalsData;
    this.state = props.newElement
      ? {
          firstName: '',
          lastName: '',
          company: '',
          job: '',
          authorPhoto: '',
          companyLogo: '',
          content: '',
          currentKind: 0,
          document: {
            authorPhoto: {
              url: '',
              text: ''
            },
            companyLogo: {
              url: '',
              text: ''
            }
          },
          containerDocumentObject: {}
        }
      : _.pick(
          _.defaults(
            _.get(
              changeProposalsFiltered,
              `[0].content.feedbacks[${props.index}]`
            ),
            {
              firstName: '',
              lastName: '',
              company: '',
              job: '',
              authorPhoto: '',
              companyLogo: '',
              content: ''
            }
          ),
          [
            'firstName',
            'lastName',
            'company',
            'job',
            'authorPhoto',
            'companyLogo',
            'content'
          ]
        );
    this.state.document = {
      authorPhoto: {
        text: '',
        url: ''
      },
      companyLogo: {
        text: '',
        url: ''
      }
    };
    this.state.containerDocumentObject = {
      authorPhoto: '',
      companyLogo: ''
    };
    this.state.changeProposalsFiltered = changeProposalsFiltered;
    this.state.collapse = {
      firstName: true,
      lastName: true,
      company: true,
      job: true,
      authorPhoto: true,
      logo: true,
      content: true,
      containerDocumentObject: true,
      changeProposalsFiltered: true
    };
    this.state.collapseFeedback = true;
  }

  componentDidMount = () => {
    const {
      dispatch,
      activeContainer,
      changeProposalsData,
      index,
      operatorEmail
    } = this.props;
    if (
      changeProposalsData[0] &&
      _.has(
        changeProposalsData[0],
        `content.feedbacks[${index}].authorPhoto`
      )
    ) {
      dispatch(
        getChangeContainerDocumentAction({
          containerId: activeContainer,
          docUuid: _.get(
            changeProposalsData,
            `[0].content.feedbacks[${index}].authorPhoto`
          ),
          operatorEmail
        })
      );
    }
    if (
      changeProposalsData[0] &&
      _.has(
        changeProposalsData[0],
        `content.feedbacks[${index}].companyLogo`
      )
    ) {
      dispatch(
        getChangeContainerDocumentAction({
          containerId: activeContainer,
          docUuid: _.get(
            changeProposalsData,
            `[0].content.feedbacks[${index}].companyLogo`
          ),
          operatorEmail
        })
      );
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      containerDocument,
      dispatch,
      activeContainer,
      changeProposalsData,
      index,
      operatorEmail
    } = this.props;
    const { containerDocumentObject } = this.state;
    if (
      containerDocument &&
      _.isPlainObject(containerDocument) &&
      !_.isEmpty(containerDocument) &&
      !_.isEqual(containerDocument, prevProps.containerDocument)
    ) {
      this.prepareDocuments();
    }
    if (
      containerDocumentObject &&
      !_.isEqual(
        containerDocumentObject,
        prevState.containerDocumentObject
      )
    ) {
      this.updateDocument(prevState);
    }
    if (
      changeProposalsData[0] &&
      _.has(changeProposalsData[0], `content`) &&
      !_.isEqual(changeProposalsData, prevProps.changeProposalsData)
    ) {
      dispatch(
        getChangeContainerDocumentAction({
          containerId: activeContainer,
          docUuid: _.get(
            changeProposalsData,
            `[0].content.feedbacks[${index}].authorPhoto`
          ),
          operatorEmail
        })
      );
    }
  };

  prepareDocuments = () => {
    const {
      containerDocument,
      index,
      containerDocumentUuid,
      changeProposalsData,
      newElement
    } = this.props;
    const { containerDocumentObject } = this.state;
    if (!newElement) {
      if (
        _.isPlainObject(containerDocument) &&
        !_.isEmpty(containerDocument) &&
        containerDocumentUuid ===
          _.get(
            changeProposalsData,
            `[0].content.feedbacks[${index}].authorPhoto`
          )
      ) {
        this.setState({
          containerDocumentObject: update(containerDocumentObject, {
            authorPhoto: {
              $set: containerDocument
            }
          })
        });
      }
      if (
        _.isPlainObject(containerDocument) &&
        !_.isEmpty(containerDocument) &&
        containerDocumentUuid ===
          _.get(
            changeProposalsData,
            `[0].content.feedbacks[${index}].companyLogo`
          )
      ) {
        this.setState({
          containerDocumentObject: update(containerDocumentObject, {
            companyLogo: {
              $set: containerDocument
            }
          })
        });
      }
    }
  };

  updateDocument = prevState => {
    const { newElement } = this.props;
    const { document, containerDocumentObject } = this.state;
    const { authorPhoto, companyLogo } = document;
    const prevAuthorPhoto =
      prevState.containerDocumentObject.authorPhoto;
    const prevCompanyLogo =
      prevState.containerDocumentObject.companyLogo;
    if (!newElement) {
      if (
        containerDocumentObject &&
        containerDocumentObject.authorPhoto &&
        !_.isEqual(
          containerDocumentObject.authorPhoto,
          prevAuthorPhoto
        )
      ) {
        this.setState({
          document: update(document, {
            authorPhoto: {
              $set: update(authorPhoto, {
                url: {
                  $set: containerDocumentObject.authorPhoto.url
                }
              })
            }
          })
        });
      }
      if (
        containerDocumentObject &&
        containerDocumentObject.companyLogo &&
        !_.isEqual(
          containerDocumentObject.companyLogo,
          prevCompanyLogo
        )
      ) {
        this.setState({
          document: update(document, {
            companyLogo: {
              $set: update(companyLogo, {
                url: {
                  $set: containerDocumentObject.companyLogo.url
                }
              })
            }
          })
        });
      }
    }
  };

  handleAuthorPhoto = () => e => {
    const { document } = this.state;
    const { authorPhoto } = document;
    e.persist();
    this.setState({
      document: update(document, {
        authorPhoto: {
          $set: update(authorPhoto, {
            text: {
              $set: e.target.value.replace(/C:\\fakepath\\/i, '')
            }
          })
        }
      })
    });
  };

  handleCompanyLogo = () => e => {
    const { document } = this.state;
    const { companyLogo } = document;
    e.persist();
    this.setState({
      document: update(document, {
        companyLogo: {
          $set: update(companyLogo, {
            text: {
              $set: e.target.value.replace(/C:\\fakepath\\/i, '')
            }
          })
        }
      })
    });
  };

  handleCollapse = tooltip => () => {
    const { collapse } = this.state;
    this.setState(prevState => ({
      collapse: {
        ...prevState.collapse,
        [tooltip]: !collapse[tooltip]
      }
    }));
  };

  handleCollapseFeedback = e => {
    e.preventDefault();
    const { collapseFeedback } = this.state;
    this.setState({ collapseFeedback: !collapseFeedback });
  };

  render() {
    const {
      index,
      hasRights,
      blocked,
      changeProposalsData
    } = this.props;
    const {
      document,
      documentRequired,
      content,
      collapse,
      collapseFeedback
    } = this.state;

    const infoTitles = {
      firstName: 'Prénom du commentateur',
      lastName: 'Nom du commentateur',
      job: 'Fonction / poste',
      company: 'Societé',
      authorPhoto: 'Photo du commentateur',
      companyLogo: 'Logo societé',
      content: 'Avis'
    };

    const textElements = ['firstName', 'lastName'];

    const textFormElements = textElements.map(textElement => {
      const { [textElement]: defaultValue } = this.state;
      return (
        <FormGroup key={textElement}>
          <Label for={`${textElement}_${index}`}>
            {infoTitles[textElement]}
          </Label>
          <FormFieldDescription
            label={textElement}
            collapse={collapse}
            handleCollapse={this.handleCollapse}
          />
          <InputCustom
            id={`${textElement}_${index}`}
            name={`feedbacks.[${index}].${textElement}`}
            defaultValue={defaultValue}
            disabled={!hasRights || blocked}
            minLength="1"
            maxLength="100"
            pattern={Constants.pattern.internationalName}
            required
          />
          <FormFeedback>
            {FrontValidation.ProjectFeedback[textElement]}
          </FormFeedback>
        </FormGroup>
      );
    });
    const textElementsBis = ['company', 'job'];

    const textFormElementsBis = textElementsBis.map(textElement => {
      const { [textElement]: defaultValue } = this.state;
      return (
        <FormGroup key={textElement}>
          <Label for={`${textElement}_${index}`}>
            {infoTitles[textElement]}
          </Label>
          <FormFieldDescription
            label={textElement}
            collapse={collapse}
            handleCollapse={this.handleCollapse}
          />
          <InputCustom
            id={`${textElement}_${index}`}
            name={`feedbacks.[${index}].${textElement}`}
            defaultValue={defaultValue}
            disabled={!hasRights || blocked}
            minLength="1"
            maxLength="100"
            pattern={Constants.pattern.internationalName}
            required
          />
          <FormFeedback>
            {FrontValidation.ProjectFeedback[textElement]}
          </FormFeedback>
        </FormGroup>
      );
    });

    return (
      <>
        {textFormElements}
        <div
          className={
            collapseFeedback
              ? 'collase_feedback d-none'
              : 'collase_feedback d-block'
          }>
          {textFormElementsBis}
          <Label for={`${index}_authorPhoto`}>
            {infoTitles.authorPhoto}
          </Label>
          <FormFieldDescription
            label="authorPhoto"
            collapse={collapse}
            handleCollapse={this.handleCollapse}
          />
          <FormGroup>
            <InputCustom
              type="file"
              className="custom-file-input"
              id={`authorPhoto${index}`}
              name={`document.${index}.authorPhoto`}
              onChange={this.handleAuthorPhoto()}
              disabled={!hasRights || blocked}
              required={documentRequired}
            />
            <Label
              className="custom-file-label"
              for={`document_${index}`}>
              {document.authorPhoto &&
              document.authorPhoto.text !== ''
                ? document.authorPhoto.text
                : 'Choisir un fichier'}
            </Label>
            <FormFeedback>
              {FrontValidation.documentFrontValidation}
            </FormFeedback>
            <div className="mt-3">
              {!document.authorPhoto && !document.authorPhoto.url ? (
                <div className="sweet-loading">
                  <ClipLoader
                    css="margin: 'O auto';"
                    sizeUnit="px"
                    loading={!document.authorPhoto.url}
                  />
                </div>
              ) : (
                <>
                  {document.authorPhoto.url && (
                    <>
                      {isImage(document.authorPhoto.url) ? (
                        <img
                          src={document.authorPhoto.url}
                          className="mw-100"
                          alt="document"
                        />
                      ) : (
                        <a href={document.authorPhoto.url}>
                          Télécharger le document
                        </a>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <InputCustom
              type="hidden"
              name={`feedbacks.[${index}].authorPhoto`}
              value={
                _.get(
                  changeProposalsData,
                  `[0].content.feedbacks.[${index}].authorPhoto`
                )
                  ? _.get(
                      changeProposalsData,
                      `[0].content.feedbacks.[${index}].authorPhoto`
                    )
                  : 'defaultValue'
              }
            />
          </FormGroup>
          <Label for={`${index}_companyLogo`}>
            {infoTitles.companyLogo}
          </Label>
          <FormFieldDescription
            label="logo"
            collapse={collapse}
            handleCollapse={this.handleCollapse}
          />
          <FormGroup>
            <InputCustom
              type="file"
              className="custom-file-input"
              id={`companyLogo${index}`}
              name={`document.${index}.companyLogo`}
              onChange={this.handleCompanyLogo()}
              disabled={!hasRights || blocked}
              required={documentRequired}
            />
            <Label
              className="custom-file-label"
              for={`document_${index}`}>
              {document.companyLogo &&
              document.companyLogo.text !== ''
                ? document.companyLogo.text
                : 'Choisir un fichier'}
            </Label>
            <FormFeedback>
              {FrontValidation.documentFrontValidation}
            </FormFeedback>
            <div className="mt-3">
              {!document.companyLogo && !document.companyLogo.url ? (
                <div className="sweet-loading">
                  <ClipLoader
                    css="margin: 'O auto';"
                    sizeUnit="px"
                    loading={!document.companyLogo.url}
                  />
                </div>
              ) : (
                <>
                  {document.companyLogo.url && (
                    <>
                      {isImage(document.companyLogo.url) ? (
                        <img
                          src={document.companyLogo.url}
                          className="mw-100"
                          alt="document"
                        />
                      ) : (
                        <a href={document.companyLogo.url}>
                          Télécharger le document
                        </a>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <InputCustom
              type="hidden"
              name={`feedbacks.[${index}].companyLogo`}
              value={
                _.get(
                  changeProposalsData,
                  `[0].content.feedbacks.[${index}].companyLogo`
                )
                  ? _.get(
                      changeProposalsData,
                      `[0].content.feedbacks.[${index}].companyLogo`
                    )
                  : 'defaultValue'
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for={`content_${index}`}>
              {infoTitles.content}
            </Label>
            <FormFieldDescription
              label="content"
              collapse={collapse}
              handleCollapse={this.handleCollapse}
            />
            <MarkdownPreview
              id={`content${index}`}
              name={`feedbacks.[${index}].content`}
              value={content}
              readOnly={!hasRights || blocked}
              minLength={20}
              maxLength={600}
              className={`markdown markdown_feedbacks.[${index}].content`}
            />
            <FormFeedback
              id={`validation_feedbacks.[${index}].content`}
              className="validation">
              {FrontValidation.ProjectFeedback.content}
            </FormFeedback>
          </FormGroup>
        </div>
        <ButtonCustom
          className="mb-3"
          onClick={this.handleCollapseFeedback}
          color="primary">
          {collapseFeedback
            ? "Afficher tout l'avis"
            : "Masquer l'avis"}
        </ButtonCustom>
      </>
    );
  }
}

const mapStateToProps = state => ({
  changeProposalsData: state.folder.getChangeProposalsData,
  containerDocument: state.folder.getContainerDocument,
  activeContainer: state.container.activeContainer.id,
  operatorEmail: state.container.activeContainer.operatorEmail,
  isLoadingGetContainerDocument:
    state.folder.isLoadingGetContainerDocument,
  containerDocumentUuid: state.folder.getContainerDocumentUuid
});

export default withRouter(connect(mapStateToProps)(FeedbackInfo));
