// @flow

import React from 'react';
import _ from 'lodash';

import ImageIcon from './image.svg';
import BoldIcon from './bold.svg';
import ItalicIcon from './italic.svg';
import QuoteIcon from './quote.svg';
import ErrorIcon from './error.svg';
import ListIcon from './list.svg';
import UrlIcon from './url.svg';
import OrderedListIcon from './orderedlist.svg';
import HelpIcon from './help.svg';
import T1Icon from './t1.svg';
import T2Icon from './t2.svg';

import './icons.scss';

type IconType =
  | 'image'
  | 'bold'
  | 'italic'
  | 'quote'
  | 'error'
  | 'h1'
  | 'h2'
  | 'h3'
  | 't1'
  | 't2';

const icons: { [IconType]: string } = {
  image: ImageIcon,
  bold: BoldIcon,
  italic: ItalicIcon,
  quote: QuoteIcon,
  error: ErrorIcon,
  list: ListIcon,
  orderedlist: OrderedListIcon,
  url: UrlIcon,
  help: HelpIcon,
  t1: T1Icon,
  t2: T2Icon
};

export default function Icon({ type }: { type: IconType }) {
  if (type[0] === 'h' && Number(type[1]) < 5) {
    return (
      <span className="icon">{type.substr(0, 2).toUpperCase()}</span>
    );
  }

  const realType = _.has(icons, type.toLowerCase())
    ? type.toLowerCase()
    : 'error';

  const icon = _.get(icons, realType);

  return (
    <img
      src={icon}
      alt={`icon-${realType}`}
      className="icon"
      style={{ width: '24px' }}
    />
  );
}
