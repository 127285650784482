// @flow

// React libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
// Components
import {
  Form,
  FormGroup,
  Label,
  FormFeedback,
  InputGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Template from '../../Components/Template/TemplateComponent';
import ButtonCustom from '../../Components/Components/ButtonCustom';
import AlertCustom from '../../Components/Components/AlertCustom';
import InputCustom from '../../Components/Components/InputCustom';
// Utils
import Messages from '../../Resources/Common/Messages';
import Routes from '../../Resources/Common/Routes';
import Constants from '../../Resources/Common/Constants';
// Actions
import { getVisibleObject } from '../../Resources/Common/Utilities';
import { postContainerInvitationAction } from '../../Business/actions/DashboardSettingsActions';

type State = {
  email: string,
  rights: Array<any>,
  validationError: string,
  visibleObject: {
    invitationError: boolean,
    validationError: boolean,
    invitationSuccess: boolean
  }
};
type Props = {
  activeContainer: string,
  dispatch: Function,
  invitationSuccess: string,
  invitationError: string,
  isLoadingPostContainerInvitation: boolean
};

class DashboardSettingsInvitationScene extends Component<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      rights: [],
      validationError: '',
      visibleObject: {
        invitationError: false,
        validationError: false,
        invitationSuccess: false
      }
    };
    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss = target => {
    this.setState(prevState => ({
      visibleObject: {
        ...prevState.visibleObject,
        [target]: false
      }
    }));
  };

  componentDidUpdate = (prevProps, prevState) => {
    this.setVisibleAlerts(prevProps, prevState);
  };

  displayAlert = () => {
    const {
      invitationError,
      invitationSuccess,
      activeContainer
    } = this.props;
    const { validationError, visibleObject } = this.state;
    const alert = [];
    let key = 0;

    if (validationError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="validationError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={validationError}
          visible={visibleObject.validationError}
        />
      );
    }
    if (invitationError) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="invitationError"
          color="danger"
          onDismiss={this.onDismiss}
          errorMessage={invitationError}
          visible={visibleObject.invitationError}
        />
      );
    }
    if (invitationSuccess) {
      key += 1;
      alert.push(
        <AlertCustom
          key={key}
          target="invitationSuccess"
          color="success"
          onDismiss={this.onDismiss}
          errorMessage={Messages.dashboardSettingsInvitationsSuccess(
            `/${Routes.routeDashboard}/${activeContainer}`
          )}
          visible={visibleObject.invitationSuccess}
        />
      );
    }
    return alert;
  };

  setVisibleAlerts = (prevProps, prevState) => {
    const errorArray = [
      'invitationError',
      'invitationSuccess',
      'validationError'
    ];
    const { props, state } = this;
    const { invitationError, invitationSuccess } = props;
    const { validationError } = state;
    if (
      invitationError !== prevProps.invitationError ||
      invitationSuccess !== prevProps.invitationSuccess ||
      validationError !== prevState.validationError
    ) {
      let newState = Object.assign({}, this.state);
      const visibleObject = getVisibleObject(
        props,
        prevProps,
        state,
        prevState,
        errorArray
      );
      newState = {
        ...newState,
        visibleObject: {
          ...newState.visibleObject,
          ...visibleObject
        }
      };
      if (
        Object.values(visibleObject).includes(true) &&
        !_.isEqual(newState, state)
      ) {
        this.setState(newState);
      }
    }
  };

  sendInvitation = e => {
    e.preventDefault();
    const { email, rights } = this.state;
    const { dispatch, activeContainer } = this.props;

    if (e.target.checkValidity()) {
      this.setState({ validationError: '' });
      if (activeContainer) {
        if (!rights.includes('ADMINISTRATION')) {
          rights.push(Constants.READING_RIGHT);
        }
        dispatch(
          postContainerInvitationAction(
            email,
            rights,
            activeContainer
          )
        );
      }
    } else {
      this.setState({ validationError: Messages.validationError });
      e.stopPropagation();
    }
    e.target.classList.add('was-validated');
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handleRightsChange = e => {
    let { rights } = this.state;
    if (e.target.checked) {
      rights.push(e.target.value);
    } else {
      rights = rights.filter(item => item !== e.target.value);
    }
    this.setState({ rights });
  };

  render() {
    const { isLoadingPostContainerInvitation } = this.props;
    return (
      <Template
        title="Paramètres"
        subtitle="Administrez les utilisateurs et leurs droits. Invitez de nouveaux membres."
        menu={Routes.routeDashboard}>
        {this.displayAlert()}
        <h2>Configurer les droits de l&apos;utilisateur</h2>
        <Form
          className="d-flex flex-column"
          id="avatar-form"
          onSubmit={this.sendInvitation}
          noValidate>
          <FormGroup>
            <Label for="email">Email</Label>
            <InputCustom
              id="email"
              name="email"
              type="email"
              onChange={this.handleEmailChange}
              required
            />
            <FormFeedback>
              {Messages.emailFrontValidation}
            </FormFeedback>
          </FormGroup>
          <p>Droits</p>
          <FormGroup className="d-flex flex-column">
            <InputGroup className="custom-control custom-checkbox">
              <InputCustom
                type="checkbox"
                id="administrateur"
                name="rights"
                className="custom-control-input"
                value="ADMINISTRATION"
                onChange={this.handleRightsChange}
              />
              <Label
                className="custom-control-label d-flex"
                for="administrateur">
                Administrateur
              </Label>
            </InputGroup>
            <InputGroup className="custom-control custom-checkbox">
              <InputCustom
                type="checkbox"
                id="informations"
                name="rights"
                className="custom-control-input"
                value="PROJECT_BASIC_INFO"
                onChange={this.handleRightsChange}
              />
              <Label
                className="custom-control-label  d-flex"
                for="informations">
                Informations
              </Label>
            </InputGroup>
            <InputGroup className="custom-control custom-checkbox">
              <InputCustom
                type="checkbox"
                id="offre"
                name="rights"
                className="custom-control-input"
                value="PROJECT_OFFER"
                onChange={this.handleRightsChange}
              />
              <Label
                className="custom-control-label  d-flex"
                for="offre">
                Offre
              </Label>
            </InputGroup>
            <InputGroup className="custom-control custom-checkbox">
              <InputCustom
                type="checkbox"
                id="media"
                name="rights"
                className="custom-control-input"
                value="PROJECT_MEDIA"
                onChange={this.handleRightsChange}
              />
              <Label
                className="custom-control-label  d-flex"
                for="media">
                Média
              </Label>
            </InputGroup>
            <InputGroup className="custom-control custom-checkbox">
              <InputCustom
                type="checkbox"
                id="finance"
                name="rights"
                className="custom-control-input"
                value="PROJECT_FINANCE"
                onChange={this.handleRightsChange}
              />
              <Label
                className="custom-control-label  d-flex"
                for="finance">
                Finance
              </Label>
            </InputGroup>
            <InputGroup className="custom-control custom-checkbox">
              <InputCustom
                type="checkbox"
                id="couriel"
                name="rights"
                className="custom-control-input"
                value="SEND_EMAIL"
                onChange={this.handleRightsChange}
              />
              <Label
                className="custom-control-label  d-flex"
                for="couriel">
                Email
              </Label>
            </InputGroup>
            <InputGroup className="custom-control custom-checkbox">
              <InputCustom
                type="checkbox"
                id="communication"
                name="rights"
                className="custom-control-input"
                value="SEND_COMMUNICATION"
                onChange={this.handleRightsChange}
              />
              <Label
                className="custom-control-label  d-flex"
                for="communication">
                Communication
              </Label>
            </InputGroup>
            <InputGroup className="custom-control custom-checkbox">
              <InputCustom
                type="checkbox"
                id="checklist"
                name="rights"
                className="custom-control-input"
                value="CHECKLIST_CALENDAR"
                onChange={this.handleRightsChange}
              />
              <Label
                className="custom-control-label d-flex"
                for="checklist">
                Checklist-Calendrier
              </Label>
            </InputGroup>
          </FormGroup>
          <ButtonCustom
            type="submit"
            color="primary"
            className="align-self-end"
            disabled={isLoadingPostContainerInvitation}>
            Envoyer l&apos;invitation
          </ButtonCustom>
        </Form>
      </Template>
    );
  }
}

const mapStateToProps = state => ({
  activeContainer: state.container.activeContainer.id,
  invitationError: state.dashboardSettings.invitationError,
  invitationSuccess: state.dashboardSettings.invitationSuccess,
  isLoadingPostContainerInvitation:
    state.dashboardSettings.isLoadingPostContainerInvitation,
  rights: state.dashboardSettings.rights,
  userRights: state.container.userRights.rights
});

export default connect(mapStateToProps)(
  DashboardSettingsInvitationScene
);
