// @flow

import NetworkUtils from '../NetworkUtils';
import NetworkUrls from '../NetworkUrls';

export default class ContainerService {
  static getContainers() {
    return NetworkUtils.get({
      url: NetworkUrls.getContainers()
    });
  }

  static getUserRights(containerId: string) {
    return NetworkUtils.get({
      url: NetworkUrls.getUserRights(containerId)
    });
  }
}
