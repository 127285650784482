// @flow

export default class LocalStorage {
  static get(key: string): any {
    const value: any = localStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  static set(key: string, value: any): void {
    if (value !== null) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  static reset(key: string, newValue: any): void {
    localStorage.set(key, newValue);
  }

  static delete(key: string): void {
    localStorage.removeItem(key);
  }
}
