// @flow

// React Libs
import { Component } from 'react';

type Props = {};
type State = {};

class Utilities extends Component<Props, State> {
  static manageMenuActiveItem(
    currentPath: Object,
    route: string
  ): boolean {
    let activeMenu: boolean = false;
    if (
      currentPath !== null &&
      typeof currentPath !== 'undefined' &&
      Object.prototype.hasOwnProperty.call(currentPath, 'pathname') &&
      currentPath.pathname !== null
    ) {
      const path = currentPath.pathname.substr(1);
      if (path.includes(route)) {
        activeMenu = true;
      } else {
        const pathArray = path.split('/');
        const firstStepPath = pathArray[0];
        if (firstStepPath === route) {
          activeMenu = true;
        }
      }
    }
    return activeMenu;
  }
}

export default Utilities;
