// @flow

import React from 'react';
import PropTypes from 'prop-types';
import FinancialData from './FinancialData';
import Tooltip from '../../../../../Resources/Common/Tooltip';

export default function Revenue({ hasRights, blocked, proposalUid }) {
  return (
    <>
      <FinancialData
        title="Chiffre d'affaires"
        tooltip={Tooltip.ProjectFinancialDataRevenue}
        fieldNames={{
          figures: "Chiffre d'affaires",
          comment: "Commentaire Chiffre d'affaires",
          highlight: "Valeur mise en avant Chiffre d'affaires",
          breakdown: "Graph Camembert Chiffre d'affaires"
        }}
        hasRights={hasRights}
        blocked={blocked}
        proposalUid={proposalUid}
      />
    </>
  );
}

Revenue.propTypes = {
  hasRights: PropTypes.bool.isRequired,
  blocked: PropTypes.bool.isRequired,
  proposalUid: PropTypes.string
};

Revenue.defaultProps = {
  proposalUid: undefined
};
