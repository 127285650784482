import Routes from './Routes';
import LocalStorage from '../../Layer/Storage/LocalStorage';

export default class Constants {
  static PENDING_STATUS = 'PENDING';

  static SUCCESS_STATUS = 'SUCCESS';

  static FAILURE_STATUS = 'FAILURE';

  static READING_RIGHT = 'READING';

  static USER_RIGHTS = {
    ADMINISTRATION: 'ADMINISTRATION',
    PROJECT_BASIC_INFO: 'PROJECT_BASIC_INFO',
    PROJECT_OFFER: 'PROJECT_OFFER',
    PROJECT_MEDIA: 'PROJECT_MEDIA',
    PROJECT_FINANCE: 'PROJECT_FINANCE',
    SEND_EMAIL: 'SEND_EMAIL',
    SEND_COMMUNICATION: 'SEND_COMMUNICATION',
    CHECKLIST_CALENDAR: 'CHECKLIST_CALENDAR',
    READING: Constants.READING_RIGHT
  };

  static USER_RIGHTS_TRAD = {
    ADMINISTRATION: 'Administration',
    PROJECT_BASIC_INFO: 'Edition Projet',
    PROJECT_OFFER: 'Edition Offre',
    PROJECT_MEDIA: 'Edition Média',
    PROJECT_FINANCE: 'Edition Finance',
    SEND_EMAIL: "Envoi d'Emails",
    SEND_COMMUNICATION: 'Edition Communication',
    CHECKLIST_CALENDAR: 'Edition Checkliste / Calendrier',
    READING: 'Lecture seule'
  };

  // Array of user rights - reading is not set cause it's implicite
  static RIGHTS_ARRAY = [
    // Need this to get the sorted order
    Constants.USER_RIGHTS.ADMINISTRATION,
    Constants.USER_RIGHTS.PROJECT_BASIC_INFO,
    Constants.USER_RIGHTS.PROJECT_OFFER,
    Constants.USER_RIGHTS.PROJECT_MEDIA,
    Constants.USER_RIGHTS.PROJECT_FINANCE,
    Constants.USER_RIGHTS.SEND_EMAIL,
    Constants.USER_RIGHTS.SEND_COMMUNICATION,
    Constants.USER_RIGHTS.CHECKLIST_CALENDAR
  ];

  static SECTION_RIGHTS = {
    'Informations basiques': 'PROJECT_BASIC_INFO',
    "L'offre": 'PROJECT_OFFER',
    Média: 'PROJECT_MEDIA',
    'Informations financières': 'PROJECT_FINANCE'
  };

  static sections = [
    'Informations basiques',
    "L'offre",
    'Média',
    'Informations financières'
  ];

  static STATUS_ARRAY = ['Prête', 'Archivée', 'Archivée', 'Consomée'];

  static LEGAL_TYPE_TOS = 0;

  static PROPOSAL_STATUS = {
    0: 'En brouillon',
    1: 'Soumise',
    2: 'Rejetée',
    3: 'Acceptée',
    4: 'Vide',
    DRAFT: 0,
    SUBMITTED: 1,
    REJECTED: 2,
    ACCEPTED: 3,
    EMPTY: 4
  };

  static SIDEBAR_VISIBLE_CLASS = 'sidebar-visible';

  static API_ERRORS = {
    ACCOUNT: {
      DOCUMENT_FORMAT_ERROR: 'account.documentFormatError'
    },
    CHANGE_CONTAINER: {
      NOT_FOUND: 'changecontainers.notFound',
      CALENDAR_NOT_FOUND: 'changecontainers.calendarNotFound',
      INSUFFICIENT_RIGHTS: 'changecontainers.userInsufficientRights',
      TODOLIST_NOT_FOUND: 'changecontainers.todoListNotFound',
      PROPOSAL_NOT_ALLOWED: 'changecontainers.proposalNotAllowed',
      DOCUMENT_FORMAT_ERROR: 'changecontainers.documentFormatError',
      DOCUMENT_NOT_FOUND: 'changecontainers.documentNotFound'
    },
    JWT: {
      INVALID: 'jwt.invalid',
      MUST_BE_LOGGED: 'jwt.mustBeLogged',
      MUST_NOT_BE_LOGGED: 'jwt.mustNotBeLogged',
      TOO_MANY_REQUESTS: 'jwt.tooManyRequests',
      WAS_CLEARED: 'jwt.wasCleared'
    },
    USER: {
      INVALID_CREDENTIALS: 'user.invalidCredentials',
      MUST_ACCEPT_PREREQUISITES: 'user.mustAcceptPrerequisites',
      TOO_MANY_REQUEST: 'user.tooManyRequests',
      FORBIDDEN: 'user.forbiddenAccess',
      FROZEN: 'user.frozenAccount',
      PASSWORD_LINK_EXPIRED: 'user.passwordLinkExpired',
      MANDATORY_INVITATIONS: 'user.mandatoryInvitation',
      ALREADY_ENROLLED: 'user.alreadyEnrolled'
    },
    MALFORMED: 'error.malformed',
    GENERAL: {
      FORBIDDEN: 'general.forbidden',
      NOT_FOUND: 'general.notFound',
      UNKNOWN_ERROR: 'general.unknownError',
      INTERNAL_SERVER_ERROR: 'general.internalServerError'
    },
    ERROR: {
      MISSING: 'error.missing'
    }
  };

  static paginateLogNumber = 20;

  static paginateDashboardNumber = 10;

  static moderationStrings = {
    0: 'Incomplet',
    1: 'A resumer',
    2: 'Mauvais wording',
    3: 'Mauvais contenu',
    4: 'Mauvaise catégorie',
    5: 'Autre'
  };

  static redirectUri = `${LocalStorage.get('GLOBAL.localhost')}/${
    Routes.routeSocialLogin
  }`;

  static generateRandomString(stringLength) {
    let randomString = '';
    let randomAscii;
    for (let i = 0; i < stringLength; i += 1) {
      randomAscii = Math.floor(Math.random() * 25 + 97);
      randomString += String.fromCharCode(randomAscii);
      LocalStorage.set('oauthState', randomString);
    }
    return randomString;
  }

  static facebookUri = `https://www.facebook.com/v2.8/dialog/oauth?client_id=${LocalStorage.get(
    'GLOBAL.facebookClientId'
  )}&redirect_uri=${
    Constants.redirectUri
  }/facebook&state=${Constants.generateRandomString(
    15
  )}&response_type=code&scope=public_profile,email&auth_type=rerequest`;

  static googleUri = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${LocalStorage.get(
    'GLOBAL.googleClientId'
  )}&redirect_uri=${
    Constants.redirectUri
  }/google&state=${Constants.generateRandomString(
    15
  )}&response_type=code&scope=openid%20email%20profile`;

  static linkedinUri = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LocalStorage.get(
    'GLOBAL.linkedinClientId'
  )}&redirect_uri=${
    Constants.redirectUri
  }/linkedin&state=${Constants.generateRandomString(
    15
  )}&scope=r_basicprofile%20r_emailaddress`;

  static tootltipNbLines = 5;

  static pattern = {
    // prettier-ignore
    // eslint-disable-next-line
    internationalName: "^['\\w\\s\\-\\d\\u00C0-\\u00D6\\u00D8-\\u00F6\\u00F8-\\u02FF\\u0370-\\u037D\\u037F-\\u1FFF\\u200C-\\u200D\\u2070-\\u218F\\u2C00-\\u2FEF\\u3001-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFFD\\u0300-\\u036F]+$",
    password: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,50}$',
    zipCode: '[A-Za-z0-9\\s\\-]{1,10}',
    siret: '[A-Za-z0-9]{14,14}',
    structure: "^[\\w\\-'\\s]{3,30}$",
    country: '[A-Z]{2,2}',
    uri: '^(https?://)(.*)?$'
  };
}
