// @flow

import {
  AUTH_SET_TOKEN,
  GET_TOKEN_VALIDITY,
  DISCONNECT_TOKEN,
  CLEAR_ALERT_MESSAGE
} from '../actions/TokenActions';
import Common from '../../Resources/Common';

const defaultState = {
  accountError: '',
  accountInfos: {
    avatar: '',
    email: '',
    firstName: '',
    lastName: ''
  },
  isLoading: false,
  isValidToken: false,
  needAcceptToS: undefined,
  token: '',
  tokenError: ''
};

const TokenReducer = (
  state: Object = defaultState,
  action: Object = {}
) => {
  switch (action.type) {
    case AUTH_SET_TOKEN:
      return Object.assign({}, state, {
        token: action.token,
        iat: new Date(),
        isValidToken: true
      });
    case GET_TOKEN_VALIDITY:
      return Object.assign({}, state, {
        isValidToken: action.payload.isValidToken,
        tokenError: action.payload.error,
        isLoading:
          action.payload.status === Common.Constants.PENDING_STATUS
      });
    case DISCONNECT_TOKEN:
      return {
        ...state,
        ...defaultState,
        isValidToken: false,
        alertMessage: action.payload.alertMessage || ''
      };
    case CLEAR_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: undefined
      };
    default:
      return state;
  }
};

export default TokenReducer;
