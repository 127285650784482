// @flow

import React from 'react';
import PropTypes from 'prop-types';
import FinancialData from './FinancialData';
import Tooltip from '../../../../../Resources/Common/Tooltip';

export default function Ebitda({ hasRights, blocked, proposalUid }) {
  return (
    <>
      <FinancialData
        title="EBE"
        tooltip={Tooltip.ProjectFinancialDataEbitda}
        fieldNames={{
          figures: 'EBE',
          comment: 'Commentaire EBE',
          highlight: 'Valeur mise en avant EBE',
          breakdown: 'Graph Camembert EBE'
        }}
        hasRights={hasRights}
        blocked={blocked}
        proposalUid={proposalUid}
      />
    </>
  );
}

Ebitda.propTypes = {
  hasRights: PropTypes.bool.isRequired,
  blocked: PropTypes.bool.isRequired,
  proposalUid: PropTypes.string
};

Ebitda.defaultProps = {
  proposalUid: undefined
};
