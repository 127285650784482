// @flow

// React libs
import React, { Component } from 'react';
import type { Element } from 'react';
// Boostrap components
import { Alert } from 'reactstrap';

type Props = {
  color: string,
  errorMessage: string | Element<*>,
  target: string,
  onDismiss: Function,
  visible: boolean
};

class AlertCustom extends Component<Props> {
  timeoutId: TimeoutID | null = null;

  componentDidMount = () => {
    const { target, onDismiss } = this.props;
    window.scrollTo(0, 0);
    this.timeoutId = setTimeout(() => {
      onDismiss(target);
    }, 5000);
  };

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  render() {
    const {
      color,
      errorMessage,
      onDismiss,
      target,
      visible
    } = this.props;
    if (!visible) {
      return '';
    }
    return (
      <Alert
        color={color}
        toggle={() => {
          onDismiss(target);
        }}
        isOpen={visible}>
        <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
      </Alert>
    );
  }
}

export default AlertCustom;
