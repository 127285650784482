// @flow

// Utils
import NetworkUrls from '../NetworkUrls';
import NetworkUtils from '../NetworkUtils';

export default class FolderService {
  static getContainerChange(uuid: string) {
    return NetworkUtils.get({
      url: NetworkUrls.getContainerChange(uuid)
    });
  }

  static getChangeProposals({
    containerId,
    kind,
    status
  }: {
    containerId: string,
    kind: string | typeof undefined,
    status: number | typeof undefined
  }) {
    return NetworkUtils.get({
      url: NetworkUrls.getChangeProposals({
        containerId,
        status,
        kind
      })
    });
  }

  static postChangeProposals({
    containerId,
    kind,
    content,
    status
  }: {
    containerId: string,
    content: Object,
    kind: string | typeof undefined,
    status: number | typeof undefined
  }) {
    return NetworkUtils.post({
      url: NetworkUrls.postChangeProposals(containerId),
      data: {
        status,
        kind,
        content
      }
    });
  }

  static getContextManifest() {
    return NetworkUtils.get({
      url: NetworkUrls.getContextManifest()
    });
  }

  static postChangeContainerDocument({
    containerId,
    file
  }: {
    containerId: string,
    file: FormData
  }) {
    return NetworkUtils.post({
      url: NetworkUrls.postChangeContainerDocument(containerId),
      data: { file }
    });
  }

  static getChangeContainerDocument({
    containerId,
    docUuid
  }: {
    containerId: string,
    docUuid: string
  }) {
    return NetworkUtils.get({
      url: NetworkUrls.getChangeContainerDocument(
        containerId,
        docUuid
      )
    });
  }
}
