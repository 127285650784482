import { combineReducers } from 'redux';
import LoginReducer from './LoginReducer';
import accountInfosReducer from './AccountInfosReducer';
import AccountSecurityReducer from './AccountSecurityReducer';
import TokenReducer from './TokenReducer';
import DashboardSettingsReducer from './DashboardSettingsReducer';
import DashboardCalendarReducer from './DashboardCalendarReducer';
import DashboardChecklistReducer from './DashboardChecklistReducer';
import FolderReducer from './FolderReducer';
import ContainerReducer from './ContainerReducer';
import LegalReducer from './LegalReducer';
import UserReducer from './UserReducer';

export default combineReducers({
  account: accountInfosReducer,
  accountSecurity: AccountSecurityReducer,
  login: LoginReducer,
  token: TokenReducer,
  dashboardSettings: DashboardSettingsReducer,
  dashboardCalendar: DashboardCalendarReducer,
  dashboardChecklist: DashboardChecklistReducer,
  folder: FolderReducer,
  container: ContainerReducer,
  legal: LegalReducer,
  user: UserReducer
});
