// @flow

// React libs
import _ from 'lodash/fp';
import { store } from '../../UI/AppScene';

// Token
import {
  SetTokenAction,
  logoutTokenAction
} from '../../Business/actions/TokenActions';
import { didNotAcceptCgu } from '../../Business/actions/LegalActions';
import Messages from '../../Resources/Common/Messages';
import Constants from '../../Resources/Common/Constants';

const ERRORS = Constants.API_ERRORS;

type MethodRequest = {
  url: string,
  headers?: { [string]: string },
  data?: any
};

type RequestInput = MethodRequest & {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
};

export default class NetworkUtils {
  static wasJwtCleared(response: Response): boolean {
    const clearedJwtHeader = 'x-jwt-clear';
    const FORCED = 'forced';
    return (
      response.headers.has(clearedJwtHeader) &&
      response.headers.get(clearedJwtHeader) === FORCED
    );
  }

  static isJson(response) {
    return /json/.test(response.headers.get('content-type') || '');
  }

  static async handleCommonError(response: Response): Promise<any> {
    const isResponseJson = NetworkUtils.isJson(response);
    if (response.ok && isResponseJson) {
      return response.json();
    }

    const bodyContent = isResponseJson ? await response.json() : {};

    if (NetworkUtils.wasJwtCleared(response)) {
      bodyContent.code = ERRORS.JWT.WAS_CLEARED;
    }

    if (
      response.status === 400 &&
      Array.isArray(bodyContent) &&
      bodyContent.some(_.has('code'))
    ) {
      bodyContent.code = ERRORS.MALFORMED;
      bodyContent.pointers = [
        ...new Set(bodyContent.map(e => e.pointer))
      ];
      bodyContent.extra = [...new Set(bodyContent.map(e => e.extra))];
    }

    if (!_.has('code', bodyContent)) {
      const bodyContentCodes = {
        '400': ERRORS.GENERAL.UNKNOWN_ERROR,
        '403': ERRORS.GENERAL.FORBIDDEN,
        '429': ERRORS.JWT.TOO_MANY_REQUESTS,
        '500': ERRORS.GENERAL.INTERNAL_SERVER_ERROR
      };

      if (
        !Object.keys(bodyContentCodes)
          .map(Number)
          .includes(response.status)
      ) {
        if (isResponseJson) {
          return response.json();
        }
        return Promise.resolve({});
      }

      bodyContent.code = bodyContentCodes[response.status];
    }

    let error = new Error(bodyContent.code);
    if (bodyContent.pointers) {
      error.pointers = bodyContent.pointers;
    }
    if (bodyContent.extra) {
      error.extra = bodyContent.extra;
    }

    switch (bodyContent.code) {
      case ERRORS.JWT.MUST_BE_LOGGED:
      case ERRORS.JWT.WAS_CLEARED:
      case ERRORS.JWT.INVALID:
        store.dispatch(
          logoutTokenAction({ alert: Messages.shouldBeLoggedError })
        );
        break;
      case ERRORS.USER.MUST_ACCEPT_PREREQUISITES:
        store.dispatch(didNotAcceptCgu());
        break;
      case ERRORS.GENERAL.FORBIDDEN:
        error = new Error('FORBIDDEN');
        break;
      case ERRORS.GENERAL.UNKNOWN_ERROR:
        error = new Error('UNKNOWN');
        break;
      case ERRORS.GENERAL.INTERNAL_SERVER_ERROR:
        error = new Error('INTERNAL');
        break;
      case ERRORS.GENERAL.NOT_FOUND:
        error = new Error('NOT_FOUND');
        break;
      case ERRORS.JWT.TOO_MANY_REQUESTS:
      case ERRORS.USER.TOO_MANY_REQUEST:
        /*
          // TODO: to implement
          store.dispatch(lockedForTooManyRequests({
            time: bodyContent.extra.timeremaining
          }));
          break;
        */
        error.retryAfter = response.headers.get('Retry-After');
        break;
      case ERRORS.JWT.MUST_NOT_BE_LOGGED:
      case ERRORS.USER.INVALID_CREDENTIALS:
        // TODO: implement later
        break;
      default:
        break;
    }
    throw error;
  }

  static request({
    url,
    method,
    data = {},
    headers = {}
  }: RequestInput = {}): Promise<Response> {
    const {
      token: { token }
    } = store.getState();

    const headersToSend = new Headers(
      token ? { ...headers, authorization: token } : headers
    );

    let /* final */ request: Request;
    if (['GET', 'HEAD'].includes(method)) {
      // we don't have a body for GET or HEAD requests
      request = new Request(url, { method, headers: headersToSend });
    } else if (
      data instanceof FormData ||
      (data.file && data.file instanceof FormData)
    ) {
      // we nedd empty headers for form-data
      const headersFormData = new Headers(
        token && { authorization: token }
      );
      request = new Request(url, {
        method,
        headers: headersFormData,
        body: data instanceof FormData ? data : data.file
      });
    } else if (
      (Array.isArray(data) || _.isPlainObject(data)) &&
      !_.isEmpty(data)
    ) {
      headersToSend.append('content-type', 'application/json');
      request = new Request(url, {
        method,
        headers: headersToSend,
        body: JSON.stringify(data)
      });
    } else {
      request = new Request(url, {
        method,
        headers: headersToSend,
        body: data
      });
    }

    return fetch(request).then(response => {
      if (response.headers.has('authorization')) {
        store.dispatch(
          SetTokenAction(response.headers.get('authorization'))
        );
      }
      return NetworkUtils.handleCommonError(response);
    });
  }

  static get({
    url,
    headers = {}
  }: { url: string, headers?: Object } = {}): Promise<Response> {
    return NetworkUtils.request({ url, headers, method: 'GET' });
  }

  static post({
    url,
    headers = {},
    data = {}
  }: MethodRequest = {}): Promise<Response> {
    return NetworkUtils.request({
      url,
      headers,
      data,
      method: 'POST'
    });
  }

  static patch({
    url,
    headers = {},
    data = {}
  }: MethodRequest = {}): Promise<Response> {
    return NetworkUtils.request({
      url,
      headers,
      data,
      method: 'PATCH'
    });
  }

  static delete({
    url,
    data = {},
    headers = {}
  }: MethodRequest = {}): Promise<Response> {
    return NetworkUtils.request({
      url,
      data,
      headers,
      method: 'DELETE'
    });
  }

  static put({
    url,
    data = {},
    headers = {}
  }: MethodRequest = {}): Promise<Response> {
    return NetworkUtils.request({
      url,
      data,
      headers,
      method: 'PUT'
    });
  }

  static handleErrors(response: any) {
    if (
      !response ||
      !response.status ||
      (response.status > 299 && response.status !== 403)
    ) {
      return true;
    }
    return false;
  }

  static handleUnlogged(response: any) {
    if (response.status === 401) {
      response.json().then(json => {
        if (json.code === ERRORS.JWT.MUST_BE_LOGGED) {
          store.dispatch(logoutTokenAction());
        }
      });
    }
  }
}
