// @flow
// React Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Components
import {
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
// Assets
import logo from '../../../Resources/assets/img/main/logo.png';
// Common data
import Common from '../../../Resources/Common';
// Actions
import { logoutAction } from '../../../Business/actions/LoginActions';
import { setSidebarToggleAction } from '../../../Business/actions/ContainerActions';
// Utilities
import Utilities from './Utilities';
import ButtonCustom from '../ButtonCustom';

type Props = {
  activeContainer: { id: string, name: string },
  containers: Array<any>,
  toggler?: boolean,
  location: Object,
  dispatch: Function,
  firstname: string,
  lastname: string,
  type: string,
  sidebarToggle: boolean
};

type State = { dropdownOpen: boolean };

class Menu extends Component<Props, State> {
  static defaultProps = {
    toggler: false
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  renderLogoLink = () => {
    const { activeContainer, containers } = this.props;
    const linkLogo =
      Array.isArray(containers) &&
      containers.length === 1 &&
      activeContainer.id
        ? `/${Common.Routes.routeDashboard}/${activeContainer.id}`
        : `/${Common.Routes.routeChoseDashboard}`;

    return (
      <Link to={linkLogo}>
        <img src={logo} height="27" alt="logo" />
      </Link>
    );
  };

  disconnect = e => {
    const { dispatch } = this.props;
    if (e.target.id === 'disconnect') {
      dispatch(logoutAction());
    }
  };

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  toggleSidebar = () => {
    const { dispatch, sidebarToggle } = this.props;
    dispatch(
      setSidebarToggleAction(
        sidebarToggle !== undefined ? !sidebarToggle : false
      )
    );
  };

  render() {
    const {
      activeContainer,
      location,
      firstname,
      type,
      toggler
    } = this.props;
    let { lastname } = this.props;
    const { dropdownOpen } = this.state;
    lastname = lastname && lastname.charAt(0);
    return (
      <Navbar
        className="menu navbar-expand-lg navbar-expand-md navbar-expand-sm navbar-light bg-white border-bottom position-fixed w-100"
        style={{
          zIndex: 98
        }}>
        {toggler && (
          <ButtonCustom
            className="d-md-none mr-3"
            onClick={() => {
              this.toggleSidebar();
            }}>
            <span>
              <i className="fas fa-bars fa-1x" />
            </span>
          </ButtonCustom>
        )}
        {this.renderLogoLink()}
        {type !== 'logoutMenu' && (
          <div className="justify-content-start">
            <>
              {activeContainer.name && (
                <span className="navbar-text ml-3 text-muted">
                  {` | ${activeContainer.name}`}
                </span>
              )}
            </>
          </div>
        )}
        <div className="navbar-collapse justify-content-end">
          <Nav navbar className="text-center">
            {type !== 'logoutMenu' && activeContainer.id && (
              <>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to={`/${Common.Routes.routeDashboard}/${
                      activeContainer.id
                    }`}
                    active={Utilities.manageMenuActiveItem(
                      location,
                      Common.Routes.routeDashboard
                    )}>
                    Dashboard
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to={`/${Common.Routes.routeFolder}/${
                      activeContainer.id
                    }`}
                    active={Utilities.manageMenuActiveItem(
                      location,
                      Common.Routes.routeFolder
                    )}>
                    Dossier
                  </NavLink>
                </NavItem>
              </>
            )}
            <Dropdown
              inNavbar
              isOpen={dropdownOpen}
              toggle={this.toggle}>
              <DropdownToggle
                nav
                caret
                className={
                  Utilities.manageMenuActiveItem(
                    location,
                    Common.Routes.routeAccount
                  )
                    ? 'active'
                    : 'not_active'
                }>
                {`${firstname} ${lastname}`}
              </DropdownToggle>
              <DropdownMenu right>
                <Nav navbar className="text-center flex-column">
                  <NavItem>
                    <DropdownItem
                      tag={Link}
                      to={`/${Common.Routes.routeInfosAccount}`}>
                      Mon Compte
                    </DropdownItem>
                  </NavItem>

                  <DropdownItem divider />
                  <NavItem>
                    <DropdownItem
                      className="text-uppercase"
                      id="disconnect"
                      onClick={e => {
                        this.disconnect(e);
                      }}>
                      Me déconnecter
                    </DropdownItem>
                  </NavItem>
                </Nav>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  activeContainer: state.container.activeContainer,
  avatar: state.account.accountInfos.avatar,
  containers: state.container.containers,
  patchAvatarError: state.account.patchAvatarError,
  firstname: state.account.accountInfos.firstName,
  lastname: state.account.accountInfos.lastName,
  sidebarToggle: state.container.sidebarToggle
});

export default withRouter(connect(mapStateToProps)(Menu));
